import { Component } from 'react'
import { Link } from "react-router-dom";
import {API_HOST} from '../../settings.config'
import SSILogo from '../../styles/images/logo/ssi-logo.png'
import '../../styles/custom/forgot-password.css'
const axios = require('axios').default;
const regExp = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            isError:''
        }
        
      }
      formValChange = e =>{
        e.preventDefault()
        var val = e.currentTarget.value
        if(!regExp.test(val)){
            var invalid_email = 'Email address is invalid'
            this.setState({
                isError : invalid_email
            })
        }else{
            this.setState({
                isError : ''
            })
        }

      }
    
      handleSubmit(event) {  
        event.preventDefault();        
        const username =  document.getElementById("username").value
        let is_valid_email = true
        let invalid_email = ''
        if(username){
            if(!regExp.test(username)){
                invalid_email = 'Email address is invalid'
                is_valid_email = false
            }
        }else{
             invalid_email =  'Please enter your Email address'
             is_valid_email = false
        }
        if(is_valid_email){        
            document.getElementById("recover-btn").disabled = true;
            document.getElementById("username").disabled = true;
            document.getElementById("error_msg").innerText = ''
            document.getElementById("success_msg").innerText = ''
            
            const form_data = 'username='+username
            axios.get(API_HOST+'/cp/user/password/reset/',{
            params: {
                'email': username
            }
            }).then(function (response) {
            if(response.status == 200){ 
                var success = response.data.success
                if(success){
                    var message = response.data.message
                    document.getElementById("success_msg").appendChild(document.createTextNode(message));
                }else{
                    var message = response.data.message
                    document.getElementById("error_msg").appendChild(document.createTextNode(message));
                    document.getElementById("recover-btn").disabled = false;
                    document.getElementById("username").disabled = false;
                }
                setTimeout(() => {
                    document.getElementById("error_msg").innerText = '';
                    document.getElementById("success_msg").innerText = '';
                }, 10000); 
            }
            })
            .catch(function (response) {
            var error_msg = response.response.data.non_field_errors[0]
            document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
                document.getElementById("success_msg").innerText = '';
            }, 10000);  
            document.getElementById("recover-btn").disabled = false;
            document.getElementById("username").disabled = false;
            }); 
        }else{
            this.setState({
                isError:invalid_email
            })
        }
      }
    render() {
        return (
            <div className="authentication backgroud-forgot forgotpwd-container">
            <div className="sign-in">
                <div className="row no-mrg-horizon">
                    <div className="col-md-8 no-pdd-horizon d-none d-md-block">
                        <div className="full-height bg video-container" >

                            <video autoPlay="autoplay" loop="loop" muted="muted" src="https://www.ssiaeration.com/wp-content/themes/ssi/images/front-page.mp4" poster=""></video>

                            <div className="img-caption">
                                <h1 className="caption-title">SMART IDEAS FOR WATER</h1>
                                <p className="caption-text">SSI Aeration fine bubble diffuser systems and products are installed on five continents at thousands of job sites. Millions of our diffusers are hard at work in wastewater treatments worldwide. SSI has received multiple US and International patents for inventions and industry improvements.</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 no-pdd-horizon">
                            <div className="login-header">
                                <img className="img-responsive ssi-logo inline-block" src={SSILogo} />
                            </div>
                        <div className="full-height bg-white height-100">
                            <div className="vertical-align full-height pdd-horizon-70">
                                <div className="table-cell"> 
                                <div className="pdd-horizon-15" >
                                    <form  noValidate onSubmit={this.handleSubmit}>
                                        <p id="error_msg" className="text-danger"></p>
                                        <p id="success_msg" className="text-success"></p>
                                            <div className="form-group">
                                                <label>E-Mail</label>
                                                <input 
                                                type="email" 
                                                className={this.state.isError.length > 0 ? "is-invalid form-control" : "form-control"}  
                                                placeholder="Email" 
                                                name="username"
                                                id="username"
                                                onChange={this.formValChange}
                                                 />
                                                {this.state.isError.length > 0 && (
                                                    <span className="invalid-feedback" style={{display:"block"}}>{this.state.isError}</span>
                                                )}
                                            </div>
                                            <button className="btn btn-info recover-btn" id="recover-btn" >Recover</button>
                                        </form>  
                                </div>                                            
                                    
                                </div>
                            </div>
                            <div className="login-footer">                                
                                <span className="font-size-13 pull-right">Already have an account? <Link to="/login">Sign In</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
