import  { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'
import {API_HOST} from '../../settings.config.js'
import signOut from '../../js/CommonFunctions'
import ErrorHandling from '../../js/CommonFunctions'
import NavProfileName from './NavProfileName'
import CartItemsCount from '../../js/CartItemsCount'
import NotificationCount from '../Notifications/NotificationCount'
import {Helmet} from "react-helmet";
const axios = require('axios').default;
class CategorySearchItemsData extends Component{
    render(){
        var div_style = {'padding':'10px','borderBottom':'1px solid #e6ecf5','cursor':'pointer' }
        var height_style = {'maxHeight':'300px','height':'auto'}  
        var table_results = this.props.searchResult.map((item,index) =>{
            return(   
                // <Link to={{
                //     pathname:'/items',
                //     state : {params:{category_id:item.category_id, category_name:item.category_name,search_input:this.props.search_text}}
                // }}
                //  data-from="search"
                //  style={text_color}>
                //     <div className={index == 0?"order-item":"order-item"} style={div_style}>                            
                //             <span className="item-name">{item.category_name}</span>
                //     </div>
                // </Link>  
                <form  action="/items/" method='post' onClick={this.props.formSubmit} data-from="search" key={index}>
                    <input type="hidden" className="category" name="categoryid" value={item.category_id} category-name={item.category_name}/>
                    <input type="hidden" className="search_input" name="search_input" value={this.props.search_text}/>
                    <div className={index == 0?"order-item":"order-item"} style={div_style}>                            
                            <span className="item-name">{item.category_name}</span>
                    </div>
                </form>
            )
        })
        var items_data = this.props.items_list.map((item,index) =>{
            return( 
                // <Link to={{
                //     pathname:'/items',
                //     state : {params:{search_input:this.props.search_text}}
                //     }} data-from="search"
                //     style={text_color}
                //     >
                //     <div className={index == 0?"order-item":"order-item"} style={div_style}>                            
                //             <span className="item-name">{item.item.item_number}</span>
                //     </div>
                // </Link>    
                <form  action="/items/" method='post' key={index} onClick={this.props.formSubmit} data-from="search">
                    <input type="hidden" className="search_input" name="search_input" value={this.props.search_text}/>
                    <div className={index == 0?"order-item":"order-item"} style={div_style}>                            
                            <span className="item-name">{item.item.item_number}</span>
                    </div>
                </form>
            )
        })
        return(
            <div className="card alert search-result main-search-result">  
                <div  className="auto-complete-table" style={height_style} >
                        {table_results}
                        {items_data}
                </div>         
            
        </div>
        )
    }

}

class TopNavBar extends Component {
    constructor(props){
        super(props)
        this.categories = []
        this.items_list = JSON.parse(localStorage.getItem('items'))?JSON.parse(localStorage.getItem('items')):[]
        this.sendRequest = 1
        this.requestCount = 0
        this.state = {
            items:[],
            search_text:localStorage.getItem('search_term')?localStorage.getItem('search_term'):'',
            from_item:false,
            count:0,
            show:false,
            mobile_show:false,
            search_result:[],
            append_category:true,
            from_browse:false,
            search_items_result:[]

        }
        this.ContentHandler = this.ContentHandler.bind(this)    
    }

    componentDidMount(){
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/items/categories/hierarchy/list/',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.categories){
                var json_data = response.data.categories
              }else{
                  var json_data = []
              }
            this.setState(()=>{
                return {
                    items: json_data                           
                }
             })            
          }
  }).catch(response =>{
    ErrorHandling(response)
  });

  setInterval(()=>{ 
    this.updateItemsList()
    }, 20000);    
     
    
	// $('.side-nav-toggle').on('click', function(e) {
    //     $('.app').toggleClass("is-collapsed");
    //     e.preventDefault();
    // });
  }

  updateItemsList = () =>{    
    this.items_list = JSON.parse(localStorage.getItem('items'))?JSON.parse(localStorage.getItem('items')):[]
 
  }
  
  componentDidUpdate = () =>{
    if(this.props.location.pathname == '/create/order/'){
        $('.search-box').hide()
        $('.save-button').show()        
    }else{
    $('.search-box').show() 
    $('.save-button').hide()
    }
    if(this.props.location.pathname == '/items'){
        $('.search_input').val(localStorage.getItem('search_term'))
    }else{
        localStorage.setItem('category', JSON.stringify({}))
        localStorage.setItem('search_term', '')
        $('.search_input').val('')                 
    }
    if(this.props.location.page){
        if(this.props.location.page.load){
            this.setState({
                search_text:''
            })
        }}
    window.onpopstate = e => { 
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open')
    }        
    // if('{{ category_id }}'){
    //     $('#category-dropdown').val('{{ category_id }}').trigger('change')
    //  }else{
    //     $('#category-dropdown').val('all').trigger('change')
    //  }
  
    //  localStorage.setItem('country_code','{{country_code}}')
     /*if(this.state.search_text){
        
     }else{
        $('.search_input').val('{{ search_text }}')  
     }*/
        
    $('#category-dropdown').selectpicker('refresh')
    $('#sub-category > form > div > div.input-group-append.category-dropdown > div > button').addClass('custom-dropdown-toggle')
   
    if(window.purechatApi){        
        window.purechatApi.on('chatbox:ready', function() {
            window.purechatApi.set('visitor.name', localStorage.getItem('first_name'));  
            window.purechatApi.set('visitor.firstName', localStorage.getItem('first_name')); // Sets the visitor first name to "Joe" 
            window.purechatApi.set('visitor.lastName', localStorage.getItem('last_name')); // Sets the visitor last name to "Doe" 
            window.purechatApi.set('visitor.email', localStorage.getItem('email')); // Sets the visitor's email address to "joe@joesmarket.com" 
            window.purechatApi.set('visitor.phoneNumber', ''); // Sets the visitor's phone number "6025551234" 
            window.purechatApi.set('visitor.company', localStorage.getItem('company')); // Sets the visitor's company "Pure Chat" 
        });
}
}
  onChangeHandle = (event) => {
      var this_val = event.currentTarget.value
      var view_type = $(event.currentTarget).attr('view-type')
      var search_result = this.categories.filter(function(item,indx){
          if(item.category_name.toLocaleLowerCase().includes(this_val.toLocaleLowerCase())){
              return item
          }
      })
      var items_data = this.items_list
      var items = items_data.items || []
    var search_items_result = []
    if(this_val){
      search_items_result = items.filter(function(item,indx){
          if( item.item.name.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) ||
              item.item.item_number.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) ||
              item.item.description.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) )
                        {
                            return item
                        }
                    })
    }
     localStorage.setItem('search_term',this_val)
     this.setState({
         search_text:this_val,
         search_result:search_result,
         show: view_type == 'desktop'?true:false,
         mobile_show:view_type == 'desktop'?false:true,  
         append_category:false,
         from_item:false,
         search_items_result:search_items_result
     })
  }

  onFocusCategory = (event) => {
    var view_type = $(event.currentTarget).attr('view-type')
    var this_val = localStorage.getItem('search_term')
    var categories = this.categories
    var items =  this.items_list.items?this.items_list.items:[]
    if(categories.length == 0){
        categories = []
        items = []
    }
    var search_result = categories
    var search_items_result = []//items
    if(this_val){
      search_result = categories.filter(function(item,indx){
        if(item.category_name.toLocaleLowerCase().includes(this_val.toLocaleLowerCase())){
            return item
        }
    })
     search_items_result = items.filter(function(item,indx){
        if( item.item.name.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) ||
            item.item.item_number.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) ||
            item.item.description.toLocaleLowerCase().includes(this_val.toLocaleLowerCase()) )
                      {
                          return item
                      }
     })
    }
    this.setState({
        search_result:search_result,
        show: view_type == 'desktop'?true:false,
        mobile_show:view_type == 'desktop'?false:true,
        append_category:false,
        from_item:false,
        search_items_result: search_items_result
    })

  }
  onFocusOutCategory = () => {
      setTimeout(()=>{
        this.onhideSearchResult()
      },500)
  }
   
  onhideSearchResult = () => {
      if(this.state.show || this.state.mobile_show){
      this.setState({
          show:false,
          from_item:false,
          mobile_show:false,
          //search_text: this.props.location.pathname == '/items'?this.state.search_text:''
      })
    }
  }

  formSubmit = (event) => {
        event.preventDefault()
        var req_from = $(event.currentTarget).attr('data-btn')
        var category = JSON.parse(localStorage.getItem('category'))
        var search_type = ''
        if(req_from == "searchBtn"){
          var search_active_div = $('.main-search-result').find('div.order-item.active')
          if(search_active_div[0]){
            var category_id = $(search_active_div).closest('form').find('input.category').val()
            var category_name = $(search_active_div).closest('form').find('input.category').attr('category-name')
            if(category_id && category_name){
                if(category){
                    category[category_id] = category_name        
                }else{
                    var category = {}
                    category[category_id] = category_name             
                }                
                this.state.search_text =''
                localStorage.setItem('search_term', '')
                search_type = category_name
            }else{
                var search_text = $(search_active_div).find('span.item-name').text()
                if(!search_text){
                    search_text = $(search_active_div).closest('form').find('input.search_input').val()
                }
                this.state.search_text = search_text
                localStorage.setItem('search_term', search_text)
                search_type = search_text
            }
          }else{
           var category_data = $(event.currentTarget).find('input.btn-category').val()
           var category = JSON.parse(category_data)
          }
          
        }else{
            var category_id = $(event.currentTarget).find('input.category').val()
            var category_name = $(event.currentTarget).find('input.category').attr('category-name')
            if(category_id && category_name){
                if(category){
                    category[category_id] = category_name        
                }else{
                    var category = {}
                    category[category_id] = category_name             
                }                
                //this.state.search_text =''
                //localStorage.setItem('search_term', '')
                search_type = category_name
            }else{
                var search_text = $(event.currentTarget).find('div.order-item').find('span').text()
                if(!search_text){
                    search_text = $(event.currentTarget).find('input.search_input').val()
                }
                this.state.search_text = search_text
                localStorage.setItem('search_term', search_text)
                search_type = search_text
            }
        }        
        localStorage.setItem('category', JSON.stringify(category))
        localStorage.setItem('search_term', this.state.search_text)
        var search_decode = btoa(search_type)
        this.props.history.push({
            pathname:'/items',
            search:'?s='+search_decode,
            state:null
        })
    
    
  }

  toogleSidenav = (e) =>{
    e.preventDefault()
    $('.app').toggleClass("is-collapsed");
  }
  
  ContentHandler(items_list){  
    if(items_list.childs != null) {
        var this_len = items_list.childs.length
        var null_childs = items_list.childs.filter(function(val,ind){if(val.childs == null){return val}else{} })
        if(this_len == null_childs.length){
            var sub_child_cate = items_list.childs.map((item,index)=>{
                if(this.state.append_category){
                var sub_cat_dict = {}
                sub_cat_dict['category_id'] = item.id
                sub_cat_dict['category_name'] = item.name       
                this.categories.push(sub_cat_dict)
                }
                return(
                    <li key={index}>
                         
                        <form  action="/items/" method='post' onClick={this.formSubmit}>
                            <input type="hidden" className="category" name="categoryid" value={item.id} category-name={item.name}/>
                            <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                            <a className="dropdown-item" href="#" category-name={item.name} category-id={item.id} >{item.name}</a>
                        </form>
                    </li>
                    /*<div className="item-sub-cate">
                        <span className="pdd-right-10">{item.name}</span>
                    </div> */
                )

            })
            //var sub_cate = <div className="dropdown-content-1 ssub-cate-items">{sub_child_cate}</div>
            var sub_cate = <ul className="custom-dropdown-content">{sub_child_cate}</ul>
             
        }else{
        var sub_child_cate = items_list.childs.map((item,index)=>{
            if(item.childs != null) {
                var content = this.ContentHandler(item)
                if(this.state.append_category){
                var sub_cat_dict = {}
                sub_cat_dict['category_id'] = item.id
                sub_cat_dict['category_name'] = item.name       
                this.categories.push(sub_cat_dict)
                }
                return(
                    <li className="custom-dropdown-header" key={index}>
                        {/* <Link to={{
                            pathname :'/items',
                            state :{params:{category_id:item.id, category_name:item.name,search_input:this.state.search_text}}
                        }} className="dropdown-item custom-dropdown-toggle">
                            {item.name}
                        </Link> */}
                        <form  action="/items/" method='post' onClick={this.formSubmit}>
                            <input type="hidden" className="category" name="categoryid" value={item.id} category-name={item.name}/>
                            <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                        <a className="dropdown-item custom-dropdown-toggle" href="#" category-name={item.name} category-id={item.id} >{item.name}</a>
                        </form>
                        {content}
                    </li>
                    /*<div className="item-sub-cate"><span className="pdd-right-10">{item.name}</span><span> <i className="fas fa-angle-right float-right pdd-top-5"></i></span>
                        {content}
                    </div> */
                )
            } else {
                if(this.state.append_category){
                var sub_cat_dict = {}
                sub_cat_dict['category_id'] = item.id
                sub_cat_dict['category_name'] = item.name       
                this.categories.push(sub_cat_dict)
                }
                return( 
                    <li key={index}>
                        <form  action="/items/" method='post' onClick={this.formSubmit}>
                            <input type="hidden" className="category" name="categoryid" value={item.id} category-name={item.name}/>
                            <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                        <a className="dropdown-item" href="#" category-name={item.name} category-id={item.id} >{item.name}</a>
                        </form>
                        {/* <Link to={{
                            pathname :'/items',
                            state :{params:{category_id:item.id, category_name:item.name,search_input:this.state.search_text}}
                        }} className="dropdown-item">
                            {item.name}
                        </Link> */}
                    </li>                       
                    //<div className="item-sub-cate"><span className="pdd-right-10">{item.name}</span></div> 
                    )
            }
        })
        //var sub_cate = <div className="dropdown-content-1 ssub-cate-items">{sub_child_cate}</div>
        var sub_cate = <ul className="custom-dropdown-content">{sub_child_cate}</ul>
    }
        
    }else{
        /*var sub_cate = <div className="dropdown-content-1">
                <div className="first"><span className="pdd-right-10">{items_list.name}</span><span> <i className="fas fa-angle-right float-right pdd-top-5"></i></span></div> 
                </div>*/
     if(this.state.append_category){
        var sub_cat_dict = {}
        sub_cat_dict['category_id'] = items_list.id
        sub_cat_dict['category_name'] = items_list.name       
        this.categories.push(sub_cat_dict)
     }
        var sub_cate = <ul className="custom-dropdown-content"><li>
            <form  action="/items/" method='post' onClick={this.formSubmit}>
                <input type="hidden" className="category" name="categoryid" value={items_list.id} category-name={items_list.name}/>
                <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                <a className="dropdown-item" href="#" category-name={items_list.name} category-id={items_list.id} >{items_list.name}</a>
            </form>
            {/* <Link to={{
                pathname:'/items',
                state :{params:{category_id:items_list.id, category_name:items_list.name,search_input:this.state.search_text}}
            }} className="dropdown-item">
                {items_list.name}
            </Link> */}
        </li> </ul>
    }    
    
    return sub_cate

}  

HandleKeyUp = (e) =>{
    e.preventDefault()
    if (e.keyCode == 40) {
        if ($(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').length != 0) {
            $(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').removeClass('active').closest('form').next('form').find('div.order-item').addClass('active')

        } else {
            $(e.target).closest('form.search-from').find('div.auto-complete-table').find('form').first().find('div.order-item').addClass('active')
        }
        var container =$(e.target).closest('form.search-from').find('div.auto-complete-table')
        var scrollTo = $('div.auto-complete-table>form>div.active');
        if (scrollTo.offset()) {
        container.animate({
            scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
        }, 150);
      }
    }
    else if (e.keyCode == 38) {
        if ($(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').length != 0) {
            var above_tr = $(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').removeClass('active').closest('form').prev('form').find('div.order-item')
            above_tr.addClass('active')

        } else {
            $(e.target).closest('form.search-from').find('div.auto-complete-table').find('form').last().find('div.order-item').addClass('active')
        }
        var container = $(e.target).closest('form.search-from').find('div.auto-complete-table'),
                scrollTo =$('div.auto-complete-table>form>div.active');
        if (scrollTo.offset()) {
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
            }, 150);
        }
    }else if (e.keyCode == 13) {
        if ($(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').length != 0) {
            $(e.target).closest('form.search-from').find('div.auto-complete-table').find('div.active').trigger('click')
        }
    }else if(e.keyCode == 27){
        this.onhideSearchResult()
    }

    }   

          
    render() {            
        var a_cursor = {'cursor': 'pointer'}
        var all_category = this.state.items.map((item,index) =>{
            if(item.childs != null){
             var sub_cate_list = item.childs.map((sub_item,sub_index)=>{
                 if(sub_item.childs != null){
                     var main_sub_cate_item = this.ContentHandler(sub_item)
                     if(this.state.append_category){
                        var sub_cat_dict = {}
                        sub_cat_dict['category_id'] = sub_item.id
                        sub_cat_dict['category_name'] = sub_item.name       
                        this.categories.push(sub_cat_dict)
                     }
                    return(
                        <li className="custom-dropdown-header" key={sub_index}>
                            <form  action="/items/" method='post' onClick={this.formSubmit}>
                                <input type="hidden" className="category" name="categoryid" value={sub_item.id} category-name={sub_item.name}/>
                                <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                                <a className="dropdown-item custom-dropdown-toggle" href="#" category-name={sub_item.name} category-id={sub_item.id} >{sub_item.name}</a>
                            </form>
                            {/* <Link to={{
                                pathname:'/items',
                                state:{params:{category_id:sub_item.id, category_name:sub_item.name,search_input:this.state.search_text}}
                            }} className="dropdown-item custom-dropdown-toggle">
                                {sub_item.name}
                            </Link> */}
                            
                            {main_sub_cate_item}</li>
                        //<div className="sub-cate-item"><span className="pdd-right-10">{sub_item.name}</span><span> <i className="fas fa-angle-right float-right pdd-top-5"></i></span>{main_sub_cate_item}</div>
                    )
                 }else{
                    if(this.state.append_category){
                        var sub_cat_dict = {}
                        sub_cat_dict['category_id'] = sub_item.id
                        sub_cat_dict['category_name'] = sub_item.name       
                        this.categories.push(sub_cat_dict)
                    }
                    return(
                    <li key={sub_index}>
                        <form  action="/items/" method='post' onClick={this.formSubmit}>
                            <input type="hidden" className="category" name="categoryid" value={sub_item.id} category-name={sub_item.name}/>
                            <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                            <a className="dropdown-item" href="#" category-name={sub_item.name} category-id={sub_item.id} >{sub_item.name}</a>
                        </form>
                        {/* <Link to={{
                                pathname:'/items',
                                state:{params:{category_id:sub_item.id, category_name:sub_item.name,search_input:this.state.search_text}}
                        }} className="dropdown-item">
                            {sub_item.name}
                        </Link> */}
                    </li>
                    //<div className="sub-cate-item"><span className="pdd-right-10">{sub_item.name}</span></div> 
                    ) 
                }               
             })
             //var sub_cate_main_div = <div className="dropdown-content-1 sub_cate ">{sub_cate_list}</div>
             //var sub_cate_main_div = <ul className="dropdown-menu">{sub_cate_list}</ul>
             if(this.state.append_category){
                var sub_cat_dict = {}
                sub_cat_dict['category_id'] = item.id
                sub_cat_dict['category_name'] = item.name       
                this.categories.push(sub_cat_dict)
             }
             var parent_sub_menu = <li className="custom-dropdown-header" key={index}>
                                    <form  action="/items/" method='post' onClick={this.formSubmit}>
                                        <input type="hidden" className="category" name="categoryid" value={item.id} category-name={item.name}/>
                                        <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                                        <a className="dropdown-item custom-dropdown-toggle" href="#!" category-name={item.name} category-id={item.id} >
                                            {item.name}</a>
                                    </form>
                                    {/* <Link to={{
                                        pathname:'/items',
                                        state:{params:{category_id:item.id, category_name:item.name,search_input:this.state.search_text}}
                                    }} className="dropdown-item custom-dropdown-toggle">
                                        {item.name}
                                    </Link> */}
                                        <ul className="custom-dropdown-content">
                                        {sub_cate_list}
                                        </ul>
                                    </li>
            }else{
                if(this.state.append_category){
                    var sub_cat_dict = {}
                    sub_cat_dict['category_id'] = item.id
                    sub_cat_dict['category_name'] = item.name       
                    this.categories.push(sub_cat_dict)
                }
                var parent_sub_menu = <li key={index}>
                    <form  action="/items/" method='post' onClick={this.formSubmit}>
                        <input type="hidden" className="category" name="categoryid" value={item.id} category-name={item.name}/>
                        <input type="hidden" className="search_input" name="search_input" value={this.state.search_text}/>
                    <a className="dropdown-item" href="#" category-name={item.name} category-id={item.id} >{item.name}</a>
                    </form>
                    {/* <Link to={{
                        pathname :'/items',
                        state : {params:{category_id:item.id, category_name:item.name, search_input:this.state.search_text }}
                    }} className="dropdown-item">
                        {item.name}
                    </Link> */}
                </li>
                //var sub_cate_main_div =''
            }
            return(
                parent_sub_menu
                /*<li className="dropdown-submenu"><a className="dropdown-item custom-dropdown-toggle" href="#!">{item.name}</a>
                    {sub_cate_main_div}
                </li>
                <div className="parent-category"><span className="pdd-right-10">{item.name}</span><span> <i className="fas fa-angle-right float-right pdd-top-5"></i></span>
                
                </div>*/
            )
        })



        var urls_obj = {
            '/create/order/':'Place Order', '/cart':'Cart','/items':'Parts Catalog',
            '/quotations':'Quotations','/orders':'Orders','/notifications':'Notifications',
            '/account':'Account','/change/password':'Change Password'
        } 
        return (            
            //{/* <!-- Header START --> */}
            <div className="header navbar">
                <Helmet>
                <script type='text/javascript'>
                    {`
                    window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } };
                    (function () { 
                        var done = false; var script = document.createElement('script');
                         script.async = true; script.type = 'text/javascript'; 
                         script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; 
                         document.getElementsByTagName('HEAD').item(0).appendChild(script);
                         script.onreadystatechange = script.onload = function (e) {
                            if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { 
                                var w = new PCWidget({c: '3111fc65-69b5-4e5b-ab6d-ebbeb83f138d', f: true }); 
                                done = true; } }; 
                        console.log(purechatApi)
                    })();
                    `}                    
                </script>

                </Helmet>
                <div className="header-container">
                    <ul className="nav-left">
                        <li>
                            <a className="side-nav-toggle" onClick={this.toogleSidenav} href={void(0)} style={a_cursor}>
                                <i className="ti-view-grid"></i>
                            </a>
                        </li>
                        <li className="" id="content-header">
                            <Link to={{
                                pathname :this.props.location.pathname,
                                page : {load:true},
                            }} className="search-toggle no-pdd-right">
                                <i className=" content-header search-icon  pdd-right-10" id="page-header">{urls_obj[this.props.location.pathname]}</i>
                            </Link>

                        </li>
                        <li className="sub-category" id="sub-category">
                        <form  action="/items/" className="search-from" method='post' onSubmit={this.formSubmit} data-btn="searchBtn">
                                <div className="input-group search-bar">                
                                    <div className="input-group-append category-dropdown ">
                                        <ul className="navbar-nav" onMouseOver={this.onhideSearchResult}>
                                            <li className="custom-dropdown-header">
                                            <a className="nav-link custom-dropdown-toggle " href="#!">
                                                    <span className="title-category">Browse</span>                                       
                                            </a>
                                            <ul className="custom-dropdown-content root-dropdown">
                                            {all_category}  
                                            </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <input type="text" className="form-control search_input" view-type="desktop" autoComplete="off" name="search_input" value={this.state.search_text} onChange={this.onChangeHandle} onKeyUp={this.HandleKeyUp} onFocus={this.onFocusCategory} onBlur={this.onFocusOutCategory}  placeholder="Search Items" />
                                    <input type="hidden" className="btn-category" name="categoryid" value={localStorage.getItem('category')}/>
                                    <div className="input-group-append">
                                    <button className="btn btn-info search-btn" type="submit" >
                                        <i className="fa fa-search"></i>
                                    </button>
                                    </div>                
                                </div>  
                                {this.state.show?<CategorySearchItemsData  formSubmit={this.formSubmit} search_text={this.state.search_text}  searchResult={this.state.search_result} items_list={this.state.search_items_result} />:null}
                            </form>
                        </li>
                        {/* {% comment %}
                        {% if request.path == '/create/order/' %}
                        {% else %}
                        <li className="">
                            <a href="{% url 'customer_portal:create_order' %}">Create Order</a>
                        </li>
                        {% endif %}
                        {% if request.path == '/quotations/' %}
                        {% else %}
                        <li className="">
                            <a href="{% url 'customer_portal:quotations' %}">View Quotes</a>
                        </li>
                        {% endif %}
                        {% if request.path == '/items/' %}
                        {% else %}
                        <li className="">
                            <a href="{% url 'customer_portal:items' %}">View Parts Catalog</a>
                        </li>
                        {% endif %}
                        {% endcomment %} */}
                    </ul>
                    <ul className="nav-right">
                        <li className="user-profile dropdown" style={{'height':'65px'}}>
                            <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                {/* <!-- <img className="profile-img img-fluid" src="" alt=""> --> */}
                                <div className="user-info">
                                    <span className="profile-name" id="username">
                                        <NavProfileName/>
                                    </span>
                                    {/* <!-- <i className="ti-angle-down font-size-10"></i> --> */}
                                </div>
                            </a>
                            <ul className="dropdown-menu user-details">
                                <li>
                                    <Link to="/account">
                                        <i className="ti-user pdd-right-10"></i>
                                        <span>Account</span>
                                    </Link>                                    
                                </li>
                                <li role="separator" className="divider"></li>
                                <li>
                                    <Link to="/change/password">
                                        <i className="ti-lock pdd-right-10"></i>
                                        <span>Change Password</span>
                                    </Link>                                    
                                </li>
                                {/* <!-- <li>
                                        <a href="">
                                            <i className="ti-email pdd-right-10"></i>
                                            <span>Inbox</span>
                                        </a>
                                    </li> --> */}
                                <li role="separator" className="divider"></li>
                                <li>
                                    <a href={void(0)} id="logout" style={a_cursor} onClick={signOut}>
                                        <i className="ti-power-off pdd-right-10"></i>
                                        <span>Sign Out</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="notifications dropdown">
                            <a href="" className="dropdown-toggle btn-icon btn-flat btn-rounded" data-toggle="dropdown">
                                <span id="notification_counter"></span>
                                <i className="ti-bell"></i>
                            </a>
                            <ul className="dropdown-menu" id="notification_menu">
                                <NotificationCount/>
                            </ul>
                        </li>
                        <li className="shopping-cart" id="shopping_cart">
                            <span id="cart_count" className="cart-count">
                             <CartItemsCount/>
                            </span>
                            <Link to="/cart" className="btn-icon btn-flat btn-rounded">
                                <i className="ti-shopping-cart"></i>
                            </Link>                            
                        </li>
                        {this.props.history.location.pathname == '/create/order'?null:
                        <li className="search-box" id="search_box" >
                            <Link  to="/create/order/" >
                                <span className="hide-on-mobile">Create</span>
                                <span className="show-font-on-mobile fa fa-plus-circle">
                                    </span> Order
                            </Link>
                        </li>

                        }
                        
                        <li className="save-button" id="save_button"></li>
                        {/* <!-- <li>
                            <a className="side-panel-toggle" href="javascript:void(0);">
                                <i className="ti-align-right"></i>
                            </a>
                        </li> --> */}
                    </ul>
                    <ul className="search-contatiner" id="search-contatiner">
                        <form  action="/items/" className="search-from" method='post' onSubmit={this.formSubmit} data-btn="searchBtn">
                                <div className="input-group search-bar">                
                                    <div className="input-group-append category-dropdown ">
                                        <ul className="navbar-nav" onMouseOver={this.onhideSearchResult}>
                                            <li className="custom-dropdown-header">
                                            <a className="nav-link custom-dropdown-toggle " href="#!">
                                                    <span className="title-category">Browse</span>                                       
                                            </a>
                                            <ul className="custom-dropdown-content root-dropdown">
                                            {/* {all_category}   */}
                                            </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <input type="text" className="form-control search_input" view-type="mobile"  autoComplete="off" name="search_input" value={this.state.search_text} onChange={this.onChangeHandle} onKeyUp={this.HandleKeyUp} onFocus={this.onFocusCategory} onBlur={this.onFocusOutCategory}  placeholder="Search Items" />
                                    <input type="hidden" className="btn-category" name="categoryid" value={localStorage.getItem('category')}/>
                                    <div className="input-group-append">
                                    <button className="btn btn-info search-btn" type="submit" >
                                        <i className="fa fa-search"></i>
                                    </button>
                                    </div>                
                                </div>  
                                {this.state.mobile_show?<CategorySearchItemsData  formSubmit={this.formSubmit} search_text={this.state.search_text}  searchResult={this.state.search_result} items_list={this.state.search_items_result} />:null}
                            </form>
                    </ul>
                    <ul className="page-action-buttons" id="page-action-buttons">
                        <li className="save-button" id="mobile-save_button">
                        </li>
                    </ul>
                </div>
            </div>
            // <!-- Header END -->           

        )
    }
}

export default withRouter(TopNavBar)
