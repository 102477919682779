import { Component } from 'react'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import {notify, ErrorHandling} from '../../js/CommonFunctions'
import '../../styles/custom/pagination.css'
const axios = require('axios').default;

const defaultProps = {
    initialPage: 1
}

class Pagination extends Component {
    constructor(props) {
    super(props);
    this.state = { pager: {} };
    }

    componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
    }

    setPage(page) {
        var items = this.props.items;
        var pager = this.state.pager;
        var quotesCount = this.props.quotescount

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        pager = this.getPager(quotesCount, page);
        var pageOfItems = items;
        this.setState({ pager: pager });
        this.props.onChangePage(pageOfItems);
    }
    getCurrentPageItems(page){
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/attachments/?is_uploaded_from_cp='+this.props.is_upload_from+'&page='+page,
            ).then(response => {
            if(response.status == 200){  
                var json_data = response.data.attchments
                var items = json_data;
                var pager = this.state.pager;
                if (page < 1 || page > pager.totalPages) {
                    return;
                }
                pager = this.getPager(response.data.attachments_count, page);
                var pageOfItems = items;
                this.setState({ pager: pager });
                this.props.onChangePage(pageOfItems);                      
            }
            }).catch(response => {
                ErrorHandling(response)
          });
    }

getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || this.props.page_size;
    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages <= this.props.page_size) {
    startPage = 1;
    endPage = totalPages;
    } else {
    if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
    } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
    }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
    };
}

render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
    // don't display pager if there is only 1 page
    return null;
    }

    return (
            <ul className="pagination">
              {pager.currentPage === 1?null:<li className="">
                  <a onClick={() => this.getCurrentPageItems(1)}>First</a>
              </li>}
              {pager.currentPage === 1 ?null:<li className= ''>
                  <a onClick={() => this.getCurrentPageItems(pager.currentPage - 1)}>Previous</a>
              </li>}
              {pager.pages.map((page, index) =>
                  <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                      <a onClick={() => this.getCurrentPageItems(page)}>{page}</a>
                  </li>
              )}
              {pager.currentPage === pager.totalPages ?null:<li className=''>
                  <a onClick={() => this.getCurrentPageItems(pager.currentPage + 1)}>Next</a>
              </li>}
              {pager.currentPage === pager.totalPages?null:
              <li className=''>
                  <a onClick={() => this.getCurrentPageItems(pager.totalPages)}>Last</a>
              </li>}
            </ul>
            );
        }
}

Pagination.defaultProps = defaultProps;



export default class Documents extends Component {
    constructor(){
        super()
        this.state={
            document: [],
            attachment_count:0,
            is_uploaded_from_cp:true,
            page_size:10
        }        
    }        
    componentDidMount(){        
       this.getDocumentData() 
          
    }
    getDocumentData = () =>{
        $('.attachment-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/attachments/?is_uploaded_from_cp='+true,
        ).then(response => {
          if(response.status == 200){ 
            var attachment_data = []
            var attachments_count = 0
            if(response.data.attchments){
                var attachment_data = response.data.attchments
                var attachments_count = response.data.attachments_count
            }
            this.setState(()=>{
                return {
                    document: attachment_data,
                    attachments_count:attachments_count,
                    page_attachments:attachment_data
                }
             })   
             $(".lds-ripple-parent").fadeOut("slow");  
             $('.attachment-card').find('.inline-loader').fadeOut("slow");          
            }
        }).catch(response => {
            ErrorHandling(response)
        });   

    }
    onChangePage = (pageOfItems) =>{
        // update state with new page of items
        this.setState({ document: pageOfItems });
    }
    handleSubmit = (event) =>{
        event.preventDefault();
        $(".lds-ripple-parent-1").fadeIn();    
        var formData = new FormData();
        formData.append('name',$('#file_name').val());
        if(document.querySelector('#file_attachment').files[0]){
        formData.append('file',document.querySelector('#file_attachment').files[0]);
        // formData.append('csrfmiddlewaretoken','{{csrf_token}}');
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token 
        axios({
            method: 'post', 
            url: API_HOST+'/cp/user/attachments/',
            data:formData,
            headers: { 
              "Content-Type": "multipart/form-data",
            }
          }).then(response => {
          if(response.status == 200 && response.data.success){ 
            notify('Document saved successfully.','success') 
            setTimeout(()=>{
                this.getDocumentData()
                document.getElementById('attachment-form').reset()
                $('.upload-button').show()
                $('.selected-button').text('').hide()
                $(".lds-ripple-parent-1").fadeOut('slow');  
            },1000)
             
          }else{
              if(response.data.errors.name){
                $('#file_name_error').html(response.data.errors.name[0])
              }else if(response.data.errors.file){
                $('#file_error').html(response.data.errors.name[0]) 
              }
              setTimeout(() => {
                $('#file_name_error').html('') 
                $('#file_error').html('')
            }, 10000);
            $(".lds-ripple-parent-1").fadeOut('slow');  
            }
            
        }).catch(response => {
            ErrorHandling(response)
        });   
        }else{
            $('#file_error').html('This field may not be blank') 
            setTimeout(() => {
                $('#file_error').html('')
            }, 10000);
            $(".lds-ripple-parent-1").fadeOut('slow');
        }     

    }
    checkFileSize = (event) =>{
        event.preventDefault()
        if (event.currentTarget.files.length) {
            if (event.currentTarget.files[0].size > 10000000) {
                event.currentTarget.value = ''
                notify('Please choose file size less than 10MB.','warning')
                $(event.currentTarget).siblings('button.upload-button').show()
                $(event.currentTarget).siblings('button.selected-button').hide()
            } else {
                var file_name = event.currentTarget.files[0].name
                $(event.currentTarget).siblings('button.upload-button').hide()
                $(event.currentTarget).siblings('button.selected-button').show().text(file_name)
            }
        } else {
            $(event.currentTarget).siblings('button.upload-button').show()
            $(event.currentTarget).siblings('button.selected-button').hide()
        }
    }
    getSsiAttachment = (event) =>{
        // ReactDOM.render(<PageLoader/>,document.getElementById('items_loader'))
        //$("#ask-4").addClass("setheight");
        $('.attachment-card').find('.inline-loader').fadeIn()
        var is_uploaded_from_cp=true
        $('.document-tfoot').show()
        if($(event.currentTarget).is(':checked')){
            is_uploaded_from_cp=false
            $('.document-tfoot').hide()
        }
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/attachments/?is_uploaded_from_cp='+is_uploaded_from_cp,
        ).then(response => {
          if(response.status == 200){ 
              $("#ask-4").removeClass("setheight");
            var attachment_data = []
            var attachments_count = 0
            if(response.data.attchments){
                var attachment_data = response.data.attchments
                var attachments_count = response.data.attachments_count
            }
            this.setState(()=>{
                return {
                    document: attachment_data,
                    page_attachments:attachment_data,
                    attachments_count:attachments_count,
                    is_uploaded_from_cp:is_uploaded_from_cp
                }
             })   
             $(".lds-ripple-parent").fadeOut("slow");  
             $('.attachment-card').find('.inline-loader').fadeOut('slow')          
            }
        }).catch(response => {
            ErrorHandling(response)
        });  

    }
    handleDelete = (event) =>{
        event.preventDefault()
        if(window.confirm('Are you sure, You want to remove this Document? ')){
        const doc_id = event.currentTarget.id
        const index = event.currentTarget.getAttribute('data-index')
        var formData = new FormData();
        formData.append('id',doc_id);
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token 
        axios({
            method: 'post', 
            url: API_HOST+'/cp/user/attachments/delete/',
            data:formData,            
          }).then(response => {
            if(response.status == 200 && response.data.success){ 
                this.state.document.splice(parseFloat(index),1)   
                if(this.state.attachments_count){
                    this.state.attachment_count -= 1
                }          
                this.setState(()=>{
                    return {
                        document: this.state.document,
                        attachment_count:this.state.attachment_count
                    }
                 })
                 notify('Document deleted successfully.','success')
                }else{
                        notify('Something went wrong please try again','error') 
                }
            
        }).catch(response => {
            ErrorHandling(response)
        });  
    }else{
    
    }

    }
    render() {
        $('.attachment-card').find('.card-block').removeClass('padding-60')
            const attachment_div = this.state.document.map((attachment,index) =>
                <tr key={index}>
                    <td className="width-40" style={{"word-break":"break-all"}}>{ attachment.name }</td>
                    <td className="width-40 text-center"><a href={ attachment.signed_url } className="ti-link attachment-external-link" target="_blank" title="Existing Attachment"></a></td>
                    <td className="width-20 text-right">
                        {attachment.is_uploaded_from_cp?
                        <a href="void(0)" className="btn btn-icon btn-flat btn-rounded remove-document" id={attachment.id} data-index={index} onClick={this.handleDelete} title="Delete">
                        <i className="fas fa-trash"></i>
                        </a>:''}
                    </td>
                </tr>

            )
        return (
            <div className="card attachment-card" id="ask-4">
                <div className="inline-loader" id="items_loader">
                    <SubLoader/>
                </div>  
                <div className="card-heading border bottom">
                    <div className="row">
                        <div className="col-lg-9 document-header">
                            <h4 className="card-title">Documents</h4>
                        </div>
                        <div className="col-lg-3 text-right" id="show-ssi-attachment">
                            <label className="no-mrg show-attch">Show SSI Attachments</label>
                            <div className="toggle-checkbox toggle-success toggle-sm">
                                <input type="checkbox" name="toggle3" id="toggle3" onChange={this.getSsiAttachment}/>
                                <label htmlFor="toggle3"></label>
                            </div>                                
                        </div>                        
                    </div>                                                                 
                </div>
                <div className="card-block padding-60" id="documents-block">
                <table className="table table-border no-mrg-btm" >
                        { this.state.document.length > 0?
                        <thead>
                            <tr>
                                <th className="label-color">Name </th>
                                <th className="text-center label-color">Link</th>
                                <th></th>
                            </tr>
                        </thead>:null}
                        <tbody>
                                { this.state.document.length > 0?
                                    attachment_div :null
                                 }
                                 
                        </tbody>
                        {/* <tfoot className="">
                                <tr className="new-form">
                                        <td colSpan="3">
                                               
                                        </td>
                                    </tr>
                        </tfoot> */}
                    </table> 
                    <form id="attachment-form"  method="post" encType="multipart/form-data" className="mrg-btm-20 mrg-top-20 document-tfoot">
                        <div className="row">
                            <div className="col-md-6">
                                    <input type="text" name="file_name" id="file_name" className="form-control" placeholder="Name" required/>
                                    <p id="file_name_error" className="text-danger no-mrg"></p>
                            </div>
                            <div className="col-md-4 " id='upload'>
                                    <div className="upload-btn-wrapper">
                                        <button className="upload-button"></button> 
                                        <button className="selected-button">
                                        </button>                                                           
                                        <input type="file" name="user_attachment" className="file-upload-element" placeholder="Url" id="file_attachment" onChange={this.checkFileSize}/>
                                    </div>
                                    <p id="file_error" className="text-danger no-mrg"></p> 
                                
                            </div>
                            <div className="col-md-2">
                                    <a href="void(0)" className="btn btn-info btn-animation min-width-btn show-on-mobile" role="button" onClick={this.handleSubmit}>Save</a> 

                                    <a href="void(0)" onClick={this.handleSubmit}  className="btn btn-icon btn-flat btn-rounded no-mrg mrg-horizon-5 hide-on-mobile" id="attachment-save-btn" title="Save">
                                            <i className="ti-save"></i>
                                    </a>

                            </div>
                        </div>
                    </form>
                    <Pagination items={this.state.page_attachments} quotescount={this.state.attachments_count} onChangePage={this.onChangePage} page_size={this.state.page_size} is_upload_from={this.state.is_uploaded_from_cp} />
                                                      
                </div>
            </div>
        )
    }
}
 
