import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import React, { Component } from 'react'
import history from './history'
import $ from 'jquery'; 
import 'axios/dist/axios.min.js'
import 'prop-types/prop-types.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/js/bootstrap.js'
import 'pace-progressbar';
// import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.min.js'
import 'perfect-scrollbar/dist/perfect-scrollbar.min.js'
import 'select2/dist/js/select2.full.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import 'moment/moment.js'
import 'selectize/dist/js/selectize.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.css'
import 'pace-progressbar/themes/blue/pace-theme-minimal.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import '../styles/ei-icon.css'
import '../styles/themify-icons.css'
import '../styles/font-awesome.min.css'
// import 'css-emojis/emojis.css'
import '../styles/custom/emoji.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-select/dist/css/bootstrap-select.min.css'
import '../styles/jquery-jvectormap-1.2.2.css'
import '../styles/nv.d3.min.css'
import '../styles/app.css'
import '../styles/custom/common.css'
import '../styles/custom/custom.css'
import LoginPage from './LoginPage'
import SignUpPage from './SignUpPage'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import Loader from './Loader/Loader'
import {PrivateRoute} from './PrivateRoutes'
import Dashboard from './Dashboard/Dashboard'
import QuotationsList from './Quotation/QuotationsList'
import Quotationview from './Quotation/Quotationview'
import OrdersList from './Order/OrdersList'
import OrderView from './Order/OrderView'
import Account from './Account/Account'
import Items from './Items/Items'
import Cart from './Cart/Cart'
import CreateOrder from './Order/CreateOrder'
import ChangePassword from './ChangePassword/ChangePassword'
import Notifications from './Notifications/Notifications'
import OauthRedirect from './OauthRedirect'
import ForgotPasswordSucess from './ForgotPassword/ForgotPasswordSucess'
import Activation from './Activation/Activation'

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Loader/>              
        <Router history={history} >
          <Switch>
          <Route path="/login"   component={withRouter(LoginPage)} />
          <Route path="/signup"   component={withRouter(SignUpPage)} />
          <Route path="/oauth/login/redirect/" exact component={OauthRedirect}/>
          <Route path="/user/password/reset/" exact component={withRouter(ForgotPasswordSucess)}/>
          <PrivateRoute path="/"  exact component={withRouter(Dashboard)} />
          <PrivateRoute path="/quotations" exact component={withRouter(QuotationsList)} />
          <PrivateRoute path="/quotation/view/:quoteId"  exact component={withRouter(Quotationview)} />
          <PrivateRoute path="/orders"  exact component={withRouter(OrdersList)} />
          <PrivateRoute path="/order/view/:orderId"  exact component={withRouter(OrderView)} />
          <PrivateRoute path="/account"  exact component={withRouter(Account)} />
          <PrivateRoute path="/items" exact component={withRouter(Items)} />
          <PrivateRoute path="/cart"  exact component={withRouter(Cart)} />
          <PrivateRoute path="/create/order"  exact component={withRouter(CreateOrder)} />
          <PrivateRoute path="/change/password"  exact component={withRouter(ChangePassword)} />
          <PrivateRoute path="/notifications"  exact component={withRouter(Notifications)} />
          <Route path="/user/password/recover"  component={withRouter(ForgotPassword)} />
          <Route path="/user/account/activation/"  component={withRouter(Activation)} />
          </Switch>
        </Router>
      </React.Fragment>
      
    )
  }
}
