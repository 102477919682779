import "noty/lib/noty.css"; 
import "noty/lib/themes/relax.css"
import '../styles/animate.min.css'
import {API_HOST} from '../settings.config.js'
import $ from 'jquery'
const Noty = require('noty');
const axios = require('axios').default;


export const Humanize = (number) =>{
    if(parseFloat(number)){
        var number = number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return number
}

export const notify = (msg,message_type) => {
    var type = message_type
    var position = "bottomCenter"
    new Noty({
        theme: 'relax',
        text: msg,
        type: type,
        timeout: 3000,
        layout: position,
        //closeWith: ['button', 'click'],
        animation: {
            open: 'animated bounceInLeft',
            close: 'animated bounceOutUp'
        }
    }).show()
  }

export const ErrorHandling = (Response) => {
    if(Response){
        var error_response = Response.response
        var status_code = error_response.status
        if(status_code >= 400 && status_code <= 600){
        var clear_local_storage = false
            if(status_code == 404){
                notify('Page Not Found','error')
                setTimeout(function(){ 
                    window.location.href = '/'
                }, 3000);
            }else if(status_code == 401){
            if(error_response.data.detail == "Invalid token."){
                notify('Invalid token.','error')
                clear_local_storage = true
            }else{
                notify('You cannot perform this action.','error')
            }                
            }else if(status_code >= 500 && status_code <= 600){
                notify('Server encounter error.','error')
                //clear_local_storage = true
            }else{
                notify('Something went wrong, Try again later.','error')  
            }
            setTimeout(function(){
                if(clear_local_storage){
                    localStorage.clear() 
                }                
            },1000)
        }
    }
    return('')
}

const signOut = (e) =>{
    e.preventDefault();
    $(".lds-ripple-parent-1").fadeIn(); 
    const auth_token = "Token "+localStorage.token
    axios.defaults.headers.get['Authorization'] =auth_token
          axios.get(API_HOST+'/cp/signout/',
          ).then(response => {
            if(response.status == 200){ 
                localStorage.clear()                               
                window.location.href="/login"
                  }
               })    
  }
export default signOut
