import  {ReactDOM, Component } from 'react'
import $ from 'jquery'
import {API_HOST} from '../../settings.config.js'
import SubLoader from '../Loader/SubLoader'
import {notify,ErrorHandling} from '../../js/CommonFunctions'
import countries from '../../data/countries2.json'
const axios = require('axios').default;

const country_obj = {}
const time_zone = {}
countries.map((data)=>{
    country_obj[data.iso_code_1] = data.country
    time_zone[data.iso_code_1] = data.timezones
})

class GetSettingsBtn extends Component{
    render(){
        return(
            <ul className="portlet-item navbar">
                    <li className="dropdown">
                            <a id="settings-btn" className="btn btn-info" onClick={this.props.getsetting}>Auto Detect</a>
                    </li>                                                
                </ul>
        )
    }
}

export default class Settings extends Component {
    constructor(props){
        super(props)
       this.state = {
        settings: {},
        countries:country_obj,
        time_zone:time_zone,
        country_code:[],
        country_time_zones:[],
        flag:true
       } 
       
    }

    componentWillMount(){
        $('select#countries').selectpicker()
        $('select#time_zone').selectpicker()
        $('select#country_code').selectpicker()             
        
    }
    componentDidUpdate(){
        $('select#countries').selectpicker('refresh');
        $('select#time_zone').selectpicker('refresh');
        $('select#country_code').selectpicker('refresh');
        if(this.state.flag){
        $('select[name=timezone]').val(this.state.settings.time_zone).trigger('change')
        $('select[name=countrycode]').val(this.state.settings.currency.code).trigger('change')
        }
        $('.bootstrap-select > button.dropdown-toggle').addClass('custom-dropdown-toggle')   
    }
 

    getSettings = (event) => {
        event.preventDefault();
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token
        var form_data = 'country='+localStorage.getItem('country_code')
        axios({
            method: 'post', 
            url: API_HOST+'/cp/user/settings/',
            data:form_data,
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded",
            }
          }).then(response => {
          if(response.status == 201){  
            this.setState(()=>{
                return {
                    settings: response.data.settings,
                }
             })  
             document.getElementById("settings-btn").style.display = "none"; 
            }
        }).catch(response => {
            ErrorHandling(response)
        });     
    }
    editSettings = (event) => {
        event.preventDefault()
        $('.setting-card').find('.inline-loader').fadeIn()
        document.getElementById('setting-save-btn').style.display='inline'; 
        document.getElementById('setting-cancel-btn').style.display='inline'; 
        document.getElementById('setting-edit-btn').style.display='none'; 
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/currency/list/',
        ).then(response => {
          if(response.status == 200){                   
            this.setState(()=>{
                return {
                    country_code: response.data,
                }
             })  
             $('.country_name').hide()
             $('.time-zone').hide() 
             $('.currency-code').hide() 
             $('.countries-dropdown').removeClass('d-none')
             $('.time-zone-dropdown').removeClass('d-none')
             $('.currency-code-dropdown').removeClass('d-none')
             $('.setting-card').find('.inline-loader').fadeOut('slow')
            }
        }).catch(response => {
            ErrorHandling(response)
            $('.setting-card').find('.inline-loader').fadeOut('slow')
        });    
        

    }
    countryChange = (event) => {
        event.preventDefault()
        var selected_country = event.currentTarget.value
        var time_zone = this.state.time_zone
        var time_zones = time_zone[selected_country]

        this.setState(()=>{
            return {
                country_time_zones:time_zones,
                flag:false

            }
         }) 

    }
    currencyChange = (event) => {
        event.preventDefault()
        var selected_currency = event.currentTarget.value
        var currency_code = this.state.country_code.filter((code) =>{
            if(code.code==selected_currency){return code}
        })
        if(currency_code.length > 0){
            $('.currency_symbol').text(currency_code[0].symbol)
            localStorage.setItem('symbol',currency_code[0].symbol)

        }
    }
    handleCancel = (event) => {
        event.preventDefault()
        $('.country_name').show()
        $('.countries-dropdown').addClass('d-none')
        $('.time-zone').show()
        $('.time-zone-dropdown').addClass('d-none')
        $('.currency-code').show()
        $('.currency-code-dropdown').addClass('d-none')
        $('#setting-save-btn').hide()
        $('#setting-cancel-btn').hide()
        $('#setting-edit-btn').show()
        localStorage.setItem('symbol',this.state.settings.currency.symbol)
        var time_zones = this.state.time_zone
        var country_time_zone = time_zones[this.state.settings.country]?time_zones[this.state.settings.country]:[]
        $('select[name=countrycode]').val(this.state.settings.currency.code).trigger('change')
        $('select[name=countries]').val(this.state.settings.country).trigger('change')
        $('.currency_symbol').text(this.state.settings.currency.symbol)
        this.setState(()=>{
            return {
                settings: this.state.settings,
                country_time_zones:country_time_zone,
                flag:true

            }
         }) 

    }
    saveSettings = (event) => {
        event.preventDefault()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.patch['Authorization'] =auth_token
        var country=$('select#countries').val()
        var country_code = $('select#country_code').val()
        var currency_code_list= this.state.country_code.filter((code) =>{if(code.code==country_code){return code}})
        var currency_id = ''
        if(currency_code_list.length > 0){
            var currency_id = currency_code_list[0].id
        }
        $('.setting-card').find('.inline-loader').fadeIn()
        var time_zone = $('select#time_zone').val()
        //const form_data = 'country='+country+'&time_zone='+time_zone+'&currency='+currency_id 
        const form_data = {'country':country,'time_zone':time_zone,'currency_id':currency_id}

        axios({
            method: 'patch', 
            url: API_HOST+'/cp/user/settings/',
            data:form_data,
            headers: { 
                'Content-Type': 'application/json',
            }
          })
        .then((response) => {
               if(response.status == 200){
                $('.country_name').show()
                $('.countries-dropdown').addClass('d-none')
                $('.time-zone').show()
                $('.time-zone-dropdown').addClass('d-none')
                $('.currency-code').show()
                $('.currency-code-dropdown').addClass('d-none')
                $('#setting-save-btn').hide()
                $('#setting-cancel-btn').hide()
                $('#setting-edit-btn').show()
                localStorage.setItem('symbol',response.data.settings.currency.symbol)
                var time_zones = this.state.time_zone
                var country_time_zone = time_zones[response.data.settings.country]?time_zones[response.data.settings.country]:[]
                $('select[name=countrycode]').val(response.data.settings.currency.code).trigger('change')
                $('select[name=countries]').val(response.data.settings.country).trigger('change')
                $('.currency_symbol').text(response.data.settings.currency.symbol)
                this.setState(()=>{
                    return {
                        settings: response.data.settings,
                        country_time_zones:country_time_zone,
                        flag:true
    
                    }
                 }) 
                 $('.setting-card').find('.inline-loader').fadeOut('slow')
                notify('Settings Updated successfully.','success')

               }
        });
    }  
    componentDidMount(){
        $('select').selectpicker();
        //ReactDOM.render(<PageLoader/>,document.getElementById('setting_loader'))
        $('.setting-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/settings/',
        ).then(response => {
          if(response.status == 200){ 
              var country_code = localStorage.getItem('country_code')
            $('select[name=countries]').val(response.data.settings.country).trigger('change')
            //$('select[name=timezone]').val(response.data.settings.time_zone).trigger('change')
            var time_zones = this.state.time_zone
            var country_time_zone = time_zones[response.data.settings.country]?time_zones[response.data.settings.country]:[]
              if(response.data.settings == null && country_code){
                ReactDOM.render(
                        <GetSettingsBtn getsetting={this.getSettings}/>,
                        document.getElementById('settingbtn')
                    );
              }
              
              document.getElementById('setting-edit-btn').style.display='none';
              if(response.data.settings.can_use_local_currency){
                document.getElementById('setting-edit-btn').style.display='inline';
              }
            this.setState(()=>{
                return {
                    settings: response.data.settings,
                    country_time_zones:country_time_zone
                }
             })   
             $(".lds-ripple-parent").fadeOut("slow");      
             $('.setting-card').find('.inline-loader').fadeOut('slow')                     
            }
        }).catch(response => {
            ErrorHandling(response)
        });    
          
    }
    render() {
        $('.setting-card').find('.card-block').removeClass('padding-60')
            var code = '-'
            var symbol = '-'
            if(this.state.settings){
                if(this.state.settings.currency){
                    var code = this.state.settings.currency.code
                    var symbol = this.state.settings.currency.symbol
                }
            }
            var countries = Object.entries(this.state.countries)
            var option_str=countries.map((country, key) =>{
                return(
                <option key={country[0]} value={country[0]}>{country[1]}</option>
            )
            })   
            var time_zone_options = '' 
            if(this.state.country_time_zones.length > 0){      
            var time_zone_options=this.state.country_time_zones.map((time_zone, key) =>{
                return(<option  key={time_zone} value={time_zone}>{time_zone}</option>)
            })
        }
            var country_code_str = ''
            if(this.state.country_code.length > 0){
                country_code_str=this.state.country_code.map((countrycode, key) =>{
                    return(
                    <option  key={countrycode.code} value={countrycode.code}>{countrycode.code}</option>
                )
                })
            }
        return (
            <div className="card setting-card" id="ask-5">
                <div className="inline-loader" id="setting_loader">
                    <SubLoader/>
                </div> 
                <div className="card-heading border bottom">
                    <div className="portlet" id="settingbtn">
                    <ul className="portlet-item navbar">
                        <li className="dropdown">                          
                                <a href="void(0)" onClick={this.handleCancel}className=" btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="setting-cancel-btn" title="Cancel">
                                        <i className="ti-close"></i>
                                </a>                            
                                <a href="void(0)" onClick={this.saveSettings} className="btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="setting-save-btn" title="Save">
                                        <i className="ti-save"></i>
                                </a>
                                <a href="void(0)" onClick={this.editSettings}className="btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="setting-edit-btn" title="Edit">
                                        <i className="ti-pencil"></i>
                                </a>
                        </li>                                                
                    </ul>
                    </div>
                    <h4 className="card-title">Settings</h4>
                </div>
                <div className="card-block padding-60" id="settings-block">
                    <div className="row">
                            <div className="col-md-3 settings-border">
                                <p className="mrg-top-10 text-dark"> <b>Country</b></p>
                            </div>
                            <div className="col-md-3 settings-border">
                                    <p className="mrg-top-10 country_name" >{ this.state.settings?this.state.settings.country_name:'-' }</p>
                                    <div className="countries-dropdown d-none">
                                            <select id="countries" className="form-control" name="countries" data-size="7" data-live-search="true" onChange={this.countryChange}>
                                                {option_str}    
                                            </select>
                                        </div>
                            </div>
                            <div className="col-md-3 settings-border">
                                    <p className="mrg-top-10 text-dark"> <b>Time Zone</b></p>
                                </div>
                                <div className="col-md-3 settings-border">
                                        <p className="mrg-top-10 time-zone">{ this.state.settings?this.state.settings.time_zone:'-' }</p>
                                        <div className="time-zone-dropdown d-none">
                                                <select id="time_zone" className="form-control" name="timezone" data-size="7" data-live-search="true" >
                                                    {time_zone_options}
                                                </select>
                                            </div>
                            </div>
                            <hr/>
                            <div className="col-md-3 mrg-top-10">
                                    <p className="mrg-top-10 text-dark"> <b>Currency Code</b></p>
                                </div>
                                <div className="col-md-3 mrg-top-10">
                                        <p className="mrg-top-10 currency-code" >{code}</p>
                                        <div className="currency-code-dropdown d-none">
                                                <select id="country_code" className="form-control" name="countrycode" data-size="7" data-live-search="true" onChange={this.currencyChange}>
                                                    {country_code_str}   
                                                </select>
                                            </div>
                                </div>
                                <div className="col-md-3 mrg-top-10">
                                        <p className="mrg-top-10 text-dark"> <b> Currency Symbol</b></p>
                                    </div>
                                    <div className="col-md-3 mrg-top-10">
                                            <p className="mrg-top-10 currency_symbol">{ symbol }</p>
                                </div>

                        </div>                                                     
                    
                    </div>
            </div>
        )
    }
}

