import { Component } from 'react'
import {Humanize, notify} from './CommonFunctions'
import $ from 'jquery'



export default class SmartLeadTimeModal extends Component{
    constructor(props){
        super(props)
        this.state = {
            selected_items:this.props.items,
            cart_flag:true,
            total_amount:0,
            total_quantity:0,
            final_lead_time:'',
            avaivable_qty :0
        }
        this.SelectItem = this.SelectItem.bind(this)
        this.SelectAllItems = this.SelectAllItems.bind(this)
        this.addToCart = this.addToCart.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.onChangeQty = this.onChangeQty.bind(this)
        this.onfocusSelect = this.onfocusSelect.bind(this)
        this. restrictDecimal = this.restrictDecimal.bind(this)
    }
    componentWillMount(){
        var cart_item_list = this.state.selected_items.item_data.map((item,index)=>{
            var req_qty = parseFloat(this.state.selected_items.this_value)
            var quantity = 0 //item.avi_qty || 0
            var warehouse_instock = 0
            var warehouse_intransit = 0
            var warehouse_backorder = 0
            if(item.avi_qty){
                if(item.avi_qty > req_qty){
                    warehouse_instock = req_qty
                }else{
                    warehouse_instock = item.avi_qty
                }
            }
            if(req_qty > item.avi_qty){
            if(item.intransit_qty){
                if(item.intransit_qty >= req_qty){
                    warehouse_intransit = req_qty-warehouse_instock
                }else if(item.intransit_qty < req_qty){
                    var remaing_qty = warehouse_instock + item.intransit_qty
                    if(remaing_qty <= req_qty){
                        warehouse_intransit = item.intransit_qty
                    }else if(remaing_qty > req_qty){
                        var balance_qty = req_qty - item.intransit_qty
                        warehouse_intransit = balance_qty + (item.intransit_qty-warehouse_instock)
                    }
                }
            }
            warehouse_backorder = req_qty-warehouse_instock-warehouse_intransit
        }
            /*if(item.avi_qty){
                quantity = item.avi_qty
            }else if(item.intransit_qty) {
                quantity = item.intransit_qty
            }else{
                quantity = this.state.selected_items.this_value
            }*/
            var lead_time = ''
            var extra_lead_time = ''
            /*if(item.avi_lead_time && item.intransit_list.length > 0){
                lead_time = item.avi_lead_time
                extra_lead_time = item.final_msg
            }else if(item.avi_lead_time){
                lead_time = item.avi_lead_time
            }else if (item.intransit_lead_time){
                lead_time = item.intransit_lead_time
            }else{
                lead_time = 'Backorder'
            }*/
            this.state.selected_items.item_data[index].warehouse_instock = warehouse_instock 
            this.state.selected_items.item_data[index].warehouse_intransit = warehouse_intransit 
            this.state.selected_items.item_data[index].warehouse_backorder = warehouse_backorder 
            this.state.selected_items.item_data[index].quantity = quantity  
            this.state.selected_items.item_data[index].selected_item = quantity?true:false
            this.state.selected_items.item_data[index].lead_time = lead_time 
            this.state.selected_items.item_data[index].extra_lead_time = extra_lead_time
            this.setState({
                selected_items:this.state.selected_items                
            }) 
        })

    }

    componentDidMount(){
        $('#smart-lead-time-modal-dialog').modal('show');
       // $('#smart-lead-time-modal-dialog').on('hidden.bs.modal', this.props.handleHideModal);
        var total_amount = 0
        var total_quantity = 0
        var itemData = this.state.selected_items.item_data
        var lead_time_data = itemData[itemData.length - 1]
        var lead_time = '8 to 12 Weeks ARO(Subject to Prior Sales)'
        if(lead_time_data.avi_lead_time){
            lead_time = lead_time_data.avi_lead_time
        }else if(lead_time_data.intransit_lead_time){
            lead_time = lead_time_data.intransit_lead_time
        }
        itemData.map((item,index) =>{
            if(item.selected_item){
                $('tr[row-index='+index+']').find('.q-select-modal').prop('checked',true)
            }else{
                $('tr[row-index='+index+']').find('.q-select-modal').prop('checked',false)
            }
            var quantity = item.avi_qty || 0
            /*if(item.avi_qty){
                quantity = item.avi_qty
            }else if(item.intransit_qty) {
                quantity = item.intransit_qty
            }else{
                quantity = item.quantity
            }*/
            //total_amount += parseFloat(item.price)*parseFloat(quantity)
            //total_quantity += parseFloat(quantity)
        })
        var select_all = true
        $('.q-select-modal').each((index,item) =>{
            if (item.checked) {
            } else {
                select_all = false
            }
        })
        if (select_all) {
            $('.q-select-all-modal').prop('checked', true)
        } else {
            $('.q-select-all-modal').prop('checked', false)
        }
        var filter_selected_item = this.state.selected_items.item_data.filter((item) =>{
            if(item.selected_item){
                return item
            }
        })
        var final_lead_time = ''
        if(filter_selected_item.length > 0){  
            final_lead_time = filter_selected_item[filter_selected_item.length -1].lead_time         
        }
        
        this.setState({
            total_amount:total_amount,
            total_quantity:total_quantity,
            final_lead_time:final_lead_time
        })
    }
    
    
    SelectItem(event){
        var row_index = $(event.currentTarget).attr('row-index')
        if (event.currentTarget.checked) {
            event.currentTarget.checked = true
            if (this.state.selected_items.item_data.indexOf(event.currentTarget.value) >= 0) {
            } else {
                this.state.selected_items.item_data[row_index].selected_item = true
            }
        } else {
            event.currentTarget.checked = false
            this.state.selected_items.item_data[row_index].selected_item = false
        }            
        var select_all = true
        var cart_flag = true
        var cart_items = this.state.all_cart_items
        var all_unselected = true
        $('.q-select-modal').each((index,item) =>{
            if (item.checked) {
                all_unselected = false
            } else {
                select_all = false
            }
            if(item.checked){
                cart_flag = false
            }
        })
        if(all_unselected){
            cart_flag = false
        }
        
        if (select_all) {
            $('.q-select-all-modal').prop('checked', true)
        } else {
            $('.q-select-all-modal').prop('checked', false)
        }
        var total_amount = 0
        var total_quantity = 0
        var filter_selected_item = this.state.selected_items.item_data.filter((item) =>{
            if(item.selected_item){
                return item
            }
        })
        var final_lead_time = ''
        if(filter_selected_item.length > 0){  
            final_lead_time = filter_selected_item[filter_selected_item.length -1].lead_time         
        }
        this.state.selected_items.item_data.map((item) =>{
            if(item.selected_item){               
            total_amount += parseFloat(item.price)*parseFloat(item.quantity)
            total_quantity += parseFloat(item.quantity)
            }
        })
        this.setState({
            cart_flag :cart_flag,
            selected_items:this.state.selected_items,
            total_amount:total_amount,
            total_quantity:total_quantity,
            final_lead_time:final_lead_time,
        })

    }

    SelectAllItems(event){
        var cart_flag=true            
        if (event.currentTarget.checked) {
            $('.q-select-modal').each((index,item) =>{
                item.checked = true
                if (this.state.selected_items.item_data.indexOf(item.value) >= 0) {
                } else {                    
                    this.state.selected_items.item_data[index].selected_item = true
                }                
            })
        } else {
            $('.q-select-modal').each((index,item) => {
                item.checked = false                
                this.state.selected_items.item_data[index].selected_item = false
            })
            cart_flag = false
        }
        var total_amount = 0
        var total_quantity = 0
        var filter_selected_item = this.state.selected_items.item_data.filter((item) =>{
            if(item.selected_item){
                return item
            }
        })
        var lead_time = ''
        if(filter_selected_item.length > 0){
            lead_time = filter_selected_item[filter_selected_item.length -1].lead_time
        }
        this.state.selected_items.item_data.map((item) =>{
            if(item.selected_item){               
            total_amount += parseFloat(item.price)*parseFloat(item.quantity)
            total_quantity += parseFloat(item.quantity)
            }
        })
        this.setState({
            cart_flag :cart_flag,
            selected_items:this.state.selected_items,
            total_amount:total_amount,
            total_quantity:total_quantity,
            final_lead_time:lead_time
        })
        
    }
    hideModal(event){
        var row_index = this.state.selected_items.item_data[0].cart_row_index
        this.props.handleHideModal(row_index)
    } 
    onChangeQty(event){
        event.preventDefault()
        var this_val = event.currentTarget.value 
        var el = $(event.currentTarget),
          val = Math.max((0, el.val())),
          max = parseInt(99999999);  
          this_val = isNaN(max) ? parseFloat(val.toString().replace(/[^0-9]+/g, "")) : Math.min(max, val)
        var this_indx = $(event.currentTarget).closest('tr').attr('row-index')
        var this_row_data = this.state.selected_items.item_data[this_indx]
        var total_amount = 0
        var total_quantity = 0
        //if(parseFloat(this_val)){       
        var available_qty = this_row_data.avi_qty  || 0
        var intransit_qty = this_row_data.intransit_qty || 0
        var lead_time =''
        var extra_lead_time = ''
        if(parseFloat(this_val) <= parseFloat(available_qty)){
            if(parseFloat(this_val)){
                lead_time = this_row_data.avi_lead_time
                extra_lead_time = this_row_data.final_msg
            }else{
                lead_time = ''
            extra_lead_time = ''
            }
            
        }else if(parseFloat(this_val) > parseFloat(available_qty) && parseFloat(this_val) <= (parseFloat(intransit_qty)+parseFloat(available_qty))){
            var avia_qty = parseFloat(available_qty)
            var match = true
            lead_time = '8 to 12 Weeks ARO(Subject to Prior Sales)'
            var intransit_list = this_row_data.intransit_list.map((intransit,indx)=>{
                avia_qty += parseFloat(intransit.quantity)
                if(parseFloat(this_val) <= parseFloat(avia_qty)){
                    if(match){
                        lead_time = intransit.lead_time
                        match = false
                    }
                }
            })          

        }else{
            if(this_val){
                lead_time = '8 to 12 Weeks ARO(Subject to Prior Sales)'
            }
            
        }
        this_row_data.lead_time = lead_time
        this_row_data.extra_lead_time = extra_lead_time
        this_row_data.quantity = this_val
        if(parseFloat(this_val || 0)){
            this_row_data.selected_item = true
            $(event.currentTarget).closest('tr').find('.q-select-modal')[0].checked = true
        }else{
            this_row_data.selected_item = false
            $(event.currentTarget).closest('tr').find('.q-select-modal')[0].checked = false
        }
        var select_all = true
        var cart_flag = true
        var cart_items = this.state.all_cart_items
        var all_unselected = true
        $('.q-select-modal').each((index,item) =>{
            if (item.checked) {
                all_unselected = false
            } else {
                select_all = false
            }
            if(item.checked){
                cart_flag = false
            }
        })
        if(all_unselected){
            cart_flag = false
        }
        
        if (select_all) {
            $('.q-select-all-modal').prop('checked', true)
        } else {
            $('.q-select-all-modal').prop('checked', false)
        }

        this.state.selected_items.item_data[this_indx] = this_row_data 
        /*}
        else{
            this_row_data.lead_time = ''
            this_row_data.extra_lead_time = ''
            this_row_data.quantity = this_val
            this.state.selected_items.item_data[this_indx] = this_row_data        
        }*/
        
        var filter_selected_item = this.state.selected_items.item_data.filter((item) =>{
            if(item.selected_item){
                return item
            }
        })
        var final_lead_time = ''
        if(filter_selected_item.length > 0){
            final_lead_time = filter_selected_item[filter_selected_item.length -1].lead_time
        }
        this.state.selected_items.item_data.map((item) =>{
            if(item.selected_item){               
            total_amount += parseFloat(item.price)*(parseFloat(item.quantity) || 0)
            total_quantity += parseFloat(item.quantity) || 0
            }
        })
        this.setState({
            selected_items:this.state.selected_items,
            total_amount:total_amount ,
            total_quantity:total_quantity || 0,
            final_lead_time:final_lead_time,
            cart_flag:cart_flag
        })




    }  
    addToCart(event){
        var selected_item_qty = 0
        var flag = true
        var filter_selected_item = this.state.selected_items.item_data.filter((item) =>{
            if(item.selected_item && parseFloat(item.quantity || 0)){
                selected_item_qty += parseFloat(item.quantity)
                return item
            }
            /*else{
                flag = false  
            }*/
        })
        /*var flag = true
        var ava_qty = 0
        this.state.selected_items.map((row_item,index)=>{
            if(flag){
                if(selected_item_qty <= parseFloat(row_item.quantity)){
                    this.state.final_lead_time = row_item.item_lead_time
                    this.setState({
                        final_lead_time:this.state.final_lead_time
                    })
                    flag = false
                    return false
                }
            }
            if(row_item.status == "available"){
                ava_qty += row_item.quantity
            }
            
        })*/
        if(filter_selected_item.length > 0 && flag ){
           /* var item_and_box_volume = 0
            if(filter_selected_item[0].item_and_box_volume){
                item_and_box_volume = parseFloat(this.state.total_quantity)*filter_selected_item[0].item_and_box_volume
            }
            var item_and_box_weight = 0
            if(filter_selected_item[0].total_item_and_box_weight){
                item_and_box_weight = parseFloat(this.state.total_quantity)*filter_selected_item[0].item_and_box_weight
            }
            var warehouse = filter_selected_item.map((item) =>{
                return item.warehouse_details.warehouse_id
            })
            var latest_item_leadtime = {
                'item_id':filter_selected_item[0].item_id,
                'quantity':this.state.total_quantity,
                'item_lead_time':this.state.final_lead_time,
                'price':filter_selected_item[0].price,
                'amount':this.state.total_amount,
                'cart_id':filter_selected_item[0].cart_id,
                'cart_row_index':filter_selected_item[0].cart_row_index,
                'item_and_box_volume':item_and_box_volume,
                'item_and_box_weight':item_and_box_weight,
                'ava_qty':ava_qty,
                'warehouse':warehouse.join(',')
            }*/
            this.props.AddToCartHandler(filter_selected_item)
        }else{
            notify('Please select quantity!', 'warning')
        }

        
    }
    onfocusSelect(event){
      $(event.currentTarget).select()
    }
    
    restrictDecimal(e){
        if(e.key==='.'){e.preventDefault()}
    }
    render(){
        var cart_item_list = this.state.selected_items.item_data.map((item,index)=>{ 
            return(
                    <tr item-id={item.item_id} row-index={index}  className="item-cart-tr" >                                                                    
                                {''?<td className="item-cart-name">
                                    <div className=""><span className="item_number">{item.item_number}</span></div>
                                    <div className="item-name"><span>{item.item_description}</span></div>                                    
                                </td>:''} 
                                <td className="item-checkbox mobile-checkbox">
                                    <label className="q-checkbox-modal mobile-checkbox" name="" value="">
                                            <input type="checkbox" name="" className="q-select-modal" value={item.item_id} onChange={this.SelectItem} item-id={item.item_id} row-index={index}  />
                                            <span className="checkmark-modal"></span>
                                    </label>
                                </td>
                                <td className="avialable-warehouse">
                                    <span>{item.warehouse_details.warehouse_city?item.warehouse_details.warehouse_city+',':item.warehouse_details.warehouse_name+','}</span>
                                    <span> {item.warehouse_details.warehouse_state?item.warehouse_details.warehouse_state+'.':item.warehouse_details.warehouse_country +'.'}</span>
                                </td> 
                                <td className="item-stock">
                                    {item.warehouse_instock?[<span className="warehouse-instock">{'In Stock : '+ item.warehouse_instock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>,<br/>]:null}
                                    {item.warehouse_intransit?[<span className="warehouse-intransit">{'In Transit : '+ item.warehouse_intransit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>,<br/>]:null}   
                                    {item.warehouse_backorder?<span className="warehouse-backorder">{'Available in 8-12 weeks : '+ item.warehouse_backorder.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>:null}                                
                                </td> 
                                <td className="text-right avialable-qty"  >
                                    <div className="mrg-vertical-5 hide-on-mobile">
                                        <p className="font-size-15">
                                        {true?null:<span>{item.quantity?item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'-'}</span>}
                                        <input type="number" min="10" onKeyDown = {this.restrictDecimal} className={item.quantity?item.quantity <= item.avi_qty?'form-control quantity text-right avi-color':item.quantity <= item.intransit_qty?'form-control quantity  text-right inst-color':'form-control quantity text-right  backorder-color':'form-control quantity  text-right backorder-color'} value={item.quantity} onChange={this.onChangeQty} onFocus={this.onfocusSelect} />
                                        </p>
                                    </div>
                                    <div className="mrg-vertical-5 show-on-mobile">
                                        <span className="font-size-15 disply-webkit">
                                        {true?null:<span>{item.quantity?item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'-'}</span>}
                                        <input type="number" min="1" onKeyDown = {this.restrictDecimal} className={item.quantity?item.quantity <= item.avi_qty?'form-control quantity input-qty text-right avi-color':item.quantity <= item.intransit_qty?'form-control quantity input-qty text-right inst-color':'form-control quantity text-right input-qty backorder-color':'form-control quantity input-qty text-right backorder-color'} value={item.quantity} onChange={this.onChangeQty}  />
                                        <span className="show-on-mobile multi-icon">X</span>
                                        <span className="show-on-mobile mobile-view-amount">{item.price?Humanize(item.price):'-'}</span>    
                                    </span>
                                        
                                    </div>
                            </td>
                            <td className="cart-lead-time">
                                <span className="primary-lead-time">{item.lead_time?item.lead_time:'-'}</span>
                                <br/>
                                {true?null:item.extra_lead_time?<span className="secondary-lead-time">{item.extra_lead_time}</span>
                               :null}
                            </td>
                                <td className="text-right hide-on-mobile">
                                        <div className="mrg-vertical-5">
                                            <p className="font-size-15">
                                            <span>{item.price?Humanize(item.price):'-'}</span>
                                            </p>
                                        </div>
                                </td>
                                <td className="text-right avialable-amount">
                                        <div className="mrg-vertical-5 hide-on-mobile">
                                            <p className="font-size-15">
                                            <span className="show-on-mobile multi-icon">=</span>
                                            <span>{item.price && item.quantity?Humanize(parseFloat(item.quantity)*parseFloat(item.price)):'-'}</span>
                                            </p>
                                        </div>
                                        <div className="mrg-vertical-5 show-on-mobile disply-webkit">
                                        <span className="show-on-mobile multi-icon">=</span>
                                        <span className="show-on-mobile">{item.price && item.quantity?Humanize(parseFloat(item.quantity)*parseFloat(item.price)):'-'}</span>
                                        </div>
                                    </td>
                                
                            </tr>

            )
        })
        
        return(
            <div className="modal fade" id="smart-lead-time-modal-dialog" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg avail-model-dialog">
                        <div className="modal-content">
                            <div className="modal-header avail-title">
                                <h4 className="modal-title "><span className="item_number">{this.state.selected_items.item_data[0].item_number} - </span><span>{this.state.selected_items.this_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> 
                                    
                                </h4>  
                                <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal}>&times;</button>                              
                                
                            </div>
                            {true?null:
                            <div className="model-stock-availability">
                                
                                <div className="stock-available avi-color">
                                    <span className="stock-desc">Available</span><span className="float-left"> - </span><span className="stock-qty">{this.state.selected_items.available?this.state.selected_items.available.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'0'}</span>
                                </div>
                                {this.state.selected_items.intransit?
                                <div className="stock-available inst-color">
                                    <span className="stock-desc">In Transit</span><span className="float-left"> - </span><span className="stock-qty">{this.state.selected_items.intransit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>:null}
                                {this.state.selected_items.backorder?
                                <div className="stock-available backorder-color">
                                    <span className="stock-desc">Backorder</span><span className="float-left"> - </span><span className="stock-qty">{this.state.selected_items.backorder.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>:null}
                            </div> }                           
                            <div className="modal-body smart-lead-time-modal-model-body">
                                <table className="table table-hover mrg-btm avialable-table" >
                                    <thead className="cart-table-header">
                                        <tr>                                            
                                            {''?<th className="width-40">Item# </th>:null}
                                            <th width="2%">
                                                <label className="q-checkbox-modal" name="" value="" >
                                                    <input type="checkbox" className="q-select-all-modal" name="" id="q-select-all-modal" onChange={this.SelectAllItems}  />
                                                    <span className="checkmark-modal" ></span>
                                                </label>
                                            </th>
                                            <th className="width-20">Warehouse </th>
                                            <th className="width-25">Stock</th> 
                                            <th className=" text-right width-10">Quantity</th>
                                            <th className="width-30">Lead Time</th>
                                            <th className=" text-right width-15">Price<span className="currency">({this.props.symbol})</span></th>
                                            <th className=" text-right width-15">Amount<span className="currency">({this.props.symbol})</span></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>{cart_item_list}</tbody>
                                    <tfoot> 
                                       {true?null: <tr className="total-details"> 
                                            <th colSpan="4" className="text-right text-dark">LEAD TIME :</th>                                          
                                            <td colSpan="3" className="text-right font-size-16">
                                                <span>{this.state.final_lead_time}</span>
                                            </td>    
                                        </tr> }
                                        <tr className="total-details"> 
                                            <th colSpan="4" className="text-right text-dark">TOTAL QUANTITY :</th>                                          
                                            <td colSpan="3" className="text-right font-size-16">
                                                <span className="amount">{this.state.total_quantity?this.state.total_quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'-'}</span>
                                            </td>    
                                        </tr>
                                        <tr className="total-details"> 
                                            <th colSpan="4" className="text-right text-dark">TOTAL AMOUNT <span className="currency">({this.props.symbol})</span> :</th>                                          
                                            <td colSpan="3" className="text-right font-size-16">
                                                <span className="amount">{this.state.total_amount?Humanize(this.state.total_amount):'-'}</span>
                                            </td>    
                                        </tr>                                       
                                        <tr className="total-details hide-on-mobile">
                                            <td colSpan="7" className="button-width">                                                
                                                <button className="btn btn-default btn-animation pull-left mrg-btm pop-back" onClick={this.hideModal} >
                                                    BACK
                                                </button>
                                                <button className="btn btn-primary btn-animation pull-right mrg-btm" onClick={this.addToCart} >
                                                    {this.props.from?'ADD TO CART':'UPDATE'}
                                                </button>
                                            </td>                                            
                                        </tr>
                                        <tr className="total-details show-on-mobile">
                                            <td colSpan="3" className="button-width">                                                
                                                <button className="btn btn-default btn-animation pull-left mrg-btm pop-back min-width-btn" onClick={this.hideModal} >
                                                    BACK
                                                </button>
                                            </td>
                                            <td colSpan="4" className="add_to_cart_btn">
                                                <button className="btn btn-primary btn-animation pull-right mrg-btm min-width-btn" onClick={this.addToCart} >
                                                    {this.props.from?'ADD TO CART':'UPDATE'}
                                                </button>
                                            </td>                                            
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            
            
                        </div>
                    </div>
                </div>
        )
    }
} 
