import { Component } from 'react'
import { withRouter} from 'react-router-dom'

class OauthRedirect extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        var redirect_data = new URLSearchParams(this.props.location.search)
        if(redirect_data.get('success') == 'login'){
            localStorage.setItem('first_name',redirect_data.get('first_name'))
            localStorage.setItem('last_name',redirect_data.get('last_name'))
            localStorage.setItem('email',redirect_data.get('email'))
            localStorage.setItem('token', redirect_data.get('token'))
            localStorage.setItem('id',redirect_data.get('id'))
            localStorage.setItem('company',redirect_data.get('company'))
            localStorage.setItem('symbol',redirect_data.get('symbol'))
            localStorage.setItem('cart_items',JSON.stringify([]))
            localStorage.setItem('cart_item_ids',JSON.stringify([]))
            localStorage.setItem('category',JSON.stringify({}))
            localStorage.setItem('help_url',redirect_data.get('help_url'))
            this.props.history.push('/')
        }else if(redirect_data.get('success') == 'signup'){
            this.props.history.push({
                pathname:'/signup',
                state:{params:{
                    first_name:redirect_data.get('first_name'),
                    last_name:redirect_data.get('last_name'),
                    email:redirect_data.get('email'),
                    message:'Are you new here? Please Sign Up to proceed...'
                }}
            })
        }else if(redirect_data.get('success') == 'rejected'){
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'You cannot perform this action, contact SSI.'
                }}
            })

        }else if(['invited','request_accepted'].includes(redirect_data.get('success'))){
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'The account activation link has already been sent to your E-Mail ID. Check your spam folder as well.'
                }}
            })

        }else if(redirect_data.get('success') == 'requested'){
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'You have already signed up, it is under review by SSI.'
                }}
            })

        }else if(redirect_data.get('success') == 'erp_user'){
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'You cannot perform this action.'
                }}
            })

        }else{
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'Something went wrong, contact SSI.'
                }}
            })  
        }
        
    }
    render() {
        var style_div = {'font-size':'17px','font-style':'italic','padding':'13px'}
        return (
            <div id="loading" style={style_div}>
                    Loading.........
            </div>
        )
    }
}
export default withRouter(OauthRedirect)