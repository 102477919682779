const ItemsListWorker = () =>{
    onmessage = (e) =>{
        const auth_token = "Token "+e.data.token
        const myHeaders = new Headers();
        myHeaders.append('Authorization', auth_token);
        const myRequest = new Request(e.data.url, {
            method: 'GET',
            headers: myHeaders,
        });
        fetch(myRequest)
            .then((response) => response.json())
            .then((data) => postMessage(data));
            }
}

let code = ItemsListWorker.toString()
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"))
const blob = new Blob([code], {type:"application/javascript"})
const workerScript = URL.createObjectURL(blob)
module.exports = workerScript