import { Component } from 'react'
import {withRouter} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import {ErrorHandling} from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;

class PackedOrders extends Component {
    constructor(props){
        super(props)
        this.state = {
            fulfillment:0,
        }
    }
    componentDidMount(){
        const auth_token = "Token "+localStorage.token
        $('.packed-order').find('.inline-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/dashboard/orders/packed/',
            ).then(response => {
            if(response.status == 200){   
                this.setState(()=>{
                    return {
                        fulfillment: Math.round(response.data.orders_packed),
                    }
                })
                $('.packed-order').find('.inline-loader').fadeOut("slow");           
            }
        }).catch(response => {
            ErrorHandling(response)
        });


    }
    render() {
        if(this.state.fulfillment > 0){
            var ele = [                          
                    <h1 className="no-mrg-vertical" key={'readyship'}><span><i className="ti-truck"></i></span>{this.state.fulfillment>0?<a href='' className="pull-right count-color">{this.state.fulfillment}</a>:<span className="pull-right">0</span>}
                    </h1>,
                    <p className="no-mrg-btm" key={'packed-msg'}>Ready to Ship</p>    
                    ]                        
                
        }else{
          var ele = <p className="no-mrg-btm text-center" key={'packed-msg'} >No Packed Orders.</p>
        }
        return (
            <div className="packed-order">
                <div className="card fullfilled-count pdd-btm-15">
                    <div className="inline-loader" id="fullfilled_count_loader">
                        <SubLoader/>
                    </div>
                    <div className="card-block" id="fulfilled-bar">
                        {ele}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PackedOrders)