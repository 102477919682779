import { Component } from 'react'
import ReactDOM from 'react-dom'
import {Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import {Humanize, notify, ErrorHandling} from '../../js/CommonFunctions'
import SmartLeadTimeModal from '../../js/SmartLeadTimeModal'
import '../../styles/custom/pagination.css'
import '../Items/items.css'
const axios = require('axios').default;
var attributes_data =[]


const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number    
}
const defaultProps = {
    initialPage: 1
}

class Pagination extends Component {
    constructor(props) {
    super(props);
    this.state = { pager: {} };
    }

    componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
    }

    setPage(page) {
        var items = this.props.items;
        var pager = this.state.pager;
        var itemsCount = this.props.itemscount

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        pager = this.getPager(itemsCount, page);
        var pageOfItems = items;
        this.setState({ pager: pager });
        if(this.props.page_from){
            this.props.onChangePageHandler(pageOfItems);
        }else{
            this.props.onChangePage(pageOfItems);
        }
    }
    getCurrentPageItems(page){
       /* if(this.props.search_term){
        }else{
            var url = '{{ api_host }}/api/items/?field=id&field=item_number&field=name&field=description&field=item_type&field=price&page='+page
        }*/
        $('.collapse').collapse('hide');
        $('.item').removeClass('item-head').removeClass('item')        
        var category = JSON.parse(localStorage.getItem('category'))
        var category_ids = Object.keys(category).join(',')
        var attr_data = ''
        if(attributes_data.length > 0){
            attr_data = attributes_data.join(',')
        } 
        var search_term = ''
        if(this.props.search_term){
            search_term = this.props.search_term
        }
        $('.items-div').find('.inline-loader').fadeIn()
        var url = API_HOST+'/api/items/?page='+page+'&search_term='+search_term+'&category_id='+category_ids+'&page_size='+this.props.page_size+'&attributes='+attr_data+'&from_group='+this.props.from_group+'&ordering='+this.props.sort_by
        axios.get(url,
            ).then(response => {
            if(response.status == 200){ 
                var json_data = response.data.items   
                var items = json_data
                var itemCount = response.data.items_count
                var pager = this.state.pager;
                if (page < 1 || page > pager.totalPages) {
                    return;
                }
                pager = this.getPager(response.data.items_count, page);
                var pageOfItems = items;
                this.setState({ pager: pager });
                if(this.props.page_from){
                    this.props.onChangePageHandler(pageOfItems);
                }else{
                    this.props.onChangePage(pageOfItems);
                }
                
            }
            $('.items-div').find('.inline-loader').fadeOut('slow')
            
            }).catch(function (response) {
                ErrorHandling(response)
                $('.items-div').find('.inline-loader').fadeOut('slow')  
              });
    }

getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || this.props.page_size;
    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages < this.props.page_size) {
    startPage = 1;
    endPage = totalPages;
    } else {
    if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
    } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
    }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
    };
}

render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1 ) {
        // don't display pager if there is only 1 page
            if (pager.totalItems == 0){
                return null;
            }
        return <ul className="show-pages pull-left"><li>Showing  {pager.endIndex+1}  of {pager.totalItems} items</li></ul>
        }
    
        return (
            <div>
             { (pager.startIndex+1 === pager.endIndex+1 ) ?
                (<ul className=" show-pages pull-left">
                    <li> Showing  {pager.endIndex+1}  of {pager.totalItems} items </li>
                </ul>):
                (<ul className=" show-pages pull-left">
                    <li>Showing  {pager.startIndex+1} to {pager.endIndex+1}  of {pager.totalItems} items</li>
                </ul>)
            }
            <ul className="pagination">
                {pager.currentPage === 1?null:<li className="">
                    <a onClick={() => this.getCurrentPageItems(1)}>First</a>
                </li>}
                {pager.currentPage === 1 ?null:<li className= ''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage - 1)}>Previous</a>
                </li>}
                {pager.pages.map((page, index) =>
                    <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a onClick={() => this.getCurrentPageItems(page)}>{page}</a>
                    </li>
                )}
                {pager.currentPage === pager.totalPages ?null:<li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage + 1)}>Next</a>
                </li>}
                {pager.currentPage === pager.totalPages?null:
                <li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.totalPages)}>Last</a>
                </li>}
            </ul>
            </div>
            );
        }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

class ItemImageModal extends Component{
    constructor(props){
        super(props)
        this.state = {
            item_attachments:[]
        }
        this.onformSubmit = this.onformSubmit.bind(this)
    }
    componentWillMount(){  
        var item_id = this.props.item.item.id
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.get['Authorization'] =auth_token
            axios.get(API_HOST+'/api/item/attachments/?item_id='+item_id,
            ).then(response => {
            if(response.status == 200){
                this.setState({
                    item_attachments:response.data.item_attachments,
                })         
            }
            }).catch(response =>{
                ErrorHandling(response)
            });
    }
    onformSubmit(event){
        $(event.currentTarget)[0].submit()
    }
    render(){
        if(this.state.item_attachments.length > 0){
        var org_temp = this.state.item_attachments.map((attachment, index) =>{            
            return <li className="" key={index}>
                    <a href={attachment.signed_url?attachment.signed_url[0]:"void(0)"} target="_blank">{attachment.name}</a>
                </li>
        })}else{
            var org_temp = ''
        }
        return(
            <div className="modal fade modal-fs" id={"modal-fs-"+this.props.index} >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="vertical-align text-center">
                                    <div className="table-cell">
                                        <div className="container">
                                            <div className="row image-modal-row">
                                                <div className="col-md-8 mr-auto ml-auto">
                                                    <div className="image-response">
                                                            <img id="img01" className="w3-image w3-animate-zoom" src={this.props.item.image_signed_url == null ?"void(0);":this.props.item.image_signed_url[0]}/>
                                                    </div>
                                                    <div className="row image-preview">
                                                        <div className="col-lg-4"></div>
                                                        <div className="col-lg-4 text-left padding-5">
                                                            <h4 className="item_number">{this.props.item.item.item_number}</h4>
                                                            <p className="item-desc no-mrg-btm">{this.props.item.item.description}</p>
                                                            <p className="lead no-mrg-btm">
                                                                <sup className="currency"> {this.props.currency_symbol? this.props.currency_symbol:''}</sup><span className=" font-size-16 item-price">{this.props.item.price?Humanize(this.props.item.price):'0.00'}</span>
                                                            </p>
                                                            <p className="in-stock">
                                                                {this.props.item.in_stock?<div className="item-in-stock">In Stock {this.props.item.in_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>:<div className="item-in-stock"></div>}
                                                            </p>
                                                        </div>
                                                        <div className="col-lg-4"></div>
                                                        
                                                    </div>
                                                </div>
                                                { this.state.item_attachments.length >0 ? 
                                                <div className="col-md-4 suggested-imges-div">
                                                    <h5 className="text-left">Product Information:</h5>
                                                    <div className="attachment-card"> 
                                                        <ul className="item-attachments">
                                                                {org_temp}      
                                                        </ul>                                                              
                                                    </div>
                                                </div>:null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="modal-close btn-icon btn-flat btn-rounded" href="" data-dismiss="modal" title="Close" >
                                        <i className="far fa-times-circle"></i>
                                </a>                      
                                
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

class ItemFilters extends Component {
    constructor(props){
        super(props)
        this.showFiltersOnMoblieView = this.showFiltersOnMoblieView.bind(this)
    }

    showFiltersOnMoblieView(event){
        $(event.currentTarget).find('.dropdown-content').show()
    }
 
    render(){
        var expand_ul = []
        var item_attributes = this.props.filters_data.map((itemattrbute,indx)=>{
            var selectd_attr = []
            var expand_li = []
            var attribute_vlaues = itemattrbute.attribute_values.map((values,inx)=>{                
                  if(values.active){
                    selectd_attr.push(
                        <a className="item-attr-link active" href="void(0)" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug={values.name_slug}>{values.name}</a>
                    )
                    expand_li.push(
                        <li className="page-item active" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug={values.name_slug}>
                            <a className="page-link" href="void(0)" >{values.name}</a>
                        </li>
                    )
                  }else{
                    expand_li.push(
                        <li className="page-item" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug={values.name_slug}>
                            <a className="page-link" href="void(0)" >{values.name}</a>
                        </li>
                    )
                  }
                  return <a key={inx} className="page-link" href="void(0)" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug={values.name_slug}>{values.name}</a>
            })
            if(selectd_attr.length > 0){
                selectd_attr.push(
                    <a className="item-attr-link clear-filter fa fa-times" href="void(0)" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug='clear'></a>
                )
            }
            expand_ul.push(
                <ul className="pagination pagination-sm filters-btn">
                    <li className="page-item disabled">
                        <a className="page-link" href="void(0)" tabIndex="-1" name_slug={itemattrbute.name_slug}>{itemattrbute.name}</a>
                      </li>
                      {expand_li}
                      <li className="page-item" onClick={this.props.apply_filters} filter-type={itemattrbute.name_slug} name_slug='clear'>
                        <a className="page-link clear-filter fa fa-times" href="void(0)"  ></a>
                      </li>
                  </ul>  
            )
            
            return <div className="attribute-dropdown" key={indx}>
                  <div className="attribute-btn" onClick={this.showFiltersOnMoblieView}>
                    <a href="void(0)" className="dropbtn" name_slug={itemattrbute.name_slug} onClick = {(e) =>e.preventDefault() } >{itemattrbute.name}  <i className="fas fa-angle-down arrow-down-icon"></i></a>
                    <div className="dropdown-content">
                        {attribute_vlaues}
                     </div>
                  </div>
                   {selectd_attr}             
                
              </div>

              

        })
          return([<div id="div-filter" ></div>,            
                <div className="filter-mrgn">
                    <div className="view-options-button filter-align-child-items">
                        <a title="Collapse" className="collapsed-view active" onClick={this.props.expandFilters}><i className="fas fa-compress-alt" aria-hidden="true"></i></a>
                        <a  title="Expand" btn-type="expand_view" className="expand-view" onClick={this.props.expandFilters}><i className="fas fa-expand-alt" aria-hidden="true"></i></a>
                    </div>
                    <a className="clear-filters" click-from="attribute" onClick={this.props.clear_filters} >CLEAR FILTERS</a>
                </div>
            ,
            <nav aria-label="..." className={this.props.filter_expand?"d-none":"filter-collapse"}>
               {item_attributes}
            </nav>,
            <nav aria-label="..." className={this.props.filter_expand?"filter-expand":"d-none"}>
                {expand_ul}
             </nav>]
          )
    }
}


export default class Items extends Component {
    constructor(props) {
        super(props);
        this.count = 0
        this.focus = true
        this.data_from_search = 0
        this.state = {
            exampleItems: [],
            pageOfItems: [],
            pageCount:0,
            cart_ids:JSON.parse(localStorage.getItem('cart_item_ids')),
            page_size:12,
            item_attachment:[],
            currency_symbol:'',
            category_id:[],
            search_term:localStorage.getItem('search_term')?localStorage.getItem('search_term'):'',
            view:{showModal:false},
            lead_time_data:[],
            selected_category:JSON.parse(localStorage.getItem('category')),
            index_count:props.index_count,     
            warehouse:'',
            filters_data:[],
            from_group:false,
            sort_by:'-in_stock',
            feature_item: this.props.location.state?this.props.location.state.params.feature_item:'',
            feature_item_id: this.props.location.state?this.props.location.state.params.feature_item_id:'',
            view_type:'list-group-item',
            selected_attributes:[],
            filter_expand:false
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.AddToCartHandler = this.AddToCartHandler.bind(this)
        this.showEntries = this.showEntries.bind(this)
        this.getItemAttachment = this.getItemAttachment.bind(this)
        this.modalAnimations = this.modalAnimations.bind(this)
        this.checkAviailableQuantity = this.checkAviailableQuantity.bind(this)
        this.handleHideModal = this.handleHideModal.bind(this)
        this.BrowseCategoryHandler = this.BrowseCategoryHandler.bind(this)
        this.fromIndexPage = this.fromIndexPage.bind(this)
        this.getItemData = this.getItemData.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.inputMaxQty = this.inputMaxQty.bind(this)
        this.changeView = this.changeView.bind(this)
        this.expandFilters = this.expandFilters.bind(this)
        this.restrictDecimal = this.restrictDecimal.bind(this)
    }

    componentDidMount(){
        $('.items-div').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token  
         var query =  new URLSearchParams(this.props.location.search)
         //if('{{ group_name }}'){
           if(query.get('groupname')){
             if(this.props.data_request){
                 this.getItemData() 
             }else{
             var url = API_HOST+'/api/items/category/'+query.get('groupname')+'/group/info/?ordering='+this.state.sort_by+'&page_size='+parseFloat(this.state.page_size)
             axios.get(url,
             ).then(response => {
               if(response.status == 200){
                   var selected_category = JSON.parse(localStorage.getItem('category'))
                   response.data.categories.map(function(category){
                     selected_category[category.id] = category.name
                   })   
                   localStorage.setItem('category', JSON.stringify(selected_category))
                   if(response.data.items){
                     // var json_data = conversion_amount(response.data.quotations)
                      var json_data = response.data.items
                    }else{
                        var json_data = []
                    }
                    localStorage.setItem('currency_symbol',response.data.currency_symbol )
                    if(response.data.warehouses){
                      var warehouse = response.data.warehouses[0] 
                  }else{
                      var warehouse = ''
                  }
                  this.setState(()=>{
                      return {
                          pageOfItems: json_data,
                          exampleItems: json_data,
                          pageCount:response.data.items_count,
                          currency_symbol:response.data.currency_symbol,
                          warehouse:warehouse,
                          selected_category:selected_category,
                          filters_data:response.data.attributes,
                          from_group:true
                      }
                   })                             
                                 
               }
               $(".lds-ripple-parent").fadeOut("slow");
               $('.inline-loader').fadeOut("slow");
                     
             }).catch(response => {
                ErrorHandling(response)
             });
          }
          }else{
             this.getItemData() 
          }
   
 }
 
 getItemData(){
    const auth_token = "Token "+localStorage.token  
     axios.defaults.headers.get['Authorization'] =auth_token 
     var search_text = this.state.search_term?this.state.search_term:''
     if(this.props.search_text){
         search_text = this.props.search_text
     }      
     var category_data= this.state.selected_category
     var category_ids = Object.keys(category_data).join(',')
     var attr_data = ''
     if(attributes_data.length > 0){
         attr_data = attributes_data.join(',')
     } 
     if(this.state.feature_item){
         var url = API_HOST+'/api/items/?search_term='+ this.state.feature_item+'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+this.state.sort_by+'&page_size='+parseFloat(this.state.page_size)
     }else{
         var url = API_HOST+'/api/items/?search_term='+ search_text+'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+this.state.sort_by+'&page_size='+parseFloat(this.state.page_size)
     }        
     axios.get(url,
     ).then(response => {
       if(response.status == 200){
         //$('#sub-category').show() 
           if(response.data.items){
            // var json_data = conversion_amount(response.data.quotations)
             var json_data = response.data.items
           }else{
               var json_data = []
           }
           localStorage.setItem('currency_symbol',response.data.currency_symbol )
           if(response.data.warehouses){
             var warehouse = response.data.warehouses[0] 
         }else{
             var warehouse = ''
         }
         if(this.props.from_browse == false){
             
         }
         this.setState(()=>{
             return {
                 pageOfItems: json_data,
                 exampleItems: json_data,
                 pageCount:response.data.items_count,
                 currency_symbol:response.data.currency_symbol,
                 search_term:search_text,
                 category_id:category_ids,                    
                 warehouse:warehouse,
                 filters_data:response.data.attributes || [],
                 from_group:false
             }
          })                    
       }
       $(".lds-ripple-parent").fadeOut("slow");
       $('.inline-loader').fadeOut("slow");
 }).catch(response => {
    ErrorHandling(response)
 });
 
 
 }
 
 handleHideModal(row_index){
     $('#item_qty_'+row_index).val('')
     this.setState({view: {showModal: false}})
 }
 
 showEntries(event){
         $('.collapse').collapse('hide');
         $('.item').removeClass('item-head').removeClass('item')
         if($(event.currentTarget).hasClass('sort_by')){
             var sort_by = event.currentTarget.value
             var page_size = this.state.page_size
         }else{
             var sort_by = this.state.sort_by
             var page_size = event.currentTarget.value
         }
         var category = JSON.parse(localStorage.getItem('category'))
         var category_ids = Object.keys(category).join(',')
         var attr_data = ''
         if(attributes_data.length > 0){
             attr_data = attributes_data.join(',')
         }
         var search_term = ''
         if(this.state.search_term){
             search_term = this.state.search_term
         }
         //$('table.table-hover').find('tbody').html('')
          
         $('.items-div').find('.inline-loader').fadeIn()
         const auth_token = "Token "+localStorage.token
         axios.defaults.headers.get['Authorization'] =auth_token
         if(this.state.feature_item){
             var url = API_HOST+'/api/items/?page_size='+parseFloat(page_size)+'&search_term='+ this.state.feature_item +'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+sort_by
         }else{
             var url = API_HOST+'/api/items/?page_size='+parseFloat(page_size)+'&search_term='+search_term+'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+sort_by
         }        
         axios.get(url,
         ).then(response => {
           if(response.status == 200){ 
               if(response.data.items){
                // var json_data = conversion_amount(response.data.quotations)
                 var json_data = response.data.items
               }else{
                   var json_data = []
               }
               if(response.data.warehouses){
                 var warehouse = response.data.warehouses[0] 
             }else{
                 var warehouse = ''
             }
             this.setState(()=>{
                 return {
                     pageOfItems: json_data,
                     exampleItems: json_data,
                     pageCount:response.data.items_count,
                     page_size:parseFloat(page_size),
                     warehouse:warehouse,
                     sort_by:sort_by        
                 }
              })            
           }
           $('.items-div').find('.inline-loader').fadeOut('slow')
   }).catch(response => {
    ErrorHandling(response)
     $('.items-div').find('.inline-loader').fadeOut('slow')
 });
 
     }
     modalAnimations(event){       
         var height = $(event.currentTarget).closest('div.items-view').find('.img-fluid')[0].height
         $(event.currentTarget).closest('div.items-view').find('#img01').addClass('modal-content-hover')
         if(height >= 190){
             $(event.currentTarget).closest('div.items-view').find('#img01').addClass('large-size-image-width')
         }
         else if(height > 160) {
             $(event.currentTarget).closest('div.items-view').find('#img01').addClass('small-image-width')
         } else {
             $(event.currentTarget).closest('div.items-view').find('#img01').addClass('big-image-width') 
         }
     }
     getItemAttachment(event){
             event.preventDefault()
             const the_tr = event.currentTarget
             //$('.item-image').find('.card-loader').fadeIn()
             $('.panel-collapse').addClass('item-attachment')
             $('.collapse').collapse('hide');
             $('.item').removeClass('item-head').removeClass('item')            
             var aria_expanded = event.currentTarget.getAttribute('aria-expanded')
             if(aria_expanded == 'true'){
                 $(event.currentTarget).closest('tr').next().addClass('item')
                 $(event.currentTarget).closest('tr').addClass('item').addClass('item-head')
                 $(event.currentTarget).closest('tr').addClass('remove-border')
                 if($(event.currentTarget).closest('tr').prev().prev()[0]){
                     $(event.currentTarget).closest('tr').prev().prev().addClass('remove-bottom-border')
                 }else{
                     $('thead.items-header > tr').addClass('remove-bottom-border')
                 }
                 var item_id = event.currentTarget.getAttribute('item_id')
                const auth_token = "Token "+localStorage.token
                 axios.defaults.headers.get['Authorization'] =auth_token
                 axios.get(API_HOST+'/api/item/attachments/?item_id='+item_id,
                 ).then(response => {
                 if(response.status == 200){
                     this.state.item_attachment = response.data.item_attachments               
                     this.setState({
                         item_attachment:this.state.item_attachment,
                         preloader:false
 
                     })          
                     $('html,body').animate({
                         scrollTop: $(the_tr).closest('tr').offset().top - $(the_tr).closest('tr').height()
                       }, 500);   
                 }
                 $('.panel-collapse').removeClass('item-attachment')
                 $('.item-image').find('.card-loader').fadeOut('slow')
                 }).catch(response =>{
                    ErrorHandling(response)
                 });
                         
         }else{
             $(event.currentTarget).closest('tr').next().removeClass('item')
             $(event.currentTarget).closest('tr').removeClass('item').removeClass('item-head')
             $('.panel-collapse').addClass('item-attachment')
             $('tr.item-details-tr').removeClass('remove-border').removeClass('remove-bottom-border')
             $('thead.items-header > tr').removeClass('remove-bottom-border')
         }
     }
   
     onChangePage(pageOfItems) {
         // update state with new page of items
         this.setState({ pageOfItems: pageOfItems });
     }
     checkAviailableQuantity(event){   
         event.preventDefault()     
         var this_value = $(event.currentTarget).closest('div.item-qty-div').find('.quantity').val()
         var row_index =  $(event.currentTarget).attr('row-index')
         var item_id = $(event.currentTarget).attr('item_id')                   
         if(parseFloat(this_value) > 0 && item_id){
         $(".lds-ripple-parent-1").fadeIn();                    
         //var stock_id = $(event.currentTarget).attr('stock-id')
         //var warehouse_id = $(event.currentTarget).attr('warehouse_id')
         var category = $(event.currentTarget).attr('category')                    
         var lead_time_json = {}
         lead_time_json[1] = {}
         lead_time_json[1][item_id] = {
             //warehouse_id:warehouse_id, 
             quantity:parseFloat(this_value)
         }                   
         /*var form_data = {
             'item_id': item_id,
             //'stock_id': stock_id,
             //'warehouse_id': warehouse_id,
             'quantity': this_value,
             'lead_time_items': JSON.stringify(lead_time_json)
         }*/
         //var csrf_token = '{{ csrf_token }}' 
         var form_data = 'item_id='+item_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)
         const auth_token = "Token "+localStorage.token
         axios.defaults.headers.post['Authorization'] =auth_token
         axios.post(API_HOST+'/api/get/item/lead/time/', form_data)
             .then(response => {
             if(response.status == 200){
                 var check_availability = false
                 var available_item_data = {}
                 available_item_data['available'] = response.data.lead_time.available
                 available_item_data['backorder'] = response.data.lead_time.backorder
                 available_item_data['intransit'] = response.data.lead_time.intransit
                 available_item_data['this_value'] = this_value
                 var item_data =[]
                 var check_count = 0
                 response.data.lead_time.data.map((warehouse_item, index) =>{
                    if(warehouse_item.lead_time.length > 0){
                     var warehouse_id = response.data.lead_time.warehouse_list[index]
                     var item_obj = {}
                     item_obj.warehouse_details = warehouse_item[warehouse_id].warehouse_details
                     //item_obj.lead_time = warehouse_item.lead_time
                     item_obj.final_msg = warehouse_item.final_message
                     item_obj.intransit_qty = 0
                     item_obj.intransit_list = []
                     warehouse_item.lead_time.map((lead_time_item,indx) =>{                         
                         if(lead_time_item.status == 'available'){
                             item_obj.avi_qty = lead_time_item.quantity
                             item_obj.avi_lead_time = lead_time_item.item_lead_time
                             item_obj.lead_time = lead_time_item.item_lead_time
                         }else if(lead_time_item.status == 'intransit'){
                             item_obj.intransit_qty += lead_time_item.quantity
                             item_obj.intransit_lead_time = lead_time_item.item_lead_time
                             var obj = {'quantity':lead_time_item.quantity,'lead_time':lead_time_item.item_lead_time,'message':lead_time_item.message}
                             item_obj.intransit_list.push(obj)
                         }
                         
                         if(parseFloat(this_value) <= parseFloat(lead_time_item.quantity) && lead_time_item.add_to_cart){
                             if(check_count == 0 ){
                                 check_availability = true 
                             }                            
                         }
                         
                         item_obj.item_id = lead_time_item.item_id                     
                     })
                     check_count += 1
                     item_obj.item_and_box_volume = response.data.lead_time.item_volume_weight.item_and_box_volume
                     item_obj.item_and_box_weight = response.data.lead_time.item_volume_weight.item_and_box_weight
                     item_obj.selected_item = true
                     item_obj.item_number = this.state.pageOfItems[parseFloat(row_index)].item.item_number
                     item_obj.item_description = this.state.pageOfItems[parseFloat(row_index)].item.name
                     item_obj.price = this.state.pageOfItems[parseFloat(row_index)].price
                     item_obj.cart_row_index = row_index
                     item_obj.quantity = this_value
                     item_data.push(item_obj)
                   }
 
                 })
                 available_item_data['item_data'] = item_data
                 if(check_availability){
                     this.AddToCartHandler(item_data) 
                 }else{
                     this.setState({
                         view: {showModal: true},
                         lead_time_data:available_item_data,
                     })
                     
                 }
                 $(".lds-ripple-parent-1").fadeOut("slow");
                 
                                 
             }
             }).catch(response => {
                ErrorHandling(response)
         });
         }else{
             $(".lds-ripple-parent-1").fadeOut("slow");
             notify("Please Check Item's Quantity.",'error')
             $(event.currentTarget).closest('div.item-qty-div').find('.quantity').addClass('error')
             setTimeout(() => {
                 $('.quantity').removeClass('error')
               }, 6000); 
 
         }
 
     }
 
     
     AddToCartHandler(data){
         var item_number = data[0].item_number
         var item_id = data[0].item_id
        /* var item_data = this.state.pageOfItems.filter((item,index) =>{
             if(item.item.id == item_id){
                 return item
             }
         })*/
         data.map((item)=>{
             if(localStorage.getItem('cart_item_ids')){
                 var cart_ids = JSON.parse(localStorage.getItem('cart_item_ids'))
                 cart_ids.push( parseFloat(item.item_id))
                 localStorage.setItem('cart_item_ids',JSON.stringify(cart_ids))
             }else{
                 localStorage.setItem('cart_item_ids',JSON.stringify([parseFloat(item.item_id)]))  
             }
         })
         
         this.setState({
             cart_ids:JSON.parse(localStorage.getItem('cart_item_ids')),
             pageOfItems:this.state.pageOfItems
         })  
         var form_data = new FormData()
         //var csrf_token = '{{ csrf_token }}'
         //form_data.append('csrfmiddlewaretoken',csrf_token)
         form_data.append('item_data', JSON.stringify(data))
         const auth_token = "Token "+localStorage.token
         axios.defaults.headers.post['Authorization'] =auth_token
         $(".lds-ripple-parent-1").fadeIn(); 
         axios({
             method: 'post', 
             url: API_HOST+'/cart/get/cart/items/',
             data: form_data,
             headers: { 
             "Content-Type": "application/x-www-form-urlencoded",
             },
         }).then((response) => {
             if(response.status == 200 && response.data.success){                         
                 notify(item_number+' added to Cart','success')
             }
             this.setState({
                 view:{showModal:false}
             })
             $(".lds-ripple-parent-1").fadeOut('slow')
             
         }).catch(response => {
             var cart_item_ids = JSON.parse(localStorage.getItem('cart_item_ids'))
             cart_item_ids.splice(cart_item_ids.indexOf(item_id.toString()),1)
             localStorage.setItem('cart_item_ids',JSON.stringify(cart_item_ids))
             this.setState({
                 cart_ids:JSON.parse(localStorage.getItem('cart_item_ids')),
                 pageOfItems:this.state.pageOfItems
             })
             ErrorHandling(response)
             $(".lds-ripple-parent-1").fadeOut('slow')
         });
     /*
         if(localStorage.getItem('cart_items')){
             var cart_items = JSON.parse(localStorage.getItem('cart_items')).concat(item_data)
             localStorage.setItem('cart_items',JSON.stringify(cart_items))
         }else{
             localStorage.setItem('cart_items',JSON.stringify(item_data))  
         }*/
     
     
 }
 
 
 BrowseCategoryHandler(event){
     event.preventDefault()
     var attribute_type = $(event.currentTarget).attr('filter-type')
     var category = JSON.parse(localStorage.getItem('category'))
     var from_attributes = false
     if(attribute_type){
         $('#div-filter').fadeIn()
         from_attributes = true
         var attr_name_slug = $(event.currentTarget).attr('name_slug')
         this.state.filters_data.map((attribute,indx)=>{
             if(attribute.name_slug == attribute_type){
                 if(attr_name_slug == 'clear'){
                     this.state.filters_data[indx].attribute_values.map((values,inx)=>{
                         this.state.filters_data[indx]['attribute_values'][inx]['active']=false
                         if(attributes_data.includes(values.name_slug)){
                            attributes_data.splice(attributes_data.indexOf(values.name_slug),1)
                         }
                     }) 
                 }else{
                     this.state.filters_data[indx].attribute_values.map((values,inx)=>{
                         if(values['name_slug'] == attr_name_slug){
                             if($(event.currentTarget).hasClass('active')){
                                 this.state.filters_data[indx]['attribute_values'][inx]['active']=false
                                 attributes_data.splice(attributes_data.indexOf(attr_name_slug),1)
                             }else{
                                 attributes_data.push(attr_name_slug)
                                 this.state.filters_data[indx]['attribute_values'][inx]['active']=true
                             }
                         }                        
                     })                    
                 }
 
             }
         })
         
     }else{
         var category_id = $(event.currentTarget).parent('span').attr('id')
         var category_name = $(event.currentTarget).parent('span').attr('name')
         delete category[category_id]
     }
     //this.state.category_id.splice(this.state.category_id.indexOf(category_id),1)
     var category_ids = Object.keys(category).join(',')
     var attr_data = ''
     if(attributes_data.length > 0){
         attr_data = attributes_data.join(',')
     } 
     var search_text = ''
     if(localStorage.getItem('search_term') ){
         search_text = localStorage.getItem('search_term') 
     }
       
     $('.items-div').find('.inline-loader').fadeIn()
     const auth_token = "Token "+localStorage.token
     axios.defaults.headers.get['Authorization'] =auth_token      
     var url = API_HOST+'/api/items/?search_term='+search_text+'&category_id='+category_ids+'&page_size='+parseFloat(this.state.page_size)+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+this.state.sort_by
     axios.get(url,
     ).then((response) => {
       if(response.status == 200){
         //$('#sub-category').show() 
           if(response.data.items){
            // var json_data = conversion_amount(response.data.quotations)
             var json_data = response.data.items
           }else{
               var json_data = []
           }
           localStorage.setItem('currency_symbol',response.data.currency_symbol )
           localStorage.setItem('category',JSON.stringify(category) )        
         if(from_attributes){
             this.setState(()=>{
             return {                
                     pageOfItems: json_data,
                     exampleItems: json_data,
                     pageCount:response.data.items_count,
                     currency_symbol:response.data.currency_symbol,
                     search_term:localStorage.getItem('search_term'),
                     selected_category:category,
                     page_size:parseFloat(this.state.page_size),
                     view_type:this.state.view_type  , 
                     filters_data:this.state.filters_data                
                 }
              }) 
 
         }else{
             this.setState(()=>{
                 return {
                     pageOfItems: json_data,
                     exampleItems: json_data,
                     pageCount:response.data.items_count,
                     currency_symbol:response.data.currency_symbol,
                     search_term:localStorage.getItem('search_term'),
                     selected_category:category,
                     page_size:parseFloat(this.state.page_size),
                     filters_data:response.data.attributes || [],
                     view_type:this.state.view_type
                 }
             }) 
         }
         $('.items-div').find('.inline-loader').fadeOut("slow");
       }
       $('#div-filter').fadeOut("slow");
       
 }).catch(response => {
    ErrorHandling(response)
    $('.items-div').find('.inline-loader').fadeOut("slow");
 
 });
 
 }
 

 
 fromIndexPage(){
         const auth_token = "Token "+localStorage.token       
         axios.defaults.headers.get['Authorization'] =auth_token        
         var category_data= JSON.parse(localStorage.getItem('category')) 
         attributes_data = []
         var category_ids = Object.keys(category_data).join(',')
         var attr_data = ''
         if(attributes_data.length > 0){
             attr_data = attributes_data.join(',')
         }
         var search_text = ''
         if(localStorage.getItem('search_term')){
             search_text = localStorage.getItem('search_term')
         }         
         $('.items-div').find('.inline-loader').fadeIn()       
         var url = API_HOST+'/api/items/?search_term='+search_text+'&page_size='+parseFloat(this.state.page_size)+'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+this.state.sort_by
         axios.get(url,
         ).then(response => {
           if(response.status == 200){
             //$('#sub-category').show() 
               if(response.data.items){
                // var json_data = conversion_amount(response.data.quotations)
                 var json_data = response.data.items
               }else{
                   var json_data = []
               }
               localStorage.setItem('currency_symbol',response.data.currency_symbol )
             this.setState(()=>{
                 return {
                     pageOfItems: json_data,
                     exampleItems: json_data,
                     pageCount:response.data.items_count,
                     currency_symbol:response.data.currency_symbol,
                     search_term:search_text,
                     category_id:category_ids,
                     selected_category:category_data,
                     index_count:1,
                     feature_item:'',
                     filters_data:response.data.attributes || []
                     
                 }
              })       
              $('.items-div').find('.inline-loader').fadeOut('slow')
           }
     }).catch(response => {
        ErrorHandling(response)
        $('.items-div').find('.inline-loader').fadeOut('slow')
     });
 }
 
 componentWillReceiveProps(nextProps) {
     this.setState({ 
         index_count: nextProps.index_count,
         search_term:nextProps.search_text
      });  
   }
 
 clearFilters(event){
     var click_from = $(event.currentTarget).attr('click-from')
     attributes_data = []
     if(click_from){         
         
     }else{
         localStorage.setItem('category',JSON.stringify({}))
         //$('.search_input').val('')
         //this.state.search_term = ''
     }
     $('.items-div').find('.inline-loader').fadeIn()
     const auth_token = "Token "+localStorage.token
     axios.defaults.headers.get['Authorization'] =auth_token        
         var category_data= JSON.parse(localStorage.getItem('category')) 
         var category_ids = Object.keys(category_data).join(',')
         var attr_data = ''
         if(attributes_data.length > 0){
             attr_data = attributes_data.join(',')
         }
         var search_text = ''
         if(this.state.search_term){
             search_text = this.state.search_term
         }         
         var url = API_HOST+'/api/items/?search_term='+search_text+'&category_id='+category_ids+'&attributes='+attr_data+'&from_group='+this.state.from_group+'&ordering='+this.state.sort_by+'&page_size='+parseFloat(this.state.page_size)
         axios.get(url,
         ).then(response => {
           if(response.status == 200){
             //$('#sub-category').show() 
               if(response.data.items){
                // var json_data = conversion_amount(response.data.quotations)
                 var json_data = response.data.items
               }else{
                   var json_data = []
               }
               localStorage.setItem('currency_symbol',response.data.currency_symbol )
             this.setState(()=>{
                 return {
                     pageOfItems: json_data,
                     exampleItems: json_data,
                     pageCount:response.data.items_count,
                     currency_symbol:response.data.currency_symbol,
                     search_term:search_text,
                     category_id:category_ids,
                     selected_category:category_data,
                     index_count:1,
                     filters_data:response.data.attributes || []
                     
                 }
              })                    
           }
           $('.items-div').find('.inline-loader').fadeOut("slow");
     }).catch(response => {
        ErrorHandling(response)
        $('.items-div').find('.inline-loader').fadeOut("slow");
     });
   }
   
 inputMaxQty(event) {
       var el = $(event.currentTarget),
           val = Math.max((0, el.val())),
           max = parseInt(99999999);  
       el.val(isNaN(max) ? parseFloat(val.toString().replace(/[^0-9]+/g, "")) : Math.min(max, val));
 }

 restrictDecimal(e){
     if(e.key==='.'){e.preventDefault()}
 }
 
 changeView(event){
     var btn_type = $(event.currentTarget).attr('btn-type')
     if(btn_type == 'list_view'){
         $('#products .items-view').addClass('list-group-item').removeClass('grid-group-item')    
     this.setState({
         view_type:'list-group-item'
     })
     }else{
         $('#products .items-view').removeClass('list-group-item').addClass('grid-group-item')
         this.setState({
             view_type:'grid-group-item'
         })    
     }
     $(event.currentTarget).closest('div').find('a.active').removeClass('active')
     $(event.currentTarget).addClass('active') 
 }
 
 expandFilters(event){
     var btn_type = $(event.currentTarget).attr('btn-type')
     if(btn_type == 'expand_view'){
         $('.collapsed-view').removeClass('active')
         $('.expand-view').addClass('active')    
         this.setState({
             filter_expand:true,
             filters_data:this.state.filters_data
         })
     }else{
         $('.collapsed-view').addClass('active')
         $('.expand-view').removeClass('active')    
         this.setState({
             filter_expand:false,
             filters_data:this.state.filters_data
         })
     }
      
 }
 
 componentDidUpdate(){
     if(this.props.location.search){
         this.fromIndexPage()
         this.props.location.search = ''
     }
    this.props.location.state = undefined
    if(this.props.location.page){
        if(this.props.location.page.load){
            this.props.location.page = {load:false}
            localStorage.setItem('category', JSON.stringify({}))
            localStorage.setItem('search_term', '')
            $('.search_input').val('')
            this.refreshPage()
        }
    }
    /* if($("div.items-view[item_id='{{ feature_item_id }}']")[0]){
         if(this.focus){       
             $("div.items-view[item_id='{{ feature_item_id }}']").addClass('feature-focus');
             $('html,body').animate({
                 scrollTop: $( "div.items-view[item_id='{{ feature_item_id }}']").offset().top - $( "div.items-view[item_id='{{ feature_item_id }}']").height()
             }, 500);
             setTimeout(()=>{            
                 $( "div.items-view[item_id='{{ feature_item_id }}']").removeClass('feature-focus'); 
                 this.focus = false            
             },5000)
         }
     }*/
 }

 refreshPage(){
    const auth_token = "Token "+localStorage.token  
    axios.defaults.headers.get['Authorization'] =auth_token 
    var url = API_HOST+'/api/items/?&ordering='+this.state.sort_by
    $('.items-div').find('.inline-loader').fadeIn()
    $('#div-filter').fadeIn();
    attributes_data = []
    axios.get(url,
    ).then(response => {
      if(response.status == 200){
        //$('#sub-category').show() 
          if(response.data.items){
           // var json_data = conversion_amount(response.data.quotations)
            var json_data = response.data.items
          }else{
              var json_data = []
          }
          localStorage.setItem('currency_symbol',response.data.currency_symbol )
          if(response.data.warehouses){
            var warehouse = response.data.warehouses[0] 
        }else{
            var warehouse = ''
        }
        if(this.props.from_browse == false){
            
        }
        this.setState(()=>{
            return {
                pageOfItems: json_data,
                exampleItems: json_data,
                pageCount:response.data.items_count,
                currency_symbol:response.data.currency_symbol,
                search_term:'',
                category_id:[],                    
                warehouse:warehouse,
                from_group:false,
                item_attachment:[],
                selected_category:JSON.parse(localStorage.getItem('category')),
                selected_attributes:[],
                filter_expand:false,
                filters_data:response.data.attributes || [],
                cart_ids:JSON.parse(localStorage.getItem('cart_item_ids')),
                page_size:12,
                view:{showModal:false},
                lead_time_data:[],
                index_count:this.props.index_count,     
                sort_by:'-in_stock',
                feature_item:'',
                feature_item_id: '',
                view_type:'list-group-item',
            }
         })
         $('.collapsed-view').addClass('active')
         $('.expand-view').removeClass('active')                    
      }
      $('.items-div').find('.inline-loader').fadeOut("slow");
     $('#div-filter').fadeOut("slow");
}).catch(response => {
   ErrorHandling(response)
});


 }
 
render() {  
        if(this.state.filters_data.length > 0){
            ReactDOM.render(<ItemFilters expandFilters={this.expandFilters} filter_expand={this.state.filter_expand} filters_data={this.state.filters_data} clear_filters={this.clearFilters}  apply_filters={this.BrowseCategoryHandler}/>,document.getElementById('item_filters'))
        }      
        var org_temp = this.state.item_attachment.map((attachment, index) =>{            
                    return <li>
                            <a href={attachment.signed_url[0]} target="_blank">{attachment.name}</a>
                        </li>
        })
        const tr_elements = this.state.pageOfItems.map((item, index) =>{
            return <div className={"items-view col-xs-3 col-lg-3 d-flex " +this.state.view_type} item_id={item.item.id}>
                <div className="thumbnail card">
                    {item.image_signed_url == null?
                        <div className="img-event no-image-available" title="No Image Available">
                        </div>
                        :
                        [
                        <div className="img-event" data-toggle="modal" data-target={"#modal-fs-"+index}  onClick={this.modalAnimations}>
                            <img className="group list-group-image img-fluid" src={item.image_signed_url == null ?"void(0);":item.image_signed_url[0]} alt="No Image Available"/>
                        </div> ,
                        <ItemImageModal index={index} item={item} currency_symbol={this.state.currency_symbol} /> ]                     
                    }
                    <div className="caption card-body">
                        <h4 className="group card-title inner list-group-item-heading">{ item.item.item_number}</h4>
                        <p className="group inner list-group-item-text">
                            {item.item.description? item.item.description:null}
                        </p>
                        <p className="lead">
                            <sup className="currency"> {this.state.currency_symbol? this.state.currency_symbol:''}</sup><span className="item-price">{item.price?Humanize(item.price):'0.00'}</span>
                        </p>
                        <p className="in-stock">
                            {item.in_stock?<div className="item-in-stock">In Stock {item.in_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>:<div className="item-in-stock"></div>}
                        </p>                        
                    </div>
                    <div className="add-to-cart">
                        {this.state.cart_ids.includes(( parseFloat(item.item.id)))?
                            <div className="input-group item-qty-div">
                                <Link to="/cart" className="btn btn-info go-to-cart btn-animation" role="button"> 
                                <i className="fa fa-shopping-cart"></i> GO TO CART
                                </Link>                                
                                </div>
                        :    
                        <div className="input-group item-qty-div">
                            <input type="number" min="1" className="form-control quantity text-right" 
                            placeholder="Quantity" id={'item_qty_'+index}  
                            onChange={this.inputMaxQty} 
                            onKeyDown = {this.restrictDecimal}
                            tabIndex="0"
                            />
                            <a href="void(0);" title="Add to Cart" item_id={item.item.id} className=" btn btn-icon btn-flat btn-rounded add-to-cart remove-item"
                            onClick={this.checkAviailableQuantity } stock-id='' 
                            warehouse_id={this.state.warehouse}   item_id={item.item.id} 
                            row-index={index} >
                            <i className="fas fa-cart-plus"></i></a>
                        </div>
                    }   
                        
                    </div>
                </div>

            </div> 
            
                    });
        if(!this.state.view.showModal){
            $('#smart-lead-time-modal-dialog').modal('hide');
        }
        var category = this.state.selected_category
        var this_category_ids = Object.keys(category)
        if(this_category_ids.length > 0){
            $('#filter-div').show()
        }else{
            $('#filter-div').hide()
        }
        var input_tags = this_category_ids.map((this_id,index) =>{
            var name = category[this_id]
            return(
                <span className="tag label label-info" id={this_id} name={name}>{name}<span data-role="remove"  onClick={this.BrowseCategoryHandler}></span></span>
            )
        })
        $('select.sort_by').val(this.state.sort_by)
        $('select.show_entries').val(this.state.page_size.toString())
        $('.card-block').removeClass('add-pdding')
        return (
            <div className="container-fluid items-container">
                <div className="row">
                    <div className="col-lg-12 div-filter ">                     
                        <div className="card parts-catalog-filters" id="item_filters">                                    
                        </div>
                    </div>
                    <div className="col-lg-12 items-div ">
                        <div className="inline-loader" id="items_loader">
                            <SubLoader/>
                        </div>
                        <div className="card ">
                            <div id="filter-div">
                            <a className="clear-filters item-clear-filter" onClick={this.clearFilters}>CLEAR FILTERS</a>
                            </div>
                            <div className="card-block" id="item-component">
                            <div className="table-overflow" > 
                <div className="row">
                    <div className="col-xs-12 col-md-1">
                        { this.state.pageCount > 0?
                        <div className="view-options-button right-align-child-items">
                            <a title="List View" className={this.state.view_type == 'list-group-item'?'active':null} btn-type="list_view" onClick={this.changeView}><i className="fa fa-list-ul" aria-hidden="true"></i></a>
                            <a  title="Grid View" btn-type="grid_view" className={this.state.view_type == 'grid-group-item'?'active':null} onClick={this.changeView}><i className="fa fa-th-large" aria-hidden="true"></i></a>
                        </div>:null}
                    </div>
                    <div className={this.state.pageCount > 12?"col-xs-12 col-md-6":"col-xs-12 col-md-8"}>
                        <div className="bootstrap-tagsinput" >{input_tags}</div> 
                    </div>                    
                    <div className="col-xs-12 col-md-3">
                        { this.state.pageCount > 0?
                            <div className="dataTables_length" id="dt-opt_length">
                                <label>Sort By
                                    <select name="dt-opt_length" className="sort_by" onChange={this.showEntries}>
                                        <option value="-in_stock">In Stock</option>
                                        <option value="name">0-9, A-Z</option>
                                        <option value="-name">Z-A, 9-0</option>
                                        <option value="price">Price: Low to High</option>
                                        <option value="-price">Price: High to Low</option>
                                    </select>
                                    </label>
                            </div>:null}
                    </div>
                    { this.state.pageCount > 12?
                        <div className="col-xs-12 col-md-2">
                            
                                <div className="dataTables_length" id="dt-opt_length">
                                    <label>Show
                                        <select name="dt-opt_length" className="show_entries" onChange={this.showEntries}>
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="48">48</option>
                                            <option value="96">96</option>
                                        </select>
                                        ITEMS</label>
                                </div>
                        </div>:null}
                            </div>
                        
                            <div id="products" className="row view-group">
                                { this.state.pageCount > 0?
                                    tr_elements:
                                    <div className="col-lg-12 text-center font-size-20">No Items Found</div>
                                }
                            </div>
                                
                        <div>
                            {this.state.pageCount > 0?
                                <Pagination items={this.state.exampleItems} itemscount={this.state.pageCount} onChangePage={this.onChangePage} page_size={this.state.page_size} search_term={this.state.search_term}  from_group={this.state.from_group} sort_by={this.state.sort_by}/>
                            :''}
                        </div>
                        {this.state.view.showModal ? <SmartLeadTimeModal handleHideModal={this.handleHideModal} items={this.state.lead_time_data} symbol={this.state.currency_symbol}  AddToCartHandler={this.AddToCartHandler} from={true} />
                        : null}
                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
