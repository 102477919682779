import { Component } from 'react'
import {withRouter} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import {ErrorHandling} from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;

class UnpaidOrders extends Component {
    constructor(props){
        super(props)
        this.state = {
            amount:0,
            percentage:0,
        }
    }
    componentDidMount(){
        const auth_token = "Token "+localStorage.token
        $('.pending-payment').find('.inline-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/dashboard/orders/unpaid/',
            ).then(response => {
            if(response.status == 200){                         
                this.setState(()=>{
                    return {
                        amount: response.data.orders_unpaid,
                    }
                })
                $('.pending-payment').find('.inline-loader').fadeOut("slow");           
                    
            }
        }).catch(response => {
            ErrorHandling(response)
        });


    }
    render() {
        if(this.state.amount > 0){
            var ele = [
            <h1 className="no-mrg-vertical" key={'unpaid'}><span className="currency-icon">$</span>{ this.state.amount > 0?<a href='{% url "customer_portal:orders" %}?payment_status=unpaid' className="pull-right count-color">{this.state.amount }</a>:<span className="pull-right">0</span>}
                
            </h1>,
            <p className="no-mrg-btm" key={'unpaid-msg'}>Orders Awaited Payment</p>
        ]

        }else{
            var ele = <p className="no-mrg-btm text-center" key={'unpaid-msg'}>No Pending Payments.</p>
        }
        return (
            <div className="pending-payment">
                <div className="card pending-order  pdd-btm-15">
                    <div className="inline-loader" id="monthly_bar_loader">
                        <SubLoader/>
                    </div>
                    <div className="card-block" id="monthlly-bar">
                        {ele}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UnpaidOrders)