import  { Component } from 'react'
import { Link, withRouter } from "react-router-dom";  
import $ from 'jquery'
import SSILogo from '../styles/images/logo/ssi-logo.png'
import countries from '../data/countries2.json'
import '../styles/custom/sign-up.css'
import swal from 'sweetalert';
import {API_HOST} from '../settings.config.js'
import {notify} from '../js/CommonFunctions'
const axios = require('axios').default;

const countries_data = countries.map((data)=>{
    return <option key={data.country} value={data.country}>{data.country}</option>
})

class SignUpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          view: {showModal: false},
          modal_flag :false,
          formControls: {
            first_name: {
              value: this.props.location.state?this.props.location.state.params.first_name:''
            },
            last_name: {
              value: this.props.location.state?this.props.location.state.params.last_name:''
            },
            email: {
              value: this.props.location.state?this.props.location.state.params.email:''
            }
        }
      }
    }

    componentDidMount(){
        $('select#id_country').selectpicker()
        if(this.props.location.state?this.props.location.state.params.message:''){
            notify(this.props.location.state.params.message,'information')
        }
    }

    changeHandler = (event) =>{
        const name = event.target.name
        const value = event.target.value
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                ...this.state.formControls[name],
                value
              }
            }
          });
        
    }

    handleShowModal = (event)=> {
        event.preventDefault();
        this.setState({
            view: {showModal: true},
          })
    }

    handleHideModal = () => {
        this.setState({view: {showModal: false}})                
    }
    
    handleDisAgree = () => {
        this.setState({modal_flag: false})
        console.log(this.state.modal_flag)
    }

    
  
    handleSubmit = (event) => {
      event.preventDefault();
      var event_from = $(event.target).attr('data-type')
      if(event_from == 'agree'){  
        //$('#form').validate()
        //$('#form').valid()
        if(true){
            this.state.modal_flag = true
            this.setState({modal_flag: true})
        }else{
            this.state.modal_flag = false 
            this.setState({modal_flag: false})
        }
      }
      if(this.state.modal_flag){
        function serialize(form) {
            var inputs = form.elements;
            var array = [];
            for(let i=0; i < inputs.length; i++) {
            var inputNameValue = inputs[i].name + '=' + inputs[i].value;
            array.push(inputNameValue);
            }
            return array.join('&');
        }
        var form = document.querySelector('form');
        var form_data = serialize(form)
      document.getElementById("signup-btn").disabled = true;
      $(".lds-ripple-parent-1").fadeIn()
      axios({
        method: 'post', 
        url: API_HOST+'/cp/signup/',
        data: form_data,
        headers: { 
          "Content-Type": "application/x-www-form-urlencoded",
        }
      }).then((response) =>{
        $(".lds-ripple-parent-1").fadeOut('slow')
        if(response.status == 200 && response.data.success){
            swal("Thank you! For signing up with us.", "Your request will be processed soon.", "success")
            .then((value) => {
                this.props.history.push('/login')
            });
        }else{
        var error_fields = response.data.errors
        var fields = Object.keys(error_fields)
        for(let i=0;i<fields.length;i++){
            var error_msg = error_fields[fields[i]][0]
            var field = fields[i]+'_error'                    
            document.getElementById(field).appendChild(document.createTextNode(error_msg));
        }
        setTimeout(() => {
            for(let i=0;i<fields.length;i++){
                document.getElementById(fields[i]+'_error').innerText = '';
            }
        }, 10000);
        
        document.getElementById("signup-btn").disabled = false;

        }
      })
      .catch(function (response) {
        $(".lds-ripple-parent-1").fadeOut('slow')                
    });
      
    } else {
        this.setState({
            view: {showModal: true},
          })
    }
}

    handleAgree = (event) => {
        event.preventDefault();
        this.setState({modal_flag: true})
        console.log(this.state.modal_flag)
        this.handleSubmit(event)
    }

    render() {
        var a_cursor = {'cursor': 'pointer'}        
        return (
            <div className="authentication signup-container">
            <div className="sign-in">
                <div className="row no-mrg-horizon">
                    <div className="col-md-6 no-pdd-horizon d-none d-md-block">
                        <div className="full-height bg video-container">

                            <video autoPlay="autoplay" loop="loop" muted="muted" src="https://www.ssiaeration.com/wp-content/themes/ssi/images/front-page.mp4" poster=""></video>

                            <div className="img-caption">
                                <h1 className="caption-title">SMART IDEAS FOR WATER</h1>
                                <p className="caption-text">SSI Aeration fine bubble diffuser systems and products are installed on five continents at thousands of job sites. Millions of our diffusers are hard at work in wastewater treatments worldwide. SSI has received multiple
                                    US and International patents for inventions and industry improvements.</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 no-pdd-horizon">
                        <div className="signup-header bg-white">
                            <img className="img-responsive signup-ssi-logo inline-block" src={SSILogo} width="100" alt=""/>
                        </div>
                        <div className="bg-white">
                            <div className="vertical-align pdd-horizon-70 pdd-vertical-10 ">
                                <div id="cp-signup-form">
                                <div className="pdd-horizon-15" >
                        <h4 className="text-center">Sign Up</h4>            
                <form  noValidate='novalidate'  id="form" onSubmit={this.handleSubmit}>
                    <p id="error_msg" className="text-danger"></p>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control" placeholder="First Name" name="first_name" id="first_name" value={this.state.formControls.first_name.value} onChange={this.changeHandler}  required/>
                                <p id="first_name_error" className="text-danger"></p>
                            </div>  
                        </div> 
                        <div className="col-md-6">                 
                            <div className="form-group">
                            <label>Last Name</label>
                                <input type="text" className="form-control" placeholder="Last Name" name="last_name" id="last_name" value={this.state.formControls.last_name.value} onChange={this.changeHandler} required/>
                                <p id="last_name_error" className="text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>E-Mail</label>
                                <input type="email" className="form-control" placeholder="Email" name="email" id="email" value={this.state.formControls.email.value}  onChange={this.changeHandler} required/>
                                <p id="email_error" className="text-danger"></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Company Name</label>
                                    <input type="text" className="form-control" placeholder="Company Name" name="company_name" id="company_name" />
                                    <p id="company_name_error" className="text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>City</label>
                                    <input type="text" className="form-control" placeholder="City" name="city" id="city" />
                                    <p id="city_error"></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                    <label>State</label>
                                        <input type="text" className="form-control" placeholder="State" name="state" id="state" required/>
                                        <p id="state_error" className="text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Country</label>
                                <select id="id_country" className="form-control selectpicker" name="country" data-size="7" data-live-search="true" required>
                                   {countries_data} 
                                </select>
                                <p id="country_error" className="text-danger"></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                    <label>ZIP Code</label>
                                        <input type="number" className="form-control" placeholder="Zipcode" name="zip_code" id="zipcode" required/>
                                        <p id="zipcode_error" className="text-danger"></p>
                                </div>
                        </div>
                    </div>
                        <a href={void(0)} style={a_cursor} className="termNcondition" onClick={this.handleShowModal}>Terms and Conditions</a>
                        <button className="btn btn-info signup-btn" id="signup-btn" >Sign Up</button>
                        {this.state.view.showModal? <TermsAndConditions handleHideModal={this.handleHideModal} handleAgree={this.handleAgree} hadleDisAgree={this.handleDisAgree}  />:null } 
                    </form> 
                </div>

                                </div>
                            </div>
                            <div className="login-footer pdd-btm-30">
                                <span className="font-size-13 pull-right">Already have an account? <Link to="/login">Sign In</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default withRouter(SignUpPage)



class TermsAndConditions extends Component{
        
    componentDidMount(){
        $('#terms-and-condition-dialog').modal('show');
       $('#terms-and-condition-dialog').on('hidden.bs.modal', this.props.handleHideModal);
       document.getElementById("agree").disabled = true;
        //document.getElementById("decline").disabled = true;
        $('#agree').addClass('disabled')
       document.getElementById("agreement").addEventListener("scroll", checkScrollHeight, false);
       function checkScrollHeight() {
            var agreementTextElement = document.getElementById("agreement");
            if (agreementTextElement.clientHeight + agreementTextElement.scrollTop >= agreementTextElement.scrollHeight) {
                document.getElementById("agree").disabled = false;
                $('#agree').removeClass('disabled')
                //document.getElementById("decline").disabled = false;
            }else{
            document.getElementById("agree").disabled = true;
                //document.getElementById("decline").disabled = true;
                $('#agree').addClass('disabled')
            }
        };
    }
    
    render(){
        return(
            <div className="modal fade" id="terms-and-condition-dialog" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                     <span>SSI – Stamford Scientific International, Inc.</span><br/>
                                     <span>Standard Terms and Conditions of Sale</span>
                                </h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body terms-and-condition-model-body" id="agreement">
                                <div >
                                    <ol>
                                            <li>
                                                   <strong>General:</strong> All products and services offered for sale by Stamford Scientific International, Inc. (hereinafter “Seller”) or its subsidiaries or through its distributors or
                                                  sales representatives are sold subject to the terms and conditions stated herein. This offer expressly limits acceptance to the terms hereof and any additional, different or
                                                  inconsistent terms proposed by Buyer, whether in writing or otherwise, are hereby objected to and rejected and Seller shall not be bound thereby unless expressly agreed in a
                                                  writing signed by an officer of the Seller that such terms and conditions shall supersede those contained herein.
                                                  
                                            </li>
                                            <li>
                                                   <strong>Estimates and Quotations:</strong> All prices are subject to adjustment on account of specifications, quantities, shipment arrangements or other terms and conditions,
                                                  which are not part of the original price quotation. Written estimates issued by the Seller will be valid for 30 days only. Products are sold at prices in effect at the time the order
                                                  is taken. The company reserves the right to change prices without notice when necessary.
                                                  
                                            </li>
                                            <li>
                                                   <strong>Terms and Method of Payment:</strong> <u>For Buyers with established credit</u>, payment of accounts due must be made within 30 days of shipment FOB Seller facility or 30
                                                  days after the goods are ready to ship and Buyer is notified of same. Seller may make partial billings of the contract price or invoice as various components of the equipment
                                                  are shipped. Partial payments are expected to be made according to partial goods shipped. Custom built and special large orders shall require deposits in advance of
                                                  production together with progress payments. <u>For Buyers without credit</u>, payment will be by company check, bank draft, Visa or Mastercard, telegraphic transfer or irrevocable
                                                  letter of credit at time of order or progress payments according to quote offer.
                                                  The Buyer shall not be entitled to withhold payment of any sums due to the Seller by reason of any disputed claim by the customer relating to any goods or deliveries. If
                                                  Buyer fails to make payment by the due dates then without prejudice to any other rights of the Seller: The Seller shall be entitled to suspend all or any other deliveries to be
                                                  made under that or any other contract with the Buyer and in such event the Buyer shall not in any respect be released from its obligations to the Seller under that or any such
                                                  other contract. The Seller shall also be entitled to treat the relevant contract or any other contract with the Buyer as having been terminated by the Buyer and to claim
                                                  damages for breach of contract accordingly.
                                            </li>
                                            <li>
                                                <strong>Taxes:</strong>Product prices do not include taxes. The amount of any present or future sales, use, excise, value-added or similar tax if applicable to the sale shall be
                                               paid by the Buyer unless a tax exemption certificate acceptable to the tax authorities is approved. If Seller is required to pay any such tax, fee or charge, Buyer shall
                                               reimburse Seller therefore.       
                                            </li>
                                            <li>
                                                <strong>Delays in Delivery and Force Majeure:</strong> All quoted delivery dates are approximate. The Seller will make commercially reasonable efforts to meet any delivery
                                               date(s) quoted in the agreement. However, under no circumstances shall the Seller be liable to Buyer for any delay in shipment or failure to meet any quoted delivery date(s)
                                               due to unforeseen circumstances or due to a cause beyond its control. Examples of such causes are acts of God, wars, riots, embargoes, acts of civil or military authorities,
                                               fires, floods, accidents, strikes, transportation delays, inability to obtain materials or supplies, interruption for any reason in the manufacture of products by Seller’s supplies or
                                               other causes not within Seller’s control.
                                            </li>
                                            <li>
                                                <strong>Warranty/Repairs:</strong>Products manufactured by Seller are warranted to be free from defects in materials or workmanship for 18 months from date of shipment or 12
                                               months from the date of start up, whichever comes first. Parts found to be defective in materials or workmanship will be repaired or replaced without charge, F.O.B. original
                                               point of shipment. <b><u>The forgoing warranty is in lieu of and excludes all other warranties expressed or implied (including any warranty of merchantability or fitness
                                               or purpose)</u></b>. The responsibility of the Seller is limited to the cost of the defective part. Wear caused by faulty maintenance or operation shall not constitute defects.
                                               Elastomeric components damaged as a result of maintenance activities, excessive airflow, foreign debris in the process solution, or excessive exposure to direct ultraviolet
                                               and thermal radiation shall be excluded. Decomposition by chemical action and chemical precipitate shall not constitute defects.
                                        
                                               Claims for defective, damaged or improper material or for shortages will not be allowed unless written notice specifying the nature and extent of the defect, damage or
                                               shortage is received in the Seller’s office within fourteen (14) days from delivery. Seller assumes no responsibility of expense for repairs made on equipment sold by it to
                                               Buyer when such repairs are done outside Seller’s factory facilities without its written consent.
                                        
                                               In all cases, a Start-Up certificate issued by SSI is for general conformance to the Manufacturer’s installation instructions only after audit for work performed. However, in no
                                               cases, SSI can audit 100% of all work performed by others and cannot guarantee their work to fully meet our installation instructions. W arranty for installation labor and
                                               services provided by others shall be covered by the warranty of the entity performing those services. Failure by the Installer to fully meet our installation instructions and
                                               requirements may result in installation errors which may lead to equipment failure, for which Seller shall not be liable.
                                            </li>
                                            <li>
                                                <strong>Cancellation and Returns Policy:</strong>(A) Cancellations: Any request for order cancellation, rescheduling or modification by Buyer must be made in writing and such
                                               action must be approved in writing by an officer of Seller. Such request should be made to Seller at least four (4) weeks from scheduled shipment date in order to be
                                               considered. Such cancellation, rescheduling or modification shall be subject to the payment of reasonable cancellation charges, which shall include but not be limited to
                                               expenses already incurred for labor and material costs, commitments made by Seller and a reasonable profit. (B) Returns: Any request for product return by Buyer must be
                                               made in writing. Returns of products will not be accepted for any reason without prior written consent of Seller and issuance of a Return Material Authorization (RMA)
                                               number. Return of products must be shipped freight prepaid by Buyer. Seller will not accept freight charges. Returns may be subject to a 15% restocking fee and any other
                                               reasonable charges. Returns made without obtaining prior authorization shall be returned to sender at Buyer’s expense. Custom made goods may not be returned under any
                                               circumstance.       
                                            </li>
                                            <li>
                                                <strong> Limitation of Liability:</strong><b><u>Notwithstanding anything else to the contrary, Seller shall have no liability for consequential, incidental, punitive, indirect
                                                       and/or special damages regarding the sale or use of a product even if the potential for such damages has been disclosed to Seller. Seller’s total liability for
                                                       product defect claims shall not exceed the purchase price paid for the product.
                                                       </u></b>
                                            </li>
                                            <li>
                                                <strong>Complete Agreement:</strong> These Terms and Conditions of Sale when combined with Seller’s invoice represent the entire agreement between the parties with
                                                       respect to the subject matter hereof. Language contained in any digital or written instrument from Buyer to Seller serving to modify the terms of any of the foregoing shall be
                                                       of no effect.               
                                            </li>
                                            <li>
                                                <strong>Severability:</strong>If any provision of these Terms and Conditions of Sale shall be deemed illegal or unenforceable, such illegality or unenforceability shall not affect
                                               the validity and enforceability of any other provisions hereof, which together shall be construed as if such illegal and unenforceable provision or provisions had not been
                                               included herein.       
                                            </li>
                                            <li>
                                                <strong>No Waiver:</strong>The failure by Seller to enforce at any time any of the provisions of this agreement, or to exercise any election or option provided herein, shall not be a
                                               waiver and shall in no way be construed as a waiver of such provisions or options, nor in any way be construed to affect the validity of this agreement or any part thereof, or
                                               the right of Seller thereafter to enforce each and every such provision.
                                            </li>
                                            <li>
                                                <strong>No Indemnification:</strong>Unless otherwise expressly provided in writing by both parties, Seller does not indemnify, nor does it hold Buyer harmless, against any
                                               liability, losses, damages and expenses (including attorney’s fees) relating to any claim whatsoever, including without limitation, claims for personal injuries, death or property
                                               damage related to the products sold hereunder.
                                            </li>
                                            <li>
                                                <strong>Governing Law; Limitations:</strong>The relationship between Buyer and Seller in relation to the products shall be governed by and construed in accordance with the
                                               laws of the State of New York, U.S.A. Any legal action with respect to any transaction must be commenced within one year after the cause of action has arisen.
                                            </li>
                                            <li>
                                                <strong>Dispute Resolution:</strong> Actions by Seller for nonpayment by Buyer of the purchase price of products sold by Seller, or for redress of other breaches by Buyer these Terms and Conditions of Sale, may be brought by Seller, at its option, before any court of competent jurisdiction in the State of New York. At Seller’s option, disputebetween Buyer and Seller, including all claims for non-performance by Seller, shall be finally settled by arbitration in Poughkeepsie, New York USA.
                                            </li>
                                            <li>
                                                <strong>Foreign Corrupt Practices Act:</strong>Buyer represents and warrants to Seller that it shall comply with all laws in relation to its sale and/or use of the Products and will
                                               not pay anything of value to any government employee in connection with the sale of the Products.
                                            </li>
                                            <li>
                                                <strong>Precedence:</strong>In the event of any inconsistency among the referenced documents, attachments, drawings, specifications or other provisions hereof, the following
                                               order of precedence shall apply: (1) special terms and conditions agreed to in writing by an Officer of the Seller, (2) these standard terms and conditions of sale, (3)
                                               specifications, (4) all other attachments or documents incorporated herein by reference.
                                            </li>
                                        </ol>  
                                    </div>                              
                            </div>
                            <div className="modal-footer">
                            <div className="col no-padding">
                                  <div className="scroll-down-message">Scroll down to read and <b>Agree</b> our Terms & Conditions.</div>
                                    <button type="button" onClick={this.props.handleAgree} className="btn btn-info float-right" id="agree" data-dismiss="modal" data-type="agree" >I Agree</button>
                                    <button type="button" onClick={this.props.hadleDisAgree} className="btn btn-default float-right" id="decline" data-dismiss="modal" >I Disagree</button>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
        )
    }
}

