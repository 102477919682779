import  { Component } from 'react'
import $ from 'jquery'
import {API_HOST} from '../../settings.config.js'
import SubLoader from '../Loader/SubLoader'
import {notify, ErrorHandling} from '../../js/CommonFunctions'
import countries from '../../data/countries2.json'
const axios = require('axios').default;
 
class AddressModal extends Component{
    componentDidMount(){
        $('select#id_country').selectpicker()
        $('select#id_country').val(this.props.addressData.country.value).selectpicker('refresh')           
        $('#customer-billing-address-create-dialog').modal('show');
        $('#customer-billing-address-create-dialog').on('hidden.bs.modal', this.props.handleHideModal);
    }
    componentDidUpdate(){
       // $('select#id_country').selectpicker('refresh')
    }
    
    render(){
        var radio_disply = {'display':'inline'}
        var billing =''
        var shipping =''
        var isdefault=''
        var isnotdefault = ''
        if(this.props.addressData.add_type.value){
            if(this.props.addressData.add_type.value == '1'){
                var billing = 'checked'
            }
            if(this.props.addressData.add_type.value == '2'){
                var shipping = 'checked'
            }
        }else{
            var billing = 'checked'
        }
        //if(this.props.addressData.isdefault.value){
            if(this.props.addressData.isdefault.value == true || this.props.addressData.isdefault.value == 'true'){
                this.props.addressData.isdefault.value = true
            }
            if(this.props.addressData.isdefault.value == false || this.props.addressData.isdefault.value == 'false'){
                this.props.addressData.isdefault.value = false
            }
            if(this.props.addressData.isdefault.value){
                var isdefault = 'checked'
            }
            var option_str = countries.map((data)=>{
                return <option key={data.country} value={data.country}>{data.country}</option>
            }) 
        //}else{
          //  var isnotdefault = 'checked'
        //}
        return(
            <div className="modal fade" id="customer-billing-address-create-dialog" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.addressData.id.value?'Update Address':'Create Address'}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body customer-billing-address-create-model-body">
                                <form className="customer-billing-address-create-model-body-form" id="address_form" onSubmit={this.props.handleSubmit}>
                                    <input type="hidden" name="id" value={this.props.addressData.id.value} id="address_id" />
                                    <div className="row">
                                        {this.props.addressData.id.value?
                                            <input type="hidden" value={this.props.addressData.add_type.value} name="add_type" id="add_type" />
                                        :
                                            <div className="col-md-12 no-padding">
                                            <div className="row">
                                                <div className="col-lg-5 no-padding font-weight-bold transform-label"> Address Type</div>
                                                <div className="radio col-lg-7 no-padding no-margin-top">
                                                    <label><input type="radio" value="1" name="add_type" style={radio_disply} checked={billing} onChange={this.props.onchange} />Billing</label>
                                                    <label><input type="radio" value="2" name="add_type" style={radio_disply} checked={shipping} onChange={this.props.onchange} />Shipping</label>
                                                </div>
                                                <p className="_error"></p>
                                            </div>
                                            </div>}
                                            <div className="col-md-6 no-padding-left">
                                                    <label>Address 1</label>
                                                    <input type="text" name="add1" id="id_add1" placeholder="Address 1" className="form-control text-capitalize" maxlength="150" value={this.props.addressData.add1.value} onChange={this.props.onchange} />
                                                    <p className="add1_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Address 2</label>
                                                        <input type="text" name="add2" id="id_add2" placeholder="Address 2" className="form-control text-capitalize" maxlength="150" value={this.props.addressData.add2.value} onChange={this.props.onchange} />
                                                        <p className="add2_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>City</label>
                                                        <input type="text" name="city" id="id_city" placeholder="City" className="form-control text-capitalize" maxlength="75" required value={this.props.addressData.city.value} onChange={this.props.onchange} />
                                                        <p className="city_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>State</label>
                                                        <input type="text" name="state" id="id_state" placeholder="State" className="form-control text-capitalize" maxlength="75" required value={this.props.addressData.state.value} onChange={this.props.onchange} />
                                                        <p className="state_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Country</label>                                                        
                                                        <select id="id_country" className="form-control" name="country" data-size="7" data-live-search="true" onChange={this.props.onchange} required>
                                                            {option_str}    
                                                        </select>
                                                        <p className="country_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Zip code</label>
                                                        <input type="number" name="zip_code" id="id_zip_code" placeholder="Zip code" className="form-control" maxlength="15" value={this.props.addressData.zip_code.value} onChange={this.props.onchange} required/>
                                                        <p className="zip_code_error"></p>
                                                    </div>
                                                <div className="col-md-12 no-padding-left">
                                                    <div className="row">
                                                    <div className="col-lg-4 no-padding font-weight-bold pdd-top-5 transform-label">Default</div>
                                                    <div className="radio col-lg-7 no-padding no-margin-top">
                                                        <label><input type="radio" value={true} title="Set as Default Address" name="isdefault" style={radio_disply} checked={isdefault} onChange={this.props.onchange} /></label>
                                                    </div>
                                                    <p className="_error"></p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                    <button className="btn btn-primary create-billing-address-submit btn-animation"
                                                            type="submit">
                                                            {this.props.addressData.id.value?'Update':'Create'}
                                                    </button>
                                                </div>
                                </form>
                            </div>
                            
            
                        </div>
                    </div>
                </div>
        )
    }
}

export default class Addresses extends Component {
    constructor(props){
        super(props)
        this.addresses = []
        this.state = {
            addresses: [],
            view:{showModal:false},
            add_button:true,
            address:{},
            card_index : -1,
            formControls: {
                id:{value:''},
                add_type: {value: ''}, add1: {value: ''},
                add2: {value: '' }, city: {value: '' },
                state: {value: '' }, country: {value: '' },
                zip_code: {value: '' }, isdefault: {value: '' }                   
            }                
       } 
       
    }
    componentDidMount(){
        //ReactDOM.render(<PageLoader/>,document.getElementById('address_loader'))
        $('.address-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/address/',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.addresses){
                  var address_data = response.data.addresses
              }else{
                var address_data = []
              }
              this.addresses = address_data
            this.setState(()=>{
                return {
                    addresses: this.addresses,
                }
             })   
             //$(".lds-ripple-parent").fadeOut("slow");   
             $('.address-card').find('.inline-loader').fadeOut('slow')          
            }
        }).catch(response => {
            ErrorHandling(response) 
        }); 
            
    }

    handleHideModal = () =>{
        this.setState({view: {showModal: false}})
    }
    handleShowModal = () =>{
        this.setState({
            view: {showModal: true},
            address:{},
            card_index : -1,
            formControls: {
              id:{value:''},
              add_type: {value: ''}, add1: {value: ''},
              add2: {value: '' }, city: {value: '' },
              state: {value: '' }, country: {value: '' },
              zip_code: {value: '' }, isdefault: {value: '' }                   
          }
          })
    }
    handleChange = (event) => {
    const name = event.target.name;
      const value = event.target.value;        
      this.setState({
        formControls: {
            ...this.state.formControls,
            [name]: {
            ...this.state.formControls[name],
            value
          }
        }
      });
      }

    handleEdit = (event) =>{
        event.preventDefault()
      const address_id = event.target.id?event.target.id:0
      const index = event.target.getAttribute('data-index')
      $(".lds-ripple-parent-1").fadeIn()
      const auth_token = "Token "+localStorage.token
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(API_HOST+'/cp/user/address/'+address_id+'/',
      ).then(response => {
        if(response.status == 200){ 
            if(response.data){
                var address_data = response.data
                this.state.card_index = parseFloat(index)
            }else{
              var address_data = {}
            }
          this.setState(()=>{
              return {
                  address: address_data,
                  card_index:this.state.card_index,                      
                  formControls: {
                    id:{value:address_data.id},
                    add_type: {value: address_data.add_type}, add1: {value:address_data.add1},
                    add2: {value:address_data.add2 }, city: {value:address_data.city},
                    state: {value:address_data.state}, country: {value:address_data.country},
                    zip_code: {value:address_data.zip_code }, isdefault: {value:address_data.isdefault }                   
                },
                  view: {showModal: true}

              }
           })

          }
          $(".lds-ripple-parent-1").fadeOut('slow')
      }).catch(response => {     
        ErrorHandling(response)   
        $(".lds-ripple-parent-1").fadeOut('slow')
    });

    }        
    handleSubmit = (event) =>{
        event.preventDefault()
        var address_id =  document.getElementById('address_id').value
        function serialize(form) {
            var inputs = form.elements;
            var array = [];
            for(let i=0; i < inputs.length; i++) {
              if(inputs[i].name == 'add_type'){

              }else if(inputs[i].name == 'isdefault'){

              }else{
                var inputNameValue = inputs[i].name + '=' + inputs[i].value;
                array.push(inputNameValue);
              }
            }
            if(address_id){
                var inputNameValue = 'add_type' + '=' + $("#add_type").val();
            }else{
                var inputNameValue = 'add_type' + '=' + document.querySelector('input[name="add_type"]:checked').value;
            }
            array.push(inputNameValue);
            if(document.querySelector('input[name="isdefault"]:checked')){
                var inputNameValue = 'isdefault' + '=' + document.querySelector('input[name="isdefault"]:checked').value;
            }else{
                var inputNameValue = 'isdefault' + '=' +'false';
            }
            array.push(inputNameValue);
            return array.join('&');
          }
        var form = document.querySelector('#address_form')
        var form_data = serialize(form)
        const auth_token = "Token "+localStorage.token                
        if(address_id){
            var url_type = API_HOST+'/cp/user/address/'+address_id+'/'
            var method_type = 'patch'
            axios.defaults.headers.patch['Authorization'] =auth_token
            
        }else{
            var url_type = API_HOST+'/cp/user/address/'
            axios.defaults.headers.post['Authorization'] =auth_token
            var method_type = 'post'
        }
        $(".lds-ripple-parent-1").fadeIn()
        axios({
            method: method_type, 
            url: url_type,
            data: form_data,
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded",
            }
          }).then((response) => {
          if(response.status == 201 || response.status == 200){ 
              if(response.data){
                  var address_data = response.data
                  if(this.state.card_index >= 0){
                      this.addresses[this.state.card_index] = response.data.data
                  }else{
                    this.addresses.push(response.data.data)
                }
              }else{
                var address_data = []
              }
            this.setState(()=>{
                return {
                    view: {showModal: false},
                    addresses:this.addresses,
                    card_index:-1
                }
             })  
             $('#customer-billing-address-create-dialog').modal('hide');
             notify('Address saved successfully.','success')
            }
            $(".lds-ripple-parent-1").fadeOut('slow')
        }).catch(response => {  
            ErrorHandling(response)          
            $(".lds-ripple-parent-1").fadeOut('slow')
        });   

    }
    
    
    render() {
        $('.address-card').find('.card-block').removeClass('padding-60')
            const address_div = this.state.addresses.map((address,indx) =>{
                return <div className="col-md-3" key={indx}>
                        <div className="card">                          
                            <div className="card-heading" id='card_heading'>
                                <h5 className="card-title">{ address.add_type == '1'?'Billing':'Shipping' }</h5>
                            </div>
                            <div className="card-body">
                                    <div className="add1" title="Address 1">{ address.add1 }</div>
                                    <div className="add2" title="Address 2">{ address.add2 }</div>
                                    <div className="city" title="City">{ address.city }</div>
                                    <div className="state" title="State">{ address.state }</div>
                                    <div className="country" title="Country">{ address.country }</div>
                                    <div className="zip_code" title="Zip Code">{ address.zip_code }</div>
                            </div> 
                            <div className="card-footer border top" > 
                                    <ul className="list-unstyled list-inline pull-right">
                                        <li className="list-inline-item">
                                            <a href="#!" className="btn btn-icon btn-flat btn-rounded profile-btn" id={address.id} onClick={this.handleEdit} data-index={indx} title="Edit">
                                                <i className="ti-pencil" id={address.id} data-index={indx}></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item d-none">
                                            <a href="#!" className="btn btn-icon btn-flat btn-rounded profile-btn" title="Delete">
                                                <i className="ti-trash"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>                                                      
                        </div>
                </div>

            })
            if(!this.state.view.showModal){
                $('#customer-billing-address-create-dialog').modal('hide');
            }
        return (
            <div className="card address-card" id="ask-2">
                <div className="inline-loader" id="address_loader">
                    <SubLoader/>
                </div> 
                <div className="card-heading border bottom">
                    <h4 className="card-title">Addresses</h4>
                </div>
                <div className="card-block padding-60" id="address-block">
                <div className="row">
                    { this.state.addresses.length > 0?
                        address_div :<h3 className="text-center no-data"> You have no Addresses.</h3>
                     }
                     <div className="col-md-3">
                            <div className="card">                          
                                <div className="card-body custom-card-body" onClick={this.handleShowModal}>
                                        <div className="text-center font-size-30"><i className="ei-addthis"></i></div>
                                        <h2 className="text-center">Add Address</h2>
                                </div>                                                                                    
                            </div>
                    </div>
                    {this.state.view.showModal ? <AddressModal handleHideModal={this.handleHideModal} handleSubmit={this.handleSubmit} addressData={this.state.formControls} onchange={this.handleChange} countries={this.state.countries} />
                     : <div ref={this.modelHide}></div>}
                    </div>                                                                                     
                </div>
            </div>
        )
    }
}

