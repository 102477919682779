import { Component } from 'react'
import { Link} from 'react-router-dom'
import ReactDOM from 'react-dom'
import SubLoader from '../Loader/SubLoader'
import './create-order.css'
import {API_HOST} from '../../settings.config.js'
import {Humanize, notify, ErrorHandling} from '../../js/CommonFunctions'
import SmartLeadTimeModal from '../../js/SmartLeadTimeModal'
import countries from '../../data/countries2.json'
import $ from 'jquery'
const axios = require('axios').default;
var previous_data = []
var current_row_index = ''


export default class CreateOrder extends Component {
    constructor(props) {
        super(props);
        this.AddRowHandler = this.AddRowHandler.bind(this)
        this.AddressEditHandler = this.AddressEditHandler.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleHideModal = this.handleHideModal.bind(this)
        this.AddressSubmitHandler = this.AddressSubmitHandler.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleRowDel = this.handleRowDel.bind(this)
        this.HandleRenderItemData = this.HandleRenderItemData.bind(this)
        this.HandleQuantityChange = this.HandleQuantityChange.bind(this)
        this.HandleGetLeadTime = this.HandleGetLeadTime.bind(this)
        this.handleAddressChange = this.handleAddressChange.bind(this)
        this.SetDefaultAddress = this.SetDefaultAddress.bind(this)
        this.HidenModal = this.HidenModal.bind(this)
        this.CreateOrder = this.CreateOrder.bind(this)
        this.HandleKeyUp = this.HandleKeyUp.bind(this)
        this.leadtimeHideModal = this.leadtimeHideModal.bind(this)
        this.checkAviailableQuantity = this.checkAviailableQuantity.bind(this)
        this.onFocusOutCategory = this.onFocusOutCategory.bind(this)
        this.onhideSearchResult = this.onhideSearchResult.bind(this)
        this.restrictDecimal = this.restrictDecimal.bind(this)
        var attachment_form_data = null
        this.previous_request = null
        this.state = {
            OrderData: [],
            table_rows:[],
            addresses:[],
            shipping_address:[],
            billing_address:[],
            items_json:[],
            view:{showModal:false},
            filterText:'',
            change_add_modal:false,
            add_type:'',
            focus:false,
            cart_items:'',
            freight_details:[],
            table_rows:[{item_id:'',item_number:'',name:'', ava_qty:0,
                         lead_time:'',quantity:'',show:false,error:false,
                         category:'',stock_id:'',warehouse_id:'',cart_id:'',
                         total_volume:0,total_weight:0,input_show:true,cart:'no',
                         attachments:[],image_url:'',price:0,amount:0,disabled_qty:false,
                         warehouse_name:'', update_lead_time:false, warehouse_city:'',
                         warehouse_state:''
                        }],
            search_result:[],
            total_volume_of_items:0,
            total_weight_of_items:0,
            total_amount:0,
            formControls: {
                id:{value:''},
                add_index:{value:''},
                add_type: {value: ''}, add1: {value: ''},
                add2: {value: '' }, city: {value: '' },
                state: {value: '' }, country: {value: '' },
                zip_code: {value: '' }               
            },
            currency_symbol:'',
            placed_cart_ids:[],
            lead_time_modal:false,
            lead_time_data:[],
        };
    }
    
    componentDidMount(){
        //$(".lds-ripple-parent").fadeOut("slow"); 
        // ReactDOM.render(<PageLoader/>,document.getElementById('address_loader'))
        // ReactDOM.render(<PageLoader/>,document.getElementById('cart_item_loader')) 
        ReactDOM.render(null, document.getElementById('save_button') )               
        $('.items-from-cart').find('inline-loader').fadeIn()
        $('.address-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/order/pre/create/'
        ).then(response => {
          if(response.status == 200){ 
            var ship_add = []
            var bill_add = []
            response.data.addresses.forEach(function(val){
                if(val.add_type == '1'){
                    bill_add.push(val)
                }else{
                    ship_add.push(val)
                }
            })
            var freight = Object.values(response.data.freight_type_choices[0])                    
            this.setState(()=>{
                return {
                    OrderData: response.data,
                    addresses:response.data.addresses,
                    shipping_address:ship_add,
                    billing_address:bill_add,
                    items_json:[],
                    freight_details:freight,
                    currency_symbol:response.data.currency_symbol
                }
        })  
        $('.address-card').find('.inline-loader').fadeOut("slow");           
                    
            }
        }).catch(response => {
            ErrorHandling(response)
        });

        $(document).keyup((event) =>{
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if(keycode == '27'){ // escape key
                this.state.table_rows.map((row,index) =>{
                    this.state.table_rows[index].show = false
                })
                this.setState({
                    table_rows:this.state.table_rows
                })
    
            }
        });
        var query_parm= {}
        if(window.location.search.length!==0)
            window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value){
                key=decodeURIComponent(key);
                if(typeof query_parm[key]==="undefined") {query_parm[key]= decodeURIComponent(value);}
                else {query_parm[key]= [].concat(query_parm[key], decodeURIComponent(value));}
            });
        if(this.props.location.state?this.props.location.state.params.selected_cart_ids:false){
            console.log(this.props.location.state.params.selected_cart_ids)
            var numberOfAxioxCAllPending = 0
            $('.item-inline-loader').fadeIn()
            axios.defaults.headers.get['Authorization'] = auth_token
            axios.get(API_HOST+'/cart/get/cart/items/?cart_ids='+this.props.location.state.params.selected_cart_ids,
            ).then(response => {
            if(response.status == 200 && response.data.success){                        
                var cart_items = response.data.cart_items
                var items = []
                var check_available_qty = []
                cart_items.map((cart_item,index) =>{
                    var item_data = {item_id:'',item_number:'',name:'',
                    lead_time:'',quantity:'',show:false,error:false,
                    category:'',stock_id:'',warehouse_id:'',price:0,amount:0,
                    total_volume:0,total_weight:0,input_show:true,cart:'no',
                    attachments:[], update_lead_time:true, warehouse_name:'',
                    warehouse_city:'', warehouse_state:''}
                    item_data.show = false
                    item_data.input_show = false
                    item_data.name = cart_item.item[0].item.name
                    item_data.item_number = cart_item.item[0].item.item_number
                    item_data.item_id = cart_item.item[0].item.id
                    item_data.stock_id = ''//cart_item.item[0].stock[0].id
                    item_data.warehouse_id = cart_item.warehouse_details?cart_item.warehouse_details.warehouse_id:'' //cart_item.item[0].stock[0].warehouse.id
                    item_data.warehouse_name = cart_item.warehouse_details?cart_item.warehouse_details.warehouse_name:''
                    item_data.warehouse_city = cart_item.warehouse?cart_item.warehouse_details.warehouse_city:''
                    item_data.warehouse_state = cart_item.warehouse?cart_item.warehouse_details.warehouse_state:''
                    item_data.category = cart_item.item[0].item.category
                    item_data.cart='yes'
                    item_data.quantity = cart_item.quantity
                    item_data.cart_id = cart_item.id
                    //if('{{ reserve }}' == 'True'){
                        item_data.disabled_qty = true
                   // }else{
                       // item_data.disabled_qty = false
                    //}                            
                    var check_qty = {}
                    check_qty['item_id'] = item_data.item_id 
                    check_qty['warehouse_id'] = item_data.warehouse_id
                    check_available_qty.push(check_qty)   
                    items.push(item_data)
                    previous_data.push(cart_item.quantity)                                     
                })
               /* var csrf_token = '{{ csrf_token }}' 
                var form_data = 'cart_items='+JSON.stringify(check_available_qty)+'&csrfmiddlewaretoken='+csrf_token
                const auth_token = "Token "+localStorage.token
                axios.defaults.headers.post['Authorization'] =auth_token
                var avialable_promise =  axios.post('{% url "customer_portal:check_avialiable_qty" %}', form_data) 
                avialable_promise.then((response) => {
                    response.data.cart_items.forEach((response,index)=>{
                        this.state.table_rows[index].ava_qty = response.quantity                            
                    })
                    this.setState({
                        table_rows: this.state.table_rows,
                    })
                });    */                    
                var promises  = items.map((item,index) =>{
                   /* var lead_time_json = {}
                    lead_time_json[1] = {}
                    lead_time_json[1][item.item_id] = {
                        //swarehouse_id:item.warehouse_id, 
                        quantity:parseFloat(item.quantity) 
                    }  */              
                    const auth_token = "Token "+localStorage.token
                    //var csrf_token = '{{ csrf_token }}'  
                    numberOfAxioxCAllPending++                     
                    //var form_data = 'item_id='+item.item_id+'&quantity='+item.quantity+'&lead_time_items='+JSON.stringify(lead_time_json)+'&csrfmiddlewaretoken='+csrf_token
                    var form_data = 'item_id='+item.item_id+'&quantity='+item.quantity+'&warehouse_id='+item.warehouse_id+'&from_cart='+true+'&cart_id='+item.cart_id
                    axios.defaults.headers.post['Authorization'] =auth_token
                    return axios.post(API_HOST+'/api/get/item/lead/time/', form_data)       
                })
                Promise.all(promises).then(results =>{
                        results.forEach((response,index)=>{
                            if(response){
                                var volume = response.data.total_item_and_box_volume
                                var weight = response.data.total_item_and_box_weight
                                //var qty = leadtime_response.quantity
                                //var total_volume = parseFloat(volume)*parseFloat(qty)?volume:0
                                //var total_weight = parseFloat(weight)*parseFloat(qty)?weight:0
                                items[index].lead_time =  response.data.lead_time.lead_time
                                items[index].warehouse_id =  response.data.lead_time.warehouse_id
                                items[index].warehouse_name =  response.data.lead_time.warehouse_name
                                items[index].ava_qty = response.data.lead_time.available_qty
                                items[index].total_volume = volume
                                items[index].total_weight = weight
                                this.state.total_weight_of_items += weight
                                this.state.total_volume_of_items += volume
                            }
                            numberOfAxioxCAllPending--
                        })
                        this.setState({
                            table_rows: items,
                            cart_items:'cart',
                            total_weight_of_items:this.state.total_weight_of_items,
                            total_volume_of_items:this.state.total_volume_of_items
                        })
                        if(numberOfAxioxCAllPending == 0){
                            ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('save_button') )
                            ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('mobile-save_button') )
                            $('.items-from-cart').find('.inline-loader').fadeOut("slow");
                        }
    
                })   
                var attachment_promises  = items.map((item,index) =>{
                    var item_id = item.item_id
                    const auth_token = "Token "+localStorage.token                        
                    axios.defaults.headers.post['Authorization'] =auth_token
                    return axios.get(API_HOST+'/api/item/attachments/?item_id='+item_id)       
    
                })
                Promise.all(attachment_promises).then(results =>{
                    results.forEach((response,index)=>{
                        if(response){
                        this.state.table_rows[index].attachments = response.data.item_attachments
                        }
                    })
                    this.setState({
                        table_rows:this.state.table_rows
                    })
                })
                var price_promises  = items.map((item,index) =>{    
                    numberOfAxioxCAllPending++                                    
                    const auth_token = "Token "+localStorage.token                        
                    axios.defaults.headers.get['Authorization'] =auth_token
                    return axios.get(API_HOST+'/api/get/item/price/?item_id='+item.item_id)       
                })
                Promise.all(price_promises).then(results =>{
                    results.forEach((response,index)=>{
                        if(response.data.success){
                        this.state.table_rows[index].price = response.data.item_price
                        var qty = this.state.table_rows[index].quantity
                        this.state.table_rows[index].amount = parseFloat(qty)*parseFloat(response.data.item_price)
                    }
                    numberOfAxioxCAllPending--
                    })
                    var total_amount = 0
                    this.state.table_rows.map((item)=>{
                        if(item.quantity && item.price){
                        total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                        }
                    })
                    this.setState({
                        table_rows:this.state.table_rows,
                        total_amount:total_amount
                    })
                    if(numberOfAxioxCAllPending == 0){
                        ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('save_button') )
                        ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('mobile-save_button') )
                        $('.items-from-cart').find('.inline-loader').fadeOut("slow");
                    }
                })
                this.setState({
                    table_rows:items,
                    cart_items:'cart',
                    placed_cart_ids:this.state.placed_cart_ids.concat(JSON.parse(this.props.location.state.params.selected_cart_ids))
                })
                

            }
            }).catch(response =>{
                ErrorHandling(response)
            });

    }else{
        $('.items-from-cart').find('.inline-loader').fadeOut("slow");
        ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('save_button') )
        ReactDOM.render(<SaveButton CreateOrder={this.CreateOrder} props_data = {this.props} />, document.getElementById('mobile-save_button') )

    }
        
        /*if($('#items-tbody').find('tr').not('tr#item-add-btn').length > 0){
            if(this.nameInput){
                this.nameInput.focus();
            }
            
        }*/

    }
   componentDidUpdate(){
       if(this.state.focus){
        if($('#items-tbody').find('tr').not('tr#item-add-btn').length > 0){
            if(this.nameInput){
                this.nameInput.focus();
            }
            this.setState({
                focus:false
            })
        }
    }
    if(this.props.location.page){
        if(this.props.location.page.load){
            this.props.location.page = {load:false}            
            $('.search_input').val('')   
            this.setState({
                OrderData: [],
            table_rows:[],
            addresses:[],
            shipping_address:[],
            billing_address:[],
            items_json:[],
            view:{showModal:false},
            filterText:'',
            change_add_modal:false,
            add_type:'',
            focus:false,
            cart_items:'',
            freight_details:[],
            table_rows:[{item_id:'',item_number:'',name:'', ava_qty:0,
                         lead_time:'',quantity:'',show:false,error:false,
                         category:'',stock_id:'',warehouse_id:'',cart_id:'',
                         total_volume:0,total_weight:0,input_show:true,cart:'no',
                         attachments:[],image_url:'',price:0,amount:0,disabled_qty:false,
                         warehouse_name:'', update_lead_time:false, warehouse_city:'',
                         warehouse_state:''
                        }],
            search_result:[],
            total_volume_of_items:0,
            total_weight_of_items:0,
            total_amount:0,
            formControls: {
                id:{value:''},
                add_index:{value:''},
                add_type: {value: ''}, add1: {value: ''},
                add2: {value: '' }, city: {value: '' },
                state: {value: '' }, country: {value: '' },
                zip_code: {value: '' }               
            },
            currency_symbol:'',
            placed_cart_ids:[],
            lead_time_modal:false,
            lead_time_data:[],
            })
            $('#customer_po').val('')
            $('#customer_reference_number').val('')
            $('#remarks').val('')       
            this.componentDidMount()
        }
    }
         
    }
    handleSearch(event){
        event.preventDefault()
        var index = event.target.getAttribute('data-index')
        var search_text = event.target.value
        this.state.table_rows[parseFloat(index)].item_number = search_text
        this.state.table_rows[index].show = false
        this.setState({
            table_rows:this.state.table_rows  
        })
        var excluded_items = []
        this.state.table_rows.map((row,index) =>{
            if(this.state.table_rows[index].item_id){
                excluded_items.push(parseFloat(this.state.table_rows[index].item_id))
            }
        })
        var items_data = JSON.parse(localStorage.getItem('items'))
        var items = items_data.items
        if(items.length > 0){
            var search_result = items.filter(function(item,indx){ 
            if( (item.item.name.toLocaleLowerCase().includes(search_text.toLocaleLowerCase()) || 
                item.item.item_number.toLocaleLowerCase().includes(search_text.toLocaleLowerCase()) ||
                item.item.description.toLocaleLowerCase().includes(search_text.toLocaleLowerCase()) ) &&
                !excluded_items.includes(item.item.id) ){
                    return item
                }
            })
            if(search_result.length == 0){
                notify('No Items Found','warning')
                this.state.table_rows.map((row,index) =>{
                    this.state.table_rows[index].show = false
                })
                this.setState({
                    table_rows:this.state.table_rows
                })
            }
            this.state.table_rows[parseFloat(index)].item_number = search_text
            this.state.table_rows[parseFloat(index)].show = search_result.length > 0?true:false                   
            this.setState({
                table_rows:this.state.table_rows,
                search_result:search_result?search_result:[],
                filterText:search_text,
            }) 
        }
     /*   
        var search_result = []
        if(search_result.length == 0){  
            this.state.table_rows[index].show = false
            this.setState({
                table_rows:this.state.table_rows  
            }) 
            var excluded_items = []
            this.state.table_rows.map((row,index) =>{
                if(this.state.table_rows[index].item_id){
                    excluded_items.push(this.state.table_rows[index].item_id)
                }
            })
        

       // if(this.previous_request){
         //   this.previous_request.cancel('Operation canceled by the user.');                                
       // }
        //const source = axios.CancelToken.source();
        //this.previous_request = source
        //const auth_token = "Token "+localStorage.token                
        axios.defaults.headers.get['Authorization'] =auth_token
        if(excluded_items.length > 0){
            var url = '{% url "customer_portal:search_items" %}?search_term='+search_text+'&excluded_items='+ JSON.stringify(excluded_items)
        }else{
            var url = '{% url "customer_portal:search_items" %}?search_term='+search_text
        }
         axios.get(url,
        {cancelToken: this.previous_request.token}).then(response => {
          if(response.status == 200){ 
            if(response.data.items == 0){
                notify('No Items Found','warning')
                this.state.table_rows.map((row,index) =>{
                    this.state.table_rows[index].show = false
                })
                this.setState({
                    table_rows:this.state.table_rows
                })
            }
            this.previous_request = null
            this.state.table_rows[parseFloat(index)].item_number = search_text
            this.state.table_rows[parseFloat(index)].show = response.data.items.length > 0?true:false                   
            this.setState({
                table_rows:this.state.table_rows,
                search_result:response.data.items?response.data.items:[],
                filterText:search_text,
                items_json:this.state.items_json.concat(response.data.items?response.data.items:[])
            }) 
                    
            }
        }).catch(response => {
            if(response.message){}else {
            ReactDOM.render(<ErrorHandling Response={response} />,
            document.getElementById('error_handling'))}
        });

    }
    else{
        //$('.search-result').show()
        this.state.table_rows[parseFloat(index)].item_number = search_text
        this.state.table_rows[parseFloat(index)].show = true
        this.setState({
            table_rows:this.state.table_rows,
            search_result:search_result,
            filterText:search_text
        })

        }*/
    }

    handleHideModal(){
        this.setState({
            change_add_modal:false
        })
    }
    HidenModal(){
        this.setState({view: {showModal: false}})  
    }
    leadtimeHideModal(row_index){
        var pre_qty = previous_data[row_index]|0
        this.state.table_rows[parseFloat(row_index)].quantity = pre_qty
        var price = this.state.table_rows[parseFloat(row_index)].price
        this.state.table_rows[parseFloat(row_index)].amount = parseFloat(pre_qty)*parseFloat(price)
        var total_amount = 0
        this.state.table_rows.map((item)=>{
            if(item.quantity && item.price){
            total_amount += parseFloat(item.price)*parseFloat(item.quantity)
            }
        })
        this.setState({
            lead_time_modal:false,
            table_rows:this.state.table_rows,
            total_amount:total_amount

        })
    }
    handleChange(event) {
        event.preventDefault()
        const name = event.target.name;
          const value = event.target.value;        
          this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                ...this.state.formControls[name],
                value
              }
            }
          });
          }
    AddRowHandler(event){
        event.preventDefault()
        this.state.table_rows.push({item_id:'',item_number:'',name:'',cart_id:'',
                                    lead_time:'',quantity:'',show:false, ava_qty:0,
                                    category:'',stock_id:'',warehouse_id:'',error:false,
                                    total_volume:0,total_weight:0,input_show:true,cart:'no',
                                    attachments:[],image_url:'',price:0,amount:0,disabled_qty:false,
                                    warehouse_name:'', update_lead_time:false, warehouse_city:'',
                                    warehouse_state:''})
        this.setState({
            table_rows:this.state.table_rows,
            focus:true
        })
        
    }
    handleRowDel(event){
        event.preventDefault()
        var index = event.currentTarget.getAttribute('data-index')
        var cart = event.currentTarget.getAttribute('cart') 
        var cart_id = event.currentTarget.getAttribute('cart_id') 
        this.state.total_volume_of_items -= this.state.table_rows[parseFloat(index)].total_volume
        this.state.total_weight_of_items -= this.state.table_rows[parseFloat(index)].total_weight
        this.state.table_rows.splice(parseFloat(index),1)
        if(cart == 'yes'){
        this.state.placed_cart_ids.splice(
            this.state.placed_cart_ids.indexOf(parseFloat(cart_id)), 1)
        }
        var total_amount = 0
            this.state.table_rows.map((item)=>{
                if(item.quantity && item.price){
                total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                }
            })
        this.setState({
            table_rows:this.state.table_rows,
            total_weight_of_items:this.state.total_weight_of_items,
            total_weight_of_items:this.state.total_weight_of_items,
            total_amount:total_amount,
            placed_cart_ids:this.state.placed_cart_ids

        })
        previous_data.splice(index,1)
    }
    AddressEditHandler(event){
        event.preventDefault()
        var address_type = event.target.getAttribute('add-type')
        var index =event.target.getAttribute('this_index')
        if(address_type == 'billing'){
            var address_data = this.state.billing_address[parseFloat(index)]
        }else{
            var address_data = this.state.shipping_address[parseFloat(index)]
        }
        

        this.setState(()=>{
            return {
                formControls: {
                  id:{value:address_data.id},
                  add_index:{value:index},
                  add_type: {value: address_type}, add1: {value:address_data.add1},
                  add2: {value:address_data.add2 }, city: {value:address_data.city},
                  state: {value:address_data.state}, country: {value:address_data.country},
                  zip_code: {value:address_data.zip_code }               
              },
                view: {showModal: true}

            }
         })

    }
    AddressSubmitHandler(event){
        event.preventDefault()
        var index = ''
        var add_type = ''
        function serialize(form) {
            var inputs = form.elements;
            var address_object = {};
            index = document.querySelector('input[name="index"]').value
            for(let i=0; i < inputs.length; i++) {
              if(inputs[i].name == 'index'){
              }else if(inputs[i].name == 'add_type'){
                add_type = inputs[i].value
              }
              else{
                address_object[inputs[i].name] = inputs[i].value
              }
            }
            //address_object['add_type'] = document.querySelector('input[name="add_type"]:checked').value;
            //address_object['isdefault']=document.querySelector('input[name="isdefault"]:checked').value;
            return address_object
          }
            var form = document.querySelector('#address_form')
            var form_data = serialize(form)
            if(add_type == 'billing'){
            var address_list = this.state.billing_address
            }else{
                var address_list = this.state.shipping_address
            }
            var option_str = ""
            Object.keys(form_data).forEach(function(val){
                address_list[parseFloat(index)][val] = form_data[val]
                if(val == 'id'){

                }else{
                    option_str +=  form_data[val] +','
                }                       
            })
            //$('select#'+add_type).find('option[value=' + address_list[parseFloat(index)].id + ']').text(option_str)
            //$('select#'+add_type).selectpicker('refresh')
            if(add_type == 'billing'){
                this.setState(()=>{
                    return {
                        billing_address: address_list
                    }
                })
            }else{
                this.setState(()=>{
                    return {
                        shipping_address: address_list
                    }
                })
            }
            
            $('#customer-billing-address-create-dialog').modal('hide');
        }

    HandleRenderItemData(event){
        var row_index = $(event.target).closest('.order-item').attr('row-index')
        var item_id= $(event.target).closest('.order-item').attr('item-id')
        var price= $(event.target).closest('.order-item').attr('price')
        var category = $(event.target).closest('.order-item').attr('category')
        var stock_id = $(event.target).closest('.order-item').attr('stock-id')
        var warehouse_id = $(event.target).closest('.order-item').attr('warehouse-id')
        //var attributes = {'category':category,'item-id':item_id,
        var attributes = {'row_index':row_index}//,'stock-id':stock_id,'warehouse_id':warehouse_id}
        $(event.target).closest('tr').find('.quantity-td').attr(attributes)
        $(event.target).closest('tr').attr(attributes)
        var item_name = $(event.target).closest('.order-item').find('.item-name').text()
        var item_number = $(event.target).closest('.order-item').find('.font-10pt-color-chocolate').text()
        this.state.table_rows[parseFloat(row_index)].show = false
        this.state.table_rows[parseFloat(row_index)].input_show = false
        this.state.table_rows[parseFloat(row_index)].name = item_name
        this.state.table_rows[parseFloat(row_index)].item_number = item_number
        this.state.table_rows[parseFloat(row_index)].item_id = item_id
        this.state.table_rows[parseFloat(row_index)].stock_id = stock_id
        this.state.table_rows[parseFloat(row_index)].price = parseFloat(price)
        this.state.table_rows[parseFloat(row_index)].warehouse_id = warehouse_id
        this.state.table_rows[parseFloat(row_index)].category = category
        this.setState({
            table_rows:this.state.table_rows,
            filterText:item_number
        })
        $(event.target).closest('tr').find('.quantity-td').find('input').focus()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/item/attachments/?item_id='+item_id,
        ).then(response => {
        if(response.status == 200){
            this.state.table_rows[parseFloat(row_index)].attachments = response.data.item_attachments               
            this.setState({
                table_rows:this.state.table_rows

            })             
        }
            }).catch(response => {
                ErrorHandling(response)
            });
        //$(event.target).closest('tr').find('.input-group').addClass('d-none')
        //$(event.target).closest('tr').find('.item-number').removeClass('d-none')
    }

    HandleQuantityChange(event){
        //var this_val = event.currentTarget.value 
        var el = $(event.currentTarget),
        val = Math.max((0, el.val())),
        max = parseInt(99999999);  
        var qty = isNaN(max) ? parseFloat(val.toString().replace(/[^0-9]+/g, "")) : Math.min(max, val)
        //var qty = event.currentTarget.value
        var row_index = $(event.target).closest('td').attr('row_index')
        this.state.table_rows[parseFloat(row_index)].quantity = qty
        var price = this.state.table_rows[parseFloat(row_index)].price
        this.state.table_rows[parseFloat(row_index)].amount = parseFloat(qty)*parseFloat(price)
        //this.state.table_rows[parseFloat(row_index)].ava_qty = 0
        var total_amount = 0
        this.state.table_rows.map((item)=>{
            if(item.quantity && item.price){
            total_amount += parseFloat(item.price)*parseFloat(item.quantity)
            }
        })
        this.setState({
            table_rows:this.state.table_rows,
            total_amount:total_amount
        })
        
    }

    checkAviailableQuantity(event){ 
        const auth_token = "Token "+localStorage.token
        var this_value = event.currentTarget.value
        var row_index = $(event.currentTarget).closest('td').attr('row_index')
        var is_updated_leadtime =   this.state.table_rows[row_index].update_lead_time
        if(is_updated_leadtime){                    
            var row_index =  $(event.target).closest('tr').attr('row_index')
            var item_id = $(event.target).closest('tr').attr('item-id')
            if(parseFloat(this_value) > 0 && item_id){                                    
            var warehouse_id = this.state.table_rows[row_index].warehouse_id
            // var csrf_token = '{{ csrf_token }}'                     
            var form_data = 'item_id='+item_id+'&quantity='+this_value+'&warehouse_id='+warehouse_id+'&from_cart='+true
            axios.defaults.headers.post['Authorization'] =auth_token
            axios.post(API_HOST+'/api/get/item/lead/time/', form_data)
              .then(response => {
                if(response.status == 200){
                    this.state.total_volume_of_items -= this.state.table_rows[parseFloat(row_index)].total_volume
                    this.state.total_weight_of_items -= this.state.table_rows[parseFloat(row_index)].total_weight
                    this.state.table_rows[parseFloat(row_index)].lead_time = response.data.lead_time.lead_time
                    this.state.table_rows[parseFloat(row_index)].total_volume = response.data.total_item_and_box_volume
                    this.state.table_rows[parseFloat(row_index)].total_weight = response.data.total_item_and_box_weight
                    this.state.table_rows[parseFloat(row_index)].ava_qty = response.data.lead_time.available_qty
                    this.state.total_volume_of_items += response.data.total_item_and_box_volume
                    this.state.total_weight_of_items += response.data.total_item_and_box_weight
                    this.setState({
                        table_rows:this.state.table_rows,
                        total_weight_of_items:this.state.total_weight_of_items,
                        total_volume_of_items:this.state.total_volume_of_items
                      })
                  }
              }).catch(response => {
                ErrorHandling(response)
            });
            }else{
                if(item_id){
                this.state.table_rows[parseFloat(row_index)].lead_time = ''                        
                this.state.total_volume_of_items -= this.state.table_rows[parseFloat(row_index)].total_volume
                this.state.total_weight_of_items -= this.state.table_rows[parseFloat(row_index)].total_weight
                this.state.table_rows[parseFloat(row_index)].total_volume = 0.0
                this.state.table_rows[parseFloat(row_index)].total_weight = 0.0
                this.setState({
                    table_rows:this.state.table_rows,
                    total_weight_of_items:this.state.total_weight_of_items,
                    total_volume_of_items:this.state.total_volume_of_items  
                })
            }

            }

        }else{
        if(!parseFloat(this_value) > 0){
            this.state.table_rows[row_index].error = true 
            this.setState({
                table_rows:this.state.table_rows
            })
            //notify("Enter Item Quantity.",'error')
            setTimeout(() => {
                this.state.table_rows.filter((row,index)=>{
                    this.state.table_rows[index].error = false
                    })
                this.setState({
                    table_rows:this.state.table_rows
                })
              }, 6000);                    
        }           
        var item_id = $(event.currentTarget).closest('td').attr('item-id')
        if(parseFloat(this_value) > 0 && item_id){
        $(".lds-ripple-parent-1").fadeIn();                    
        var stock_id = $(event.currentTarget).closest('td').attr('stock-id')
        var warehouse_id = $(event.currentTarget).closest('td').attr('warehouse_id')
        var lead_time_json = {}
        lead_time_json[1] = {}
        lead_time_json[1][item_id] = {
            warehouse_id:warehouse_id, quantity:parseFloat(this_value)
        }                   
        var form_data = {
            'item_id': item_id,
            'stock_id': stock_id,
            'warehouse_id': warehouse_id,
            'quantity': this_value,
            'lead_time_items': JSON.stringify(lead_time_json)
        }
        //var csrf_token = '{{ csrf_token }}' 
        var form_data = 'item_id='+item_id+'&stock_id='+stock_id+'&warehouse_id='+warehouse_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)
        axios.defaults.headers.post['Authorization'] =auth_token
        axios.post(API_HOST+'/api/get/item/lead/time/', form_data)
            .then(response => {
            if(response.status == 200){
                var check_availability = false
                var cart_items = this.state.all_cart_items

               /* response.data.lead_time.map((item, index) =>{
                    if(response.data.lead_time[index].add_to_cart){
                        
                    }else{
                        check_availability = true
                    }
                    response.data.lead_time[index].selected_item = true
                    response.data.lead_time[index].item_number = this.state.table_rows[row_index].item_number
                    response.data.lead_time[index].item_description = this.state.table_rows[row_index].name
                    response.data.lead_time[index].price = this.state.table_rows[row_index].price
                    response.data.lead_time[index].cart_row_index = row_index
                })*/

                var available_item_data = {}
                available_item_data['available'] = response.data.lead_time.available
                available_item_data['backorder'] = response.data.lead_time.backorder
                available_item_data['intransit'] = response.data.lead_time.intransit
                available_item_data['this_value'] = this_value
                var item_data =[]
                var check_count = 0
                response.data.lead_time.data.map((warehouse_item, index) =>{
                if(warehouse_item.lead_time.length > 0){
                    var warehouse_id = response.data.lead_time.warehouse_list[index]
                    var item_obj = {}
                    item_obj.warehouse_details = warehouse_item[warehouse_id].warehouse_details
                    //item_obj.lead_time = warehouse_item.lead_time
                    item_obj.final_msg = warehouse_item.final_message
                    item_obj.intransit_qty = 0
                    item_obj.intransit_list = []
                    warehouse_item.lead_time.map((lead_time_item,indx) =>{                         
                        if(lead_time_item.status == 'available'){
                            item_obj.avi_qty = lead_time_item.quantity
                            item_obj.avi_lead_time = lead_time_item.item_lead_time
                            item_obj.lead_time = lead_time_item.item_lead_time
                        }else if(lead_time_item.status == 'intransit'){
                            item_obj.intransit_qty += lead_time_item.quantity
                            item_obj.intransit_lead_time = lead_time_item.item_lead_time
                            var obj = {'quantity':lead_time_item.quantity,'lead_time':lead_time_item.item_lead_time,'message':lead_time_item.message}
                            item_obj.intransit_list.push(obj)
                        }

                        if(parseFloat(this_value) <= parseFloat(lead_time_item.quantity) && lead_time_item.add_to_cart){
                            if(check_count == 0 ){
                                check_availability = true 
                            }                            
                        }
                        item_obj.item_id = lead_time_item.item_id                     
                    })
                    check_count += 1
                    item_obj.item_and_box_volume = response.data.lead_time.item_volume_weight.item_and_box_volume
                    item_obj.item_and_box_weight = response.data.lead_time.item_volume_weight.item_and_box_weight
                    item_obj.selected_item = true                            
                    item_obj.item_number = this.state.table_rows[row_index].item_number
                    item_obj.item_description = this.state.table_rows[row_index].name
                    item_obj.price = this.state.table_rows[row_index].price
                    item_obj.cart_row_index = row_index
                    item_obj.quantity = this_value
                    item_data.push(item_obj)
                }

                })
                available_item_data['item_data'] = item_data
                if(check_availability){  
                    this.HandleGetLeadTime(item_data)                          
                    
                }else{
                    this.setState({
                        lead_time_modal:true,
                        lead_time_data:available_item_data,
                    })
                }




                
             /* if(check_availability){
                     
                }else{
                    response.data.lead_time[0].cart_row_index = row_index
                    response.data.lead_time[0].ava_qty = response.data.lead_time[0].quantity
                    this.HandleGetLeadTime(response.data.lead_time[0])
                }*/
                $(".lds-ripple-parent-1").fadeOut("slow");
                
                                
            }
            }).catch(response => {
                ErrorHandling(response)
        });
        }else{
            
            $(".lds-ripple-parent-1").fadeOut("slow");
        }
    }

}
    HandleGetLeadTime(selected_data){
        /*
            var this_value = $(event.target).val()
            var row_index =  $(event.target).closest('tr').attr('row_index')
            var item_id = $(event.target).closest('tr').attr('item-id')
            if(parseFloat(this_value) > 0 && item_id){                    
            var stock_id = $(event.target).closest('tr').attr('stock-id')
            var warehouse_id = $(event.target).closest('tr').attr('warehouse_id')
            var category = $(event.target).closest('tr').attr('category')                    
            var lead_time_json = item_section_lead_time_json()                    
            const auth_token = "Token "+localStorage.token
            var csrf_token = '{{ csrf_token }}' 
            var form_data = {
                'item_id': item_id,
                'stock_id': stock_id,
                'warehouse_id': warehouse_id,
                'quantity': this_value,
                'lead_time_items': JSON.stringify(lead_time_json)
            }
            var form_data = 'item_id='+item_id+'&stock_id='+stock_id+'&warehouse_id='+warehouse_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)+'&csrfmiddlewaretoken='+csrf_token
            axios.defaults.headers.post['Authorization'] =auth_token
            axios.post('{% url "customer_portal:get_item_lead_time" %}', form_data)
              .then(response => {
                if(response.status == 200){
                    this.state.total_volume_of_items -= this.state.table_rows[parseFloat(row_index)].total_volume
                    this.state.total_weight_of_items -= this.state.table_rows[parseFloat(row_index)].total_weight
                    this.state.table_rows[parseFloat(row_index)].lead_time = response.data.lead_time
                    this.state.table_rows[parseFloat(row_index)].total_volume = response.data.total_item_and_box_volume
                    this.state.table_rows[parseFloat(row_index)].total_weight = response.data.total_item_and_box_weight
                    this.state.total_volume_of_items += response.data.total_item_and_box_volume
                    this.state.total_weight_of_items += response.data.total_item_and_box_weight
                    this.setState({
                        table_rows:this.state.table_rows,
                        total_weight_of_items:this.state.total_weight_of_items,
                        total_volume_of_items:this.state.total_volume_of_items
                      })
                  }
              }).catch(response => {
                ReactDOM.render(<ErrorHandling Response={response} />,
                document.getElementById('error_handling'))
            });
            }else{
                if(item_id){
                this.state.table_rows[parseFloat(row_index)].lead_time = ''                        
                this.state.total_volume_of_items -= this.state.table_rows[parseFloat(row_index)].total_volume
                this.state.total_weight_of_items -= this.state.table_rows[parseFloat(row_index)].total_weight
                this.state.table_rows[parseFloat(row_index)].total_volume = 0.0
                this.state.table_rows[parseFloat(row_index)].total_weight = 0.0
                this.setState({
                    table_rows:this.state.table_rows,
                    total_weight_of_items:this.state.total_weight_of_items,
                    total_volume_of_items:this.state.total_volume_of_items  
                })
            }

            }*/
        
        selected_data.map((data,indx)=>{
            var row_index = parseFloat(data.cart_row_index)+indx
            if(this.state.table_rows[parseFloat(row_index)]){

            }else{
                this.state.table_rows[parseFloat(row_index)] = {item_id:'',item_number:'',name:'',cart_id:'',
                lead_time:'',quantity:'',show:false, ava_qty:0,
                category:'',stock_id:'',warehouse_id:'',error:false,
                total_volume:0,total_weight:0,input_show:true,cart:'no',
                attachments:[],image_url:'',price:0,amount:0,disabled_qty:false,
                warehouse_name:'', update_lead_time:false, warehouse_city:'',
                warehouse_state:''
            }
            }
            var qty = data.quantity                    
            previous_data[row_index] = qty
            this.state.table_rows[parseFloat(row_index)].show = false
            this.state.table_rows[parseFloat(row_index)].input_show = false
            this.state.table_rows[parseFloat(row_index)].name = data.item_description
            this.state.table_rows[parseFloat(row_index)].item_number = data.item_number
            this.state.table_rows[parseFloat(row_index)].item_id = data.item_id
            this.state.table_rows[parseFloat(row_index)].stock_id = ''
            this.state.table_rows[parseFloat(row_index)].category = ''
            this.state.table_rows[parseFloat(row_index)]['quantity'] = qty
            this.state.table_rows[parseFloat(row_index)]['price'] = data.price
            var price = data.price
            this.state.table_rows[parseFloat(row_index)]['amount'] = parseFloat(qty)*parseFloat(price)
            var total_amount = 0
            this.state.table_rows.map((item)=>{
                if(item.quantity && item.price){
                total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                }
            })                
            this.state.total_volume_of_items -= this.state.table_rows[parseFloat(row_index)]?this.state.table_rows[parseFloat(row_index)].total_volume:0
            this.state.total_weight_of_items -= this.state.table_rows[parseFloat(row_index)]?this.state.table_rows[parseFloat(row_index)].total_weight:0
            this.state.table_rows[parseFloat(row_index)]['lead_time'] = data.lead_time
            this.state.table_rows[parseFloat(row_index)]['total_volume'] = data.item_and_box_volume
            this.state.table_rows[parseFloat(row_index)]['total_weight'] = data.item_and_box_weight
            this.state.table_rows[parseFloat(row_index)]['ava_qty'] = data.avi_qty
            this.state.table_rows[parseFloat(row_index)]['warehouse_id'] = data.warehouse_details.warehouse_id
            this.state.table_rows[parseFloat(row_index)]['warehouse_name'] = data.warehouse_details.warehouse_name
            this.state.table_rows[parseFloat(row_index)]['warehouse_city'] = data.warehouse_details.warehouse_city
            this.state.table_rows[parseFloat(row_index)]['warehouse_state'] = data.warehouse_details.warehouse_state
            this.state.table_rows[parseFloat(row_index)]['update_lead_time'] = true
            this.state.total_volume_of_items += data.item_and_box_volume
            this.state.total_weight_of_items += data.item_and_box_weight
            this.setState({
                table_rows:this.state.table_rows,
                total_weight_of_items:this.state.total_weight_of_items,
                total_volume_of_items:this.state.total_volume_of_items,
                total_amount:total_amount,
                lead_time_modal:false,
                })

                })
        
    }

    handleAddressChange(event){
        event.preventDefault()
        var add_type = event.target.getAttribute('add-type')
        $('#change-address-dialog').modal('hide');                
        this.setState({
            change_add_modal:true,
            add_type:add_type
        })

    } 

    SetDefaultAddress(event){
        event.preventDefault()
        var ischecked = event.currentTarget.checked
        var add_type = event.currentTarget.getAttribute('this_type')
        var this_index = event.currentTarget.getAttribute('this_index')
        if(add_type == '1'){
            var billingadd = []
            this.state.billing_address.map((address,index) =>{
                if(index == parseFloat(this_index)){
                    address.isdefault = true
                    billingadd.push(address)
                }else{
                    address.isdefault = false
                    billingadd.push(address)
                }
            })
            this.setState({
                billing_address:billingadd,
                change_add_modal:false
            })
        }
        else if(add_type == '2'){
            var shippingadd = []
            this.state.shipping_address.map((address,index) =>{
                if(index == parseFloat(this_index)){
                    address.isdefault = true
                    shippingadd.push(address)
                }else{
                    address.isdefault = false
                    shippingadd.push(address)
                }
            })
            this.setState({
                shipping_address:shippingadd,
                change_add_modal:false
            })
        }
        $('#change-address-dialog').modal('hide');

    } 
    HandleKeyUp(e){
        e.preventDefault()
        if (e.keyCode == 40) {
            if ($(e.target).closest('tr').find('div.auto-complete-table').find('div.active').length != 0) {
                $(e.target).closest('tr').find('div.auto-complete-table').find('div.active').removeClass('active').next('div').addClass('active')

            } else {
                $(e.target).closest('tr').find('div.auto-complete-table').find('div.order-item').first().addClass('active')
            }
            var container =$(e.target).closest('tr').find('div.auto-complete-table')
            var scrollTo = $('div.auto-complete-table>div.active');
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
            }, 150);
        }
        else if (e.keyCode == 38) {
            if ($(e.target).closest('tr').find('div.auto-complete-table').find('div.active').length != 0) {
                var above_tr = $(e.target).closest('tr').find('div.auto-complete-table').find('div.active').removeClass('active').prev('div.order-item')
                above_tr.addClass('active')

            } else {
                $(e.target).closest('tr').find('div.auto-complete-table').find('div.order-item').last().addClass('active')
            }
            var container = $(e.target).closest('tr').find('div.auto-complete-table'),
                    scrollTo =$('div.auto-complete-table>div.active');
            if (scrollTo) {
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
                }, 150);
            }
        }else if (e.keyCode == 13) {
            if ($(e.target).closest('tr').find('div.auto-complete-table').find('div.active').length != 0) {
                $(e.target).closest('tr').find('div.auto-complete-table').find('div.active').trigger('click')
            }
        }

    }  
    
    onFocusOutCategory(event){
        current_row_index = $(event.currentTarget).closest('tr').attr('row_index')
        setTimeout(()=>{
            this.onhideSearchResult(current_row_index)
        },500)
    }

    onhideSearchResult(row_index){
        this.state.table_rows[row_index].show = false 
        this.setState(()=>{
            return {table_rows:this.state.table_rows}
        })
    }

    CreateOrder(event){
        event.preventDefault()
        var reserve = $(event.currentTarget).attr('reserve')
        var check_quantity = true
        var items_list = []
        var item_attachments = []  
        var check_item = true 
        var flag_error = false
        var check_item_qty = false
        var lead_time_check = false
        this.state.table_rows.filter((row,index)=>{ 
            if(row.item_id){
                if(row.item_id && row.quantity > 0 && row.lead_time){
                    items_list.push(row)
                    if(row.attachments.length > 0){
                        row.attachments.map((attachment,indx)=>{
                            item_attachments.push(attachment)
                        })
                    }
                }
                else if(row.item_id && !row.quantity){
                    this.state.table_rows[index].error = true
                    this.setState({
                        table_rows:this.state.table_rows
                    })
                    flag_error = true 
                    check_item_qty = true                       
                }else if(row.item_id && !row.lead_time){
                    lead_time_check = true
                    this.state.table_rows[index].error = true
                    this.setState({
                        table_rows:this.state.table_rows
                    })
                    flag_error = true 
                }
            }
        })
        if($('#customer_po').val().length > 50){
            notify("Ensure Customer PO has no more than 50 characters",'error')
            return false
        }
        if($('#customer_reference_number').val().length > 50){
            notify("Ensure Customer Reference Number has no more than 50 characters",'error')
            return false
        }               
        if(flag_error){
            if(check_item_qty){
                notify("Enter Item Quantity.",'error')
            }else if (lead_time_check){
                notify("Please Check Items Lead Time.",'error')
            }
            setTimeout(() => {
                this.state.table_rows.filter((row,index)=>{
                    this.state.table_rows[index].error = false
                    })
                this.setState({
                    table_rows:this.state.table_rows
                })
            }, 6000); 
            $(".lds-ripple-parent-1").fadeOut('slow'); 
            return false
        }else{
            if(!items_list.length > 0){
                notify('Please Select Items.','error')
                $(".lds-ripple-parent-1").fadeOut('slow'); 
                return false
            }
        }  

        if(this.props.location.state?this.props.location.state.params.reserve:false){
            var msg = 'Are you sure you want to reserve the order?'
            var success_msg = 'Your requested quantity of stock has been successfully reserved for a period of one week.'
            var check_reserved = true
            items_list.map((row_item,index)=>{
                if(parseFloat(row_item.quantity) <= parseFloat(row_item.ava_qty)){
                    
                }else{
                    check_reserved = false
                    this.state.table_rows[index].error = true  
                }
            })
            if(!check_reserved){                            
                check_quantity = false                                
                notify('You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.', 'warning')
                return false
            }
            this.setState({
                table_rows:this.state.table_rows                        
            })
            reserve = true
        }else{
            if(reserve){
                var msg = 'Are you sure you want resvere the order?'
                var success_msg = 'Your requested quantity of stock has been successfully reserved for a period of one week.'
                var check_reserved = true
                items_list.map((row_item,index)=>{                        
                    if(parseFloat(row_item.quantity) <= parseFloat(row_item.ava_qty)){
                        
                    }else{
                        check_reserved = false
                        this.state.table_rows[index].error = true                                                                       
                    }                         
                })
                if(!check_reserved){                            
                    check_quantity = false                                
                    notify('You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.', 'warning')
                    return false
                }
                this.setState({
                    table_rows:this.state.table_rows                        
                })
                reserve = true
            }else{
                var msg = 'Are you sure you want to place the order?'
                var success_msg = 'Order Placed Successfully.'
                //var check_quantity = true
                reserve = false
            }
        }                
                 
        this.attachment_form_data = new FormData()
        var order_form_fields = $('#order_form').serializeArray()
        order_form_fields.forEach((order)=>{
            this.attachment_form_data.append(
                order.name, order.value
            )
        })
                
        this.attachment_form_data.append('freight_type',$('select#freight_type option:selected').val())
        var total_volume = $('#total_item_volume').attr('total_volume')
        var total_weight =  $('#total_item_weight').attr('total_weight')
        this.attachment_form_data.append('total_volume',total_volume)
        this.attachment_form_data.append('total_weight',total_weight)
        var billing_address = this.state.billing_address.filter(function(val){ if(val.isdefault){return val}})
        var shipping_address = this.state.shipping_address.filter(function(val){ if(val.isdefault){return val}})
        this.attachment_form_data.append('billing_address',JSON.stringify(billing_address))
        this.attachment_form_data.append('shipping_address',JSON.stringify(shipping_address))
        var items_data ={}
        if(items_list.length > 0){                    
            items_list.forEach(function(item,index){
                items_data[index] = {
                    'item_id':item.item_id,
                    'warehouse_id':item.warehouse_id,
                    'category':item.category,
                    'stock_id':item.stock_id,
                    'quantity': parseFloat(item.quantity),
                    'item_description': item.name,
                    'lead_time':item.lead_time
                } 
            })

        }else{
            notify('Please Select Items.','error')
            //$(".lds-ripple-parent-1").fadeOut('slow'); 
            return false
        }
        if(window.confirm(msg)){
            $(".lds-ripple-parent-1").fadeIn(); 
            //var csrf_token = '{{ csrf_token }}' 
            //this.attachment_form_data.append('csrfmiddlewaretoken',csrf_token)
            this.attachment_form_data.append('quotation_items', JSON.stringify(items_data))
            this.attachment_form_data.append('cp_item_attachments', JSON.stringify(item_attachments))
            if(reserve){
                this.attachment_form_data.append('reserve', true)  
            }else{
                this.attachment_form_data.append('reserve', false)  
            }
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.post['Authorization'] =auth_token
            axios({
                method: 'post', 
                url: API_HOST+'/api/sales/orders/',
                data: this.attachment_form_data,
                headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                }
            }).then((response) =>{
                if(response.status == 200){                         
                    if(response.data.sales_order){
                        notify(success_msg, 'success')
                        if(this.state.cart_items == 'cart'){
                        var cart_items = this.state.table_rows
                        var cart_item_ids = JSON.parse(localStorage.getItem('cart_item_ids'))
                        cart_items.filter((item,index) =>{
                            if(item.cart == 'yes'){
                                cart_item_ids.splice(cart_item_ids.indexOf(item.item_id),1)                                      
                            }
                            })
                        localStorage.setItem('cart_item_ids',JSON.stringify(cart_item_ids))
                        //localStorage.setItem('cart_items',JSON.stringify(remaining_items))
                        axios.defaults.headers.delete['Authorization'] =auth_token
                        axios.delete(API_HOST+'/cart/update/cart/item/?cart_ids='+ JSON.stringify(this.state.placed_cart_ids),
                        ).then(response => {
                        if(response.status == 200 && response.data.success){

                        }
                        }).catch(response => {
                            ErrorHandling(response)
                        });
                        }
                        setTimeout(() =>{
                            $(".lds-ripple-parent-1").fadeOut('slow'); 
                            this.props.history.push('/order/view/'+response.data.sales_order.id+'/')
                            //window.location.href = '/order/view/'+response.data.sales_order.id+'/'
                        },5000)
                        
                    }
                    
                }
            }).catch(response => {
                $(".lds-ripple-parent-1").fadeOut('slow'); 
                ErrorHandling(response)
            }); 
        }
    }

    restrictDecimal(e){
        if(e.key==='.'){e.preventDefault()}
    }

    render() {
        var table_data = this.state.table_rows.map((row,index) =>{
            return(
                <tr id="new-tr" className="variant-row" category={row.category} item-id={row.item_id} key={row.item_id}  stock-id={row.stock_id} warehouse_id={row.warehouse_id} row_index={index} cart_id={row.cart_id}>
                        <td  className="create-item-number">
                                <div className="input-group" >
                                        {row.input_show? <input className="form-control item-search-field clearable" id="" type="text" ref={(input) => { this.nameInput = input; }} 
                                                placeholder="Add Items" onChange={this.handleSearch} value={row.item_number} placeholder="Search for Item" data-index={index} onBlur={this.onFocusOutCategory} onKeyUp={this.HandleKeyUp}/>:null}
                                    </div>
                                    {row.show?<SearchItemsData index={index} HandleRenderItemData={this.HandleRenderItemData} searchResult={this.state.search_result}/>:null}  
                                    
                                    {row.input_show?null:<span className="item-number">{row.item_number}</span>}
                                    {row.input_show?null:<br/>}
                                    {row.input_show?null:<span className="item-name">{row.name}</span>}
                                </td>
                        {true?null:<td className="create-item-name"><span className="item-name">{row.name}</span> </td>}
                        <td className="create-warehouse-name">
                            <span>{row.warehouse_city?row.warehouse_city+',':row.warehouse_name}</span>
                            <span> {row.warehouse_state?row.warehouse_state+'.':null}</span>
                        </td>
                        <td className="create-lead-time">
                            <span className="lead-time"> {row.lead_time}</span>
                            {row.ava_qty?parseFloat(row.quantity) > parseFloat(row.ava_qty)? 
                                <p className="available-qty">Available Quantity: {row.ava_qty}</p>
                                :'':''}
                        </td>
                        <td className="text-right create-item-price" ><span>{row.price?Humanize(row.price):'0.00'}</span></td>
                        <td className="text-right quantity-td create-item-quantity" row_index={row.index} category={row.category} item-id={row.item_id}  stock-id={row.stock_id} warehouse_id={row.warehouse_id} row_index={index}>
                                <input type="number" name="quantity" id="quantity" className= {row.error?"form-control text-right quantity error":"form-control text-right quantity"}  value={row.quantity} placeholder="Quantity" 
                                onKeyDown = {this.restrictDecimal}  
                                onBlur={this.checkAviailableQuantity} 
                                onChange={this.HandleQuantityChange} disabled={row.disabled_qty} 
                                />
                        </td>
                        <td className="text-right"><span>{row.amount?Humanize(row.amount):'0.00'}</span></td>
                        <td className="text-center" >
                            <a href="#!" className="fas fa-trash remove-item btn-icon btn-flat btn-rounded" data-index={index} onClick={this.handleRowDel} cart={row.cart} cart_id={row.cart_id}></a>
                        </td>
                    </tr>
                    )
                })
                if(!this.state.lead_time_modal){
                    $('#smart-lead-time-modal-dialog').modal('hide');
                }
                $('#filter_loader').fadeOut('slow')
        return (
            <div className="container-fluid create-order-container" >                
                <div id="order-view">
                    <div className="row item-row-card">
                        <div className="col-md-12">                                        
                            <div className="card pdd-15 items-from-cart">
                                <div className="inline-loader" id="cart_item_loader">
                                    <SubLoader/> 
                                </div>                                                 
                                    <table className="table table-hover create-order-table">
                                        <thead className="create-order-header">
                                            <tr>
                                                <th className="width-25">Item #</th>
                                                {true?null:<th className="width-20">Name</th>}
                                                <th className="width-20">Warehouse</th>
                                                <th className="width-20">Lead Time</th>
                                                <th className="text-right width-10 ">Price<span className="currency">( {this.state.currency_symbol?this.state.currency_symbol:'$'} )</span></th>
                                                <th className="text-right width-10">Quantity</th>
                                                <th className="text-right width-15">Amount<span className="currency">( {this.state.currency_symbol?this.state.currency_symbol:'$'} )</span></th>
                                                <th className="width-5"></th>
                                            </tr>
                                        </thead>
                                        <tbody id="items-tbody">
                                            {table_data}
                                            <tr id="item-add-btn">
                                                    <td colSpan="7">
                                                            <button className="btn btn-default background-white-no-border add-item-tr btn-animation" onClick={this.AddRowHandler}>
                                                                    Item <i className="fa fa-plus-circle"></i></button>
                                                    </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                    {this.state.total_amount?
                                    <div className="row">
                                            <div className="col-md-12">
                                                <div className="pull-right text-right total-amount-padding">
                                                    <b className="total_amount text-dark font-size-16">Total Amount : <span className="currency">( {this.state.currency_symbol?this.state.currency_symbol:'$'} )</span> <span className="amount">{Humanize(this.state.total_amount)}</span></b>
                                                </div>
                                            </div>
                                    </div>:null }                             
                                </div>                                 
                            </div>
                        </div>
                    <form id="order_form">                   
                    <CustomerAddress order_data={this.state.OrderData} 
                    AddressEditHandler={this.AddressEditHandler} 
                    billing_add={this.state.billing_address} 
                    shipping_add={this.state.shipping_address}
                    handleAddressChange={this.handleAddressChange}/>
                    <SSIDetails freight_details={this.state.freight_details} total_volume_of_items={this.state.total_volume_of_items} total_weight_of_items={this.state.total_weight_of_items} />
                    </form>                   
                    {this.state.view.showModal ? <AddressModal HidenModal={this.HidenModal} AddressSubmitHandler={this.AddressSubmitHandler} addressData={this.state.formControls} onchange={this.handleChange} />
                     : null}
                     {this.state.change_add_modal?<ChangeDefaultAddress  handleHideModal={this.handleHideModal} add_type={this.state.add_type} billing_add={this.state.billing_address} shipping_add={this.state.shipping_address} SetDefaultAddress={this.SetDefaultAddress}/>:null}
                     <ItemAttachments table_rows={this.state.table_rows}/>
                     <Organisation order_data={this.state.OrderData}  />
                     {this.state.lead_time_modal ? <SmartLeadTimeModal handleHideModal={this.leadtimeHideModal} items={this.state.lead_time_data} symbol={this.state.currency_symbol}  AddToCartHandler={this.HandleGetLeadTime} from={false} />
                            : <div ref={this.modelHide}></div>}
                    
                </div>
            </div>
        )
    }
}


class SaveButton extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div className="page-action-buttons-div">
            {this.props.props_data.location.state?
            this.props.props_data.location.state.params.reserve?
            <a className="btn btn-warning btn-animation resvere-order-btn" onClick={this.props.CreateOrder}  title="Reserve" href="void(0)" key={1} >
                Reserve
            </a>:
            <a className="btn btn-success btn-animation place-order-btn" onClick={this.props.CreateOrder}  title="Place Order" href="void(0)" key={2}>
                Place Order
            </a>:[
            <a className="btn btn-warning btn-animation resvere-order-btn" onClick={this.props.CreateOrder}  title="Reserve" href="void(0)" reserve="true" key={3}>
                Reserve
            </a>,
            <a className="btn btn-success btn-animation place-order-btn" onClick={this.props.CreateOrder}  title="Place Order" href="void(0)" key={4}>
                Place Order
            </a>
            ] }
        </div>
        )
    }
}

 

class CustomerAddress extends Component{
    componentDidUpdate(){
        $('select#billing-address').selectpicker("refresh");  
        $('select#shipping-address').selectpicker("refresh");  
    }
    
    render(){  
        var style = { whiteSpace: 'pre-wrap' }
        var portlet = {'padding': '10px','paddingRight': '1px'}
        var heading = {'padding':'10px','borderBottom': '1px solid #e6ecf5'}
        var font_size={'fontSize':'16px'}
        if(this.props.billing_add){            
            var bil_addresses = this.props.billing_add.map((address,indx) => {
            if(address.isdefault){
                return <div className="col-md-6" key={indx}>
                        <div className="card" >
                                <div className="portlet">
                                        <ul className="portlet-item navbar" style={portlet}>
                                            <li>
                                                <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  onClick={this.props.handleAddressChange} add-type="billing" title="Change Billing Address">
                                                        <i className="ti-arrow-circle-down"  onClick={this.props.handleAddressChange} add-type="billing"></i>
                                                </a>
                                            </li>
                                            {''?<li>
                                                <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  add-type="billing" this_index={indx} onClick={this.props.AddressEditHandler} title="Edit Billing Address">
                                                      <i className="ti-pencil" add-type="billing" this_index={indx} onClick={this.props.AddressEditHandler}></i>  
                                                </a>
                                            </li>:''}
                                        </ul>
                                            
                                    </div>
                            <div className="card-heading" style={heading}>
                                <h5 className="card-title">Billing Address</h5>
                            </div>
                            <div className="card-body" id='bl-address'>
                                <div className="add1" title="Address 1">{address.add1}</div>
                                <div className="add2" title="Address 2">{address.add2}</div>
                                <div className="city" title="City">{address.city}</div>
                                <div className="state" title="State">{address.state}</div>
                                <div className="country" title="Country">{address.country}</div>
                                <div className="zip_code" title="Zip Code">{address.zip_code}</div>
                            </div>                    
                        </div>
                    </div>
            } 
            })
        var shi_addresses = this.props.shipping_add.map((address,indx) => {
            if(address.isdefault){
                return <div className="col-md-6" key={indx}>
                        <div className="card">
                                <div className="portlet">
                                    <ul className="portlet-item navbar" style={portlet}>
                                        <li>
                                            <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  onClick={this.props.handleAddressChange} add-type="shipping" title="Change Shipping Address">
                                                    <i className="ti-arrow-circle-down" onClick={this.props.handleAddressChange} add-type="shipping"></i>
                                            </a>
                                        </li>
                                       {''? <li>
                                            <a href="#!" className="btn-icon btn-flat btn-rounded " style={font_size} add-type="shipping" this_index={indx} onClick={this.props.AddressEditHandler} title="Edit Shipping Address">
                                               <i className="ti-pencil"  add-type="shipping" this_index={indx} onClick={this.props.AddressEditHandler}></i>  
                                            </a>
                                        </li>:''}
                                    </ul>
                                        
                                </div>
                            <div className="card-heading" style={heading}>
                                <h5 className="card-title">Shipping Address</h5>

                            </div>
                            <div className="card-body" id='sh-address'>
                                <div className="add1" title="Address 1">{address.add1}</div>
                                <div className="add2" title="Address 2">{address.add2}</div>
                                <div className="city" title="City">{address.city}</div>
                                <div className="state" title="State">{address.state}</div>
                                <div className="country" title="Country">{address.country}</div>
                                <div className="zip_code" title="Zip Code">{address.zip_code}</div>
                            </div>                    
                        </div>
                    </div>
            }
        })       
            
        
    }
        return(
            <div className="row">
                    <div className="col-md-6">
                            <div className="card">                       
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Customer PO</label>
                                                    <input type="text" className="form-control"  name="customer_po" id="customer_po" placeholder="Customer PO"  required/>
                                                </div>  
                                            </div>
                                            <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Customer Reference #</label>
                                                        <input type="text" className="form-control"  name="customer_reference_number" id="customer_reference_number" placeholder="Customer Reference #"  required/>
                                                    </div>  
                                                </div>
                                                <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Remarks</label>
                                                            <textarea type="text" className="form-control"  name="remarks" id="remarks" placeholder="Remarks"  ></textarea>
                                                        </div>  
                                                </div> 
                                        </div>
                                    </div> 
                            </div>
                    </div>
                    <div className="col-md-6 address-card">
                            <div className="inline-loader" id="address_loader"> 
                                <SubLoader/>
                            </div> 
                            <div className="card">
                                    
                                <div className="card-block" id="address-block"> 
                                    <div className="row">                                    
                                       {bil_addresses}{shi_addresses}  
                                    </div>                                                                                 
                                </div>
                            </div>
                    </div>                    
            </div>
        )
    }
    
      

}
class SSIDetails extends Component{
    componentDidUpdate(){
       $('select#freight_type').selectpicker('refresh') 
    }
    render(){
        var option_str = this.props.freight_details.map((freight,indx) =>{
            return(
                <option vlaue={freight} key={indx}>{freight}</option>
            )
        })
        return(
            <div className="row">
                    <div className="col-md-6">
                            <div className="card">               
                                    
                                    <div className="card-body">
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark">Total Weight of Items</p>
                                                </div>
                                                
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" >
                                                            {this.props.total_weight_of_items?
                                                            <b id="total_item_weight" total_weight={Humanize(this.props.total_weight_of_items)}>{Humanize(this.props.total_weight_of_items)} <span>lbs</span></b>
                                                            :'-'}
                                                        </p>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                        <p className="mrg-top-10 text-dark"> Total Volume of Items</p>
                                                    </div>
                                                    <div className="col-md-8">
                                                            <p className="mrg-top-10" >
                                                                {this.props.total_volume_of_items?
                                                                <b id="total_item_volume" total_volume={Humanize(this.props.total_volume_of_items)}>{Humanize(this.props.total_volume_of_items)} <span>ft<sup>3</sup></span></b>:'-'}
                                                            </p>
                                                    </div>
                                        </div>
                                        <div className="form-group row">
                                                <label htmlFor="form-1-1" className="col-md-4 control-label text-dark">Freight type</label>
                                                <div className="col-md-8">
                                                        <select id="freight_type" className="form-control selectpicker" data-size="7" data-live-search="true">
                                                                <option value="">------</option>   
                                                               {option_str}    
                                                               </select>
                                                </div>
                                            </div>                                                                                                                      
                                    </div> 
                            </div>
                    </div>

            </div> 

        )
    }
}

class Organisation extends Component{
    render(){
        if(this.props.order_data.organisations){
        var org_temp = this.props.order_data.organisations[0].template_fields.map((org_field,indx) =>{
            return org_field.field_name !='Lead Time'?<div key={indx}>
                <div className="line-with-color line-padding">
                    {org_field.field_name}
                </div>
                <div className="line-padding">
                    <ul>
                            <li>
                                { JSON.parse(org_field.values)[0]}
                            </li>
                    </ul>
                </div>
            </div>:''
        })
    }else{
        var org_temp=""
    }
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">                            
                            <div className="card-body">
                                {org_temp}    
                            </div>
    
                        </div>
                    </div>
                </div>

        )
    }
}
class ItemAttachments extends Component{
    render(){
        if(this.props.table_rows.length > 0){
        var org_temp = this.props.table_rows.map((row, index) =>{
            if(row.attachments.length > 0){
                var attchements = row.attachments.map((attachment,index)=>{
                    return <li key={index}>
                            <a href={attachment.signed_url[0]} target="_blank">{attachment.name}</a>
                        </li>
                })
                return attchements
                
            }
            
        })
    }else{
        var org_temp=""
    }
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">
                                <h5 className="card-title">DOCUMENTS</h5>
                            <div className="card-body">
                                <ul className="item-attachments">
                                    {org_temp}      
                                </ul>
                                           
                            </div>
    
                        </div>
                    </div>
                </div>

        )
    }
}

class AddressModal extends Component{
    componentDidMount(){
        $('select#id_country').selectpicker()
        $('select#id_country').val(this.props.addressData.country.value).selectpicker('refresh')
        $('#customer-billing-address-create-dialog').modal('show');
        $('#customer-billing-address-create-dialog').on('hidden.bs.modal', this.props.HidenModal);
    }
    
    render(){
        if(this.props.addressData.add_type.value == 'billing'){
            var Add_type = 'Update Billing Address'
        }else {
            var Add_type = 'Update Shipping Address'
        }
        var option_str = countries.map((data)=>{
            return <option key={data.country} value={data.country}>{data.country}</option>
        }) 
        
        return(
            <div className="modal fade" id="customer-billing-address-create-dialog" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.addressData.id.value?Add_type:'Create Address'}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body customer-billing-address-create-model-body">
                                <form className="customer-billing-address-create-model-body-form" id="address_form" onSubmit={this.props.AddressSubmitHandler}>
                                    <input type="hidden" name="id" value={this.props.addressData.id.value} id="address_id" />
                                    <input type="hidden" name="index" value={this.props.addressData.add_index.value} id="address_index" />
                                    <input type="hidden" name="add_type" value={this.props.addressData.add_type.value} id="address_type" />
                                    <div className="row">                                            
                                            <div className="col-md-6 no-padding-left">
                                                    <label>Address 1</label>
                                                    <input type="text" name="add1" id="id_add1" placeholder="Address 1" className="form-control" maxlength="150" value={this.props.addressData.add1.value} onChange={this.props.onchange} />
                                                    <p className="add1_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Address 2</label>
                                                        <input type="text" name="add2" id="id_add2" placeholder="Address 2" className="form-control" maxlength="150" value={this.props.addressData.add2.value} onChange={this.props.onchange} />
                                                        <p className="add2_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>City</label>
                                                        <input type="text" name="city" id="id_city" placeholder="City" className="form-control" maxlength="75" required value={this.props.addressData.city.value} onChange={this.props.onchange} />
                                                        <p className="city_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>State</label>
                                                        <input type="text" name="state" id="id_state" placeholder="State" className="form-control" maxlength="75" required value={this.props.addressData.state.value} onChange={this.props.onchange} />
                                                        <p className="state_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Country</label>
                                                        <select id="id_country" className="form-control" name="country" data-size="7" data-live-search="true" onChange={this.props.onchange} required>
                                                            {option_str}    
                                                        </select>
                                                        <p className="country_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Zip code</label>
                                                        <input type="text" name="zip_code" id="id_zip_code" placeholder="Zip code" className="form-control" maxlength="15" value={this.props.addressData.zip_code.value} onChange={this.props.onchange} />
                                                        <p className="zip_code_error"></p>
                                                    </div>                                                
                                            </div>
                                            <div className="modal-footer">
                                                    <button className="btn btn-primary create-billing-address-submit"
                                                            type="submit" >
                                                            {this.props.addressData.id.value?'Update':'Create'}
                                                    </button>
                                                </div>
                                </form>
                            </div>
                            
            
                        </div>
                    </div>
                </div>
        )
    }
}

class SearchItemsData extends Component{
    render(){
        var div_style = {'padding':'10px','borderBottom':'1px solid #e6ecf5','cursor':'pointer' }
        
        var table_results = this.props.searchResult.map((item,index) =>{
            return(
                        <div className= {index == 0?"order-item active":"order-item"} row-index={this.props.index} item-id={item.item.id} key={item.item.id} style={div_style} category={item.item.category} stock-id='' warehouse-id='' price={item.price} onClick={this.props.HandleRenderItemData} >
                            <div  item-id={item.item.id} category={item.item.category} className="font-10pt-color-chocolate item-number width-100percent" title="Item Number" data-item-number="">
                            {item.item.item_number}
                            </div>
                            <span className="item-name">{item.item.name}</span>
                        </div>
            )
        })
        return(
            <div className="card alert search-result" >  
                <div  className="auto-complete-table" >
                        {table_results}
                </div>         
            
        </div>
        )
    }

}


class ChangeDefaultAddress extends Component{
    componentDidMount(){
        $('#change-address-dialog').modal('show');
        $('#change-address-dialog').on('hidden.bs.modal', this.props.handleHideModal);
    }
    
    render(){
        var radio_disply = {'display':'inline'}
        var isdefault=''
        var addtype=""
        if(this.props.add_type == 'billing'){
            var addtype="Change Billing Address"
            var customer_add = this.props.billing_add.map((address,index)=>{
                if(address.isdefault){
                    var isdefault = true
                }
                return(
                    <div className="col-md-4" key={index}>
                            <div className={isdefault?"card change-address selected-address":"card change-address"} checked={isdefault} this_index={index}  this_type={address.add_type} onClick={this.props.SetDefaultAddress} title="Click to Select Address"  >                                                           
                                <div className="card-body">
                                    <div className="add1" title="Address 1">{address.add1}</div>
                                    <div className="add2" title="Address 2">{address.add2}</div>
                                    <div className="city" title="City">{address.city}</div>
                                    <div className="state" title="State">{address.state}</div>
                                    <div className="country" title="Country">{address.country}</div>
                                    <div className="zip_code" title="Zip Code">{address.zip_code}</div>
                                </div>                    
                            </div>
                         </div>   

                )

            })

        }else if(this.props.add_type == 'shipping'){
            var addtype="Change Shipping Address"
            var customer_add = this.props.shipping_add.map((address,index)=>{
                if(address.isdefault){
                    var isdefault = true
                }
                return(
                    <div className="col-md-4" key={index}>
                            <div className={isdefault?"card change-address selected-address":"card change-address"} checked={isdefault} this_index={index}  this_type={address.add_type} onClick={this.props.SetDefaultAddress}>                                                           
                                <div className="card-body">
                                    <div className="add1" title="Address 1">{address.add1}</div>
                                    <div className="add2" title="Address 2">{address.add2}</div>
                                    <div className="city" title="City">{address.city}</div>
                                    <div className="state" title="State">{address.state}</div>
                                    <div className="country" title="Country">{address.country}</div>
                                    <div className="zip_code" title="Zip Code">{address.zip_code}</div>

                                </div>                    
                            </div>
                         </div>   

                )

            })

        }       
        
        
        return(
            <div className="modal fade" id="change-address-dialog" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{addtype}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body change-address-model-body">
                            <div className="row">
                                {customer_add}                                         
                            </div>
                            
                        </div>
                            
            
                    </div>
                </div>
            </div>
        )
    }
}