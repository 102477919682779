import { Component } from 'react'
import PropTypes from 'prop-types';
// import '../../styles/custom/pagination.css'
import './account.css'
import Profile from './Profile'
import Addresses from './Addresses'
import Contacts from './Contacts'
import Documents from './Documents'
import Settings from './Settings'


var propTypes= {
    handleHideModal: PropTypes.func.isRequired
}

export default class Account extends Component {
    componentDidUpdate(){
        if(this.props.location.page){
            if(this.props.location.page.load){
                this.props.location.page = {load:false}       
                window.location.reload(false)
            }
        }
    }
    render() {
        return (
            <div className="container-fluid account-container"> 
                <div className="row">
                    <div className="col-md-12">
                        <Profile/> 
                        <Addresses/>
                        <Contacts/>                
                        <Documents/>
                        <Settings/>
                    </div>
                </div>
            </div>
        )
    }
}

