import  { Component } from 'react'
import { withRouter } from 'react-router-dom';
import SideNavBar from './SideNavBar'
import TopNavBar from './TopNavBar'
import NavFooter from './NavFooter'
import CheckToken from '../../js/CheckToken'
import GetItemsList from '../../js/GetItemsList'


class MainNavBar  extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div className="app">
                <div className="layout">
                    <GetItemsList/>
                    <SideNavBar/>
                    <div className="page-container">
                        <TopNavBar/>
                        {/* <!-- Side Panel START --> */}
                        <div className="side-panel">
                            <div className="side-panel-wrapper bg-white"></div>
                        </div>
                        {/* <!-- Side Panel END --> */}
                        <div id="login-check">
                            <CheckToken/>                            
                        </div>
                        <div className="main-content">
                        {this.props.children }                        
                        </div>
                    </div>
                    <NavFooter/>                    
                </div>
            </div>
        )
    }
}

export default withRouter(MainNavBar)

