import  { Component } from 'react'
import './ssi-loader.css'

export default class Loader extends Component {
    render() {
        return (
            <div className="lds-ripple-parent-1">
                <div id="ssi-logo-animation">
                    <svg width="120" height="100">
                        <circle className="orange-circle-col-1" r="6" cx="10" cy="10" fill="transparent" />
                        <circle className="orange-circle-col-1" r="6" cx="10" cy="30" fill="transparent" />
                        <circle className="orange-circle-col-1" r="6" cx="10" cy="50" fill="#a2c02f" />
                        <circle className="orange-circle-col-1" r="6" cx="10" cy="70" fill="#00acdd" />
                        <circle className="orange-circle-col-1" r="6" cx="10" cy="90" fill="transparent" />
                        <circle className="orange-circle-col-2" r="6" cx="30" cy="10" fill="transparent" />
                        <circle className="orange-circle-col-2" r="6" cx="30" cy="30" fill="#00879c" />
                        <circle className="orange-circle-col-2" r="6" cx="30" cy="50" fill="#449aba" />
                        <circle className="orange-circle-col-2" r="6" cx="30" cy="70" fill="#00acdd" />
                        <circle className="orange-circle-col-2" r="6" cx="30" cy="90" fill="#88c86f" />
                        <circle className="orange-circle-col-3" r="6" cx="50" cy="10" fill="transparent" />
                        <circle className="orange-circle-col-3" r="6" cx="50" cy="30" fill="transparent" />
                        <circle className="orange-circle-col-3" r="6" cx="50" cy="50" fill="#39b54a" />
                        <circle className="orange-circle-col-3" r="6" cx="50" cy="70" fill="#0080bb" />
                        <circle className="orange-circle-col-3" r="6" cx="50" cy="90" fill="transparent" />
                        <circle className="orange-circle-col-4" r="6" cx="70" cy="10" fill="#4ebfa8" />
                        <circle className="orange-circle-col-4" r="6" cx="70" cy="30" fill="#39b54a" />
                        <circle className="orange-circle-col-4" r="6" cx="70" cy="50" fill="transparent" />
                        <circle className="orange-circle-col-4" r="6" cx="70" cy="70" fill="#c3c00f" />
                        <circle className="orange-circle-col-4" r="6" cx="70" cy="90" fill="transparent" />
                        <circle className="orange-circle-col-5" r="6" cx="90" cy="10" fill="transparent" />
                        <circle className="orange-circle-col-5" r="6" cx="90" cy="30" fill="#bfd730" />
                        <circle className="orange-circle-col-5" r="6" cx="90" cy="50" fill="#799b28" />
                        <circle className="orange-circle-col-5" r="6" cx="90" cy="70" fill="transparent" />
                        <circle className="orange-circle-col-5" r="6" cx="90" cy="90" fill="transparent" />
                        <circle className="orange-circle-col-6" r="6" cx="110" cy="10" fill="#bad532" />
                        <circle className="orange-circle-col-6" r="6" cx="110" cy="30" fill="transparent" />
                        <circle className="orange-circle-col-6" r="6" cx="110" cy="50" fill="transparent" />
                        <circle className="orange-circle-col-6" r="6" cx="110" cy="70" fill="#00ab4e" />
                        <circle className="orange-circle-col-6" r="6" cx="110" cy="90" fill="transparent" />
                    </svg>
                </div>
            </div>
        )
    }
}

