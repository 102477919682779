import { Component } from 'react'
import { API_HOST } from '../settings.config'
import ErrorHandling from './CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;

export default class CartItemsCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart_item_ids:localStorage.getItem('cart_item_ids')?JSON.parse(localStorage.getItem('cart_item_ids')):[],
            count:0
        };
      }

     componentDidMount() {
         const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] = auth_token
        axios.get(API_HOST+'/cart/get/cart/items/',
        ).then(response => {
        if(response.status == 200 && response.data.success){
            var cart_item_ids = response.data.cart_item_ids //JSON.parse
            localStorage.setItem('cart_item_ids', JSON.stringify(cart_item_ids))
            localStorage.setItem('help_url',response.data.help_url)
        }
        }).catch(response =>{
            ErrorHandling(response)
        }); 
        this.timerID = setInterval(
          () => this.CheckItemsCount(),
          1
        );
        if(localStorage.getItem('help_url')){
            $('.help-url').attr('href', localStorage.getItem('help_url'))
        }else{
            $('.help-url').attr('href', 'void(0)')
        }
        
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }
      CheckItemsCount = () =>{
        var item_ids = localStorage.getItem('cart_item_ids')?JSON.parse(localStorage.getItem('cart_item_ids')):[]
        var count = item_ids.length
        this.setState({
            count: count
          });
      }
      render(){
          var item_count = ''
          if(this.state.count){
              var item_count = <span className="counter" >{this.state.count}</span>
          }
          return(
              item_count
          )
      }
}
