import React, { Component } from 'react'
import notify from './CommonFunctions'

export default class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = {
             error: null,
             errorInfo: null 
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
          error: error,
          errorInfo: errorInfo
        });
      }          
      render() {
        if (this.state.errorInfo) {
          notify('Something went wrong, Try again later.','error')
          return (
            <div class="error-handler"> 
              <h2>Try refreshing the page.</h2>
              <a href="" >Refresh</a>
              
            </div>
          );
        }    
        return this.props.children;
      }
}
