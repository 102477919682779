import { Component } from 'react'
import { Link, withRouter } from "react-router-dom";    
import '../styles/custom/sign-in.css'
import SSILogo from '../styles/images/logo/ssi-logo.png'
import {API_HOST} from '../settings.config.js'
import {notify} from '../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;




class LoginPage extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        if(this.props.location.state?this.props.location.state.params.message:''){
            notify(this.props.location.state.params.message,'information')
        }
    }

    loginSubmit = (event) =>{
        event.preventDefault();
          $(".lds-ripple-parent-1").fadeIn()
          document.getElementById("login-btn").disabled = true;
          document.getElementById("username").disabled = true;
          document.getElementById("password").disabled = true;
          document.getElementById("error_msg").innerText = ''
          const data = new FormData(event.target);    
          const username =  document.getElementById("username").value
          const password =  document.getElementById("password").value
          const form_data = 'username='+username+'&password='+password
          //'username=prashanthk432@gmail.com&password=brio@1234',
          axios({
            method: 'post', 
            url: API_HOST+'/cp/login/',
            data:form_data,
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded",
            }
          }).then((response) =>{
            $(".lds-ripple-parent-1").fadeOut('slow')
              localStorage.setItem('first_name',response.data.first_name)
              localStorage.setItem('last_name',response.data.last_name)
              localStorage.setItem('email',response.data.email)
              localStorage.setItem('token',response.data.token)
              localStorage.setItem('id',response.data.id)
              localStorage.setItem('company',response.data.customer)
              localStorage.setItem('symbol',response.data.symbol)
              localStorage.setItem('cart_items',JSON.stringify([]))
              localStorage.setItem('cart_item_ids',JSON.stringify([]))
              localStorage.setItem('category',JSON.stringify({}))
              localStorage.setItem('help_url',response.data.help_url)
              this.props.history.push('/')
              //setCookie('auth_token',response.data.token,365)
          })
          .catch(function (response) {
            $(".lds-ripple-parent-1").fadeOut('slow')
            var error_msg = response.response.data.non_field_errors[0]
            document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);  
            document.getElementById("login-btn").disabled = false;
          document.getElementById("username").disabled = false;
          document.getElementById("password").disabled = false;
        });         
    }
    render() {
        return (
                <div className="authentication login-container">
                    <div className="sign-in">
                        <div className="row no-mrg-horizon">
                            <div className="col-md-8 no-pdd-horizon d-none d-md-block">
                                <div className="full-height bg video-container" >
                                    <video autoPlay="autoplay" loop="loop" muted="muted" src="https://www.ssiaeration.com/wp-content/themes/ssi/images/front-page.mp4" poster=""></video>
                                    <div className="img-caption">
                                        <h1 className="caption-title">SMART IDEAS FOR WATER</h1>
                                        <p className="caption-text">SSI Aeration fine bubble diffuser systems and products are installed on five continents at thousands of job sites. Millions of our diffusers are hard at work in wastewater treatments worldwide. SSI has received multiple US and International patents for inventions and industry improvements.</p>
                                    </div>    
                                </div>
                            </div>
                        <div className="col-md-4 no-pdd-horizon">                                
                            <div className="full-height bg-white height-100">
                                    <div className="signin-header">
                                            <img className="img-responsive sign-in-ssi-logo inline-block" src={SSILogo} />
                                        </div>
                                <div className="vertical-align  pdd-horizon-70 pdd-vertical-35"> 
                                    <div  id="cp-login-form">
                                    <div className="pdd-horizon-15" >
                                        <h3 className="text-center">Sign In</h3>
                                        <form onSubmit={this.loginSubmit} noValidate className="login-form">
                                            <p id="error_msg" className="text-danger"></p>
                                                <div className="form-group">
                                                    <label>E-Mail</label>
                                                    <input type="email" className="form-control" placeholder="Email" name="username" id="username" required/>
                                                    <p className="username_error"></p>
                                                </div>
                                                
                                                <div className="form-group">
                                                <label>Password</label>
                                                    <input type="password" className="form-control" placeholder="Password" name="password" id="password" required/>
                                                    <p className="username_error"></p>
                                                </div>
                                                <button className="btn btn-info login-btn" id="login-btn" >Sign In</button>
                                            </form>  
                                        <div className="or-seperator"><i>or</i></div>
                                        <a href={API_HOST+'/cp/login/'} className="googleicon" title="Google Login"></a>
                                        <a href={API_HOST+'/cp/user/get/signed/url/'} className="o365icon" title="O365 Login"></a>

                                    </div>    
                                    </div>
                                </div>
                                <div className="login-footer">                                    
                                    <span className="font-size-13"><Link to="/user/password/recover">Forgot Password</Link></span>
                                    <span className="font-size-13 pull-right">Don't have an account?<Link to="/signup"> Sign Up Now</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginPage)