import React, { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import ErrorHandling from '../../js/CommonFunctions'
import moment from 'moment'
import $ from 'jquery'
const axios = require('axios').default;

class Orderupdates extends Component {
    constructor() {
        super();
        this.state = {
            pageOfItems: [],
        };
    }
    componentDidMount(){
        $('.order-table').find('.item-inline-loader').fadeIn()
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.get['Authorization'] =auth_token
            // axios.get('{% url "customer_portal:sales_orders_list" %}?field=id&field=status&field=document_number&field=date&field=packaging_status&field=payment_status&field=inventorytnx_set&ordering=-date&field=cp_status_choices&page_size=5',
            axios.get(API_HOST+'/api/sales/orders/?field=id&field=status&field=document_number&field=date&field=inventorytnx_set&ordering=-date&field=cp_status_choices&page_size=5',
        ).then(response => {
        if(response.status == 200){
            console.log('ORDERS LIST')
            if(response.data.orders){
                var json_data = response.data.orders
                if(json_data.length > 0){
                    document.getElementById("order-link").style.display = "block";
                }else{
                    document.getElementById("order-link").style.display = "none"; 
                }
            }else{
                var json_data = []
            }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                }
            })   
            $('.order-table').find('.inline-loader').fadeOut("slow");         
                
        }
    }).catch(response => {
        ErrorHandling(response)
    });
    }
    render() {
        const tr_elements = this.state.pageOfItems.map((quote,indx) =>{
            if(quote.status == 'open'){
                var status = <i className="fa fa-envelope-open order-open order_open_status " title="Order Processing"></i>
            }else if(quote.status == 'closed'){
                var status = <i className="fa fa fa-check-circle order_closed_status" title="Closed"></i>
            }else if(quote.status == 'closed_manually'){
                var status = <i className="fa fa-window-close order_closed_manually" title="Cancelled"></i>
            }else if(quote.status == 'requested' || quote.status == 'under_review_by_ssi_admin' || quote.status == 'under_review_by_customer' || quote.status== 'review_rejected_by_customer' || quote.status == "review_accepted_by_customer"){
                var status = <i className="em em-hourglass hourglass" title={quote.cp_status_choices[quote.status]}></i>
            }else if(quote.status == 'reserved'){
                var status = <i className="em em-lock order-reserved " title="Reserved"></i>
            }
            else {
                var status = <i className="fa fa-window-close quotation_status_cancelled" title="Cancelled"></i>
            }
            if(quote.inventorytnx_set.length > 0 && quote.status == 'closed'){
                var fulfilled_status = <i className="fas fa-shipping-fast fullfilled" title="Fulfilled"></i>                    
            }else{
                var fulfilled_status = <i className="fas fa-shipping-fast fulfill-pending" title="Fulfillment Pending"></i>
            }
            if(quote.inventorytnx_set.length > 0 && quote.status == 'closed_manually'){
                var status = <i className="fa fa-envelope-open hide-icon-color" ></i>
            }
            if(quote.packaging_status == 'packed'){
                var package_status = <i className="fa fa-cube packed" title="Packed"></i>
            }else{
                var package_status = <i className="fa fa-cube no-success" title="Packing Awaited"></i>
            }
            if(quote.payment_status == 'paid'){
                var payment_status = <i className="fas fa-dollar-sign paid" title="Paid"></i>
            }else{
                var payment_status =<i className="fas fa-dollar-sign no-success" title="Payment Pending"></i>
            }
                return <tr className="order-list-tr" key={indx}>
                        <td className="width-25 order-document">
                            <Link to={"/order/view/"+quote.id+"/"} className="text-info">
                                <span >{ quote.document_number }</span>
                            </Link>
                        </td>
                        <td className="width-25 dash-order-date" >
                                    <span className="date">{ moment(quote.date).format('MMM, D YYYY') }</span>
                        </td>
                        <td className="width-50 text-right dash-order-status-td">
                                <span className="dashboard-order-status">{status}</span>
                                <span className="dashboard-order-status">{package_status}</span>
                                <span className="dashboard-order-status">{payment_status}</span>
                                <span className="dashboard-order-status">{fulfilled_status}</span>
                        </td>
                    </tr>        
            });
        return (
            <div className="col-lg-6">
            <div className="card order-table">
                <div className="inline-loader" id="order_table_loader">
                    <SubLoader/>
                </div>
                <div className="col-lg-12 ">
                    <div className="card-heading">
                        <h4 className="card-title inline-block">Order Updates</h4>
                        <Link to="/orders" className="btn btn-default pull-right no-mrg " id="order-link">
                            <span className="see-all">See All</span>
                        </Link>
                    </div>
                    <div className="pdd-horizon-20 pdd-vertical-5 col-lg-12">
                        <div className="overflow-y-auto relative scrollable" style={{ 'maxHeight': '381px'}}>
                            <table className="table table-lg table-hover">
                                <tbody id="orders-table">
                                {this.state.pageOfItems.length > 0?
                                    tr_elements :<tr><td colSpan="5" className="text-center"><h4> You have no Orders.</h4></td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default withRouter(Orderupdates)