import { Component } from 'react'
import {Link } from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import './quotationview.css'
import $ from 'jquery'
import moment from 'moment'
import {Humanize, ErrorHandling} from '../../js/CommonFunctions'
const axios = require('axios').default;



class QuoteDetails extends Component{        
    render(){
        var pageheader = document.getElementById('page-header')
        var version = ('0'+this.props.quote_data.version).slice(-2)
        if(pageheader){
            pageheader.innerText = '#'+this.props.quote_data.document_number+'.'+version
        }
        if(this.props.quote_data.status == 'open'){
            var status = <i className="fas fa-envelope-open order_open_status pdd-5" title="Open"></i>
        }else if(this.props.quote_data.status == 'won'){
            var status = <i className="em em---1 order_closed_status pdd-5" title="Ordered/Closed"></i>
        }else if(this.props.quote_data.status == 'lost') {
            var status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled pdd-5" title="Lost"></i>
        }
        else {
            var status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled pdd-5" title="Cancelled"></i>
        }
        var order_url = ''
        if(this.props.quote_data.salesorder_set){
            if(this.props.quote_data.salesorder_set.length > 0){
                var order_url =  <Link to={"/order/view/"+this.props.quote_data.salesorder_set[0].id+"/"} className="em em-shopping_trolley fon-size" title="Order"></Link>
                    
            }
    }
        return(
            <div className="card">
                    <div className="row">
                        <div className="col-md-4 quote-date">
                            <div className="pdd-15 order-no" >
                                    <span title="Quotation Date">{ moment(this.props.quote_data.date).format('MMM D, YYYY')}</span>                            
                            </div>
                            
                        </div>
                        <div className="col-md-4 text-center quote-status">
                                <div className="pdd-15 icon-size" >
                                        {status}
                                        {order_url}
                                </div>
                        </div>
                        <div className="col-md-4 text-right quote-btn">
                                <div className="pdd-15 icon-size">                                        
                                    <a href={this.props.quote_data.pdf_document_url} className="btn btn-success" title="Download Quotation as PDF" target="_blank">
                                        <i className="fas fa-download download-btn"> PDF</i>
                                    </a>
                                </div>
                        </div> 
                    </div>
             </div> 
            
        )
    }
}

class CustomerAddress extends Component{
render(){  
    var style = { whiteSpace: 'pre-wrap' }
    return(
        <div className="row">
                <div className="col-md-6">
                        <div className="card">                       
                                <div className="card-body">
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark"> Customer Reference # </p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref">
                                                            <b>
                                                                    {this.props.quote_data.customer_reference_number?this.props.quote_data.customer_reference_number:'-'}
                                                                </b>
                                                            </p>
                                                </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                                <p className="mrg-top-10 text-dark">Remarks</p>
                                        </div>
                                        <div className="col-md-8">
                                                <p className="mrg-top-10" style={style} id="remarks"><span>{this.props.quote_data.remarks? this.props.quote_data.remarks:'-'}</span></p>
                                        </div>
                                    </div>
                                </div> 
                        </div>
                </div>
                <div className="col-md-6">
                        <div className="card">
                                
                            <div className="card-block" id="address-block"> 
                                <div className="row">
                                    <div className="col-md-6">
                                            <div className="card no-border">                          
                                                <div className="card-heading no-pdd-vertical">
                                                    <h5 className="card-title">Billing Address</h5>
                                                </div>
                                                <div className="card-body" id='bl-address'>
                                                        <div className="add1" title="Address 1">
                                                            {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.add1:''}
                                                        </div>
                                                        <div className="add2" title="Address 2">
                                                                {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.add2:''}
                                                        </div>
                                                        <div className="city" title="City">
                                                                {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.city:''}
                                                        </div>
                                                        <div className="state" title="State">
                                                                {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.state:''}
                                                        </div>
                                                        <div className="country" title="Country">
                                                                {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.country:''}
                                                        </div>
                                                        <div className="zip_code" title="Zip Code">
                                                                {this.props.quote_data.billing_address?this.props.quote_data.billing_address.fields.zip_code:''}
                                                        </div>
                                                </div> 
                                                        
                                            </div>
                                    </div> 
                                    <div className="col-md-6">
                                            <div className="card no-border">                          
                                                <div className="card-heading no-pdd-vertical">
                                                    <h5 className="card-title">Shipping Address</h5>
                                                </div>
                                                <div className="card-body" id='sh-address'>
                                                        <div className="add1" title="Address 1">
                                                            {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.add1:''}
                                                        </div>
                                                        <div className="add2" title="Address 2">
                                                                {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.add2:''}
                                                        </div>
                                                        <div className="city" title="City">
                                                                {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.city:''}
                                                        </div>
                                                        <div className="state" title="State">
                                                                {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.state:''}
                                                        </div>
                                                        <div className="country" title="Country">
                                                                {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.country:''}
                                                        </div>
                                                        <div className="zip_code" title="Zip Code">
                                                                {this.props.quote_data.shipping_address?this.props.quote_data.shipping_address.fields.zip_code:''}
                                                        </div>
                                                </div> 
                                                        
                                            </div>
                                    </div>  
                                </div>                                                                                 
                            </div>
                        </div>
                    </div>                    
        </div>
    )
}

}
class SSIDetails extends Component{
render(){
    return(
        <div className="row">
                <div className="col-md-6">
                        <div className="card">                        
                                
                                <div className="card-body">
                                    <div className="row">
                                            <div className="col-md-4">
                                                <p className="mrg-top-10 text-dark"> SSI Contact</p>
                                            </div>
                                            <div className="col-md-8">
                                                    <p className="mrg-top-10" id=""><b>{this.props.quote_data.ssi_contact?this.props.quote_data.ssi_contact.name:'-'}</b></p>
                                            </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark">Reference #</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref"><b>{this.props.quote_data.ref_no?this.props.quote_data.ref_no:'-'}</b></p>
                                                </div>
                                    </div>                                        
                                </div> 
                        </div>
                </div>
                <div className="col-md-6">
                        <div className="card">               
                                
                                <div className="card-body">
                                    <div className="row">
                                            <div className="col-md-4">
                                                <p className="mrg-top-10 text-dark">Total Weight of Items</p>
                                            </div>
                                            
                                            <div className="col-md-8">
                                                    <p className="mrg-top-10" id="customer_po">
                                                        {this.props.quote_data.total_weight_of_items?
                                                        <b>{Humanize(this.props.quote_data.total_weight_of_items)} <span>lbs</span></b>
                                                        :'-'}
                                                    </p>
                                            </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark"> Total Volume of Items</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref">
                                                            {this.props.quote_data.total_volume_of_items?
                                                            <b>{Humanize(this.props.quote_data.total_volume_of_items)} <span>ft<sup>3</sup></span></b>:'-'}
                                                        </p>
                                                </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark">Freight type</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref"><b>{this.props.quote_data.freight_type?this.props.quote_data.freight_type:'-'}</b></p>
                                                </div>
                                    </div> 
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark"> Freight Charges</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref"><b>{this.props.quote_data.freight_charges?Humanize(this.props.quote_data.freight_charges):'-'}</b></p>
                                                </div>
                                    </div>                                         
                                </div> 
                        </div>
                </div>

        </div> 

    )
}
}

class QuotationSectionItems extends Component{
render(){
    var count = 0
    var local_exchange_rate = parseFloat(this.props.exchange_rate)
    var tr_elements = this.props.section_items.map((sec_item,indx) =>{
        if(sec_item.text_item == null){
            count+=1
        }
        return (sec_item.text_item == null? (<tr className="quotes-tr" key={indx}>
                <td className="tr-sno">{ count }</td>
                <td className="quote-item-number"><span className="item_number">{ sec_item.item.item.item_number}</span><br/>{ sec_item.item.item_name}</td>
                <td className="quote-view-lead-time"><span>{ sec_item.item.lead_time }</span></td>
                <td className="text-right quote-view-qty"><span>{sec_item.item.quantity}</span></td>
                <td className="text-right quote-view-price">
                    <span className="show-on-mobile"><span className="inline-block pdd-right-30">X</span><span className="currency show-on-mobile inline-block">{this.props.currency?this.props.currency:''}</span> <span className="price">{sec_item.item.price?Humanize(parseFloat(sec_item.item.price)):'0.00'}</span></span> 
                    <span className="price hide-on-mobile">{Humanize(parseFloat(sec_item.item.price)?parseFloat(sec_item.item.price):'0.00')}</span>
                </td>
                <td className="text-right quote-view-amount">
                    <span className="show-on-mobile"><span className="show-on-mobile inline-block pdd-right-10">=</span><span className="currency show-on-mobile inline-block">{this.props.currency?this.props.currency:''}</span> <span className="amount">{Humanize(parseFloat(sec_item.item.amount)?parseFloat(sec_item.item.amount):'0.00')}</span></span>  
                    <span className="amount hide-on-mobile">{Humanize(parseFloat(sec_item.item.amount)?parseFloat(sec_item.item.amount):'0.00')}</span>
                </td>
            </tr>):(<tr key={indx}><td colspan="6" id="item-text">{sec_item.text_item.text}</td></tr>)
            )

    })
    return(
        <tbody>{tr_elements}</tbody>

    )
}
}

class QuotationSectionTotal extends Component{
render(){
    if(this.props.quote_data.quotationtotal_set){
        var total_amount = this.props.quote_data.quotationtotal_set.map((sec_total,indx) =>{
            var section_titles = []
            sec_total.sections.map((section) =>{
                section_titles.push(section.title)
            })
            return(
                <h5 key={indx}><b>{section_titles.toString()} Total Price <span>{this.props.quote_data.fields_values}</span> :</b> <b><span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} </span> <span className="amount">{Humanize(parseFloat(sec_total.amount)?parseFloat(sec_total.amount):'0.00')}</span></b></h5>  
            )
            
        })
    }
    return(
        <div className="card pdd-15">
            <div className="row mrg-top-30">
                <div className="col-md-12">
                    <div className="pull-right text-right">
                            {this.props.quote_data.freight_charges?
                                <p>Freight Charges (<span className="freight-type">{this.props.quote_data.freight_type}</span>) : <span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} </span> <span className="amount">{Humanize(parseFloat(this.props.quote_data.freight_charges)?parseFloat(this.props.quote_data.freight_charges):'0.00')}</span></p>
                                :''}
                                <hr/>
                                {total_amount}
                        </div>
                </div>
            </div>     
        </div>                   

    )
}
}

class QuotationItems extends Component{

render(){
    if(this.props.quote_data.quotationsection_set){
        if(this.props.quote_data.quotationsection_set.length > 1){
            var this_section = this.props.quote_data.quotationsection_set.map((quote_section, indx) =>{
                return  <div className="card pdd-15" key={indx}>
                                <div className="card-heading">
                                        <h5 className="card-title"><b>{quote_section.title}</b></h5>
                                    </div>
                            <table className="table table-hover quotes-table">
                                <thead>
                                    <tr className="quotes-list-header">
                                        <th>#</th>
                                        <th className="width-30">Items</th>
                                        <th className="width-20">Lead Time</th>
                                        <th className="text-right width-20">Quantity</th>
                                        <th className="text-right width-20">Price<span className="currency">( {this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} )</span></th>                                        
                                        <th className="text-right width-20">Amount<span className="currency">( {this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} )</span></th>
                                    </tr>
                                </thead>
                                <QuotationSectionItems section_items={quote_section.quotationsectionitems_set} exchange_rate={this.props.quote_data.local_exchange_rate} currency={this.props.quote_data.currency.symbol}/>
                            </table>
                            <div className="row mrg-top-30 quote-total">
                                <div className="col-md-12 quote-total-div">
                                    <div className="pull-right text-right">
                                            <h5><b>Total Amount for Items : <span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''}</span> <span className="amount">{Humanize( parseFloat(quote_section.total_amount)?parseFloat(quote_section.total_amount):'0.00')}</span></b></h5>
                                        </div>
                                </div>
                            </div>     
                        </div>                                 
            })

        }else{
            var this_section = this.props.quote_data.quotationsection_set.map((quote_section, indx) =>{
                return  <div className="card pdd-15" key={indx}>
                            <table className="table table-hover quotes-table">
                                <thead>
                                    <tr className="quotes-list-header">
                                        <th>#</th>
                                        <th className="width-30">Items</th>
                                        <th className="width-20">Lead Time</th>
                                        <th className="text-right width-15">Quantity</th>
                                        <th className="text-right width-20">Price<span className="currency">( {this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} )</span></th>                                        
                                        <th className="text-right width-20">Amount<span className="currency">( {this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} )</span></th>
                                    </tr>
                                </thead>
                                <QuotationSectionItems section_items={quote_section.quotationsectionitems_set} exchange_rate={this.props.quote_data.local_exchange_rate} currency={this.props.quote_data.currency.symbol}/>
                            </table>
                            <div className="row mrg-top-30 quote-total">
                                <div className="col-md-12 quote-total-div">
                                    <div className="pull-right text-right">
                                        <p>Total Amount for Items : <span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''}</span> <span className="amount">{Humanize( parseFloat(quote_section.total_amount)?parseFloat(quote_section.total_amount):'0.00')}</span></p>
                                        {this.props.quote_data.freight_charges?
                                            <p>Freight Charges (<span className="freight-type">{this.props.quote_data.freight_type}</span>) :  <span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''}</span> <span className="amount">{Humanize( parseFloat(this.props.quote_data.freight_charges)?parseFloat(this.props.quote_data.freight_charges):'0.00')}</span></p>
                                            :''}
                                            <hr/>
                                            <h5 className="quote-h5"><b>Total Price <span>{this.props.quote_data.fields_values}</span> :</b> <span className="currency">{this.props.quote_data.currency?this.props.quote_data.currency.symbol:''} </span><b><span className="amount">{Humanize(parseFloat(this.props.quote_data.total_quoted_amount)?parseFloat(this.props.quote_data.total_quoted_amount):'0.00')}</span></b></h5>
                                    </div>
                                </div>
                            </div>     
                        </div>                                 
                    
            })

        }
         
        
    }else{
        var items_tr = ''
    }
    
    return(
        <div className="row">
                <div className="col-md-12">
                {this_section}   
                </div>
        </div>

    )
}
}

class Organisation extends Component{
render(){
    if(this.props.quote_data.organisation){
    var org_temp = this.props.quote_data.organisation.template_fields.map((org_field,indx) =>{
        return org_field.field_name !='Lead Time'?<div key={indx}>
            <div className="line-with-color line-padding">
                {org_field.field_name}
            </div>
            <div className="line-padding">
                <ul>
                        <li>
                            { JSON.parse(org_field.values)[0]}
                        </li>
                </ul>
            </div>
        </div>:''
    })
}else{
    var org_temp=""
}
    return(
        <div className="row">
                <div className="col-md-12">
                    <div className="card pdd-15">                            
                        <div className="card-body">
                            {org_temp}    
                        </div>

                    </div>
                </div>
            </div>

    )
}
}

export default class Quotationview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quoteData: [],
            sections_count:0
        };
    }
    componentDidMount(){
        $("#filter_loader").fadeOut("slow");
        $(".lds-ripple-parent-1").fadeIn();
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotation/'+this.props.match.params.quoteId+'/'
        ).then(response => {
          if(response.status == 200){ 
              var section_count = response.data.quotationsection_set.length                      
            this.setState(()=>{
                return {
                    quoteData: response.data,
                    sections_count:section_count
                }
        })   
        $(".lds-ripple-parent-1").fadeOut("slow");           
                    
            }
        }).catch(response => {
            ErrorHandling(response)
        });

    }
    componentDidUpdate(){
        if(this.props.location.page){
            if(this.props.location.page.load){
                this.props.location.page = {load:false}            
                $('.search_input').val('')
                this.componentDidMount()
            }
        }
    }
    render() {
        return (
            <div className="container-fluid quote-view-container" >
                <div className="inline-loader" id="filter_loader">
                    <SubLoader/>
                </div> 
                <div id="quote-view">
                    <QuoteDetails quote_data={this.state.quoteData} />
                    <CustomerAddress quote_data={this.state.quoteData} /> 
                    <SSIDetails quote_data={this.state.quoteData} />
                    <QuotationItems quote_data={this.state.quoteData} />
                    {this.state.sections_count > 1?
                    <QuotationSectionTotal quote_data={this.state.quoteData}/>:''
                    }                    
                    <Organisation quote_data={this.state.quoteData} />
                </div>
            </div>
        )
    }
}
