import  { Component } from 'react'
import SubLoader from '../Loader/SubLoader'
import './changepassword.css'
import $ from 'jquery'
import ErrorHandling from '../../js/CommonFunctions'
import {API_HOST} from '../../settings.config.js'
const axios = require('axios').default;
 
export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.state = {
            old_password:'',
            new_password:'',
            repeat_password:'',
            isError:{
                old_password:'',
                new_password:'',
                repeat_password:''
            }
        }
    }
    componentDidMount(){
        $('#filter_loader').fadeOut('slow')
    }
    componentDidUpdate(){
        if(this.props.location.page){
            if(this.props.location.page.load){
                this.props.location.page = {load:false}            
                $('.search_input').val('')
                $('#password0').val('') 
                $('#password1').val('') 
                $('#password2').val('')                
                this.componentDidMount()
            }
        }
    }
    cancelClick(){
        this.props.history.push('/')
    }

    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let isError = { ...this.state.isError };

        switch (name) {
            case "old_password":
                isError.old_password =
                    value.length < 8 ? "Atleast 8 characters required" : "";
                break;
            case "new_password":
                isError.new_password =
                    value.length < 8 ? "Atleast 8 characters required" : "";
                break;
            case "repeat_password":
                isError.repeat_password =
                    value.length < 8 ? "Atleast 8 characters required" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };
    
    handleSubmit(event) {
        event.preventDefault();
        // const password0 =  document.getElementById("password0").value
        // const password1 =  document.getElementById("password1").value
        // const password2 =  document.getElementById("password2").value
        let is_valid_form = true
        let isError = { ...this.state.isError };        
        if(this.state.old_password.length < 8){
            isError.old_password = "Atleast 8 characters required"
            is_valid_form = false;
        }else if (this.state.new_password.length < 8){
            isError.new_password = "Atleast 8 characters required"
            is_valid_form = false;
        }else if (this.state.repeat_password.length < 8){
            isError.repeat_password = "Atleast 8 characters required"
            is_valid_form = false;
        }               
        if(is_valid_form){
            const email = localStorage.getItem('email')
            document.getElementById("password-btn").disabled = true;
            const change_password=true
            if(this.state.new_password == this.state.repeat_password ){    
                    const form_data = 'password1='+this.state.new_password+'&password2='+this.state.repeat_password+'&email='+email+'&change_password='+change_password+'&password0='+this.state.old_password
                    axios({
                    method: 'post', 
                    url: API_HOST+'/cp/user/password/reset/',
                    data:form_data,
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                    }).then(function (response) {
                        if(response.status == 200){
                            if(response.data.success){
                                document.getElementById("success-msg").appendChild(document.createTextNode(response.data.message));
                                localStorage.clear()
                            }else{                        
                                document.getElementById("mesg").appendChild(document.createTextNode(response.data.message));
                            }
                            document.getElementById("password-btn").disabled = false;
                            setTimeout(() => {
                                document.getElementById("mesg").innerText = '';
                                document.getElementById("success-msg").innerText = '';
                            }, 10000); 
                        }
                    })
                    .catch(function (response) {
                        ErrorHandling(response)
                })
            }else{
                document.getElementById("mesg").innerText = '';
                var mess = "New Password and Repeat Password doesn't match"
                document.getElementById("mesg").appendChild(document.createTextNode(mess));
                document.getElementById("password-btn").disabled = false;
                setTimeout(() => {
                document.getElementById("mesg").innerText = '';
                }, 10000); 
            }
      }else{
        this.setState({
            isError
        })
      }
    }
     render() {  
        $('.row').removeClass('padding-70')
        const { isError } = this.state;
         return (
            <div className="container-fluid change-pwd-container">
                <div className="col-md-12">
                    <div className="inline-loader" id="filter_loader">
                        <SubLoader/>
                    </div> 
                    <div className="row">                
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 pdd-btm-20" id="change-password">
                    <div className="card">
                        <div className="card-block">
                        <form onSubmit={this.handleSubmit} noValidate> 
                                <p id="mesg" className="text-danger"></p>
                                <p id="success-msg" className="text-success"></p>
                                <div className="form-group">
                                        <label>Old Password</label>
                                        <input 
                                            type="password" 
                                            className={isError.old_password.length > 0 ? "is-invalid form-control" : "form-control"} 
                                            placeholder="Old Password"
                                            name="old_password"
                                            onChange={this.formValChange}
                                            id="password0"
                                         />
                                        {isError.old_password.length > 0 && (
                                            <span className="invalid-feedback">{isError.old_password}</span>
                                        )}
                                </div>               
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input 
                                            type="password" 
                                            className={isError.new_password.length > 0 ? "is-invalid form-control" : "form-control"} 
                                            placeholder="New Password" 
                                            name="new_password" 
                                            onChange={this.formValChange}
                                            id="password1"
                                        />
                                       {isError.new_password.length > 0 && (
                                            <span className="invalid-feedback">{isError.new_password}</span>
                                        )} 
                                    </div>
                                    <div className="form-group">
                                        <label>Repeat Password</label>
                                        <input 
                                            type="password" 
                                            className={isError.repeat_password.length > 0 ? "is-invalid form-control" : "form-control"}
                                            placeholder="Repeat Password" 
                                            name="repeat_password" 
                                            onChange={this.formValChange}
                                            id="password2"
                                        />
                                        {isError.repeat_password.length > 0 && (
                                            <span className="invalid-feedback">{isError.repeat_password}</span>
                                        )}

                                    </div>
                                    <button className="btn btn-info password-btn" id="password-btn" >Save Changes</button>
                                    <button className="btn btn-default cancel-btn" id="cancel-btn" onClick={this.cancelClick}>Cancel</button>
                            </form> 
                        </div>
                        </div>                    
                    </div>
                    <div className="col-lg-3"></div>
                    </div>
                </div>
            </div>
         )
     }
 }
 
