import { Component } from 'react'
import {Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import moment from 'moment'
import {Humanize, ErrorHandling} from '../../js/CommonFunctions'
import '../../styles/custom/pagination.css'
import './quotationlist.css'
const axios = require('axios').default;


const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number    
}

const defaultProps = {
    initialPage: 1
}

class Pagination extends Component {
    constructor(props) {
    super(props);
    this.state = { pager: {} };
    }

    componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
    }

    setPage(page) {
        var items = this.props.items;
        var pager = this.state.pager;
        var quotesCount = this.props.quotescount
        /*
        if (page < 1 || page > pager.totalPages) {
            return;
        }*/

        pager = this.getPager(quotesCount, page);
        var pageOfItems = items;
        this.setState({ pager: pager });
        this.props.onChangePage(pageOfItems);
    }
    getCurrentPageItems(page){
        const auth_token = "Token "+localStorage.token 
        $('.quote-card').find('.inline-loader').fadeIn() 
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()
        var cust_ref_no = $('#customer_reference_no').val()
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotations/?field=id&field=document_number&field=date&field=status&field=customer_reference_number&field=salesorder_set&field=total_section_amount&field=currency&field=exchange_rate&field=local_exchange_rate&field=total_quoted_amount&field=version&ordering=-date&quotation_no='+order_no+'&page='+page+'&page_size='+this.props.page_size+'&customer_reference_number='+cust_ref_no,
            ).then(response => {
            if(response.status == 200){ 
                var json_data = response.data.quotations   
                var items = json_data
                var quoteCount = response.data.quotations_count
                var pager = this.state.pager;
                if (page < 1 || page > pager.totalPages) {
                    return;
                }
                pager = this.getPager(response.data.quotations_count, page);
                var pageOfItems = items;
                this.setState({ pager: pager });
                this.props.onChangePage(pageOfItems);
            }
            $('.quote-card').find('.inline-loader').fadeOut('slow') 
            }).catch(function (response) {
                ErrorHandling(response)
                $('.quote-card').find('.inline-loader').fadeOut('slow')
              });
    }

getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || this.props.page_size;
    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages <= this.props.page_size) {
    startPage = 1;
    endPage = totalPages;
    } else {
    if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
    } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
    }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
    };
}

render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1 ) {
        // don't display pager if there is only 1 page
        if (pager.totalItems == 0){
            return null;
        }
    return <ul className="show-pages pull-left"><li>Showing {pager.endIndex+1}  of {pager.totalItems} quotations</li></ul>
    }
    return (
        <div>
            { (pager.startIndex+1 === pager.endIndex+1 ) ?
                (<ul className=" show-pages pull-left">
                    <li> Showing {pager.endIndex+1}  of {pager.totalItems} quotations </li>
                </ul>):
                (<ul className=" show-pages pull-left">
                    <li>Showing {pager.startIndex+1} to {pager.endIndex+1}  of {pager.totalItems} quotations</li>
                </ul>)
            }
            <ul className="pagination">
                {pager.currentPage === 1?null:<li className="">
                    <a onClick={() => this.getCurrentPageItems(1)}>First</a>
                </li>}
                {pager.currentPage === 1 ?null:<li className= ''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage - 1)}>Previous</a>
                </li>}
                {pager.pages.map((page, index) =>
                    <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a onClick={() => this.getCurrentPageItems(page)}>{page}</a>
                    </li>
                )}
                {pager.currentPage === pager.totalPages ?null:<li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage + 1)}>Next</a>
                </li>}
                {pager.currentPage === pager.totalPages?null:
                <li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.totalPages)}>Last</a>
                </li>}
            </ul>
            </div>
            );
        }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;


class QuotationsListFilters extends Component {
    constructor(props){
        super(props)
        this.state={
            filters:{},
            show:false
        }
    }
    componentDidUpdate(){
        if(this.state.show){
            $('#order-filters').find('select').selectpicker('refresh')
            $('.bootstrap-select > button.dropdown-toggle').addClass('custom-dropdown-toggle')   
        }

    }
    componentWillMount(){
        this.setState(()=>{
            return {
                filters: this.props.filters,
            }
      })
    }
    componentDidMount(){
        this.setState(()=>{
            return {
                show: true,
            }
      })
    }
    render(){
        var order_nos = this.state.filters.quotation_numbers.map((quotation_no,indx)  =>{
            return <option value={quotation_no} key={indx}>{quotation_no}</option>
        })       
        var order_status = Object.keys(this.state.filters.cp_status_choices).map((choice,indx)  =>{
            if(choice == 'lost'){
                return <option value={choice} key={indx}>Lost</option>
            }else{
                return <option value={choice} key={indx}>{this.state.filters.cp_status_choices[choice]}</option>
            }
        })
        return(
            <div className="row">                
                <a className="clear-filters" href='void(0)' onClick={this.props.clearFilter}>CLEAR FILTERS</a>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Quotation #</label>
                        <select className="selectpicker form-control" id="order_numbers" onChange={this.props.filterOrders} data-live-search="true" data-size="6">
                            <option value="">--------</option>
                            {order_nos}
                        </select>
                    </div>
                </div> 
                {true?null :           
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Quotation Status</label>
                        <select className="selectpicker form-control" id="order_status" data-size="5" onChange={this.props.filterOrders}>
                            <option value="">--------</option>
                            {order_status}
                        </select>
                    </div>
                </div>}
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Customer Reference #</label>
                        <input type="text" name="customer_reference" className="form-control" id="customer_reference_no" placeholder="Search Customer Reference #" onChange={this.props.filterOrders}/>
                    </div>
                </div>
            </div>
        )
    }
}


export default class QuotationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exampleItems: [],
            pageOfItems: [],
            pageCount:0,
            page_size:10,
            choices:{}
        };
        this.previous_request = null        
    }
    componentDidMount(){  
        $('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotations/?field=id&field=document_number&field=date&field=customer_reference_number&field=status&field=salesorder_set&field=total_section_amount&field=currency&field=exchange_rate&field=local_exchange_rate&field=total_quoted_amount&ordering=-date&field=cp_status_choices&field=version',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.quotations){
               // var json_data = conversion_amount(response.data.quotations)
                var json_data = response.data.quotations
              }else{
                  var json_data = []
              }
            if(response.data.quotations_count > 0){
                $('#order-filters').css('padding-bottom', '0px')
            }else{
                $('.order-filters').hide()
            }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.quotations_count,
                    choices:response.data              
                }
             })            
          }
          $('.inline-loader').fadeOut('slow')
          $('.card-block').removeClass('remove-order-filters')          
        }).catch(function (response) {  
            ErrorHandling(response)    
            $('.inline-loader').fadeOut('slow')        
            });  
    }


    showEntries = (event) =>{
        const auth_token = "Token "+localStorage.token
        var page_size = event.currentTarget.value
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()
        var cust_ref_no = $('#customer_reference_no').val()        
         $('.quote-card').find('.inline-loader').fadeIn() 
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotations/?field=id&field=document_number&field=date&field=customer_reference_number&field=status&field=salesorder_set&field=total_section_amount&field=currency&field=exchange_rate&field=local_exchange_rate&field=total_quoted_amount&field=version&ordering=-date&field=cp_status_choicesu&quotation_no='+order_no+'&page_size='+parseFloat(page_size)+'&customer_reference_number='+cust_ref_no,
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.quotations){
               // var json_data = conversion_amount(response.data.quotations)
                var json_data = response.data.quotations
              }else{
                  var json_data = []
              }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.quotations_count,
                    page_size:parseFloat(page_size),
                    choices:response.data             
                }
             })            
          }
          $('.quote-card').find('.inline-loader').fadeOut('slow')
          
  }).catch(response => {   
    ErrorHandling(response) 
    $('.quote-card').find('.inline-loader').fadeOut('slow')
});
    }
  
    onChangePage = (pageOfItems) => {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    filterOrders = (event) =>{
        event.preventDefault()
        const auth_token = "Token "+localStorage.token
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()
        var cust_ref_no = $('#customer_reference_no').val()
        if(this.previous_request){
            this.previous_request.cancel('Operation canceled by the user.');                                
        }
        $('.quote-card').find('.inline-loader').fadeIn() 
        const source = axios.CancelToken.source();
        this.previous_request = source
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotations/?field=id&field=document_number&field=date&field=customer_reference_number&field=status&field=salesorder_set&field=total_section_amount&field=currency&field=exchange_rate&field=local_exchange_rate&field=total_quoted_amount&field=version&ordering=-date&field=cp_status_choicesu&quotation_no='+order_no+'&customer_reference_number='+cust_ref_no+'&page_size='+parseFloat(this.state.page_size),
        {cancelToken: this.previous_request.token}).then(response => {
          if(response.status == 200){ 
            if(response.data.quotations){
                var json_data = response.data.quotations
            }else{
                  var json_data = []
              }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.quotations_count,
                    choices:response.data             
                }
             }) 
             $('.quote-card').find('.inline-loader').fadeOut('slow') 
             this.previous_request = null 
            }
    }).catch(response => { 
        //ErrorHandling(response)       
        //$('.quote-card').find('.inline-loader').fadeOut('slow')
    });
   }
   clearFilter = (event) =>{
       event.preventDefault()
       $('#order_numbers').val('').selectpicker('refresh')
       $('#customer_reference_no').val('')
       $('.inline-loader').fadeIn()
       const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/quotations/?field=id&field=document_number&field=date&field=customer_reference_number&field=status&field=salesorder_set&field=total_section_amount&field=currency&field=exchange_rate&field=local_exchange_rate&field=total_quoted_amount&ordering=-date&field=cp_status_choices&field=version',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.quotations){
               // var json_data = conversion_amount(response.data.quotations)
                var json_data = response.data.quotations
              }else{
                  var json_data = []
              }
            if(response.data.quotations_count > 0){
                $('#order-filters').css('padding-bottom', '0px')
            }else{
                $('.order-filters').hide()
            }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.quotations_count,
                    choices:response.data              
                }
             })            
          }
          $('.inline-loader').fadeOut('slow')
          $('.card-block').removeClass('remove-order-filters')          
        }).catch(function (response) {
            ErrorHandling(response)      
            $('.inline-loader').fadeOut('slow')        
        });  

   }

componentDidUpdate(){
    if(this.state.pageCount > 0){
        ReactDOM.render(
            <QuotationsListFilters filters={this.state.choices} filterOrders={this.filterOrders} clearFilter={this.clearFilter}/>
            ,document.getElementById('order-filters')
        )
    }
    if(this.props.location.page){
        if(this.props.location.page.load){
            this.props.location.page = {load:false}            
            $('.search_input').val('')
            $('#order_numbers').val('').selectpicker('refresh')
            $('#customer_reference_no').val('')
            this.componentDidMount()
        }
    }
}

    render() {
        $('.card').removeClass('add-pdding')        
        const tr_elements = this.state.pageOfItems.map((quote,indx) =>{
                var symbol = quote.currency?quote.currency.symbol:''
                if(quote.total_section_amount){
                    var quotes_amount = quote.total_section_amount
                }else if(quote.total_quoted_amount){
                    var quotes_amount = quote.total_quoted_amount
                }
                else{
                    var quotes_amount = '0.00'          
                }
                if(quote.status == 'open'){
                    var quote_status = <i className="fas fa-envelope-open quotation_status_open" title="Open"></i>
                }else if(quote.status == 'won'){
                    var quote_status = <i className="em em---1 quotation_status_won" title="Ordered/Closed"></i>
        
                }else if(quote.status == 'lost') {
                    var quote_status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled" title="Lost"></i>
                }else {
                    var quote_status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled" title="Cancelled"></i>
                }
                var version = ('0'+quote.version).slice(-2)
                return quote.document_number ?
                    <tr className="quotes-tr" key={indx}>                                      
    
                        <td className="quotes-document-no-td">
                            <div className="relative mrg-vertical-5">
                                <div className="relative mrg-vertical-5">
                                    <Link to={"/quotation/view/"+quote.id+"/"} className="text-info">
                                            <span className='quote-number'>{ quote.document_number }.{ version }</span>
                                    </Link>                                            
                                </div>
                            </div>
                        </td>
                        <td className="quotes-date-td">
                            <div className="mrg-vertical-5">
                                <span className="date">{ moment(quote.date).format('MMM, D YYYY') }</span>
                            </div>
                        </td>
                        <td className="quotes-status-td">
                            <div className="mrg-vertical-5 text-center">
                                {quote_status}
                            </div>
                        </td>
                        <td className="quotes-cust-ref-td">
                            <div className="mrg-vertical-5">
                                {quote.customer_reference_number?quote.customer_reference_number:'-'}
                            </div>
                        </td>
                        <td className="order-doc-no">
                            <div className="mrg-vertical-5">
                                    { quote.salesorder_set.length > 0 ?
                                    <Link to={"/order/view/"+quote.salesorder_set[0].id+"/"} className="text-info">
                                        <span  className='order-number'>{ quote.salesorder_set[0].document_number }</span>
                                    </Link>:'-' }
                            </div>
                        </td>
                        <td className="text-right quotes-amount-td">
                            <div className="mrg-vertical-5">
                                <b className="text-dark font-size-16">
                                    <span className="currency">{quote.currency.symbol?quote.currency.symbol:''} </span>
                                    {Humanize(parseFloat(quotes_amount)?parseFloat(quotes_amount):'0.00')}
                                </b>
                            </div>
                        </td>
                    </tr>:''
                        }
                    );
            $('select.show_entries').val(this.state.page_size.toString())
        return (
            <div className="container-fluid quotation-list">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card order-filters">
                            <div className="inline-loader" id="filter_loader">
                                <SubLoader/>
                            </div>                    
                            <div className="card-block remove-order-filters" id="order-filters">
                            </div>
                        </div>
                    </div>
                </div>        
                    <div className="row">
                            <div className="col-md-12">
                                <div className="card quote-card add-pdding">
                                    <div className="inline-loader" id="quotation_loader">
                                        <SubLoader/>
                                    </div> 
                                    <div className="card-block" id="table-overflow">
                                        <div className="table-overflow quotes-list-div" > 
                                            { this.state.pageCount > 10 ?                        
                                                <div className="portlet">
                                                    <ul className="portlet-item navbar" >                                
                                                        <li className="dataTables_length" id="dt-opt_length">
                                                    <label>Show 
                                                        <select name="dt-opt_length" className="show_entries" onChange={this.showEntries}>
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select> 
                                                        entries</label>
                                                    </li>
                                                </ul>
                                                </div>:null}     
                                                <table className="table table-hover" >
                                                        <thead className="quotes-list-header">
                                                            <tr>
                                                                <th className="width-20">Quotation # </th>
                                                                <th className="width-15">Date</th>
                                                                <th className="text-center width-10">Status</th>
                                                                <th className="width-20">Cust Ref #</th>
                                                                <th className="width-15">Order #</th>
                                                                <th className="text-right width-30">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { this.state.pageOfItems.length > 0?
                                                                tr_elements :<tr><td colSpan="6" className="text-center"><h2> You have no Quotes.</h2></td></tr>

                                                        }
                                                        </tbody>
                                                    </table>
                                                    <div>
                                                            <Pagination items={this.state.exampleItems} quotescount={this.state.pageCount} onChangePage={this.onChangePage} page_size={this.state.page_size} />
                                                    </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
        )
    }
}


