import React from 'react'
import { Route } from 'react-router-dom'
import MainNavBar from './NavBar/MainNavBar'
import ErrorBoundary from '../js/ErrorBoundary'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('token')
            ? <ErrorBoundary><MainNavBar><Component/></MainNavBar></ErrorBoundary>
            :props.history.push('/login')
    )} />
)
