import { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import {Humanize, ErrorHandling} from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;


class ItemImageModal extends Component{
    constructor(props){
        super(props)
        this.state = {
            item_attachments:[]
        }
    }
    componentWillMount(){        
            var item_id = this.props.item.id
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.get['Authorization'] =auth_token
            axios.get(API_HOST+'/api/item/attachments/?item_id='+item_id,
            ).then(response => {
            if(response.status === 200){
                this.setState({
                    item_attachments:response.data.item_attachments,
                })         
            }
            }).catch(response =>{
                ErrorHandling(response) 
            });
    }
    onformSubmit = (event) =>{
        $('.modal').modal('hide');
        this.props.history.push({
            pathname: '/items',
            state: { params: {feature_item_id: this.props.item.id, feature_item: this.props.item.item_number} }
        })
    }
    render(){
        var org_temp = ''
        if(this.state.item_attachments.length > 0){
            org_temp = this.state.item_attachments.map((attachment, index) =>{            
                return <li className="" key={index}>
                        <a href={attachment.signed_url?attachment.signed_url[0]:"void(0)"} target="_blank">{attachment.name}</a>
                    </li>
            })
        }
        return(
            <div className="modal fade modal-fs" id={"modal-fs-"+this.props.index} >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="vertical-align text-center">
                                    <div className="table-cell">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-8 mr-auto ml-auto">
                                                    <div className="image-response">
                                                            <img id="img01" className="w3-image w3-animate-zoom" src={this.props.item.image_signed_url === null ?"void(0)":this.props.item.image_signed_url[0]}/>
                                                    </div>
                                                    <div className="row image-preview">
                                                        <div className="col-lg-4"></div>
                                                        <div className="col-lg-4 text-left padding-5">
                                                            <h4 className="item_number">{this.props.item.item_number}</h4>
                                                            <p className="item-desc no-mrg-btm">{this.props.item.description}</p>
                                                            <p className="lead no-mrg-btm">
                                                                <sup className="currency"> {localStorage.getItem('symbol')? localStorage.getItem('symbol'):''}</sup>
                                                                <span className=" font-size-16 item-price">{this.props.item.price?Humanize(this.props.item.price):'0.00'}</span>
                                                            </p>
                                                            <p className="in-stock">
                                                                {this.props.item.in_stock?<span className="item-in-stock">In Stock {this.props.item.in_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>:<span className="item-in-stock"></span>}
                                                            </p>                                                            
                                                        </div>
                                                        <div className="col-lg-4"></div>
                                                    </div>
                                                        
                                                </div>
                                                { this.state.item_attachments.length >0 ? 
                                                <div className="col-md-4 suggested-imges-div">
                                                    <h5 className="text-left">Product Information:</h5>
                                                    <div className="attachment-card"> 
                                                        <ul className="item-attachments">
                                                                {org_temp}      
                                                        </ul>                                                              
                                                    </div>
                                                </div>:null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="modal-close btn-icon btn-flat btn-rounded" href="" data-dismiss="modal" title="Close" >
                                        <i className="far fa-times-circle"></i>
                                </a>
                                <a className="btn-icon btn-flat btn-rounded feature-add-cart" title="Add to Cart" onClick={this.onformSubmit}>
                                    <i className="ti-shopping-cart"></i>
                                </a>                                
                                {/* <form  action="/items/" method='post'  data-from="search" >
                                <input type="hidden" className="feature_item_id" name="feature_item_id" value={}/>
                                    <input type="hidden" className="feature_item" name="feature_item" value={}/>
                                    <a href="void(0)" >
                                        
                                    </a>
                                </form> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

class FeaturedProducts extends Component {
    constructor(props){
        super(props)
        this.state = {
            items:[],
        }
    }
    componentDidMount(){
        const auth_token = "Token "+localStorage.token
        //ReactDOM.render(<PageLoader/>,document.getElementById('suggested_item_div'))
        $('.suggested-item').find('.inline-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] =auth_token
        // axios.get('{% url "customer_portal:suggested_ssi_products" %}',
        axios.get(API_HOST+'/api/suggested/item/images/',
            ).then(response => {
            if(response.status === 200 && response.data.success) {
                this.setState(()=>{
                    return {
                        items: response.data.item_images,
                    }
                })
                $('.suggested-item').find('.inline-loader').fadeOut("slow");
                $('.suggested-item').removeClass('add-padding')
            }
        }).catch(response => {
            ErrorHandling(response) 
        });
    }
    modalAnimations = (event) => {
        var height = $(event.currentTarget).closest('div.suggested-image').find('.thumbnail_image')[0].height
        $(event.currentTarget).closest('div.suggested-image').find('#img01').addClass('modal-content-hover')
        if(height > 160) {
            $(event.currentTarget).closest('div.suggested-image').find('#img01').addClass('small-image-width')
        } else {
            $(event.currentTarget).closest('div.suggested-image').find('#img01').addClass('big-image-width') 
        }
        var item_number = $(event.currentTarget).attr('item-no')
    }
    render() {
        const tr_elements = this.state.items.map((item, index) =>{
            return  <div className="col-md-2 center-child-items suggested-image" title={item.item_number} key={index}>
                    {item.image_signed_url === null?<span>-</span>:
                            [
                                <img data-toggle="modal" key={index} onClick={this.modalAnimations} item-no={item.item_number} data-target={"#modal-fs-"+index}  className="thumbnail_image" src={item.image_signed_url === null ?"void(0)":item.image_signed_url[0]} alt="No Image Available"/>
                                , 
                                <ItemImageModal index={index} item={item} history={this.props.history}/>]
                    }                    
                    <div className="suggested-image-title">{item.item_number}</div>
            </div>
        })
        return (
            <div className="col-lg-12">
                    <div className="row">
                        <div className="card col-lg-12 suggested-item">
                            <div className="inline-loader" id="suggested_item_div">
                                <SubLoader/>
                            </div>
                            <h4 className="card-title inline-block">Featured Products </h4>
                            <div className="" id="suggested_items">
                            <div className="row">
                              {tr_elements}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default withRouter(FeaturedProducts)