 
import { Component } from 'react'
import {API_HOST} from '../settings.config.js'
import workerScript from './items.worker.js'
//const axios = require('axios').default;

export default class GetItemsList extends Component {
    constructor(props) {
        super(props);
        this.sendRequest = 1
        this.requestCount = 0
      }
      componentDidMount() {
        this.worker = new Worker(workerScript);
            this.worker.onmessage = (e) =>{
                var response = e.data
                localStorage.setItem('items', JSON.stringify(response))
                this.sendRequest = 1
            }
            if(localStorage.getItem('items') == null){
                    if(this.sendRequest){
                        var url = API_HOST+'/api/items/search/list/'
                        this.worker.postMessage({'token':localStorage.token,'url':url});
                        this.sendRequest = 0
                        this.requestCount += 1
                    }
            }
                
        // this.sendRequest = 0
        // const auth_token = "Token "+localStorage.token
        // axios.defaults.headers.get['Authorization'] =auth_token
        // axios.get(API_HOST+'/api/items/search/list/',
        // ).then(response => {
        // if(response.status == 200){
        //     localStorage.setItem('items', JSON.stringify(response.data))
        //     this.sendRequest = 1         
        // }
        // }).catch(response =>{
            
        // });
        this.timerID = setInterval(
          () => this.getList(),
          60000
        );
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }

    getList = () => {
        if(this.requestCount <= 20){
            if(this.sendRequest){
                this.worker = new Worker(workerScript);
                this.worker.onmessage = (e) =>{
                    var response = e.data
                    localStorage.setItem('items', JSON.stringify(response))
                    this.sendRequest = 1
                }      
                var url = API_HOST+'/api/items/search/list/'
                this.worker.postMessage({'token':localStorage.token,'url':url});
                this.sendRequest = 0
                this.requestCount += 1
            }
        }
    }
    render(){
        return(
            ''
        )
    }   
}

