import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import {notify, ErrorHandling} from '../../js/CommonFunctions'
import '../../styles/custom/pagination.css'
import './notifications.css'
const axios = require('axios').default;


const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number    
}
const defaultProps = {
    initialPage: 1
}

class Pagination extends Component {
    constructor(props) {
    super(props);
    this.state = { pager: {} };
    }

    componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
    }

    setPage(page) {
        var items = this.props.items;
        var pager = this.state.pager;
        var notificationCount = this.props.notificationCount

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        pager = this.getPager(notificationCount, page);
        var pageOfItems = items;
        this.setState({ pager: pager });
        this.props.onChangePage(pageOfItems);
    }
    getCurrentPageItems(page){
        axios.get(API_HOST+'/notifications/api/notifications/?page='+page,
            ).then(response => {
            if(response.status == 200){ 
                var json_data = response.data.notifications   
                var items = json_data
                var notificationCount = response.data.notifications_count
                var pager = this.state.pager;
                if (page < 1 || page > pager.totalPages) {
                    return;
                }
                pager = this.getPager(notificationCount, page);
                var pageOfItems = items;
                this.setState({ pager: pager });
                $('.q-select-all').prop('checked', false)
                $('.q-select').each((index,item) => {
                    item.checked = false
                })
                this.props.onChangePage(pageOfItems);
            }
            
            }).catch(function (response) {
                ErrorHandling(response)                  
            });
    }

getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || this.props.page_size;
    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages <= this.props.page_size) {
    startPage = 1;
    endPage = totalPages;
    } else {
    if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
    } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
    }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
    };
}

render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
    // don't display pager if there is only 1 page
    return null;
    }

    return (
            <ul className="pagination">
                {pager.currentPage === 1?null:<li className="">
                    <a onClick={() => this.getCurrentPageItems(1)}>First</a>
                </li>}
                {pager.currentPage === 1 ?null:<li className= ''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage - 1)}>Previous</a>
                </li>}
                {pager.pages.map((page, index) =>
                    <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a onClick={() => this.getCurrentPageItems(page)}>{page}</a>
                    </li>
                )}
                {pager.currentPage === pager.totalPages ?null:<li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage + 1)}>Next</a>
                </li>}
                {pager.currentPage === pager.totalPages?null:
                <li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.totalPages)}>Last</a>
                </li>}
            </ul>
            );
        }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default class Notifications extends Component{
    constructor(props){
        super(props)
        this.state = {
            notifications:[],
            pageCount:0,
            selected_notification_count:0,
            notification_list:[],
            page_size:10,
            unread_count:0,
            this_page_unread:0
        }
        this.selectAllNotifications = this.selectAllNotifications.bind(this)
        this.selectNotification = this.selectNotification.bind(this)
        this.markAllAsRead = this.markAllAsRead.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
    }

    componentDidMount(){
        //$(".lds-ripple-parent").fadeOut("slow");
        //ReactDOM.render(<PageLoader/>,document.getElementById('items_loader'))
        $('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/notifications/api/notifications/',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.notifications){
                var notifications_data = response.data.notifications
                var notify_count = response.data.notifications_count
              }else{
                  var notifications_data = []
                  var notify_count = 0
              }
              var this_page_unread = 0
              notifications_data.map((notification,index) =>{
                notifications_data[index].is_selected = false
                if(notification.status=='Unread'){
                    this_page_unread += 1
                }
              })
            this.setState(()=>{
                return {
                    notifications: notifications_data,
                    pageCount:notify_count,
                    notification_list:notifications_data,
                    this_page_unread:this_page_unread
                }
             })            
          }
          $('.inline-loader').fadeOut("slow");
        }).catch(function (response) {
            ErrorHandling(response)                
        });
  
    }
    componentDidUpdate(){
        if(this.props.location.page){
            if(this.props.location.page.load){
                this.props.location.page = {load:false}            
                $('.search_input').val('')
                this.setState({
                    notifications:[],
                    selected_notification_count:0,
                    page_size:10,
                    unread_count:0,
                    this_page_unread:0
                })
                this.componentDidMount()
            }
        }
    }
    onChangePage(pageOfItems) {
        var this_page_unread = 0
        pageOfItems.map((notification,index) =>{
                if(notification.status=='Unread'){
                    this_page_unread += 1
                }
              })
        this.setState({ 
            notifications: pageOfItems,
            selected_notification_count:0,
            this_page_unread:this_page_unread
         });
    }

    selectAllNotifications(event){
        var selected_notification_count = 0            
        if (event.currentTarget.checked) {
            $('.q-select').each((index,item) =>{
                item.checked = true
                selected_notification_count += 1
                var this_index = $(item).attr('row-index') 
                this.state.notifications[this_index].is_selected = true 
            })
        } else {
            $('.q-select').each((index,item) => {
                item.checked = false
                var this_index = $(item).attr('row-index') 
                this.state.notifications[this_index].is_selected = false  
            })
        }
        
        this.setState({
            selected_notification_count:selected_notification_count,
            notifications:this.state.notifications
        })
}
selectNotification(event){
    var row_index = $(event.currentTarget).attr('row-index')
    var selected_notification_count = this.state.selected_notification_count
    if (event.currentTarget.checked) {
        event.currentTarget.checked = true
        this.state.notifications[row_index].is_selected = true
        selected_notification_count += 1
    } else {
        event.currentTarget.checked = false
        this.state.notifications[row_index].is_selected = false
        if(this.state.selected_notification_count){
            selected_notification_count -= 1  
        }
    }            
    var select_all = true
    $('.q-select').each((index,item) =>{
        if (item.checked) {
        } else {
            select_all = false
        }
    })        
    if (select_all) {
        $('.q-select-all').prop('checked', true)
    } else {
        $('.q-select-all').prop('checked', false)
    }
    
    this.setState({
        selected_notification_count:selected_notification_count,
        notifications:this.state.notifications
    })

}
markAllAsRead(event){
    event.preventDefault()
    var notification_ids = []
    var notification_index = []
    var notifications = this.state.notifications
    var is_check_icon = $(event.currentTarget).attr('data-notification')
    if(is_check_icon == 'single'){
        notification_ids.push($(event.currentTarget).attr('value'))
        notification_index.push($(event.currentTarget).attr('row-index'))
    }else{
        notifications.map((notification,index)=>{
            if(notification.is_selected && notification.status=='Unread'){
                notification_ids.push(notification.id)
                notification_index.push(index)
            }
        })

    }        
    if(notification_ids.length > 0){
    //var csrf_token = '{{ csrf_token }}' 
    var form_data = 'unread_notifications='+JSON.stringify(notification_ids)
    const auth_token = "Token "+localStorage.token
    axios.defaults.headers.post['Authorization'] =auth_token
    axios.post(API_HOST+'/notifications/api/notifications/', form_data)
        .then(response => {
        if(response.status == 200 && response.data.success){
            response.data.notifications.map((noti,indx)=>{
                notifications[notification_index[indx]] = noti
            })
            $('.q-select').each((index,item) => {
                item.checked = false
                var this_index = $(item).attr('row-index') 
                notifications[this_index].is_selected = false  
            })  
            var this_page_unread = 0
            notifications.map((notification,index) =>{
                if(notification.status=='Unread'){
                    this_page_unread += 1
                }
              })              
            this.setState({
                notifications:notifications,
                selected_notification_count:0,
                unread_count:response.data.unread_count,
                this_page_unread:this_page_unread

            })
            var notification_count = ''
            if(this.state.unread_count){
                notification_count = <span className="counter" >{this.state.unread_count}</span>
            }
            ReactDOM.render(notification_count, document.getElementById('notification_counter'));
            
            }
        })
        .catch(function (error) {
            ErrorHandling(error)
        });
    }else{
        notify('No unread notifications','warning')
        $('.q-select-all').prop('checked', false)
        $('.q-select').each((index,item) => {
            item.checked = false
        })
        this.setState({
            selected_notification_count:0
        })
    }

}

render(){
        $('.card-block').removeClass('padding-60')
        var cart_item_list = this.state.notifications.map((notification,index)=>{
            // var text_message = window.HTMLReactParser(notification.message)
            // var title = window.HTMLReactParser(notification.title)
            return(
                <tr notification-id={notification.id} key={index} row-index={index} className={notification.status =='Unread'?"notification-unread":"notification-read"}>
                    <td className="item-checkbox">
                {notification.status =='Unread'?
                    <label className="q-checkbox" name="" value="">
                            <input type="checkbox" name="" className="q-select" value={notification.id} row-index={index} onChange={this.selectNotification}/>
                            <span className="checkmark"></span>
                    </label>:''}
                    </td>                                    
                    <td className="notification-details">                                        
                        {notification.sender_name?
                        <div className="sender-name" >{notification.announcement?<i className="fas fa-bullhorn pdd-right-10" title="Announcement"></i>:null} <span title="Sender">{notification.sender_name}</span></div>:
                        <div className="sender-name" > {notification.announcement?<i className="fas fa-bullhorn pdd-right-10" title="Announcement"></i>:null} <span title="Sender">{notification.sender_user.first_name} {notification.sender_user.last_name}</span></div>
                        }
                        <div className="notification-title"><span dangerouslySetInnerHTML={{ __html: notification.title }}></span></div>
                        <div className="notification-message"><span dangerouslySetInnerHTML={{ __html: notification.message }}></span></div>
                        <div className="notification-relative-time">
                        {notification.status =='Unread'?!notification.is_selected?
                            <span className="unread-icon"
                                title='Mark as read'
                                onClick={this.markAllAsRead}
                                value={notification.id} row-index={index} data-notification='single'>
                                <i className="fas fa-check-circle"></i>
                            </span>:
                            <span className="read-icon">
                                <i className="fas fa-check-circle"></i>
                            </span>:
                            <span className="read-icon">
                            <i className="fas fa-check-circle"></i>
                            </span>
                            }
                            <span className="pdd-left-15">{notification.created_date}</span>
                        </div> 
                    </td>                                   
                </tr>

            )
        })
        
        return(
            <div className="container-fluid notification-container">
            <div className="row">
                <div className="col-md-12 notifi-card">
                    <div className="inline-loader" id="items_loader">
                        <SubLoader/>
                    </div> 
                    <div className="card ">                        
                        <div className="card-block padding-60" id="table-overflow">
                        <div className="table-overflow" >    
                    {this.state.notifications.length >0?
                        <table className="table table-hover" >
                            <thead className="cart-table-header">
                                <tr>
                                    <th width="2%">
                                        {this.state.this_page_unread?
                                        <label className="q-checkbox" name="" value="" >
                                            <input type="checkbox" className="q-select-all" name="" id="q-select-all" onChange={this.selectAllNotifications} />
                                            <span className="checkmark" ></span>
                                        </label>:null}
                                    </th>
                                    <th className="">
                                        {this.state.selected_notification_count?
                                        <span className="items_count">{this.state.selected_notification_count} notification(s) selected. </span>
                                        :null}
                                        {this.state.selected_notification_count?
                                        <a href="" className="mark-all-as-read float-right" onClick={this.markAllAsRead}>Mark all as read</a>
                                        :null}
                                    </th>                                        
                                </tr>
                            </thead>
                            <tbody>{cart_item_list}</tbody>
                            <tfoot>
                                <tr>  
                                    <td></td>                                          
                                    <td>
                                            {this.state.selected_notification_count?
                                            <span className="items_count">{this.state.selected_notification_count} notification(s) selected. </span>
                                            :null}
                                            {this.state.selected_notification_count?
                                                <a href="" className="mark-all-as-read float-right" onClick={this.markAllAsRead}>Mark all as read</a>
                                                :null}
                                        </td>
                                </tr>
                                    </tfoot>
                        </table>:
                        <table className="empty-table">
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="empty-td"><span>No Notifications.</span></td>
                                
                                </tr>                                    
                        </tbody></table>}
                        <Pagination items={this.state.notification_list} notificationCount={this.state.pageCount} onChangePage={this.onChangePage} page_size={this.state.page_size}/>
                    </div>
                        </div>
                    </div>
                </div>  
            </div> 
        </div>            
        )
    }
}
