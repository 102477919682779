import  { Component } from 'react'
import $ from 'jquery'
import {API_HOST} from '../../settings.config.js'
import SubLoader from '../Loader/SubLoader'
import {notify} from '../../js/CommonFunctions'
const axios = require('axios').default;


 
 export default class Profile extends Component {
     constructor(props){
        super(props);
       this.state = {
        formControls: {
            first_name: {
              value: localStorage.getItem('first_name') != 'null'?localStorage.getItem('first_name'):'' 
            },
            last_name: {
              value: localStorage.getItem('last_name') != 'null'?localStorage.getItem('last_name'):'' 
            }
        }
       } ;
    }
    
    componentDidMount(){
        $('.profile-card').find('.inline-loader').fadeOut('slow')
    }

    handlechange = (event) =>{
        event.preventDefault()
        const name = event.target.name;
        const value = event.target.value;    
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                ...this.state.formControls[name],
                value
            }
            }
        });
    }

    handleEdit = (event) =>{
        event.preventDefault()
        document.getElementById('raw_first_name').style.display='none';
        document.getElementById('raw_last_name').style.display='none';
        document.getElementById('input_first_name').style.display='block'; 
        document.getElementById('input_last_name').style.display='block'; 
        document.getElementById('save-btn').style.display='inline'; 
        document.getElementById('cancel-btn').style.display='inline'; 
        document.getElementById('edit-btn').style.display='none'; 
        
    }

    handleSubmit = (event) =>{
        event.preventDefault()
        document.getElementById("first_name_error").innerText = '';
        document.getElementById("last_name_error").innerText = '';
        var first_name = document.getElementById('input_first_name').value
        var last_name = document.getElementById('input_last_name').value
        if(first_name.length > 15){
            notify("Ensure First Name has no more than 15characters",'error')
            return false
        }
        if(last_name.length > 15){
            notify("Ensure Last Name has no more than 15 characters",'error')
            return false
        }
        //let csrf_token = document.querySelector('[name="csrfmiddlewaretoken"]').value
        if(first_name && last_name){
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.patch['Authorization'] =auth_token
            //const form_data = 'first_name='+first_name+'&last_name='+last_name//+'&csrfmiddlewaretoken='+csrf_token            
            const form_data = {'first_name':first_name,'last_name':last_name}
            axios({
                method: 'patch', 
                url: API_HOST+'/cp/users/',
                data:form_data,
                headers: { 
                    'Content-Type': 'application/json',
                }
              })
            .then((response) => {
                   if(response.status == 200){
                    this.setState({
                        formControls: {
                            first_name: {
                                value: response.data.first_name 
                              },
                              last_name: {
                                value: response.data.last_name
                              }
                        }
                      });
                    notify('Profile Data Updated successfully.','success')
                    document.getElementById('raw_first_name').style.display='block';
                    document.getElementById('raw_last_name').style.display='block';
                    document.getElementById('input_first_name').style.display='none'; 
                    document.getElementById('input_last_name').style.display='none'; 
                    document.getElementById('save-btn').style.display='none'; 
                    document.getElementById('cancel-btn').style.display='none'; 
                    document.getElementById('edit-btn').style.display='inline'; 
                    localStorage.setItem('first_name',response.data.first_name)
                    localStorage.setItem('last_name',response.data.last_name)
                    $('.profile-name').attr('title',response.data.first_name+' '+response.data.last_name)
                    $('.profile-pic').attr('title',response.data.first_name+' '+response.data.last_name)
                    $('.first-name').text(response.data.first_name.charAt(0).toLocaleUpperCase())
                    $('.last-name').text(response.data.last_name.charAt(0).toLocaleUpperCase())                   

                   }
            });

        }else{
            if(!first_name){
                document.getElementById("first_name_error").appendChild(document.createTextNode('Please fill out this field'));
            }
            if(!last_name){
                document.getElementById("last_name_error").appendChild(document.createTextNode('Please fill out this field'));
            }
            setTimeout(() => {
                document.getElementById("first_name_error").innerText = '';
                document.getElementById("last_name_error").innerText = '';
            }, 5000); 
        }     
    }

    handleCancel = (event) =>{
        event.preventDefault()
        this.setState({
            formControls: {
                first_name: {
                    value: localStorage.getItem('first_name') != 'null'?localStorage.getItem('first_name'):'' 
                  },
                  last_name: {
                    value: localStorage.getItem('last_name') != 'null'?localStorage.getItem('last_name'):'' 
                  }
            }
          });
          document.getElementById('raw_first_name').style.display='block';
        document.getElementById('raw_last_name').style.display='block';
        document.getElementById('input_first_name').style.display='none'; 
        document.getElementById('input_last_name').style.display='none'; 
        document.getElementById('save-btn').style.display='none'; 
        document.getElementById('cancel-btn').style.display='none'; 
        document.getElementById('edit-btn').style.display='inline'; 

    }
     render() {
        $('.profile-card').find('.card-block').removeClass('padding-60')
        var style={'display':'none'}
         return (
            <div className="card profile-card" id="ask-1">
            <div className="inline-loader" id="profile_loader">
                <SubLoader/>
            </div>
                <div className="card-heading border bottom">
                        <div className="portlet" id="profilebtn">
                            <ul className="portlet-item navbar">
                                <li className="dropdown">                          
                                        <a href="void(0)" onClick={this.handleCancel}className=" btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="cancel-btn" title="Cancel">
                                                <i className="ti-close"></i>
                                        </a>                            
                                        <a href="void(0)" onClick={this.handleSubmit} className="btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="save-btn" title="Save">
                                                <i className="ti-save"></i>
                                        </a>
                                        <a href="void(0)" onClick={this.handleEdit}className="btn btn-icon btn-flat btn-rounded profile-btn mrg-horizon-5" id="edit-btn" title="Edit">
                                                <i className="ti-pencil"></i>
                                        </a>
                                </li>                                                
                            </ul>                                
                        </div>
                        <h4 className="card-title">Profile</h4>
                </div>
                    <div className="card-block" id="profile-block">
                    <form>
                    <div className="row">
                            <div className="col-md-2">
                                <p className="mrg-top-10 text-dark"> <b>First Name</b></p>
                            </div>
                            <div className="col-md-4">
                                    <p className="mrg-top-10" id="raw_first_name" >{this.state.formControls.first_name.value}</p>
                                    <input type="text" name="first_name" id="input_first_name" style={style} className="form-control" value={this.state.formControls.first_name.value} onChange={this.handlechange} required/>
                                    <p id="first_name_error" className="text-danger"></p>
                            </div>
                            <div className="col-md-2">
                                    <p className="mrg-top-10 text-dark"> <b>Last Name</b></p>
                                </div>
                                <div className="col-md-4">
                                        <p className="mrg-top-10" id="raw_last_name">{this.state.formControls.last_name.value}</p>
                                        <input type="text" name="last_name" style={style} id="input_last_name" className="form-control" value={this.state.formControls.last_name.value} onChange={this.handlechange} required/>
                                        <p id="last_name_error" className="text-danger"></p>
                                    </div>
                            </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-2">
                                <p className="mrg-top-10 text-dark"> <b>Email</b></p>
                            </div>
                            <div className="col-md-4">
                                <p className="mrg-top-10" id="email">{ localStorage.getItem('email') }</p>
                            </div>
                            <div className="col-md-2">
                                    <p className="mrg-top-10 text-dark"> <b>Company</b></p>
                                </div>
                                <div className="col-md-4">
                                    <p className="mrg-top-10" id="company_name">{ localStorage.getItem('company') != 'null'?localStorage.getItem('company'):'-' }</p>
                                </div>
                                
                        </div>                        
                    </form>                       
                        
                    </div>
        </div>
         )
     }
 }
 
