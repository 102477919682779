import { Component } from 'react'
import '../../styles/custom/sign-in.css'
import SSILogo from '../../styles/images/logo/ssi-logo.png'
import {API_HOST} from '../../settings.config.js'
import {ErrorHandling,notify} from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;

export default class ForgotPasswordSucess extends Component {
    constructor() {
        super();
        this.state = {
            email:'',
            first_name:'',
            last_name:'',
            id:'',
        }
    }
    componentWillMount(){
        var query_parm= {};
        if(window.location.search.length!==0)
            window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value){
                key=decodeURIComponent(key);
                if(typeof query_parm[key]==="undefined") {query_parm[key]= decodeURIComponent(value);}
                else {query_parm[key]= [].concat(query_parm[key], decodeURIComponent(value));}
            });
            this.setState(()=>{
                return {
                    id:query_parm.id,
                    email:query_parm.email,
                    first_name:query_parm.first_name,
                    last_name:query_parm.last_name
                }
             })  
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const password1 =  document.getElementById("password1").value
        const password2 =  document.getElementById("password2").value
        const email = this.state.email
        const id = this.state.id
        if(password1 == password2 ){    
        const form_data = 'password1='+password1+'&password2='+password2+'&email='+email+'&id='+id
        axios({
          method: 'post', 
          url: API_HOST+'/cp/user/password/reset/',
          data:form_data,
          headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
          }
        }).then((response) => {
            if(response.status == 200){
                if(response.data.success){
                    notify('Your password has been reset successfully!', 'success')
                    setTimeout(() =>{
                        this.props.history.push('/login')
                    },1000)
                    
                }else{                        
                    document.getElementById("mesg").appendChild(document.createTextNode(response.data.message));
                    setTimeout(() => {
                        document.getElementById("mesg").innerText = '';
                    }, 10000); 
                } 
            }
        })
        .catch(function (response) {
            ErrorHandling(response)
      })
        }else{
            var mess = "Password1 and Password2 doesn't match"
            document.getElementById("mesg").appendChild(document.createTextNode(mess));
        setTimeout(() => {
            document.getElementById("mesg").innerText = '';
        }, 10000); 

        }
    }
    render() {
        var css_style = {'color': "#8a6d3b"}
        return (
            <div className="authentication login-container">
            <div className="sign-in">
                <div className="row no-mrg-horizon">
                    <div className="col-md-8 no-pdd-horizon d-none d-md-block">
                        <div className="full-height bg video-container" >
                            <video autoPlay="autoplay" loop="loop" muted="muted" src="https://www.ssiaeration.com/wp-content/themes/ssi/images/front-page.mp4" poster=""></video>
                            <div className="img-caption">
                                <h1 className="caption-title">SMART IDEAS FOR WATER</h1>
                                <p className="caption-text">SSI Aeration fine bubble diffuser systems and products are installed on five continents at thousands of job sites. Millions of our diffusers are hard at work in wastewater treatments worldwide. SSI has received multiple US and International patents for inventions and industry improvements.</p>
                            </div>    
                        </div>
                    </div>
                <div className="col-md-4 no-pdd-horizon">                                
                    <div className="full-height bg-white height-100">
                            <div className="signin-header">
                                    <img className="img-responsive sign-in-ssi-logo inline-block" src={SSILogo} />
                                </div>
                        <div className="vertical-align  pdd-horizon-70 pdd-vertical-35"> 
                            <div  id="cp-login-form">
                            <div className="pdd-horizon-15" >
                            <h2 className="text-center">Reset Password</h2>
                            <span style={css_style}>Hello, { this.state.first_name } { this.state.last_name }. Set a new Password. </span><br/><br/>
                            <p id="mesg" className="text-danger"></p>
                            <form onSubmit={this.handleSubmit} novalidate>                
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Enter Password" name="password" id="password1" minlength="8" required/>
                                            <p className="new_password_error"></p>
                                        </div>
                                        <div className="form-group">
                                                    <input type="password" className="form-control" placeholder="Repeat Password" name="password2" id="password2" minlength="8" required/>
                                                    <p className="re_password_error"></p>
                                                </div>
                                        <button className="btn btn-info password-btn" id="password-btn" >Set Password</button>
                                    </form>                                 
                            </div>    
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
                    
        )
    }
}
