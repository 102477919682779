import { Component } from 'react'
import { Link} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import './cart.css'
import {API_HOST} from '../../settings.config.js'
import {Humanize, notify, ErrorHandling} from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;
var previous_data = []


export default class Cart extends Component {
    constructor(props){
        super(props)
        this.state = {
            all_cart_items:[],
            all_cart_item_ids:[],
            selected_items:[],
            cart_flag:true,
            selected_items_count:0,
            selected_cart_ids:[],
            total_amount:0,
            view:{showModal:false},
            lead_time_data:[],
            availability_quantity:true
        }
        this.restrictDecimal = this.restrictDecimal.bind(this)
    }

    componentWillMount() {  
        $('.cart-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token          
        axios.defaults.headers.get['Authorization'] = auth_token
        axios.get(API_HOST+'/cart/get/cart/items/',
        ).then(response => {
        if(response.status == 200 && response.data.success){                
            var cart_items = response.data.cart_items //JSON.parse(localStorage.getItem('cart_items'))
            var cart_item_ids = response.data.cart_item_ids //JSON.parse(localStorage.getItem('cart_item_ids'))
            var total_amount = 0
            var cart_ids_list = []
            var check_available_qty = []
            var promises = cart_items.map((item,index)=>{
                cart_ids_list.push(item.id)
                cart_items[index].lead_time = ''
                cart_items[index].selected_item = true
                cart_items[index].aviailable_qty = 0
                if(item.quantity){
                    cart_items[index].quantity = item.quantity
                    previous_data.push(item.quantity)
                }else{
                    cart_items[index].quantity = 1
                    previous_data.push(item.quantity)
                }
                
                cart_items[index].error= false
                cart_items[index].total_volume= 0
                cart_items[index].total_weight= 0
                cart_items[index].amount = cart_items[index].quantity * parseFloat(cart_items[index].price)
                total_amount += cart_items[index].amount
                cart_items[index].stock_id= '' //item.item[0].stock[0].id
                cart_items[index].warehouse_id = '' //item.item[0].stock[0].warehouse.id
                var this_value = parseFloat(cart_items[index].quantity)
                var item_id = item.item[0].item.id                             
                var stock_id = '' //item.item[0].stock[0].id
                var category = item.item[0].item.category
                var warehouse_id = item.warehouse?item.warehouse.id:''
                var check_qty = {}
                check_qty['item_id'] = item_id 
                check_qty['warehouse_id'] = item.warehouse?item.warehouse.id:''
                check_available_qty.push(check_qty)       
                /*var lead_time_json = {}
                lead_time_json[1] = {}
                lead_time_json[1][item_id] = {
                   // warehouse_id:warehouse_id,
                     quantity:this_value 
                }  */              
                var form_data = {
                    'item_id': item_id,
                    'stock_id': stock_id,
                    'warehouse_id': warehouse_id,
                    'quantity': this_value,
                    //'lead_time_items': JSON.stringify(lead_time_json),
                    'cart_id':item.id
                }
                //var form_data = 'item_id='+item_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)+'&csrfmiddlewaretoken='+csrf_token
                var form_data = 'item_id='+item_id+'&quantity='+this_value+'&warehouse_id='+warehouse_id+'&from_cart='+true+'&cart_id='+item.id
                axios.defaults.headers.post['Authorization'] =auth_token
                return axios.post(API_HOST+'/api/get/item/lead/time/', form_data)                    
                })
                localStorage.setItem('selected_cart_items', JSON.stringify(cart_ids_list))
                Promise.all(promises).then(results =>{                        
                        var check_flag=true                            
                    results.forEach((response,index)=>{
                        if(parseFloat(response.data.lead_time.quantity) > parseFloat(response.data.lead_time.available_qty)){
                            check_flag = false
                        }
                        cart_items[index].aviailable_qty = response.data.lead_time.available_qty
                        cart_items[index].lead_time = response.data.lead_time.lead_time
                        cart_items[index].warehouse = cart_items[index].warehouse?cart_items[index].warehouse:{'id':response.data.lead_time.warehouse_id,'name':response.data.lead_time.warehouse_name,}
                        cart_items[index].total_volume = response.data.total_item_and_box_volume
                        cart_items[index].total_weight = response.data.total_item_and_box_weight
                    })
                    this.setState({
                        all_cart_items: cart_items,
                        all_cart_item_ids:cart_item_ids,
                        availability_quantity:check_flag
                    })
                localStorage.setItem('cart_item_ids',JSON.stringify(cart_item_ids))
                //localStorage.setItem('cart_items',JSON.stringify(cart_items))
                })
                var price_promises  = cart_items.map((item,index)=>{                                    
                    axios.defaults.headers.get['Authorization'] =auth_token
                    return axios.get(API_HOST+'/api/get/item/price/?item_id='+item.item[0].item.id+'&cart_id='+item.id )       
                })
                Promise.all(price_promises).then(results =>{
                    $('.cart-card').find('.inline-loader').fadeOut('slow')
                    results.forEach((response,index)=>{
                        if(response.data.success){
                        cart_items[index].price = response.data.item_price
                        cart_items[index].amount = parseFloat(cart_items[index].price)*parseFloat(cart_items[index].quantity)
                    }                    
                    }) 

                    var total_amount = 0
                    cart_items.map((item)=>{
                    if(item.quantity){
                    total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                    }
                    })   
                    this.setState({
                        all_cart_items: cart_items,
                        total_amount:total_amount
                    })
                    //localStorage.setItem('cart_items',JSON.stringify(cart_items))              
                })
               /* var csrf_token = '{{ csrf_token }}' 
                var form_data = 'cart_items='+JSON.stringify(check_available_qty)+'&csrfmiddlewaretoken='+csrf_token
                axios.defaults.headers.post['Authorization'] =auth_token
                var avialable_promise =  axios.post('{% url "customer_portal:check_avialiable_qty" %}', form_data) 
                avialable_promise.then((response) => {
                    response.data.cart_items.forEach((response,index)=>{
                        cart_items[index].aviailable_qty = response.quantity
                        var check_flag=true
                        cart_items.map((item)=>{
                            if(item.quantity > item.aviailable_qty){
                                check_flag = false
                            }
                        })
                        this.setState({
                            all_cart_items: cart_items,
                            availability_quantity:check_flag
                        }) 

                    })
                });*/
            this.setState({
                all_cart_items: cart_items,
                all_cart_item_ids:cart_item_ids,
                selected_items:this.state.selected_items.concat(cart_item_ids),
                selected_items_count:cart_items.length,
                selected_cart_ids:cart_ids_list,
                total_amount:total_amount
            })
            localStorage.setItem('cart_item_ids',JSON.stringify(cart_item_ids))
            //localStorage.setItem('cart_items',JSON.stringify(cart_items))
            
        }
       
        }).catch(response =>{
            ErrorHandling(response)
        });

    }
    componentDidUpdate(){
        this.state.all_cart_items.map((item) =>{
            if(item.selected_item){
                $('tr[cart_id='+ item.id+']').find('.q-select').prop('checked',true)
            }else{
                $('tr[cart_id='+ item.id+']').find('.q-select').prop('checked',false)
            }
        })
        var select_all = true
        $('.q-select').each((index,item) =>{
            if (item.checked) {
            } else {
                select_all = false
            }
        })
        if (select_all) {
            $('.q-select-all').prop('checked', true)
        } else {
            $('.q-select-all').prop('checked', false)
        }
        if(this.props.location.page){
            if(this.props.location.page.load){
                this.props.location.page = {load:false}            
                $('.search_input').val('')
                $('.cart-card').find('.inline-loader').fadeIn()
                this.setState({
                    all_cart_items:[],
                    all_cart_item_ids:[],
                    selected_items:[],
                    cart_flag:true,
                    selected_items_count:0,
                    selected_cart_ids:[],
                    total_amount:0,
                    view:{showModal:false},
                    lead_time_data:[],
                    availability_quantity:true
                })
                this.componentWillMount()
            }
        }
    }

    checkAviailableQuantity = (event) =>{            
        var this_value = event.currentTarget.value
        if(!parseFloat(this_value) > 0){ 
            this_value = 1
            event.currentTarget.value = this_value
        }
        var val = Math.max((0, this_value)),
        max = parseInt(99999999);  
        this_value = isNaN(max) ? parseFloat(val.toString().replace(/[^0-9]+/g, "")) : Math.min(max, val)           
        var row_index = $(event.currentTarget).attr('row-index')
        var item_id = $(event.currentTarget).attr('item-id')
        var cart_id = $(event.currentTarget).attr('cart_id')
        var data={'cart_id':cart_id,'cart_row_index':row_index,'quantity':this_value}
        this.HandleQuantityChange(data)                   
        /*if(parseFloat(this_value) > 0 && item_id){
        $(".lds-ripple-parent").fadeIn();                    
        var stock_id = $(event.currentTarget).attr('stock-id')
        var warehouse_id = $(event.currentTarget).attr('warehouse_id')
        var category = $(event.currentTarget).attr('category')                      
        var lead_time_json = {}
        lead_time_json[1] = {}
        lead_time_json[1][item_id] = {
            warehouse_id:warehouse_id, quantity:parseFloat(this_value)
        }                   
        var form_data = {
            'item_id': item_id,
            'stock_id': stock_id,
            'warehouse_id': warehouse_id,
            'quantity': this_value,
            'lead_time_items': JSON.stringify(lead_time_json)
        }
        var csrf_token = '{{ csrf_token }}' 
        var form_data = 'item_id='+item_id+'&stock_id='+stock_id+'&warehouse_id='+warehouse_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)+'&csrfmiddlewaretoken='+csrf_token
        axios.defaults.headers.post['Authorization'] =auth_token
        axios.post('{% url "customer_portal:get_item_lead_time" %}', form_data)
            .then(response => {
            if(response.status == 200){
                var check_availability = false
                var cart_items = this.state.all_cart_items
                response.data.lead_time.map((item, index) =>{
                    if(response.data.lead_time[index].add_to_cart){
                        
                    }else{
                        check_availability = true
                    }
                    response.data.lead_time[index].selected_item = true
                    response.data.lead_time[index].item_number = cart_items[parseFloat(row_index)].item[0].item.item_number
                    response.data.lead_time[index].item_description = cart_items[parseFloat(row_index)].item[0].item.name
                    response.data.lead_time[index].price = cart_items[parseFloat(row_index)].price
                    response.data.lead_time[index].cart_row_index = row_index
                    response.data.lead_time[index].cart_id = cart_id
                })
                if(check_availability){
                    this.setState({
                        view: {showModal: check_availability},
                        lead_time_data:response.data.lead_time,
                    }) 
                }else{
                    response.data.lead_time[0].cart_row_index = row_index
                    response.data.lead_time[0].cart_id = cart_id
                    this.HandleQuantityChange(response.data.lead_time[0])
                }
                $(".lds-ripple-parent").fadeOut("slow");
                
                                
            }
            }).catch(response => {
            ReactDOM.render(<ErrorHandling Response={response} />,
            document.getElementById('error_handling'))
        });
        }else{
            
            $(".lds-ripple-parent").fadeOut("slow");
        }*/

    }

    HandleQuantityChange = (data) =>{
        var qty = data.quantity
        var row_index = data.cart_row_index
        previous_data[row_index] = qty
        var cart_id = data.cart_id
        var price = 0            
        var form_data = new FormData()
        // var csrf_token = '{{ csrf_token }}'
        // form_data.append('csrfmiddlewaretoken',csrf_token)
        form_data.append('cart_id', cart_id)
        form_data.append('quantity', qty)
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token
        $(".lds-ripple-parent").fadeIn()

        axios({
            method: 'post', 
            url: API_HOST+'/cart/update/cart/item/',
            data: form_data,
            headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then(response => {
        if(response.status == 200 && response.data.success){
            qty = response.data.quantity
            price = response.data.price
            this.state.all_cart_items[parseFloat(row_index)].quantity = qty
            this.state.all_cart_items[parseFloat(row_index)].amount = parseFloat(qty)*parseFloat(price)
            this.state.all_cart_items[parseFloat(row_index)].lead_time = response.data.lead_time
            this.state.all_cart_items[parseFloat(row_index)].total_volume = response.data.total_item_and_box_volume
            this.state.all_cart_items[parseFloat(row_index)].total_weight = response.data.total_item_and_box_weight
            this.state.all_cart_items[parseFloat(row_index)].aviailable_qty = response.data.available_qty
            localStorage.setItem('cart_items',JSON.stringify(this.state.all_cart_items))
            if(this.state.all_cart_items[parseFloat(row_index)].selected_item && !parseFloat(qty)){
                this.state.cart_flag=false                    
            }else if(this.state.all_cart_items[parseFloat(row_index)].selected_item && parseFloat(qty)){
                this.state.cart_flag=true
            }
            var total_amount = 0
            var check_avialble = true
            this.state.all_cart_items.map((item)=>{                    
                if(item.selected_item){
                    if(item.quantity){
                        total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                    }
                    if(parseFloat(item.quantity) <= parseFloat(item.aviailable_qty)){

                    }else{
                        check_avialble = false 
                    }
                } 
            })               
            this.setState({
                cart_flag:this.state.cart_flag,
                all_cart_items: this.state.all_cart_items,
                total_amount :total_amount,
                view: {showModal: false},
                availability_quantity:check_avialble
            })
            $(".lds-ripple-parent").fadeOut('slow')
        }
        }).catch(response => { 
            ErrorHandling(response)           
            $(".lds-ripple-parent").fadeOut('slow')
        });
     
     //this.HandleGetLeadTime(event)
        
    }
    SelectAllItems = (event) =>{
            var cart_flag=true
            var selected_items = []
            var selected_cart_ids = []           
            if (event.currentTarget.checked) {
                $('.q-select').each((index,item) =>{
                    item.checked = true
                    //if (this.state.selected_items.indexOf(parseFloat(item.value)) >= 0) {
                    //} else {
                        var cart_id = $(item).attr('cart_id')
                        selected_items.push(parseFloat(item.value))
                        selected_cart_ids.push(parseFloat(cart_id))
                        this.state.all_cart_items[index].selected_item = true
                    //}
                    if(item.checked && !parseFloat(this.state.all_cart_items[index].quantity)){
                        cart_flag = false
                    }
                })
            } else {
                $('.q-select').each((index,item) => {
                    item.checked = false
                    var cart_id = $(item).attr('cart_id')
                    //selected_items.splice(this.state.selected_items.indexOf(parseFloat(item.value)), 1)
                    //selected_cart_ids.splice(this.state.selected_cart_ids.indexOf(parseFloat(cart_id)), 1)
                    this.state.all_cart_items[index].selected_item = false
                })
                cart_flag = false
            }
            localStorage.setItem('selected_cart_items', JSON.stringify(this.state.selected_cart_ids))
            var check_avialble = true
            var total_amount = 0
            this.state.all_cart_items.map((item,index)=>{
            if(item.selected_item){
                if(item.quantity){
                    total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                }
                if(parseFloat(item.quantity) <= parseFloat(item.aviailable_qty)){

                }else{
                    check_avialble = false 
                }
            }
            })
            if(!cart_flag){
                check_avialble = false
            }
            this.setState({
                all_cart_items:this.state.all_cart_items,
                cart_flag:cart_flag,
                selected_cart_ids:selected_cart_ids,
                selected_items_count:selected_items.length,
                availability_quantity:check_avialble,
                selected_items:selected_items,
                total_amount:total_amount
            })
    }
    SelectItem = (event) => {
        var row_index = $(event.currentTarget).attr('row-index')
        var cart_id = $(event.currentTarget).attr('cart_id')
        if (event.currentTarget.checked) {
            event.currentTarget.checked = true
            //if (this.state.selected_items.indexOf(parseFloat(event.currentTarget.value)) >= 0) {
            //} else {
                this.state.selected_items.push(parseFloat(event.currentTarget.value))
                this.state.selected_cart_ids.push(parseFloat(cart_id))
                this.state.all_cart_items[row_index].selected_item = true
                
            //}
        } else {
            event.currentTarget.checked = false
            this.state.selected_items.splice(this.state.selected_items.indexOf(parseFloat(event.currentTarget.value)), 1)
            this.state.selected_cart_ids.splice(this.state.selected_cart_ids.indexOf(parseFloat(cart_id)), 1)
            this.state.all_cart_items[row_index].selected_item = false
        }            
        var select_all = true
        var cart_flag = true
        var cart_items = this.state.all_cart_items
        var all_unselected = true
        $('.q-select').each((index,item) =>{
            if (item.checked) {
                all_unselected = false
            } else {
                select_all = false
            }
            if(item.checked && !parseFloat(cart_items[index].quantity)){
                cart_flag = false
            }
        })
        if(all_unselected){
            cart_flag = false
        }
        
        if (select_all) {
            $('.q-select-all').prop('checked', true)
        } else {
            $('.q-select-all').prop('checked', false)
        }
        localStorage.setItem('selected_cart_items', JSON.stringify(this.state.selected_cart_ids))
        var check_avialble = true
        var total_amount = 0
        this.state.all_cart_items.map((item,index)=>{
            if(item.selected_item){
                if(item.quantity){
                    total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                }
                if(parseFloat(item.quantity) <= parseFloat(item.aviailable_qty)){

                }else{
                    check_avialble = false 
                }
            }

        })
        if(!cart_flag){
            check_avialble = false 
        }
        this.setState({
            all_cart_items:this.state.all_cart_items,
            cart_flag :cart_flag,
            selected_items:this.state.selected_items,
            selected_cart_ids:this.state.selected_cart_ids,
            selected_items_count:this.state.selected_items.length,
            availability_quantity:check_avialble,
            total_amount:total_amount
        })

    }
    componentDidMount(){
        $('.q-select-all').prop('checked', true)
        $('.q-select').each((index,item) =>{
            item.checked = true
        });
        
    }
    handleRowDel = (event) =>{
        event.preventDefault()
        if(window.confirm('Are you sure, You want to remove this Item? ')){
            $('.cart-loader').fadeIn()
            var index = event.currentTarget.getAttribute('row-index')
            var cart_id = this.state.all_cart_items[parseFloat(index)].id
            var item_id = this.state.all_cart_items[parseFloat(index)].item[0].item.id
            var item_number = this.state.all_cart_items[parseFloat(index)].item[0].item.item_number
            var cart_item_ids = JSON.parse(localStorage.getItem('cart_item_ids'))
            var cart_items = this.state.all_cart_items
            cart_item_ids.splice(cart_item_ids.indexOf(item_id.toString()),1)
            var delete_cart_ids = [cart_id]
            const auth_token = "Token "+localStorage.token
            axios.defaults.headers.delete['Authorization'] =auth_token
            axios.delete(API_HOST+'/cart/update/cart/item/?cart_ids='+ JSON.stringify(delete_cart_ids),
            ).then(response => {
            if(response.status == 200 && response.data.success){
                var item_count = 0
                cart_items.filter((item,index) =>{
                    if(parseFloat(item.id) == parseFloat(cart_id)){
                        cart_items.splice(index,1)
                    }
                    
                })
                cart_items.map((item,index) =>{
                    if(item.selected_item){
                        item_count += 1
                    }
                })
                var total_amount = 0
                this.state.all_cart_items.map((item)=>{
                    if(item.selected_item){
                        if(item.quantity){
                        total_amount += parseFloat(item.price)*parseFloat(item.quantity)
                        }
                    }
                })
                this.state.selected_items.splice(this.state.selected_items.indexOf(parseFloat(item_id)), 1)
                this.state.selected_cart_ids.splice(this.state.selected_cart_ids.indexOf(parseFloat(cart_id)), 1)
                localStorage.setItem('cart_item_ids',JSON.stringify(cart_item_ids))
                //localStorage.setItem('cart_items',JSON.stringify(cart_items))
                localStorage.setItem('selected_cart_items', JSON.stringify(this.state.selected_cart_ids))
                var cart_flag = true
                var all_unselected = true  
                var check_avialble = true
                cart_items.map((item,index)=>{
                if(item.selected_item){
                    all_unselected = false
                    if(parseFloat(item.quantity) <= parseFloat(item.aviailable_qty)){

                    }else{
                        check_avialble = false 
                    }
                } 

                })
                if(all_unselected){
                    cart_flag = false
                }
                if(!cart_flag){
                    check_avialble = false 
                }
                this.setState({
                    all_cart_items: cart_items,
                    all_cart_item_ids:JSON.parse(localStorage.getItem('cart_item_ids')),
                    selected_items:this.state.selected_items,
                    selected_cart_ids:this.state.selected_cart_ids,
                    selected_items_count:item_count,
                    total_amount:total_amount,
                    availability_quantity:check_avialble,
                    cart_flag:cart_flag
                    
                })
                previous_data.splice(index,1)
                notify(item_number +' removed from Cart !', 'success' )
                $('.cart-loader').fadeOut('slow')
                    
                }else{
                    notify('Something went wrong, Try again later.','error')  
                    $('.cart-loader').fadeOut('slow')
                }
                }).catch(response => {
                    ErrorHandling(response)                    
                    $('.cart-loader').fadeOut('slow')
                });                
        }
    }
    handleChange = (event) =>{
        var this_value = $(event.currentTarget).val()
        var row_index =  $(event.currentTarget).attr('row-index')            
        this.state.all_cart_items[parseFloat(row_index)].quantity = this_value
        this.setState({
            all_cart_items:this.state.all_cart_items
        })
    }
    
    HandleGetLeadTime = (event) => {
                var this_value = $(event.currentTarget).val()
                //var cart_flag = true
                var row_index =  $(event.currentTarget).attr('row-index')
                var item_id = $(event.currentTarget).attr('item-id')
                /*if(this.state.all_cart_items[parseFloat(row_index)].selected_item && !parseFloat(this_value)>0){
                    cart_flag=false
                }else if(!this.state.all_cart_items[parseFloat(row_index)].selected_item){
                    cart_flag=false
                }*/
                if(parseFloat(this_value) > 0 && item_id){                    
                var stock_id = $(event.currentTarget).attr('stock-id')
                var warehouse_id = $(event.currentTarget).attr('warehouse_id')
                var category = $(event.currentTarget).attr('category')                    
                var lead_time_json = {}
                lead_time_json[1] = {}
                lead_time_json[1][item_id] = {
                    warehouse_id:warehouse_id, quantity:parseFloat(this_value)
                }                   
                var form_data = {
                    'item_id': item_id,
                    'stock_id': stock_id,
                    'warehouse_id': warehouse_id,
                    'quantity': this_value,
                    'lead_time_items': JSON.stringify(lead_time_json)
                }
                // var csrf_token = '{{ csrf_token }}' 
                var form_data = 'item_id='+item_id+'&stock_id='+stock_id+'&warehouse_id='+warehouse_id+'&quantity='+this_value+'&lead_time_items='+JSON.stringify(lead_time_json)
                const auth_token = "Token "+localStorage.token
                axios.defaults.headers.post['Authorization'] =auth_token
                axios.post(API_HOST+'/api/get/item/lead/time/', form_data)
                  .then(response => {
                    if(response.status == 200){
                        this.state.all_cart_items[parseFloat(row_index)].quantity = this_value
                        this.state.all_cart_items[parseFloat(row_index)].lead_time = response.data.lead_time
                        this.state.all_cart_items[parseFloat(row_index)].total_volume = response.data.total_item_and_box_volume
                        this.state.all_cart_items[parseFloat(row_index)].total_weight = response.data.total_item_and_box_weight
                        localStorage.setItem('cart_items',JSON.stringify(this.state.all_cart_items))
                        this.setState({
                            all_cart_items:this.state.all_cart_items,
                            //cart_flag:cart_flag  
                        })
                        
                      }
                  }).catch(response => {
                    ErrorHandling(response)
                });
                }else{
                    if(item_id){
                        this.state.all_cart_items[parseFloat(row_index)].lead_time =''
                    this.setState({
                        all_cart_items:this.state.all_cart_items,
                        //cart_flag:cart_flag    
                    })
                }

                }

    }
    handleHideModal = (row_index) =>{
        if(parseFloat(row_index) >= 0){
        var pre_qty = previous_data[row_index]
        this.state.all_cart_items[parseFloat(row_index)].quantity = pre_qty
        this.setState({
            view: {showModal: false},
            all_cart_items:this.state.all_cart_items

        })
        }
    }
    formSubmit = (event) => {
        if(window.confirm('You can only reserve available stock. Would you like to proceed?')){
            $(event.currentTarget)[0].submit()
        }else{
            event.preventDefault()
        }
        
    }
    ProceedToOrder = (event) =>{
        event.preventDefault()
        localStorage.setItem('cart_item_ids',JSON.stringify(this.state.all_cart_item_ids))
        localStorage.setItem('cart_items',JSON.stringify(this.state.all_cart_items))
        var form_data = new FormData()
        // var csrf_token = '{{ csrf_token }}'
        // form_data.append('csrfmiddlewaretoken',csrf_token)
        form_data.append('cart_ids', JSON.stringify(this.state.selected_cart_ids))
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token
        axios({
            method: 'post', 
            url: API_HOST+'/create/order/?ref=cart',
            data: form_data,
            headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then((response) =>{
            if(response.status == 200 && response.success){                         
            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    restrictDecimal(e){
        if(e.key==='.'){e.preventDefault()}
    }
    
    render() {
        $('.card-block').removeClass('add-padding')
            var cart_item_list = this.state.all_cart_items.map((item,index)=>{
                return(
                <tr item-id={item.item[0].item.id} row-index={index} category={item.item[0].item.category} className="item-cart-tr" cart_id={item.id} key={item.id}>
                    <td className="item-checkbox">
                    <label className="q-checkbox" name="" value="">
                            <input type="checkbox" name="" className="q-select" value={item.item[0].item.id} onChange={this.SelectItem} item-id={item.item[0].item.id} row-index={index} cart_id={item.id} />
                            <span className="checkmark"></span>
                    </label>
                    </td> 
                    <td className="item-cart-name">
                        <div className=""><span className="item-number">{item.item[0].item.item_number}</span></div>
                        <div className="item-name"><span>{item.item[0].item.name}</span></div>
                        <div className="input-group item-quantity">
                            <input type="number" min="1" className="form-control quantity text-right" 
                            id={'cart_qty_'+index} placeholder="Quantity"  
                            stock-id={item.stock_id} warehouse_id={item.warehouse_id} 
                            value={item.quantity} item-id={item.item[0].item.id}
                            onChange={this.handleChange} row-index={index} 
                            category={item.item[0].item.category} 
                            onKeyDown = {this.restrictDecimal}
                            onBlur={this.checkAviailableQuantity} cart_id={item.id} 
                            />
                            <a href="void(0)" className="btn btn-icon btn-flat btn-rounded remove-item" title="Remove Item" row-index={index} onClick={this.handleRowDel} ><i className="fas fa-trash"></i></a>
                        </div>
                        {item.quantity > item.aviailable_qty?
                            <p className="available-qty">Available Quantity: {item.aviailable_qty}</p>
                            :''}
                    </td>
                    <td className="item-warehouse">{item.warehouse_details?
                        [<span warehouse_id={item.warehouse_details.warehouse_id} key={item.warehouse_details.warehouse_id}>{item.warehouse_details.warehouse_city?item.warehouse_details.warehouse_city+',':item.warehouse_details.warehouse_name+','}</span>,
                        <span key={item.id}> {item.warehouse_details.warehouse_state?item.warehouse_details.warehouse_state+'.':item.warehouse_details.warehouse_country +'.'}</span>]:'-'}
                    </td>                                   
                    <td className="cart-lead-time text-align-right-mobile"><span className="show-on-mobile">Lead Time : </span><span>{item.lead_time}</span></td>
                    <td className="text-right">
                                <span>{item.price?Humanize(item.price):'0.00'}</span> <span className="show-on-mobile currency">{localStorage.currency_symbol?localStorage.currency_symbol:'$'}</span>
                        </td>
                        <td className="text-right">
                                    <span className="show-on-mobile">= </span><span>{item.amount?Humanize(item.amount):'0.00'}</span> <span className="show-on-mobile currency">{localStorage.currency_symbol?localStorage.currency_symbol:'$'}</span>
                            </td>
                </tr>

                )
            })
            if(!this.state.view.showModal){
                $('#smart-lead-time-modal-dialog').modal('hide');
            }
        return (
            <div className="container-fluid cart-container">
                <div className="row">        
                    <div className="col-md-12 cart-card pdd-btm-20">
                        <div className="inline-loader" id="filter_loader"> 
                            <SubLoader/>
                        </div>                       
                        <div className="card">                                 
                            <div className="cart-loader">
                            </div>                 
                            <div className="card-block pdd-btm-40" id="table-overflow">
                            <div className="table-overflow" >    
                        {this.state.all_cart_items.length >0?<table className="table table-hover cart-mobile-view" >
                                <thead className="cart-table-header">
                                    <tr>
                                        <th width="2%">
                                            <label className="q-checkbox" name="" value="" >
                                                <input type="checkbox" className="q-select-all" name="" id="q-select-all" onChange={this.SelectAllItems}  />
                                                <span className="checkmark" ></span>
                                            </label>
                                        </th>
                                        <th className="width-30">Item</th> 
                                        <th className="width-20">Warehouse</th>                                       
                                        <th className="width-20">Lead Time</th>
                                        <th className=" text-right width-10">Price<span className="currency">( {localStorage.currency_symbol?localStorage.currency_symbol:'$'} )</span></th>
                                        <th className=" text-right width-20">Amount<span className="currency">( {localStorage.currency_symbol?localStorage.currency_symbol:'$'} )</span></th>
                                    </tr>                                    
                                    </thead>
                                    <thead className="show-on-mobile">
                                        <tr>
                                            <th width="2%">
                                                <label className="q-checkbox" name="" value="" >
                                                    <input type="checkbox" className="q-select-all" name="" id="q-select-all" onChange={this.SelectAllItems}  />
                                                    <span className="checkmark add-top" ></span>
                                                    Select All
                                                </label>
                                            </th>                                            
                                        </tr>
                                    </thead>
                                <tbody>{cart_item_list}</tbody>
                                <tfoot>
                                    <tr>                                            
                                        <td colSpan ="3">
                                                {this.state.selected_items_count?
                                                <span className="items_count">{this.state.selected_items_count} item(s) selected. </span>
                                                :null}</td>
                                        <td colSpan ="3" className="text-right text-dark font-size-16">
                                            <b>Total Amount : <span className="currency">( {localStorage.currency_symbol?localStorage.currency_symbol:'$'} )</span> <span className="amount">{this.state.total_amount?Humanize(this.state.total_amount):'0.00'}</span></b>
                                        </td>

                                    </tr>                                    
                                    <tr className={this.state.selected_items_count?'hide-on-mobile':'btn-border hide-on-mobile'}>
                                        <td colSpan ="6" className="cart-btns">
                                                    <Link to={"/items"} className="btn btn-info add-items btn-animation" role="button">Add Items</Link>
                                                    <Link to={this.state.cart_flag?{
                                                        pathname:"/create/order/",
                                                        state:{params:{selected_cart_ids:JSON.stringify(this.state.selected_cart_ids)}}
                                                        }:'#'}
                                                        className={this.state.cart_flag?'btn proceed-order btn-animation':'btn proceed-order-disabled btn-animation'}
                                                    >Proceed to Order</Link>
                                                {/* <form  action={} method='post' >
                                                    <input type="hidden" name="selected_cart_ids" value={ JSON.stringify(this.state.selected_cart_ids)} />                                        
                                                    <button  className={this.state.cart_flag?'btn proceed-order btn-animation':'btn proceed-order-disabled btn-animation'}>Proceed to Order</button>
                                                </form> */}
                                                <Link to={this.state.cart_flag?this.state.availability_quantity?{
                                                        pathname:"/create/order/",
                                                        state:{params:{selected_cart_ids:JSON.stringify(this.state.selected_cart_ids),reserve:true}}
                                                        }:'#':'#'}
                                                        className={this.state.cart_flag?this.state.availability_quantity?'btn btn-warning reserve-order btn-animation':'btn proceed-order-disabled btn-animation':'btn proceed-order-disabled btn-animation'}
                                                        title={this.state.availability_quantity?'':'You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.'}
                                                    >Proceed to Reserve</Link>
                                                {/* <form  action={this.state.cart_flag?this.state.availability_quantity?"/create/order/":'void(0) ':'void(0) '} method='post' >
                                                    <input type="hidden" name="selected_cart_ids" value={ JSON.stringify(this.state.selected_cart_ids)} />                                        
                                                    <input type="hidden" name="reserve" value={true} />
                                                    <button  className={this.state.cart_flag?this.state.availability_quantity?'btn btn-warning reserve-order btn-animation':'btn proceed-order-disabled btn-animation':'btn proceed-order-disabled btn-animation'}
                                                    title={this.state.availability_quantity?'':'You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.'} >Proceed to Reserve</button>
                                                </form> */}
                                        </td>                                                                               
                                    </tr>
                                    <tr className={this.state.selected_items_count?'show-on-mobile':'btn-border show-on-mobile'}>
                                        <td colSpan ="2" className="inline-block" >
                                            <Link to={"/items"} className="btn btn-info add-items btn-animation min-width-btn" role="button">Add Items</Link>
                                            {/* <a href="/items/" className="btn btn-info add-items btn-animation min-width-btn" role="button">Add Items</a>  */}
                                        </td>
                                        <td colSpan ="2" className="inline-block">
                                        <Link to={this.state.cart_flag?{
                                                pathname:"/create/order/",
                                                state:{params:{selected_cart_ids:JSON.stringify(this.state.selected_cart_ids)}}
                                                }:'#'}
                                                className={this.state.cart_flag?'btn proceed-order btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn'}
                                        >Proceed to Order</Link>
                                            {/* <form  action={this.state.cart_flag?"/create/order/":'void(0) '} method='post' >
                                                <input type="hidden" name="selected_cart_ids" value={ JSON.stringify(this.state.selected_cart_ids)} />                                        
                                                <button  className={this.state.cart_flag?'btn proceed-order btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn'}>Proceed to Order</button>
                                            </form> */}
                                        </td>
                                        <td colSpan ="2" className="inline-block">
                                        <Link to={this.state.cart_flag?this.state.availability_quantity?{
                                            pathname:"/create/order/",
                                            state:{params:{selected_cart_ids:JSON.stringify(this.state.selected_cart_ids),reserve:true}}
                                            }:'#':'#'}
                                            className={this.state.cart_flag?this.state.availability_quantity?'btn btn-warning reserve-order btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn'}
                                            title={this.state.availability_quantity?'':'You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.'}
                                        >Proceed to Reserve</Link>
                                            {/* <form  action={this.state.cart_flag?this.state.availability_quantity?"/create/order/":'void(0) ':'void(0) '} method='post' >
                                                <input type="hidden" name="selected_cart_ids" value={ JSON.stringify(this.state.selected_cart_ids)} />                                        
                                                <input type="hidden" name="reserve" value={true} />
                                                <button  className={this.state.cart_flag?this.state.availability_quantity?'btn btn-warning reserve-order btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn':'btn proceed-order-disabled btn-animation min-width-btn'}
                                                title={this.state.availability_quantity?'':'You can only reserve available stock. One or More item(s) have quantities greater than the available stock. Adjust the required quantity to available to proceed to reserve.'} >Proceed to Reserve</button>
                                            </form>  */}
                                        </td>                                        
                                    </tr>

                                </tfoot>
                            </table>:
                            <table className="empty-table">
                                <tbody>
                                    <tr>
                                        <td colSpan ="6" className="empty-td"><span>Your Cart is empty.</span></td>
                                    </tr>
                                    <tr>
                                        <td colSpan ="6" className="text-center">
                                            <Link to="/items" className="btn btn-info  btn-animation" role="button">Add Items</Link>
                                        </td>
                                    </tr>
                            </tbody></table>}
                                            {/* {this.state.view.showModal ? null<SmartLeadTimeModal handleHideModal={this.handleHideModal} items={this.state.lead_time_data} symbol={localStorage.currency_symbol}  AddToCartHandler={this.HandleQuantityChange} from={false}/>
                                            : <div ref={this.modelHide}></div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


