import  { Component } from 'react'
import $ from 'jquery'
import {API_HOST} from '../../settings.config.js'
import SubLoader from '../Loader/SubLoader'
import {notify, ErrorHandling} from '../../js/CommonFunctions'
const axios = require('axios').default;


class ContactModal extends Component{
    componentDidMount(){
        $('#customer-contact-create-dialog').modal('show');
        $('#customer-contact-create-dialog').on('hidden.bs.modal', this.props.handleHideModal);
    }
    
    render(){        
        return(
            <div className="modal fade" id="customer-contact-create-dialog" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.contactData.id.value?'Update Contact':'Create Contact'}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body customer-contact-create-model-body">
                                <form className="customer-contact-create-model-body-form" id="contact_form" onSubmit={this.props.handleSubmit}>
                                    <input type="hidden" name="id" value={this.props.contactData.id.value} id="contact_id" />
                                    <div className="row">                                                
                                            <div className="col-md-6 no-padding-left">
                                                    <label>First Name</label>
                                                    <input type="text" name="name" id="id_name" placeholder="First Name" className="form-control text-capitalize" maxlength="150" required value={this.props.contactData.name.value} onChange={this.props.onchange} />
                                                    <p className="name_error"></p>
                                            </div>
                                            <div className="col-md-6 no-padding-left">
                                                <label>Last Name</label>
                                                <input type="text" name="last_name" id="id_last_name" placeholder="Last Name" className="form-control text-capitalize" maxlength="150" required value={this.props.contactData.last_name.value} onChange={this.props.onchange} />
                                                <p className="last_name_error"></p>
                                        </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Position</label>
                                                        <input type="text" name="position" id="id_position" placeholder="Position" className="form-control text-capitalize" maxlength="150" required value={this.props.contactData.position.value} onChange={this.props.onchange} />
                                                        <p className="position_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Email</label>
                                                        <input type="email" name="email" id="id_email" placeholder="Email" className="form-control" maxlength="75" 
                                                        required value={this.props.contactData.email.value} 
                                                        onChange={this.props.onchange} disabled={this.props.contactData.is_user.value} />
                                                        <p className="email_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Phone Number</label>
                                                        <input type="number" name="phone_number" id="id_phone_number" placeholder="Phone Number" className="form-control" maxlength="75" required value={this.props.contactData.phone_number.value} onChange={this.props.onchange} />
                                                        <p className="phone_number_error"></p>
                                                    </div>                                                       
                                               
                                            </div>
                                            <div className="modal-footer">
                                                    <button className="btn btn-primary create-contact-submit btn-animation"
                                                            type="submit">
                                                            {this.props.contactData.id.value?'Update':'Create'}
                                                    </button>
                                                </div>
                                </form>
                            </div>
                            
            
                        </div>
                    </div>
                </div>
        )
    }
}
export default class Contacts extends Component {
    constructor(props){
        super(props)
        this.contacts = []
        this.state = {
            contacts: [],
            view:{showModal:false},
            contact:{},
            card_index : -1,
            formControls: {
                id:{value:''},
                name: {value: ''}, position: {value: ''}, last_name: {value: ''},
                email: {value: '' }, phone_number: {value: '' },
                is_user:{value:false}                   
            }                
           } 
           
    }
    componentDidMount(){
        //ReactDOM.render(<PageLoader/>,document.getElementById('contact_loader'))
        $('.contact-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/cp/user/contacts/',
        ).then(response => {
          if(response.status == 200){ 
              if(response.data.contacts){
                  var contacts_data = response.data.contacts
              }else{
                var contacts_data = []
              }
              this.contacts = contacts_data
            this.setState(()=>{
                return {
                    contacts: this.contacts,
                }
             })   
             $(".lds-ripple-parent").fadeOut("slow");   
             $('.contact-card').find('.inline-loader').fadeOut('slow')          
            }
        }).catch(response => {
            ErrorHandling(response)
        });
            
    }
    handleHideModal = () =>{
        this.setState({view: {showModal: false}})
    }
    handleShowModal = () =>{
        this.setState({
            view: {showModal: true},
            contact:{},
            card_index:-1,
            formControls: {
                id:{value:''},
                name: {value: ''}, position: {value: ''}, last_name: {value: ''},
                email: {value: '' }, phone_number: {value: '' },
                is_user:{value:false}                   
            } 
          })
    }

    handleChange = (event) => {
    const name = event.target.name;
      const value = event.target.value;        
      this.setState({
        formControls: {
            ...this.state.formControls,
            [name]: {
            ...this.state.formControls[name],
            value
          }
        }
      });
      }
handleEdit = (event) =>{
        event.preventDefault()
      const id = event.currentTarget.id
      const index = event.currentTarget.getAttribute('data-index')
      const auth_token = "Token "+localStorage.token
      $(".lds-ripple-parent-1").fadeIn()
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(API_HOST+'/cp/user/contacts/'+id+'/',
      ).then(response => {
        if(response.status == 200){ 
            if(response.data){
                var contact_data = response.data.contacts
                this.state.card_index = parseFloat(index)
            }else{
              var contact_data = {}
            }
          this.setState(()=>{
              return {
                  contact: contact_data,
                  card_index:this.state.card_index,                      
                  formControls: {
                    id:{value:contact_data.id}, last_name:{value:contact_data.last_name},
                    position: {value: contact_data.position}, name: {value:contact_data.name},
                    email: {value:contact_data.email }, phone_number: {value:contact_data.phone_number},
                    is_user:{value:contact_data.is_user}                
                },
                  view: {showModal: true}

              }
           })

          }
          $(".lds-ripple-parent-1").fadeOut('slow')
      }).catch(response => {
        ErrorHandling(response)        
        $(".lds-ripple-parent-1").fadeOut('slow')
    });    

    }        
    handleSubmit = (event) =>{
        event.preventDefault()
        function serialize(form) {
            var inputs = form.elements;
            var array = [];
            for(let i=0; i < inputs.length; i++) {
                var inputNameValue = inputs[i].name + '=' + inputs[i].value;
                array.push(inputNameValue);
            }
            
            return array.join('&');
          }
            var form = document.querySelector('#contact_form')
            var form_data = serialize(form)
            const auth_token = "Token "+localStorage.token
            var contact_id =  document.getElementById('contact_id').value
        if(contact_id){
            var url_type = API_HOST+'/cp/user/contacts/'+contact_id+'/'
            var method_type = 'patch'
            axios.defaults.headers.patch['Authorization'] =auth_token
            
        }else{
            var url_type = API_HOST+'/cp/user/contacts/'
            axios.defaults.headers.post['Authorization'] =auth_token
            var method_type = 'post'
        }
        $(".lds-ripple-parent-1").fadeIn()
        axios({
            method: method_type, 
            url: url_type,
            data: form_data,
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded",
            }
          }).then(response => {
          if(response.status == 200 && response.data.success){ 
              if(response.data.contacts){
                  var contact_data = response.data.contacts
                  if(this.state.card_index >= 0){
                      this.contacts[this.state.card_index] = response.data.contacts
                  }else{
                    this.contacts.push(response.data.contacts)
                }
              }else{
                var contact_data = []
              }
            this.setState(()=>{
                return {
                    view: {showModal: false},
                    contacts:this.contacts,
                    card_index:-1
                }
             })  
             $('#customer-contact-create-dialog').modal('hide');
             notify('Contact saved successfully.','success')
            }else{
                if(response.data.errors[0].email){
                    notify(response.data.errors[0].email,'error') 
                }
            }
            $(".lds-ripple-parent-1").fadeOut('slow')
        }).catch(response => {
            ErrorHandling(response)            
            $(".lds-ripple-parent-1").fadeOut('slow')
        });  

    }
    handleDelete = (event) =>{
        if(window.confirm('Are you sure, You want to remove this Contact? ')){
        event.preventDefault()
        const id = event.currentTarget.id
        const index = event.currentTarget.getAttribute('data-index')
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.delete['Authorization'] =auth_token
        axios.delete(API_HOST+'/cp/user/contacts/'+id+'/',
        ).then(response => {
          if(response.status == 200 && response.data.success){ 
            this.contacts.splice(parseFloat(index),1)             
            this.setState(()=>{
                return {
                    contact: this.contacts
                }
             })
             notify('Contact Deleted successfully.','success')
            }else{
                    notify('Something went wrong please try again','error') 
                }
        }).catch(response => {
            ErrorHandling(response)
        });
        } 

    }
    render() {
        $('.contact-card').find('.card-block').removeClass('padding-60')
        const contacts_data = this.state.contacts.map((contact,indx) =>{
            return <div className="col-md-3" key={indx}>
                    <div className="card">                          
                        <div className="card-body">
                                <div className="name" title="Name">{ contact.name } { contact.last_name }</div>
                                <div className="position" title="Position">{ contact.position}</div>
                                <div className="email" title="Email">{ contact.email }</div>
                                <div className="phone_number" title="Phone Number">{ contact.phone_number }</div>
                        </div> 
                        <div className="card-footer border top" > 
                                <ul className="list-unstyled list-inline pull-right">
                                    <li className="list-inline-item">
                                        <a href="#!" className="btn btn-icon btn-flat btn-rounded profile-btn" id={contact.id}  data-index={indx}  onClick={this.handleEdit} title="Update Contact">
                                            <i className="ti-pencil"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item d-none">
                                        <a href="#!" className="btn btn-icon btn-flat btn-rounded profile-btn" id={contact.id}  data-index={indx}   title="Delete">
                                            <i className="ti-trash"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>                                                      
                    </div>
            </div>

        })
        if(!this.state.view.showModal){
            $('#customer-contact-create-dialog').modal('hide');
        }    
        return (
            <div className="card contact-card" id="ask-3">
                <div className="inline-loader" id="contact_loader">
                    <SubLoader/>
                </div>
                <div className="card-heading border bottom">
                    <h4 className="card-title">Contacts</h4>
                </div>
                <div className="card-block padding-60" id="contacts-block"> 
                <div className="row">
                    { this.state.contacts.length > 0?
                        contacts_data :<h3 className="text-center no-data"> You have no Contacts.</h3>
                    }
                    <div className="col-md-3">
                            <div className="card">                          
                                <div className="card-body custom-contact-card-body" onClick={this.handleShowModal}>
                                        <div className="text-center font-size-30"><i className="ei-addthis"></i></div>
                                        <h2 className="text-center">Add Contact</h2>
                                </div>                                                                                    
                            </div>
                    </div>
                    {this.state.view.showModal ? <ContactModal handleHideModal={this.handleHideModal} handleSubmit={this.handleSubmit} contactData={this.state.formControls} onchange={this.handleChange} />
                    : <div ref={this.modelHide}></div>}
                </div>      
                </div>
            </div>
        )
    }
}

