import { Component } from 'react'
import { Link } from "react-router-dom";
import {API_HOST} from '../../settings.config'
import SSILogo from '../../styles/images/logo/ssi-logo.png'
import '../../styles/custom/sign-in.css'
const axios = require('axios').default;                     

export default class Activation extends Component {
    constructor() {
        super();
        this.state = {
            resp_message: '',
            after_success:false,
            id:'',
            email:'',
            new_password:'',
            repeat_password:'',
            isError:{
                new_password:'',
                repeat_password:''
            }
        };
    }
    componentDidMount(){
        var query_parm= {};
        if(window.location.search.length!==0)
            window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value){
                key=decodeURIComponent(key);
                if(typeof query_parm[key]==="undefined") {query_parm[key]= decodeURIComponent(value);}
                else {query_parm[key]= [].concat(query_parm[key], decodeURIComponent(value));}
            });
        var userid=query_parm.id
        var email = query_parm.email
        axios.get(API_HOST+'/cp/user/account/activation/',{
            params: {
              'id': userid,'email':email
            }
          }
        ).then(response => {
          if(response.status == 200){ 
            var success = response.data.success
            if(success){
                var message = 'success'
            }else{
                var message = response.data.message
            }
              
            this.setState(()=>{
                return {
                    resp_message: message,
                    id:userid,
                    email:email        
                }
             })            
          }
  }).catch(function (response) {
    });
  
}

formValChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };
    switch (name) {        
        case "new_password":
            isError.new_password =
                value.length < 8 ? "Atleast 8 characters required" : "";
            break;
        case "repeat_password":
            isError.repeat_password =
                value.length < 8 ? "Atleast 8 characters required" : "";
            break;
        default:
            break;
    }

    this.setState({
        isError,
        [name]: value
    })
};

    handleSubmit = (event) => {
        event.preventDefault();
        // const password1 =  document.getElementById("password1").value
        // const password2 =  document.getElementById("password2").value
        let is_valid_form = true
        let isError = { ...this.state.isError };        
        if (this.state.new_password.length < 8){
            isError.new_password = "Atleast 8 characters required"
            is_valid_form = false;
        }else if (this.state.repeat_password.length < 8){
            isError.repeat_password = "Atleast 8 characters required"
            is_valid_form = false;
        }
        if(is_valid_form){
            const email = this.state.email
            const id = this.state.id
            if(this.state.new_password == this.state.repeat_password ){    
                const form_data = 'password1='+this.state.new_password+'&password2='+this.state.repeat_password+'&email='+email+'&id='+id
                axios({
                method: 'post', 
                url: API_HOST+'/cp/user/account/activation/',
                data:form_data,
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                }
                }).then(response => {
                    if(response.status == 200){
                        if(response.data.success){
                        this.onChangeHandler('success')
                        }else{                        
                            document.getElementById("mesg").appendChild(document.createTextNode(response.data.message));
                            setTimeout(() => {
                                document.getElementById("mesg").innerText = '';
                            }, 10000); 
                        } 
                    }
                })
                .catch(function (response) {
                
                })
            }else{
                var mess = "New Password and Repeat Password doesn't match"
                document.getElementById("mesg").appendChild(document.createTextNode(mess));
                setTimeout(() => {
                    document.getElementById("mesg").innerText = '';
                }, 10000); 

            }
        }else{
            this.setState({
                isError
            })
        }
    }
  

    onChangeHandler = (activated) => {
        if(activated == 'success' ){
            this.setState({
                resp_message:'success',
                after_success:true
            })
        }

    }       

    render() {
        var css_style = {'color': "#8a6d3b"}
        const { isError } = this.state;
        return (
            <div className="authentication login-container">
            <div className="sign-in">
                <div className="row no-mrg-horizon">
                    <div className="col-md-8 no-pdd-horizon d-none d-md-block">
                        <div className="full-height bg video-container" >
                            <video autoPlay="autoplay" loop="loop" muted="muted" src="https://www.ssiaeration.com/wp-content/themes/ssi/images/front-page.mp4" poster=""></video>
                            <div className="img-caption">
                                <h1 className="caption-title">SMART IDEAS FOR WATER</h1>
                                <p className="caption-text">SSI Aeration fine bubble diffuser systems and products are installed on five continents at thousands of job sites. Millions of our diffusers are hard at work in wastewater treatments worldwide. SSI has received multiple US and International patents for inventions and industry improvements.</p>
                            </div>    
                        </div>
                    </div>
                <div className="col-md-4 no-pdd-horizon">                                
                    <div className="full-height bg-white height-100">
                            <div className="signin-header">
                                    <img className="img-responsive sign-in-ssi-logo inline-block" src={SSILogo} />
                                </div>
                        <div className="vertical-align  pdd-horizon-70 pdd-vertical-35">
                            <div  id="cp-login-form">
                            { this.state.resp_message == 'success'?
                                <div className="pdd-horizon-15" >
                                {this.state.after_success?
                                    <p className="msg_success">Hey your password is set, You may <Link to="/login">Login</Link> now</p>
                                    :
                                    <>
                                    <h3 className="text-center">Set Password</h3> 
                                    <span style={css_style}>Account Created for { this.state.email }</span><br/><br/>
                                    <p id="mesg" className="text-danger"></p>
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <div className="form-group">
                                            <input 
                                                type="password" 
                                                className={isError.new_password.length > 0 ? "is-invalid form-control" : "form-control"} 
                                                placeholder="Enter Password" 
                                                name="new_password" 
                                                id="password1" 
                                                minLength="8" 
                                                onChange={this.formValChange}
                                            />
                                            {isError.new_password.length > 0 && (
                                            <span className="invalid-feedback" style={{display:"block"}}>{isError.new_password}</span>
                                        )}
                                        </div>
                                        <div className="form-group">
                                            <input 
                                                type="password" 
                                                className={isError.repeat_password.length > 0 ? "is-invalid form-control" : "form-control"}
                                                placeholder="Repeat Password" 
                                                name="repeat_password" 
                                                id="password2" 
                                                minLength="8"
                                                onChange={this.formValChange} 
                                            />
                                            {isError.repeat_password.length > 0 && (
                                                <span className="invalid-feedback" style={{display:"block"}}>{isError.repeat_password}</span>
                                            )}
                                        </div>
                                        <button className="btn btn-info password-btn" id="password-btn" >Activate Account</button>
                                    </form>  
                                    </>
                                }                              
                                </div> :<p className="text-danger">{this.state.resp_message}</p>
                            }  
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
                    
        )
    }
}
