import { Component } from 'react'

export default class NavProfileName extends Component {
    render(){
        var first_char = {"color":"palevioletred"}
        var last_char = {"color":"steelblue"}
        var first_name = ""
        var last_name = ""
        if(localStorage.getItem('first_name') != 'null'){
            var first_name = localStorage.getItem('first_name')
        }
        if(localStorage.getItem('last_name') != 'null' || localStorage.getItem('last_name') != 'None'){var last_name = localStorage.getItem('last_name')} 
        return(
            <div className="profile-pic" title={first_name+' '+last_name}>
                    <span style={first_char} className="first-name">{ localStorage.getItem('first_name') != 'null'?localStorage.getItem('first_name').charAt(0).toLocaleUpperCase():'' }</span>
                    <span style={last_char} className="last-name">{ localStorage.getItem('last_name') != 'null' || localStorage.getItem('last_name') != 'None'?localStorage.getItem('last_name').charAt(0).toLocaleUpperCase():'' }</span>
                </div>
        )
    }
}
