import { Component } from 'react'
import {Link} from 'react-router-dom'
import ReactDOM from 'react-dom'
import moment from 'moment'
import {API_HOST} from '../../settings.config.js'
import notificationScript from './notification.woker.js'
// const axios = require('axios').default;

export default class NotificationCount extends Component{
    constructor(){
        super()
        this.state = {
            notifications:[],
            count:0
        }
    }
    componentDidMount(){
    //    const auth_token = "Token "+localStorage.token
    //     axios.defaults.headers.get['Authorization'] =auth_token
    //     axios.get(API_HOST+'/notifications/api/notifications/'
    //     ).then(response => {
    //         if(response.status == 200 && response.data.success){ 
    //             this.setState(()=>{
    //                 return {
    //                     notifications: response.data.notifications,
    //                     count:response.data.unread_count
    //                 }
    //              })  
    //         }
    //     }).catch(function (response) {
    //         if(response.response.status == 404){                        
    //             ReactDOM.render(<PageNotFound />,
    //                 document.getElementById('body'));
    //         }
    //         var error_response = response.response
    //     if(error_response){
    //         var error_msg = error_response.data.detail
    //         if(error_msg == 'Invalid token.'){
    //             window.location.href='/login'
    //         }
    //     }
    // }) 
    this.worker = new Worker(notificationScript);
    this.worker.onmessage = (e) =>{
        var response = e.data
        if(response.success){ 
            this.setState(()=>{
                return {
                    notifications: response.notifications,
                    count:response.unread_count
                }
             })  
        }
    }    
    var url = API_HOST+'/notifications/api/notifications/'
    this.worker.postMessage({'token':localStorage.token,'url':url});
    }
    componentDidUpdate(){
        if(this.state.count){
            var notification_count = <span className="counter" >{this.state.count}</span>
            ReactDOM.render(notification_count, document.getElementById('notification_counter'));
        }
    }
    
    render(){         
        var notification_link = ''
        if(this.state.count){            
            notification_link = <li className="notice-footer" key={'link'+1}>
                <span>
                    <Link to='/notifications' className="text-gray">See all</Link>
                </span>
            </li>
        }
          var notification_limit = this.state.notifications.slice(0,5).map((notification,indx)=>{
            var send_name = notification.sender_name.charAt(0).toLocaleUpperCase()
            //var title = window.HTMLReactParser(notification.title)
            return(
                <li key={indx} className={notification.status == 'Unread'?"notification-unread":"notification-read"}>
                        <Link to="/notifications" key={indx}>
                            <span className="thumb-img bg-primary" >
                                { notification.announcement?
                                    <span className="text-white" title={notification.sender_name}><i className="fas fa-bullhorn"></i></span>
                                    :<span className="text-white" title={notification.sender_name}>{send_name}</span>}
                            </span>
                            <div className="info">
                                <span className="title">
                                <span className="text-gray" dangerouslySetInnerHTML={{ __html: notification.title }} />
                                </span>
                                <span className="sub-title">{notification.created_date}</span>
                            </div>
                        </Link>
                    </li>
            )

        })
        if(notification_limit.length == 0){
            notification_limit = <li className="no-notification">All caught up!</li>
        }
        return(
            [<li className="notice-header" key={'noti'+1}>
                    <i className="fas fa-bell pdd-right-10"></i>
                    <span>Notifications</span>
                </li>,
                <li key={'noti'+2}> 
                    <ul className="list-info overflow-y-auto relative scrollable">
                            {notification_limit} 
                    </ul>
                </li>,
                notification_link
            ]
        )
    }
}
