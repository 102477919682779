import { Component } from 'react'
import {Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import moment from 'moment'
import {Humanize, ErrorHandling} from '../../js/CommonFunctions'
import '../../styles/custom/pagination.css'
import './orderlist.css'
const axios = require('axios').default;

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number    
}
const defaultProps = {
    initialPage: 1
}

class Pagination extends Component {
    constructor(props) {
    super(props);
    this.state = { pager: {} };
    }

    componentWillMount() {
    if (this.props.items && this.props.items.length) {
        this.setPage(this.props.initialPage);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    if (this.props.items !== prevProps.items) {
        this.setPage(this.props.initialPage);
    }
    }

    setPage(page) {
        var items = this.props.items;
        var pager = this.state.pager;
        var quotesCount = this.props.quotescount
        /*
        if (page < 1 || page > pager.totalPages) {
            return;
        }*/

        pager = this.getPager(quotesCount, page);
        var pageOfItems = items;
        this.setState({ pager: pager });
        this.props.onChangePage(pageOfItems);
    }
    getCurrentPageItems(page){
        var payment_status = $('#payment_status').val()
        var package_status = $('#packaging_status').val()
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()
        $('.order-card').find('.inline-loader').fadeIn() 
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/orders/?field=status&field=document_number&field=id&field=date&field=ssi_contact&field=contact_name&field=ref_no&field=total_amount&field=currency&field=exchange_rate&field=local_exchange_rate&ordering=-date&field=customer_reference_number&field=cp_status_choices&field=freight_charges&page='+page+'&page_size='+this.props.page_size+'&payment_status='+payment_status+'&packaging_status='+package_status+'&order_status='+order_status+'&order_no='+order_no,
            ).then(response => {
            if(response.status == 200){  
                var json_data = response.data.orders
                var items = json_data;
                var pager = this.state.pager;
                if (page < 1 || page > pager.totalPages) {
                    return;
                }
                pager = this.getPager(response.data.orders_count, page);
                var pageOfItems = items;
                this.setState({ pager: pager });
                this.props.onChangePage(pageOfItems);
                /*this.setState(()=>{
                    return {
                        pageOfItems: response.data.quotations
                    }
                }) */
                
            }
            $('.order-card').find('.inline-loader').fadeOut("slow");
            
            }).catch(response => { 
                ErrorHandling(response)               
                $('.order-card').find('.inline-loader').fadeOut("slow");
            });
    }

getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || this.props.page_size;
    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage;
    if (totalPages <= this.props.page_size) {
    startPage = 1;
    endPage = totalPages;
    } else {
    if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
    } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
    }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
    };
}

render() {
    var pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1 ) {
       // don't display pager if there is only 1 page
           if (pager.totalItems == 0){
               return null;
           }
       return <ul className="show-pages pull-left"><li>Showing  {pager.endIndex+1}  of {pager.totalItems} orders</li></ul>
       }
   
       return (
           <div>
            { (pager.startIndex+1 === pager.endIndex+1 ) ?
               (<ul className=" show-pages pull-left">
                   <li> Showing  {pager.endIndex+1}  of {pager.totalItems} orders </li>
               </ul>):
               (<ul className=" show-pages pull-left">
                   <li>Showing  {pager.startIndex+1} to {pager.endIndex+1}  of {pager.totalItems} orders</li>
               </ul>)
           }
            <ul className="pagination">
                {pager.currentPage === 1?null:<li className="">
                    <a onClick={() => this.getCurrentPageItems(1)}>First</a>
                </li>}
                {pager.currentPage === 1 ?null:<li className= ''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage - 1)}>Previous</a>
                </li>}
                {pager.pages.map((page, index) =>
                    <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a onClick={() => this.getCurrentPageItems(page)}>{page}</a>
                    </li>
                )}
                {pager.currentPage === pager.totalPages ?null:<li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.currentPage + 1)}>Next</a>
                </li>}
                {pager.currentPage === pager.totalPages?null:
                <li className=''>
                    <a onClick={() => this.getCurrentPageItems(pager.totalPages)}>Last</a>
                </li>}
            </ul>
            </div>
            );
        }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;


class OrderFilters extends Component{    
    constructor(props){
        super(props)
        this.state={
            filters:{},
            show:false
        }
    }
    componentDidUpdate(){
        if(this.state.show){
            $('#order-filters').find('select').selectpicker('refresh')
            $('.bootstrap-select > button.dropdown-toggle').addClass('custom-dropdown-toggle')   
            if(this.props.dropdown_check){
            // if('{{ request.GET.payment_status }}'){
            //     if($('#payment_status').val()){
            //         $('#payment_status').selectpicker('refersh')
            //     }else{
            //         $('#payment_status').val('{{ request.GET.payment_status }}').trigger('change')
            //     }

            // }
            // if('{{ request.GET.packaging_status }}'){
            //     if($('#packaging_status').val()){
            //         $('#payment_status').selectpicker('refersh')
            //     }else{
            //         $('#packaging_status').val('{{ request.GET.packaging_status }}').trigger('change')
            //     }
            // }
        }

        }

    }
    componentWillMount(){
        this.setState(()=>{
            return {
                filters: this.props.filters,
            }
      })
    }
    componentDidMount(){
        this.setState(()=>{
            return {
                show: true,
            }
      })
    }
    render(){
        var order_nos = this.state.filters.order_numbers.map((order_list,indx)  =>{
            return <option value={order_list[0]} key={indx}>{order_list[1]}</option>
        })
        var payment_status = Object.keys(this.state.filters.payment_status).map((choice,indx)  =>{
            return <option value={choice} key={indx}>{this.state.filters.payment_status[choice]}</option>
        })
        var packaging_status = Object.keys(this.state.filters.packaging_status_choices).map((choice,indx)  =>{
            return <option value={choice} key={indx}>{this.state.filters.packaging_status_choices[choice]}</option>
        })
        var order_status = Object.keys(this.state.filters.cp_status_choices).map((choice,indx)  =>{
            if(choice == 'closed_manually'){
                return <option value={choice} key={indx}>Closed Manually</option>
            }else{
                return <option value={choice} key={indx}>{this.state.filters.cp_status_choices[choice]}</option>
            }
            
        })
        return(
            <div className="row">
                <a className="clear-filters" href='void(0)' onClick={this.props.clearFilters}>CLEAR FILTERS</a>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <select className="selectpicker form-control" id="order_numbers" onChange={this.props.filterOrders} data-live-search="true" data-size="6">
                            <option value="">--------</option>
                            {order_nos}
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Payment Status</label>
                        <select className="selectpicker form-control" id="payment_status" onChange={this.props.filterOrders}>
                            <option value="">--------</option>
                            {payment_status}
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Packaging Status</label>
                        <select className="selectpicker form-control" id="packaging_status" onChange={this.props.filterOrders}>
                            <option value="">--------</option>
                            {packaging_status}
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order Status</label>
                        <select className="selectpicker form-control" id="order_status" data-size="5" onChange={this.props.filterOrders}>
                            <option value="">--------</option>
                            {order_status}
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

export default class OrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exampleItems: [],
            pageOfItems: [],
            pageCount:0,
            page_size:10,
            choices:{},
            dropdown_check:true
        };
    }

    componentDidMount(){
        //$(".lds-ripple-parent").fadeOut("slow");
        var package_status = '' //'{{ request.GET.packaging_status }}'
        var payment_status = '' //'{{ request.GET.payment_status }}'        
        //ReactDOM.render(<PageLoader/>,document.getElementById('orders_loader'))
        //ReactDOM.render(<PageLoader/>,document.getElementById('filter_loader'))
        $('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/orders/?field=id&field=status&field=document_number&field=id&field=date&field=ssi_contact&field=total_amount&field=currency&field=exchange_rate&field=local_exchange_rate&ordering=-date&field=customer_reference_number&field=freight_charges&field=cp_status_choices&field=contact_name&field=ref_no&payment_status='+payment_status+'&packaging_status='+package_status,
        ).then(response => {
          if(response.status == 200){ 
            if(response.data.orders){
                var json_data = response.data.orders
            }else{
                  var json_data = []
              }
            if(response.data.orders_count > 0){
                $('#order-filters').css('padding-bottom', '0px')
            }else{
                $('.order-filters').hide()
            }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.orders_count,
                    choices:response.data             
                }
             })  
             $('.inline-loader').fadeOut('slow')  
             $('.card-block').removeClass('remove-order-filters')    
            }
        }).catch(response => {
            ErrorHandling(response)  
        });

    }

    showEntries = (event) => {
        var page_size = event.currentTarget.value
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        var payment_status = $('#payment_status').val()
        var package_status = $('#packaging_status').val()
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()        
         $('.order-card').find('.inline-loader').fadeIn() 
        axios.get(API_HOST+'/api/sales/orders/?field=id&field=status&field=document_number&field=contact_name&field=ref_no&field=id&field=date&field=ssi_contact&field=total_amount&field=currency&field=exchange_rate&field=local_exchange_rate&ordering=-date&field=customer_reference_number&field=cp_status_choices&field=freight_charges&page_size='+parseFloat(page_size)+'&payment_status='+payment_status+'&packaging_status='+package_status+'&order_status='+order_status+'&order_no='+order_no,
        ).then(response => {
          if(response.status == 200){ 
            if(response.data.orders){
                var json_data = response.data.orders
            }else{
                  var json_data = []
              }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.orders_count,
                    page_size:parseFloat(page_size),
                    choices:response.data
                               
                }
             })   
             $('.order-card').find('.inline-loader').fadeOut('slow')
                       
    }
    }).catch(response => {  
        ErrorHandling(response)  
        $('.order-card').find('.inline-loader').fadeOut('slow')
    });

    }
    

    onChangePage = (pageOfItems) =>{
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }
    
    filterOrders = (event) =>{
        event.preventDefault()
        var payment_status = $('#payment_status').val()
        var pakage_status = $('#packaging_status').val()
        var order_status = $('#order_status').val()
        var order_no = $('#order_numbers').val()        
         $('.order-card').find('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token 
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/orders/?field=id&field=status&field=document_number&field=id&field=contact_name&field=ref_no&field=date&field=ssi_contact&field=total_amount&field=currency&field=exchange_rate&field=local_exchange_rate&ordering=-date&field=customer_reference_number&field=freight_charges&field=cp_status_choices&payment_status='+payment_status+'&packaging_status='+pakage_status+'&order_status='+order_status+'&order_no='+order_no+'&page_size='+parseFloat(this.state.page_size),
        ).then(response => {
          if(response.status == 200){ 
            if(response.data.orders){
                var json_data = response.data.orders
            }else{
                  var json_data = []
              }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.orders_count,
                    choices:response.data,
                    page_size:parseFloat(this.state.page_size),
                    dropdown_check:false             
                }
             })
             $('.order-card').find('.inline-loader').fadeOut('slow')   
            }
    }).catch(response => { 
        ErrorHandling(response)       
        $('.order-card').find('.inline-loader').fadeOut('slow')
    });
   }
   clearFilters = (event) =>{
       event.preventDefault()
        $('#payment_status').val('').selectpicker('refresh')
        $('#packaging_status').val('').selectpicker('refresh')
        $('#order_status').val('').selectpicker('refresh')
        $('#order_numbers').val('').selectpicker('refresh')     
        $('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/orders/?field=id&field=status&field=document_number&field=id&field=date&field=ssi_contact&field=total_amount&field=currency&field=exchange_rate&field=local_exchange_rate&ordering=-date&field=customer_reference_number&field=freight_charges&field=cp_status_choices&field=contact_name&field=ref_no',
        ).then(response => {
          if(response.status == 200){ 
            if(response.data.orders){
                var json_data = response.data.orders
            }else{
                  var json_data = []
              }
            if(response.data.orders_count > 0){
                $('#order-filters').css('padding-bottom', '0px')
            }else{
                $('.order-filters').hide()
            }
            this.setState(()=>{
                return {
                    pageOfItems: json_data,
                    exampleItems: json_data,
                    pageCount:response.data.orders_count,
                    choices:response.data             
                }
             })  
             $('.inline-loader').fadeOut('slow')  
             $('.card-block').removeClass('remove-order-filters')    
            }
        }).catch(response => {
            ErrorHandling(response)   
        });

   }
   componentDidUpdate(){
    if(this.state.pageCount > 0){
        ReactDOM.render(<OrderFilters filters={this.state.choices} filterOrders={this.filterOrders} dropdown_check={this.state.dropdown_check} clearFilters={this.clearFilters}/>,document.getElementById('order-filters'))
    }
    if(this.props.location.page){
        if(this.props.location.page.load){
            this.props.location.page = {load:false}            
            $('.search_input').val('')
            $('#payment_status').val('').selectpicker('refresh')
            $('#packaging_status').val('').selectpicker('refresh')
            $('#order_status').val('').selectpicker('refresh')
            $('#order_numbers').val('').selectpicker('refresh') 
            this.componentDidMount()
        }
    }
   }
    render() {
        $('.card').removeClass('add-pdding')                
        const tr_elements = this.state.pageOfItems.map((quote) =>{
                if(quote.total_amount){
                    var freight_val = quote.freight_charges?quote.freight_charges:0
                    var total_amount = parseFloat(freight_val)+parseFloat(quote.total_amount)
                    var order_amount = Humanize(parseFloat(total_amount))    
                }else{
                    var freight_val = quote.freight_charges?quote.freight_charges:0
                    var order_amount = parseFloat(freight_val)+parseFloat(0.00)         
                }
                if(quote.status == 'open'){
                    var status = <i className="fas fa-envelope-open quotation_status_open"></i>
                }else if(quote.status == 'closed'){
                    var status = <i className="em em-white_check_mark quotation_status_closed"></i>
                }else if(quote.status == 'reserved'){
                    var status = <i className="em em-lock quotation_status_closed"></i>
                }
                else if(quote.status == 'closed_manually'){
                    var status = <i className="em em-negative_squared_cross_mark quotation_status_closed_manually"></i>
                }else if(quote.status == 'requested' || quote.status == 'under_review_by_ssi_admin' || quote.status == 'under_review_by_customer' || quote.status == 'review_rejected_by_customer' || quote.status == 'review_accepted_by_customer'){
                    var status = <i className="em em-hourglass" title={quote.cp_status_choices[quote.status]}></i>
                }
                else {
                    var status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled"></i>
                }
                return <tr className="order-list-tr" key={quote.id}>
                        <td className="document-no-td">
                            <div className="relative mrg-vertical-5">
                                <Link to={"/order/view/"+quote.id+"/"} className="text-info">
                                    <span className='orders'>{ quote.document_number }</span>
                                </Link>                                 
                            </div>
                        </td>
                        <td className="order-date-td">
                            <div className="mrg-vertical-5">
                                <span className="span-item">{ moment(quote.date).format('MMM, D YYYY') }</span>
                            </div>
                        </td>
                        <td className="ssi-contact-td">
                                <div className="mrg-vertical-5">
                                    <span>{ quote.contact_name?quote.contact_name:'-' }</span>
                                </div>
                            </td>
                        <td className="cust-ref-no-td">
                            <div className="mrg-vertical-5">
                                <span className='span-item'>{ quote.customer_reference_number?quote.customer_reference_number:'-' }</span>
                            </div>
                        </td>
                        <td className="cust-ref-no-td">
                            <div className="mrg-vertical-5">
                                <span>{ quote.ref_no?quote.ref_no:'-' }</span>
                            </div>
                        </td>
                        <td className="order-status-td">
                            <div className="mrg-vertical-5 text-center" title={ quote.status }>
                                { status }
                            </div>
                        </td>
                        <td className="text-right order-amount-td">
                            <div className="">                                
                                <b className="text-dark font-size-16"><span className="currency">{quote.currency.symbol?quote.currency.symbol:'$'}</span> { order_amount }</b>
                            </div>
                        </td>
                    </tr>}
                    );
            $('select.show_entries').val(this.state.page_size.toString())
        return (
            <div className="container-fluid orderlist-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card order-filters">
                            <div className="inline-loader" id="filter_loader"> 
                              <SubLoader/>
                            </div>                    
                            <div className="card-block remove-order-filters" id="order-filters">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">             
                        <div className="card order-card add-pdding">
                            <div className="inline-loader" id="orders_loader"> 
                                <SubLoader/>
                            </div>                                  
                            <div className="card-block" id="table-overflow">
                            <div className="table-overflow" >  
                    { this.state.pageCount > 10?
                        <div className="order-show-entries">
                                <div className="dataTables_length orderDataTable" id="dt-opt_length">
                                    <label>Show 
                                        <select name="dt-opt_length" className="show_entries" onChange={this.showEntries}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> 
                                        entries</label>
                                    </div>
                                
                        </div>
                        :null}                       
                            <table className="table table-hover" >
                                    <thead className="order-list-header">
                                            <tr>
                                                <th>Order # </th>
                                                <th>Date</th>
                                                <th>Ordered By</th>
                                                <th>Cust Ref #</th>
                                                <th>SSI Ref #</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-right">Total</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                            { this.state.pageOfItems.length > 0?
                                                tr_elements :<tr><td colSpan="7" className="text-center"><h2> You have no Orders.</h2></td></tr>
                                        }
                                    </tbody>
                                </table>
                            <div>
                                    <Pagination items={this.state.exampleItems} quotescount={this.state.pageCount} onChangePage={this.onChangePage}  page_size={this.state.page_size}/>
                            </div>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
