import { Component } from 'react'
import './dashboard.css'
import CategoryGroups from './CategoryGroups'
import FeaturedProducts from './FeaturedProducts'
import PackedOrders from './PackedOrders'
import UnpaidOrders from './UnpaidOrders'
import OrderUpdates from './OrderUpdates'

export default class Dashboard extends Component {
    render() {
        return (
            <div className="container-fluid dashboard-container">             
                <CategoryGroups/>
                <FeaturedProducts/>
                <div className="row">
                    <div className="col-lg-6">
                        <PackedOrders/>
                        <UnpaidOrders/>                        
                    </div>
                    <OrderUpdates/>                 
                </div>
            </div>
        )
    }
}

