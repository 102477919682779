import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'


class SideNavBar extends Component {
    openHelp = (e) =>{
        e.preventDefault()
        window.purechatApi.set('chatbox.expanded', true);
    }
    toogleSidenav = (e) =>{
        e.preventDefault()
        $('.app').toggleClass("is-collapsed");
      }
    render() {
        return (
            <div className="side-nav">
            <div className="side-nav-inner">
                <div className="side-nav-logo">
                    <Link to="/">
                        <div className="logo logo-dark"></div>
                        <div className="logo logo-white"></div>
                    </Link>
                    <div className="mobile-toggle side-nav-toggle" onClick={this.toogleSidenav}> 
                        <a href="">
                            <i className="ti-arrow-circle-left"></i>
                        </a>
                    </div>
                    <hr className='horizantal_ruler' />
                </div>
                <ul className="side-nav-menu scrollable">
                    <li className="nav-item active">
                        <Link to="/" className="mrg-top-0">
                            <span className="icon-holder">
                                <i className="ti-home"></i>
                            </span>
                            <span className="title">Dashboard</span>
                        </Link>                        
                    </li>
                    
                    <li className="nav-item ">
                        <Link to="/quotations" className="mrg-top-0">
                            <span className="icon-holder">
                                <i className="ti-write"></i>
                            </span>
                            <span className="title">Quotations</span>
                        </Link>                        
                    </li>
                    <li className="nav-item ">
                        <Link to="/orders" className="mrg-top-0">
                            <span className="icon-holder">
                                <i className="ti-shopping-cart"></i>
                            </span>
                            <span className="title">Orders</span>
                        </Link>                        
                    </li>
                    <li className="nav-item help">
                        <Link to=" " className="mrg-top-0" target="_blank" onClick={this.openHelp}>
                            <span className="icon-holder">
                                <i className="far fa-question-circle"></i>
                            </span>
                            <span className="title">Help</span>
                        </Link>                        
                    </li>
                </ul>
            </div>
        </div>
        )
    }
}

export default withRouter(SideNavBar)