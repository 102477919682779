import  { Component } from 'react'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'

class NavFooter extends Component {
    componentDidMount(){
        $('.year').text(moment().format('YYYY'))
    }
    render() {
        return (
            // footer Start
            <footer className="content-footer">
                <div className="footer">
                    <div className="copyright">
                        <span>SSI Aeration, Inc. <span className="year"></span>. All rights reserved.</span>
                        <span className="go-right">
                            <a href="" className="text-gray mrg-right-15 d-none">Term &amp; Conditions</a>
                        </span>
                    </div>
                </div>
            </footer>
            //footer End
        )
    }
}

export default withRouter(NavFooter)
