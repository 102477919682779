import  { Component } from 'react'

export default class SubLoader extends Component {
    render() {
        return (
            <svg width="120" height="100">
                <circle className="orange-circle-col-1" r="4" cx="10" cy="10" fill="transparent"></circle>
                <circle className="orange-circle-col-1" r="4" cx="10" cy="25" fill="transparent"></circle>
                <circle className="orange-circle-col-1" r="4" cx="10" cy="40" fill="#a2c02f"></circle>
                <circle className="orange-circle-col-1" r="4" cx="10" cy="55" fill="#00acdd"></circle>
                <circle className="orange-circle-col-1" r="4" cx="10" cy="70" fill="transparent"></circle>
                <circle className="orange-circle-col-2" r="4" cx="25" cy="10" fill="transparent"></circle>
                <circle className="orange-circle-col-2" r="4" cx="25" cy="25" fill="#00879c"></circle>
                <circle className="orange-circle-col-2" r="4" cx="25" cy="40" fill="#449aba"></circle>
                <circle className="orange-circle-col-2" r="4" cx="25" cy="55" fill="#00acdd"></circle>
                <circle className="orange-circle-col-2" r="4" cx="25" cy="70" fill="#88c86f"></circle>
                <circle className="orange-circle-col-3" r="4" cx="40" cy="10" fill="transparent"></circle>
                <circle className="orange-circle-col-3" r="4" cx="40" cy="25" fill="transparent"></circle>
                <circle className="orange-circle-col-3" r="4" cx="40" cy="40" fill="#39b54a"></circle>
                <circle className="orange-circle-col-3" r="4" cx="40" cy="55" fill="#0080bb"></circle>
                <circle className="orange-circle-col-3" r="4" cx="40" cy="70" fill="transparent"></circle>
                <circle className="orange-circle-col-4" r="4" cx="55" cy="10" fill="#4ebfa8"></circle>
                <circle className="orange-circle-col-4" r="4" cx="55" cy="25" fill="#39b54a"></circle>
                <circle className="orange-circle-col-4" r="4" cx="55" cy="40" fill="transparent"></circle>
                <circle className="orange-circle-col-4" r="4" cx="55" cy="55" fill="#c3c00f"></circle>
                <circle className="orange-circle-col-4" r="4" cx="55" cy="70" fill="transparent"></circle>
                <circle className="orange-circle-col-5" r="4" cx="70" cy="10" fill="transparent"></circle>
                <circle className="orange-circle-col-5" r="4" cx="70" cy="25" fill="#bfd730"></circle>
                <circle className="orange-circle-col-5" r="4" cx="70" cy="40" fill="#799b28"></circle>
                <circle className="orange-circle-col-5" r="4" cx="70" cy="55" fill="transparent"></circle>
                <circle className="orange-circle-col-5" r="4" cx="70" cy="70" fill="transparent"></circle>
                <circle className="orange-circle-col-6" r="4" cx="85" cy="10" fill="#bad532"></circle>
                <circle className="orange-circle-col-6" r="4" cx="85" cy="25" fill="transparent"></circle>
                <circle className="orange-circle-col-6" r="4" cx="85" cy="40" fill="transparent"></circle>
                <circle className="orange-circle-col-6" r="4" cx="85" cy="55" fill="#00ab4e"></circle>
                <circle className="orange-circle-col-6" r="4" cx="85" cy="70" fill="transparent"></circle>
            </svg>
        )
    }
}
