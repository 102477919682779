import { Component } from 'react'
import {Link } from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import $ from 'jquery'
import moment from 'moment'
import {Humanize, notify, ErrorHandling} from '../../js/CommonFunctions'
import './orderview.css'
import countries from '../../data/countries2.json'
const axios = require('axios').default;


class OrderDetails extends Component{        
    render(){
        var pageheader = document.getElementById('page-header')
        // pageheader.innerText = ''
        if(this.props.order_data.document_number && pageheader){
            pageheader.innerText = '#'+this.props.order_data.document_number
        }
        if(this.props.order_data.status === 'open'){
            var status = <i className="fas fa-envelope-open order_open_status pdd-5" title="Open"></i>
        }else if(this.props.order_data.status === 'closed'){
            var status = <i className="em em-white_check_mark order_closed_status pdd-5" title="Closed"></i>
        }else if(this.props.order_data.status === 'reserved'){
            var status = <i className="em em-lock order_closed_status pdd-5" title="Reserved"></i>
        }else if(this.props.order_data.status === 'closed_manually'){
            var status = <i className="em em-negative_squared_cross_mark order_closed_manually pdd-5" title="Cancelled"></i>
        }else if(this.props.order_data.status === 'requested' || this.props.order_data.status === 'under_review_by_ssi_admin' || this.props.order_data.status === 'under_review_by_customer' || this.props.order_data.status=== 'review_rejected_by_customer' || this.props.order_data.status === "review_accepted_by_customer"){
            var status = <i className="em em-hourglass hourglass" title={this.props.order_data.cp_status_choices[this.props.order_data.status]}></i>
        }
        else {
            var status = <i className="em em-negative_squared_cross_mark quotation_status_cancelled pdd-5" title="Cancelled"></i>
        }


        if(this.props.order_data.packaging_status === 'packed'){
            var package_status = <i className="fa fa-cube packed" title="Packed"></i>
        }else{
            var package_status = <i className="fa fa-cube no-success" title="Packing Awaited"></i>
        }


        if(this.props.order_data.payment_status === 'paid'){
            var payment_status = <i className="fas fa-dollar-sign paid" title="Paid"></i>
        }else{
            var payment_status =<i className="fas fa-dollar-sign no-success" title="Payment Pending"></i>
        }

        var fulfilled_status = ''
        var fullfill = ''
        if(this.props.order_data.inventorytnx_set){
            if(this.props.order_data.inventorytnx_set.length > 0 && this.props.order_data.status === 'closed'){
                fulfilled_status = <i className="em em-truck fullfilled pdd-5" title="Fulfilled"></i>                    
                fullfill =  <span className="pdd-left-5" title="Fulfilled Date"><span className="arriving">{ moment(this.props.order_data.inventorytnx_set[0].date).format('MMM D, YYYY')}</span></span> 
            }else{
                fulfilled_status = <i className="em em-truck fullfilled fulfill-pending pdd-5 emoji-bulr" title="Fulfillment Pending"></i>
                if(this.props.order_data.projected_shipping_date){
                    fullfill =<span className="pdd-left-5" title="Projected Shipping Date"> <span className="arriving">{ moment(this.props.order_data.projected_shipping_date).format('MMM D, YYYY')}</span></span>
                }else{
                    fullfill=""
                }
            }
        }
        var check_status = ''
        if(this.props.order_data.status === 'requested' || this.props.order_data.status === 'under_review_by_ssi_admin' || this.props.order_data.status === 'under_review_by_customer' || this.props.order_data.status=== 'review_rejected_by_customer') {
            if(this.props.order_data.status === 'under_review_by_customer') {   
                check_status = <div className="display-block">
                                <a href="#!" className="btn-icon btn-flat btn-rounded no-mrg-btm" onClick={this.props.CustomerApproveAndReject} data-status="review_accepted_by_customer" sales-order-id={this.props.order_data.id} title="Accept Review">
                                    <i className="far fa-check-circle fontsize accept"></i>
                                </a>
                                <a href="#!" className="btn-icon btn-flat btn-rounded no-mrg-btm mrg-left-25" onClick={this.props.CustomerApproveAndReject} data-status="review_rejected_by_customer" sales-order-id={this.props.order_data.id} title="Reject Review">
                                    <i className="fa fa-ban fontsize reject"></i>
                                </a>                                                    
                        </div>
            }
            else{
                check_status = ""
            }
        }else if(this.props.order_data.status === 'reserved'){
            check_status = <div className="display-block">
                <Link to="/orders" className="btn btn-default btn-animation"  title="Back">
                    <i className="fas fa-arrow-left"></i>
                </Link>
                <a className="btn  btn-animation mrg-left-10 cancel-btn" title="Cancel this reservation." button-click="cancel" onClick={this.props.convertToOrder} >Cancel</a>
                <a className="btn btn-success btn-animation place-order-btn" title="Convert the reserved stock to order." button-click="placeorder" onClick={this.props.convertToOrder} >Place Order</a>
            </div>

        }
        else if(this.props.order_data.pdf_document_url) {                                     
            check_status = <a href={this.props.order_data.pdf_document_url} title="Download Order Acknowledgement as PDF" target="_blank" className="btn btn-success">
                <i className="fas fa-download download-btn"> PDF</i>
            </a>
        }
        
        return(
            <div className="card">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="pdd-15 order-no" >
                                    <span title="Order Date">{ moment(this.props.order_data.date).format('MMM D, YYYY')}</span>
                                    {fullfill?<span> <i className="fas fa-long-arrow-alt-right arrow"></i> <span><i className="em em-truck mrg-btm-10"></i></span> { fullfill } </span>:''}   
                                    {this.props.order_data.status === 'reserved'?<p className="reserve-msg">*This is a reserved document and will expire on {moment(this.props.order_data.date).add('days', 7).format("MMM D, YYYY")}.</p>:''}
                                </div>
                            
                        </div>
                        <div className="col-md-3 text-center">
                                <div className="pdd-15 icon-size" >
                                        {status}
                                        {package_status}
                                        {payment_status}
                                        {fulfilled_status}
                                        
                                </div>
                        </div>
                        <div className="col-md-4 text-right">
                                <div className="pdd-15 icon-size">
                                    {check_status}
                                </div>
                        </div>
                    </div>
             </div> 
            
        )
    }
}

class Customer_Address extends Component{    
render(){  
    var style = { whiteSpace: 'pre-wrap' }
    return(
        <div className="row">
                <div className="col-md-6">
                        <div className="card">                       
                                <div className="card-body">
                                    <div className="row">
                                            <div className="col-md-4">
                                                <p className="mrg-top-10 text-dark"> Customer PO</p>
                                            </div>
                                            <div className="col-md-8">
                                                    <p className="mrg-top-10" id="customer_po">
                                                        <b>
                                                        {this.props.order_data.customer_po?this.props.order_data.customer_po:'-'}
                                                        </b>
                                                    </p>
                                            </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark"> Customer Reference # </p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_ref">
                                                            <b>
                                                                    {this.props.order_data.customer_reference_number?this.props.order_data.customer_reference_number:'-'}
                                                                </b>
                                                            </p>
                                                </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                                <p className="mrg-top-10 text-dark">Remarks</p>
                                        </div>
                                        <div className="col-md-8">
                                                <p className="mrg-top-10" style={style} id="remarks"><span>{this.props.order_data.remarks? this.props.order_data.remarks:'-'}</span></p>
                                        </div>
                                    </div>
                                </div> 
                        </div>
                </div>
                <div className="col-md-6">
                        <div className="card">
                                
                            <div className="card-block" id="address-block"> 
                                <div className="row">
                                    <div className="col-md-6">
                                            <div className="card no-border">                          
                                                <div className="card-heading no-pdd-vertical">
                                                    <h5 className="card-title">Billing Address</h5>
                                                </div>
                                                <div className="card-body" id="bl-address">
                                                        <div className="add1" title="Address 1">
                                                            {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.add1:''}
                                                        </div>
                                                        <div className="add2" title="Address 2">
                                                                {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.add2:''}
                                                        </div>
                                                        <div className="city" title="City">
                                                                {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.city:''}
                                                        </div>
                                                        <div className="state" title="State">
                                                                {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.state:''}
                                                        </div>
                                                        <div className="country" title="Country">
                                                                {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.country:''}
                                                        </div>
                                                        <div className="zip_code" title="Zip Code">
                                                                {this.props.order_data.billing_address?this.props.order_data.billing_address.fields.zip_code:''}
                                                        </div>
                                                </div> 
                                                        
                                            </div>
                                    </div> 
                                    <div className="col-md-6">
                                            <div className="card no-border">                          
                                                <div className="card-heading no-pdd-vertical">
                                                    <h5 className="card-title">Shipping Address</h5>
                                                </div>
                                                <div className="card-body" id='sh-address'>
                                                        <div className="add1" title="Address 1">
                                                            {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.add1:''}
                                                        </div>
                                                        <div className="add2" title="Address 2">
                                                                {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.add2:''}
                                                        </div>
                                                        <div className="city" title="City">
                                                                {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.city:''}
                                                        </div>
                                                        <div className="state" title="State">
                                                                {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.state:''}
                                                        </div>
                                                        <div className="country" title="Country">
                                                                {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.country:''}
                                                        </div>
                                                        <div className="zip_code" title="Zip Code">
                                                                {this.props.order_data.shipping_address?this.props.order_data.shipping_address.fields.zip_code:''}
                                                        </div>
                                                </div> 
                                                        
                                            </div>
                                    </div>  
                                </div>                                                                                 
                            </div>
                        </div>
                    </div>                    
        </div>
    )
}

}
class CustomerAddress extends Component{
componentDidUpdate(){
    $('select#billing-address').selectpicker("refresh");  
    $('select#shipping-address').selectpicker("refresh");  
}

render(){  
    var style = { whiteSpace: 'pre-wrap' }
    var portlet = {'padding': '10px','paddingRight': '1px'}
    var heading = {'padding':'10px','borderBottom': '1px solid #e6ecf5'}
    var font_size={'fontSize':'16px'}
    var cust_size={'fontSize':'20px'}
    var portel = {'padding': '10px'}        
    return(
        <div className="row">  
            <div className="col-md-6">
                <div className="card">                                       
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Customer Po</label>
                                        <input type="text" className="form-control"  name="customer_po" id="customer_po" placeholder="Customer Po" value={this.props.order_data.customer_po} onChange={this.props.customerdetailsChange}  required/>
                                    </div>  
                                </div>
                                <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Customer Reference #</label>
                                            <input type="text" className="form-control"  name="customer_reference_number" id="customer_reference_number" placeholder="Customer Reference #" value={this.props.order_data.customer_reference_number} onChange={this.props.customerdetailsChange}   required/>
                                        </div>  
                                    </div>
                                    <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Remarks</label>
                                                <textarea type="text" className="form-control"  name="remarks" id="remarks" placeholder="Remarks" value={this.props.order_data.remarks} onChange={this.props.customerdetailsChange}  ></textarea>
                                            </div>  
                                    </div> 
                            </div>
                        </div> 
                </div>
        </div>
            <div className="col-md-6">                           
                        <div className="card">
                                
                            <div className="card-block" id="address-block"> 
                                <div className="row">                                    
                                    <div className="col-md-6">
                                        <div className="card" >
                                                <div className="portlet">
                                                        <ul className="portlet-item navbar" style={portlet}>
                                                            <li>
                                                                <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  onClick={this.props.handleAddressChange} add-type="billing" title="Change Billing Address">
                                                                        <i className="ti-arrow-circle-down"  onClick={this.props.handleAddressChange} add-type="billing"></i>
                                                                </a>
                                                            </li>
                                                            {''?<li className="hide-ad">
                                                                <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  add-type="billing"  onClick={this.props.AddressEditHandler} title="Edit Billing Address">
                                                                      <i className="ti-pencil" add-type="billing"  onClick={this.props.AddressEditHandler}></i>  
                                                                </a>
                                                            </li>:''}
                                                        </ul>
                                                            
                                                    </div>
                                            <div className="card-heading" style={heading}>
                                                <h5 className="card-title">Billing Address</h5>
                                            </div>
                                            <div className="card-body" id='bl-address'>
                                                <div className="add1" title="Address 1">{this.props.billing_add.add1}</div>
                                                <div className="add2" title="Address 2">{this.props.billing_add.add2}</div>
                                                <div className="city" title="City">{this.props.billing_add.city}</div>
                                                <div className="state" title="State">{this.props.billing_add.state}</div>
                                                <div className="country" title="Country">{this.props.billing_add.country}</div>
                                                <div className="zip_code" title="Zip Code">{this.props.billing_add.zip_code}</div>
                                            </div>                    
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                                <div className="portlet">
                                                    <ul className="portlet-item navbar" style={portlet}>
                                                        <li>
                                                            <a href="#!" className="btn-icon btn-flat btn-rounded" style={font_size}  onClick={this.props.handleAddressChange} add-type="shipping" title="Change Shipping Address">
                                                                    <i className="ti-arrow-circle-down" onClick={this.props.handleAddressChange} add-type="shipping"></i>
                                                            </a>
                                                        </li>
                                                        {''?<li className="hide-ad">
                                                            <a href="#!" className="btn-icon btn-flat btn-rounded " style={font_size} add-type="shipping"  onClick={this.props.AddressEditHandler} title="Edit Shipping Address">
                                                               <i className="ti-pencil"  add-type="shipping"  onClick={this.props.AddressEditHandler}></i>  
                                                            </a>
                                                        </li>:''}
                                                    </ul>
                                                        
                                                </div>
                                            <div className="card-heading" style={heading}>
                                                <h5 className="card-title">Shipping Address</h5>
                
                                            </div>
                                            <div className="card-body" id='sh-address'>
                                                <div className="add1" title="Address 1">{this.props.shipping_add.add1}</div>
                                                <div className="add2" title="Address 2">{this.props.shipping_add.add2}</div>
                                                <div className="city" title="City">{this.props.shipping_add.city}</div>
                                                <div className="state" title="State">{this.props.shipping_add.state}</div>
                                                <div className="country" title="Country">{this.props.shipping_add.country}</div>
                                                <div className="zip_code" title="Zip Code">{this.props.shipping_add.zip_code}</div>
                                            </div>                    
                                        </div>
                                    </div>


                                </div>                                                                                 
                            </div>
                        </div>
                </div>                    
        </div>
    )
}

  

}
class AddressModal extends Component{
    componentDidMount(){
        $('select#id_country').selectpicker()
        $('select#id_country').val(this.props.addressData.country.value).selectpicker('refresh')
        $('#customer-billing-address-create-dialog').modal('show');
        $('#customer-billing-address-create-dialog').on('hidden.bs.modal', this.props.HidenModal);
    }

    render(){
        if(this.props.addressData.add_type.value === 'billing'){
            var Add_type = 'Update Billing Address'
        }else {
            var Add_type = 'Update Shipping Address'
        }
        // var countries = Object.entries(JSON.parse(this.props.countries))
        // var option_str=countries.map((country, key) =>{
        //     return(
        //     <option key={country[1]} value={country[1]}>{country[1]}</option>
        //     )
        // }) 
        const option_str = this.props.countries.map((data)=>{
            return <option key={data.country} value={data.country}>{data.country}</option>
        })
        
        return(
            <div className="modal fade" id="customer-billing-address-create-dialog" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{Add_type}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body customer-billing-address-create-model-body">
                                <form className="customer-billing-address-create-model-body-form" id="address_form" onSubmit={this.props.AddressSubmitHandler}>
                                    <input type="hidden" name="id" value={this.props.addressData.id.value} id="address_id" />
                                    <input type="hidden" name="index" value={this.props.addressData.add_index.value} id="address_index" />
                                    <input type="hidden" name="add_type" value={this.props.addressData.add_type.value} id="address_type" />
                                    <div className="row">                                            
                                            <div className="col-md-6 no-padding-left">
                                                    <label>Address 1</label>
                                                    <input type="text" name="add1" id="id_add1" placeholder="Address 1" className="form-control" maxlength="150" value={this.props.addressData.add1.value} onChange={this.props.onchange} />
                                                    <p className="add1_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Address 2</label>
                                                        <input type="text" name="add2" id="id_add2" placeholder="Address 2" className="form-control" maxlength="150" value={this.props.addressData.add2.value} onChange={this.props.onchange} />
                                                        <p className="add2_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>City</label>
                                                        <input type="text" name="city" id="id_city" placeholder="City" className="form-control" maxlength="75" required value={this.props.addressData.city.value} onChange={this.props.onchange} />
                                                        <p className="city_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>State</label>
                                                        <input type="text" name="state" id="id_state" placeholder="State" className="form-control" maxlength="75" required value={this.props.addressData.state.value} onChange={this.props.onchange} />
                                                        <p className="state_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Country</label>
                                                        <select id="id_country" className="form-control" name="country" data-size="7" data-live-search="true" onChange={this.props.onchange} required>
                                                            {option_str}    
                                                        </select>
                                                        <p className="country_error"></p>
                                                    </div>
                                                    <div className="col-md-6 no-padding-left">
                                                        <label>Zip code</label>
                                                        <input type="text" name="zip_code" id="id_zip_code" placeholder="Zip code" className="form-control" maxlength="15" value={this.props.addressData.zip_code.value} onChange={this.props.onchange} />
                                                        <p className="zip_code_error"></p>
                                                    </div>                                                
                                            </div>
                                            <div className="modal-footer">
                                                    <button className="btn btn-primary create-billing-address-submit"
                                                            type="submit" >
                                                            Update
                                                    </button>
                                                </div>
                                </form>
                            </div>
                            
            
                        </div>
                    </div>
                </div>
        )
    }
}

class ChangeDefaultAddress extends Component{
    componentDidMount(){
        $('#change-address-dialog').modal('show');
        $('#change-address-dialog').on('hidden.bs.modal', this.props.handleHideModal);
    }

    render(){
        var radio_disply = {'display':'inline'}
        var isdefault=''
        var addtype=""
        if(this.props.add_type === 'billing'){
            var addtype="Change Billing Address"
            var customer_add = this.props.billing_add.map((address,index)=>{
                if(address.isdefault){
                    var isdefault = true
                }
                return(
                    <div className="col-md-4" key={index}>
                            <div className={isdefault?"card change-address selected-address":"card change-address"} checked={isdefault} this_index={index}  this_type={address.add_type} onClick={this.props.SetDefaultAddress} title="Click to Select Address"  >                                                           
                                <div className="card-body">
                                    <div className="add1" title="Address 1">{address.add1}</div>
                                    <div className="add2" title="Address 2">{address.add2}</div>
                                    <div className="city" title="City">{address.city}</div>
                                    <div className="state" title="State">{address.state}</div>
                                    <div className="country" title="Country">{address.country}</div>
                                    <div className="zip_code" title="Zip Code">{address.zip_code}</div>
                                </div>                    
                            </div>
                        </div>   

                )

            })

        }else if(this.props.add_type === 'shipping'){
            var addtype="Change Shipping Address"
            var customer_add = this.props.shipping_add.map((address,index)=>{
                if(address.isdefault){
                    var isdefault = true
                }
                return(
                    <div className="col-md-4" key={index}>
                            <div className={isdefault?"card change-address selected-address":"card change-address"} checked={isdefault} this_index={index}  this_type={address.add_type} onClick={this.props.SetDefaultAddress}>                                                           
                                <div className="card-body">
                                    <div className="add1" title="Address 1">{address.add1}</div>
                                    <div className="add2" title="Address 2">{address.add2}</div>
                                    <div className="city" title="City">{address.city}</div>
                                    <div className="state" title="State">{address.state}</div>
                                    <div className="country" title="Country">{address.country}</div>
                                    <div className="zip_code" title="Zip Code">{address.zip_code}</div>

                                </div>                    
                            </div>
                        </div>   

                )

            })

    }
    return(
        <div className="modal fade" id="change-address-dialog" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{addtype}</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body change-address-model-body">
                        <div className="row">
                            {customer_add}                                         
                        </div>
                        
                    </div>
                        
        
                </div>
            </div>
        </div>
    )
}
}


class SSIDetails extends Component{
    render(){
        return(
            <div className="row">
                    <div className="col-md-6">
                            <div className="card"> 
                                    <div className="card-body">
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark"> SSI Contact</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id=""><b>{this.props.order_data.ssi_contact?this.props.order_data.ssi_contact.name:'-'}</b></p>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                        <p className="mrg-top-10 text-dark">SSI Reference #</p>
                                                    </div>
                                                    <div className="col-md-8">
                                                            <p className="mrg-top-10" id="customer_ref"><b>{this.props.order_data.ref_no?this.props.order_data.ref_no:'-'}</b></p>
                                                    </div>
                                        </div>                                        
                                    </div> 
                            </div>
                    </div>
                    <div className="col-md-6">
                            <div className="card"> 
                                    <div className="card-body">
                                        <div className="row">
                                                <div className="col-md-4">
                                                    <p className="mrg-top-10 text-dark">Total Weight of Items</p>
                                                </div>
                                                <div className="col-md-8">
                                                        <p className="mrg-top-10" id="customer_po">
                                                            {this.props.order_data.total_weight_of_items?
                                                            <b>{Humanize(this.props.order_data.total_weight_of_items)} <span>lbs</span></b>
                                                            :'-'}
                                                        </p>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                        <p className="mrg-top-10 text-dark"> Total Volume of Items</p>
                                                    </div>
                                                    <div className="col-md-8">
                                                            <p className="mrg-top-10" id="customer_ref">
                                                                {this.props.order_data.total_volume_of_items?
                                                                <b>{Humanize(this.props.order_data.total_volume_of_items)} <span>ft<sup>3</sup></span></b>:'-'}
                                                            </p>
                                                    </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                        <p className="mrg-top-10 text-dark">Freight type</p>
                                                    </div>
                                                    <div className="col-md-8">
                                                            <p className="mrg-top-10" id="customer_ref"><b>{this.props.order_data.freight_type?this.props.order_data.freight_type:'-'}</b></p>
                                                    </div>
                                        </div> 
                                        <div className="row">
                                                <div className="col-md-4">
                                                        <p className="mrg-top-10 text-dark"> Freight Charges</p>
                                                    </div>
                                                    <div className="col-md-8">
                                                            <p className="mrg-top-10" id="customer_ref"><b>{this.props.order_data.freight_charges?Humanize(this.props.order_data.freight_charges):'-'}</b></p>
                                                    </div>
                                        </div>                                         
                                    </div> 
                            </div>
                    </div>

            </div> 

        )}
}

class OrderItems extends Component{
    render(){
        var incoterm_text = ''
        if(this.props.order_data.salesorderitem_set){            
            if( this.props.order_data.status === 'requested' || this.props.order_data.status === 'under_review_by_ssi_admin' ){
                var status = false}
            else{var status=true}
            if(this.props.order_data.salesorderitem_set.length > 0){
                var items_tr = this.props.order_data.salesorderitem_set.map((order_item, indx) =>{
                    return <tr className="order-view-tr" key={indx}>
                        <td className="order-view-item">
                            <span className="item_number" key={indx+'_nub'}>{ order_item.item.item_number}</span><br/>{ order_item.item_name}</td>
                        <td className="order-view-warehouse">{ order_item.warehouse?
                            [<span key={indx+'_city'}>{order_item.warehouse.city?order_item.warehouse.city+',':order_item.warehouse.name+','}</span>,
                            <span key={indx+'_state'}> {order_item.warehouse.state?order_item.warehouse.state+'.':order_item.warehouse.country +'.'}</span>]:'-'}
                        </td>
                        <td className="order-view-leadtime">
                            <span key={indx+'_time'}>{ order_item.lead_time }</span></td>
                        <td className="text-right order-view-qty">
                            <span className="qty-span" key={indx+'_qty'}>Quantity: </span>
                            <span key={indx+'_qty1'}>{order_item.quantity}</span>
                        </td> 
                        <td className="text-right order-view-price">
                            <span className="show-on-mobile" key={indx+'_mob'}>
                                <span className="inline-block pdd-right-30" key={indx+'_icon'}>X</span>
                                <span className="currency show-on-mobile inline-block" key={indx+'_cur'}>{this.props.currency?this.props.currency:''} </span>
                                <span className="price" key={indx+'_price'}>{order_item.price?Humanize(parseFloat(order_item.price)):'0.00'}</span>
                            </span> 
                            <span className="price hide-on-mobile" key={indx+'_price1'}>{order_item.price?Humanize(parseFloat(order_item.price)):'0.00'}</span>
                        </td>
                        <td className="text-right order-view-amount">
                            <span className="show-on-mobile">
                                <span className="show-on-mobile inline-block pdd-right-10">= </span>
                                <span className="currency show-on-mobile inline-block">{this.props.currency?this.props.currency:''}</span>
                                <span className="amount">{order_item.quantity && order_item.price?Humanize(parseFloat(order_item.price)*parseFloat(order_item.quantity)):'0.00'} </span>
                            </span> 
                            <span className="amount hide-on-mobile">{order_item.quantity && order_item.price?Humanize(parseFloat(order_item.price)*parseFloat(order_item.quantity)):'0.00'} </span>
                            </td>
                    </tr>
                    
            
                })  
            }else{
            var items_tr = <tr key={'no'}><td colspan="6" className="text-center"><h3> You have no Items.</h3></td></tr>   
            } 
            var incoterms = this.props.order_data.fields_values.filter((inco,indx)=>{ 
                if(inco.field_name ==='Incoterms'){return inco}
            })            
            if(incoterms.length > 0){
                incoterm_text = incoterms[0].field_value[0]
            }  
            
        }else{
            var items_tr = null
        }
        
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">
                            <table className="table table-hover">
                                <thead className="order-view-table-header">
                                    <tr>
                                        <th className="width-30">Items</th>
                                        <th className="width-20">Warehouse</th>
                                        <th className="width-20">Lead Time</th>
                                        <th className="text-right">Quantity</th>
                                        <th className="text-right">Price <span className="currency">( {this.props.currency?this.props.currency:''} )</span></th>                                     
                                        <th className="text-right">Amount <span className="currency">( {this.props.currency?this.props.currency:''} )</span></th>
                                    </tr>
                                </thead>
                                <tbody className="order-view-tbody">
                                {items_tr}                                   
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pull-right text-right">
                                        <p>Total Amount : <span className="currency">( {this.props.currency?this.props.currency:''} )</span> <span className="amount">{this.props.order_data.total_amount?Humanize(this.props.order_data.total_amount):'0.00'}</span></p>
                                        {this.props.order_data.freight_charges?
                                        <p>Freight Charges (<span className="freight-type">{this.props.order_data.freight_type}</span>) :  <span className="currency">( {this.props.currency?this.props.currency:''} )</span> <span className="amount">{this.props.order_data.freight_charges?Humanize(parseFloat(this.props.order_data.freight_charges)):'0.00'}</span></p>
                                        :''}
                                        <hr/>
                                        <h5><b className="text-dark">Total Ordered Amount <span></span>:</b> <span className="currency">( {this.props.currency?this.props.currency:''} )</span> <span className="amount">{ this.props.order_data.total_ordered_amount?Humanize(parseFloat(this.props.order_data.total_ordered_amount)):'0.00'}</span></h5>
                                    </div>
                                </div>
                            </div>   
                        </div>                                 
                    </div>
            </div>

        )}
}

class Organisation extends Component{
    render(){
        if(this.props.order_data.organisation){
        var org_temp = this.props.order_data.fields_values.map((org_field,indx) =>{
            var fileds_value = org_field.field_value.map((value,indx)=>{
                return <li key={indx}>{ value}</li>
            })
            return org_field.field_name !='Lead Time'?<div key={indx}>
                <div className="line-with-color line-padding">
                    {org_field.field_name}
                </div>
                <div className="line-padding">
                    <ul>
                        {fileds_value}    
                    </ul>
                </div>
            </div>:''
        })
    }else{
        var org_temp=""
    }
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">                            
                            <div className="card-body">
                                {org_temp}    
                            </div>

                        </div>
                    </div>
                </div>

        )}
}

class OrderAttachments extends Component{
    render(){
    var attchements=""
    if(this.props.order_data.attachments){
        if(this.props.order_data.attachments.length > 0){
                var attchements = this.props.order_data.attachments.map((attachment,index)=>{
                    return <li key={index}>
                            <a href={attachment.signed_url[0]} target="_blank">{attachment.name}</a>
                        </li>
                })
            }
    }
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">
                                <h5 className="card-title">Order Attachments</h5>
                            <div className="card-body">
                                {attchements?
                                <ul className="item-attachments">
                                    { attchements}
                                </ul>:<p className="no-mrg-btm text-center">You have no Order Attachments.</p>
                                }
                                        
                            </div>

                        </div>
                    </div>
                </div>

        )
    }
}
class ItemFiles extends Component{
    render(){
    var file_attchements =''   
    if(this.props.order_data.item_files){
        if(this.props.order_data.item_files.length > 0){                
                var file_attchements = this.props.order_data.item_files.map((attachment,index)=>{
                    return <li key={index}>
                            <a href={attachment.signed_url[0]} target="_blank">{attachment.name}</a>
                        </li>
                })
            }
    }
        return(
            <div className="row">
                    <div className="col-md-12">
                        <div className="card pdd-15">
                                <h5 className="card-title">DOCUMENTS</h5>
                            <div className="card-body">
                                {file_attchements?
                                <ul className="item-attachments">
                                    {file_attchements}      
                                </ul>:<p className="no-mrg-btm text-center">You have no Documents.</p>
                                }
                                        
                            </div>

                        </div>
                    </div>
                </div>

        )
    }
}

export default class OrderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderData: {},
            currency:'',
            addresses:[],
            show_add_modal:false,
            change_add_modal:false,
            shipping_address:{},
            billing_address:{},
            all_billing_addresses:[],
            all_shipping_addresses:[],
            cust_detail_edit:false,
            countries:countries,
            add_type:'',
            order_status:'',
            formControls: {
                id:{value:''},
                add_index:{value:''},
                add_type: {value: ''}, add1: {value: ''},
                add2: {value: '' }, city: {value: '' },
                state: {value: '' }, country: {value: '' },
                zip_code: {value: '' }               
            },
        };
        
    }
    componentDidMount(){ 
        this.getOrderData()
    }

    getOrderData = () =>{
        $("#filter_loader").fadeOut("slow"); 
        $(".lds-ripple-parent-1").fadeIn();  
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(API_HOST+'/api/sales/order/'+this.props.match.params.orderId+'/'
        ).then(response => {
          if(response.status === 200){
            this.setState(()=>{
                return {
                    OrderData: response.data,
                    currency:response.data.currency.symbol,
                    order_status:response.data.status,
                    billing_address:response.data.billing_address.fields,
                    shipping_address:response.data.shipping_address.fields
                }
            })
            if(response.data.status === 'reserved'){
            axios.defaults.headers.get['Authorization'] =auth_token
            var addresses = axios.get(API_HOST+'/cp/user/address')
            addresses.then(response => {
                if(response.status === 200){ 
                    if(response.data.addresses){
                        var address_data = response.data.addresses
                    }else{
                        var address_data = []
                    }
                    var all_billing_addresses = []
                    var all_shipping_addresses = []
                    response.data.addresses.forEach(function(val){
                        if(val.add_type === '1'){
                            all_billing_addresses.push(val)
                        }else{
                            all_shipping_addresses.push(val)
                        }                              
                    })
                    this.setState(()=>{
                        return {
                            addresses: address_data,
                            all_billing_addresses:all_billing_addresses,
                            all_shipping_addresses:all_shipping_addresses
                        }
                    })   
                          
            }
            $("#filter_loader").fadeOut("slow"); 
            })                             
        }else{
           
        }
        $(".lds-ripple-parent-1").fadeOut('slow');         
        }
        }).catch(response => {  
            ErrorHandling(response)
            $("#filter_loader").fadeOut("slow"); 
        });              
    }

    convertToOrder = (event) =>{
        var button_action = $(event.currentTarget).attr('button-click')
        const auth_token = "Token "+localStorage.token
        axios.defaults.headers.post['Authorization'] =auth_token
        if($('#customer_po').val().length > 50){
            notify("Ensure Customer PO has no more than 50 characters",'error')
            return false
        }
        if($('#customer_reference_number').val().length > 50){
            notify("Ensure Customer Reference Number has no more than 50 characters",'error')
            return false
        }
        var attachment_form_data = new FormData()
        if(button_action === 'placeorder'){
            var msg = 'Are you sure you want to place the order?'
            var url = API_HOST+'/api/sales/order/'+this.props.match.params.orderId+'/reserve/to/place/order/'
            var success_msg = 'Order Placed Successfully.'                    
            var billing_address = [this.state.billing_address]
            var shipping_address = [this.state.shipping_address]
            attachment_form_data.append('billing_address',JSON.stringify(billing_address))
            attachment_form_data.append('shipping_address',JSON.stringify(shipping_address))
            attachment_form_data.append('customer_po', $('#customer_po').val())
            attachment_form_data.append('customer_reference_number', $('#customer_reference_number').val())
            attachment_form_data.append('remarks', $('#remarks').val())
        }else{
            var msg = 'Your requested quantity of stock will be unreserved. Would you like to proceed?'
            var url = API_HOST+'/api/sales/order/'+this.props.match.params.orderId+'/reserve/to/cancel/order/'
            var success_msg = 'The stock has been successfully unreserved.'
        }
        if(window.confirm(msg)){  
            $(".lds-ripple-parent-1").fadeIn();            
        axios.post(url, attachment_form_data
        ).then(response => {
          if(response.status === 200&&response.data.success){               
              notify(success_msg, 'success')                          
              setTimeout(() =>{
                  this.getOrderData()
                 //window.location.reload(false)
                // this.props.history.push('/order/view/'+this.props.match.params.orderId+'/')
                $(".lds-ripple-parent-1").fadeOut("slow");  
               },1000)
            }
        }).catch(response => {
            ErrorHandling(response)
            $(".lds-ripple-parent-1").fadeOut("slow");
        });
    }

    }

    CustomerApproveAndReject = (event) =>{
        event.preventDefault()
        const auth_token = "Token "+localStorage.token
        var order_status = $(event.currentTarget).attr('data-status')
        if(order_status === 'review_accepted_by_customer'){
            var message = 'Are you sure, you want to approve this order?'
        }else{
            var message = 'Are you sure, you want to reject this order?' 
        }
        if(window.confirm(message)){
        var order_id = $(event.currentTarget).attr('sales-order-id')
        if(order_status && order_id){
        $(".lds-ripple-parent-1").fadeIn('slow');
        axios.defaults.headers.post['Authorization'] =auth_token
        const form_data = 'sales_order_id='+order_id+'&sales_order_status='+order_status
        axios({
            method: 'post', 
            url: API_HOST+'/api/sales/order/change/status/',
            data: form_data,
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded",
            }
          }).then(function (response) {
              if(response.status === 200){
                  if(response.data.success){
                      this.getOrderData()
                  }else{
                    notify(response.data.message, 'error')
                  }
              }
          }).catch(response => {
            ErrorHandling(response)
        });
        }
        $(".lds-ripple-parent-1").fadeOut('slow');
    }
    }            
    
    AddressEditHandler = (event) =>{
        event.preventDefault()
        var address_type = event.target.getAttribute('add-type')
        var index =event.target.getAttribute('this_index')
        if(address_type === 'billing'){
            var address_data = this.state.billing_address[parseFloat(index)]
        }else{
            var address_data = this.state.shipping_address[parseFloat(index)]
        }      

        this.setState(()=>{
            return {
                formControls: {
                  id:{value:address_data.id},
                  add_index:{value:index},
                  add_type: {value: address_type}, add1: {value:address_data.add1},
                  add2: {value:address_data.add2 }, city: {value:address_data.city},
                  state: {value:address_data.state}, country: {value:address_data.country},
                  zip_code: {value:address_data.zip_code }               
              },
              show_add_modal: true

            }
         })

    }

    handleAddressChange = (event) =>{
        event.preventDefault()
        var add_type = event.target.getAttribute('add-type')
        $('#change-address-dialog').modal('hide');                
        this.setState({
            change_add_modal:true,
            add_type:add_type
        })

    }

    handleHideModal = () =>{
        this.setState({
            change_add_modal:false
        })
    }

    HidenModal = () =>{
        this.setState({
            show_add_modal:false
        })  
    }

    handleChange = (event) =>{
        event.preventDefault()
        const name = event.target.name;
          const value = event.target.value;        
          this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                ...this.state.formControls[name],
                value
              }
            }
          });
    }
    customerdetailsChange = (event) => {
            event.preventDefault()
            const name = event.target.name;
              const value = event.target.value;
              this.state.OrderData[name]  = value       
              this.setState({
                OrderData:this.state.OrderData
              });
    }
        
    AddressSubmitHandler = (event) =>{
        event.preventDefault()
        var index = ''
        var add_type = ''
        function serialize(form) {
            var inputs = form.elements;
            var address_object = {};
            index = document.querySelector('input[name="index"]').value
            for(let i=0; i < inputs.length; i++) {
              if(inputs[i].name === 'index'){
              }else if(inputs[i].name === 'add_type'){
                add_type = inputs[i].value
              }
              else{
                address_object[inputs[i].name] = inputs[i].value
              }
            }
            //address_object['add_type'] = document.querySelector('input[name="add_type"]:checked').value;
            //address_object['isdefault']=document.querySelector('input[name="isdefault"]:checked').value;
            return address_object
          }
            var form = document.querySelector('#address_form')
            var form_data = serialize(form)
            if(add_type === 'billing'){
            var address_list = this.state.billing_address
            }else{
                var address_list = this.state.shipping_address
            }
            var option_str = ""
            Object.keys(form_data).forEach(function(val){
                address_list[parseFloat(index)][val] = form_data[val]
                if(val === 'id'){

                }else{
                    option_str +=  form_data[val] +','
                }                       
            })
            //$('select#'+add_type).find('option[value=' + address_list[parseFloat(index)].id + ']').text(option_str)
            //$('select#'+add_type).selectpicker('refresh')
            if(add_type === 'billing'){
                this.setState(()=>{
                    return {
                        billing_address: address_list
                    }
                })
            }else{
                this.setState(()=>{
                    return {
                        shipping_address: address_list
                    }
                })
            }
            
            $('#customer-billing-address-create-dialog').modal('hide');
        }

        SetDefaultAddress = (event) =>{
            event.preventDefault()
            var ischecked = event.currentTarget.checked
            var add_type = event.currentTarget.getAttribute('this_type')
            var this_index = event.currentTarget.getAttribute('this_index')
            if(add_type === '1'){
                var billingadd = {}
                var all_bill_add = []
                this.state.all_billing_addresses.map((address,index) =>{
                    if(index === parseFloat(this_index)){
                        address.isdefault = true
                        billingadd = address
                        all_bill_add.push(address)
                    }else{
                        address.isdefault = false
                        all_bill_add.push(address)
                    }
                })
                this.setState({
                    billing_address:billingadd,
                    all_billing_addresses:all_bill_add,
                    change_add_modal:false
                })
            }
            else if(add_type === '2'){
                var shippingadd = {}
                var all_shipp_add = []
                this.state.all_shipping_addresses.map((address,index) =>{
                    if(index === parseFloat(this_index)){
                        address.isdefault = true
                        shippingadd = address
                        all_shipp_add.push(address)
                    }else{
                        address.isdefault = false
                        all_shipp_add.push(address)
                    }
                })
                this.setState({
                    shipping_address:shippingadd,
                    all_shipping_addresses:all_shipp_add,
                    change_add_modal:false
                })
            }
            $('#change-address-dialog').modal('hide');

        } 

        componentDidUpdate(){
            if(this.props.location.page){
                if(this.props.location.page.load){
                    this.props.location.page = {load:false}            
                    $('.search_input').val('')                    
                    this.getOrderData()
                }
            }
        }
    
    render() {                
        return (
            <div className="container-fluid order-view-container" >
                <div className="inline-loader" id="filter_loader"> 
                    <SubLoader/>
                </div> 
                <div id="order-view">
                    <OrderDetails order_data={this.state.OrderData} CustomerApproveAndReject={this.CustomerApproveAndReject} convertToOrder={this.convertToOrder} />
                    {this.state.order_status === 'reserved'?
                    <CustomerAddress order_data={this.state.OrderData} 
                    AddressEditHandler={this.AddressEditHandler} 
                    billing_add={this.state.billing_address} 
                    shipping_add={this.state.shipping_address}
                    handleAddressChange={this.handleAddressChange}
                    customerdetailsChange = {this.customerdetailsChange}
                    />
                    :
                    <Customer_Address order_data={this.state.OrderData}  addresses={this.state.addresses}/> 
                    }
                    {this.state.show_add_modal ? <AddressModal HidenModal={this.HidenModal} AddressSubmitHandler={this.AddressSubmitHandler} addressData={this.state.formControls} onchange={this.handleChange} countries={this.state.countries} />
                    : null}
                    {this.state.change_add_modal?<ChangeDefaultAddress  handleHideModal={this.handleHideModal} add_type={this.state.add_type} billing_add={this.state.all_billing_addresses} shipping_add={this.state.all_shipping_addresses} SetDefaultAddress={this.SetDefaultAddress}/>:null}
                    <SSIDetails order_data={this.state.OrderData} />
                    <OrderItems order_data={this.state.OrderData} currency={this.state.currency} />
                    <OrderAttachments order_data={this.state.OrderData}/>
                    <ItemFiles order_data={this.state.OrderData}/>
                    <Organisation order_data={this.state.OrderData} />                    
                </div>
            </div>
         
        )
    }
}
